import { useEffect, useState } from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import CurrencyComp from '../../../components/currencyComponent/CurrencyComp';
import DateTimeComp from '../../../components/dateComponent/DateTimeComp';
import { LoadingComponent } from '../../../components/loadingComponent/LoadingComponent';
import NoRecordFound from '../../../components/noRecord/NoRecordFound';
import { useGetAllDraftInvoicesQuery } from '../../../services/InvoiceDataDraftApi';
import labelKey from '../../localization/label.json';
import { FaCheck, FaExclamation, FaTicket } from 'react-icons/fa6';
import { Spinner } from 'react-bootstrap';
import { FaExclamationTriangle } from 'react-icons/fa';
import { KTSVG } from '../../../_metronic/helpers';
interface LightboxData {
    imageURL: string;
}
const Invoices = () => {
    const [lightboxOpen, setLightboxOpen] = useState(false);
    const [lightboxData, setLightboxData] = useState<LightboxData>({ imageURL: '' });
    const { data, isLoading, refetch } = useGetAllDraftInvoicesQuery('')
    useEffect(() => {
        refetch();
    }, [refetch]);

    const openPdfInBrowserOrLightbox = (attachmentFile: string | undefined) => {
        if (attachmentFile) {
            // For image files, open in the Lightbox
            setLightboxData({ imageURL: attachmentFile });
            setLightboxOpen(true);
        }
    };
    return (
        <>
            <div className='card mb-5'>

                {lightboxOpen && (
                    <Lightbox
                        mainSrc={lightboxData.imageURL}
                        onCloseRequest={() => setLightboxOpen(false)}
                        imageCaption="Attachment"
                        enableZoom={true}
                        imagePadding={50}
                    />
                )}
                <div className='card-body py-8'>
                    <div className="row" style={{ minHeight: "80vh" }}>
                        {!isLoading &&
                            <>
                                {data?.result?.length > 0 ? (
                                    <>
                                        {data?.result?.map((data: any, index: any) => (
                                            <div className="col-md-6 col-lg-4 col-xl-3 mt-4">
                                                <div className="card card-xl-stretch mb-xl-8" style={{ border: "1px solid #cccccc" }}>
                                                    <div className='position-absolute my-3 mx-3' style={{ right: 0 }}>
                                                        {data.ocrReadStatus === 0 ?
                                                            <span className="btn btn-sm btn-primary fs-7 fw-semibold w-max-content">{labelKey.processing} <Spinner animation="border" className='ms-2 w-10px h-10px' /> </span>
                                                            : data.ocrReadStatus === 1 ?
                                                                <span className="btn btn-sm btn-success fs-7 fw-semibold w-max-content">{labelKey.processed} <FaCheck className='ms-2' /> </span>
                                                                : data.ocrReadStatus === 2 ?
                                                                    <span className="btn btn-sm btn-danger fs-7 fw-semibold w-max-content">{labelKey.failed} <FaExclamationTriangle className='ms-2' /> </span>
                                                                    : null
                                                        }
                                                    </div>
                                                    <img className="card-img-top w-100 cursor-pointer" src={data?.fileURL}
                                                    height={450}
                                                        onClick={() => openPdfInBrowserOrLightbox(data?.fileURL)}
                                                        alt="Card image cap" />
                                                    <div className="card-body">
                                                        <h5 className='card-title'>{labelKey.amount}: <CurrencyComp amount={data?.amount} /></h5>
                                                        <h5 className="card-title text-nowrap ">{labelKey.dateAndTime}:
                                                            {data.ocrReadStatus !== 0 &&
                                                                <span className='ms-2'>
                                                                    <DateTimeComp formattedDate={data?.invoiceDate} />
                                                                </span>
                                                            }
                                                        </h5>
                                                        <h5 className="card-title">{labelKey.vendor}: {data?.vendorName}</h5>
                                                        <h5 className="card-title">{labelKey.uploadedFrom}:
                                                            <KTSVG path="/media/icons/figmaIcons/Mobile.svg" className='svg-icon-1 ms-3'
                                                                data-tooltip-id="mobile-source" data-tooltip-content='Mobile' />
                                                        </h5>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </>
                                ) :
                                    <NoRecordFound />
                                }
                            </>
                        }
                        {isLoading && (
                            <LoadingComponent />
                        )}
                    </div>
                </div>
            </div >
        </>
    )
}

export default Invoices