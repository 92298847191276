/* eslint-disable jsx-a11y/anchor-is-valid */
import labelKey from "../../../../localization/label.json";
import CurrencyComp from "../../../../../components/currencyComponent/CurrencyComp";
import { useAllManagersAndAdminsQuery } from "../../../../../services/authApi";
import { useGetApproversPolicyModeLookupQuery } from "../../../../../services/GeneralApi";

type Props = {
  formik: any
}

const Step5 = ({ formik }: Props) => {
  const workflowApprover = formik.values.workflowApprover;
  const { data: allManagersAndAdmin } = useAllManagersAndAdminsQuery('')
  const { data: policyMode } = useGetApproversPolicyModeLookupQuery('')
  return (
    <>
      <div data-kt-stepper-element='content' className="overflow-auto" style={{ height: "51vh" }}>
        <div className='w-100 text-center'>
          {/* begin::Heading */}
          <h1 className='fw-bold text-gray-800 mb-3'>{labelKey.review}!</h1>
          {/* end::Heading */}
          <div className="row m-0">
            <div className="col-md-12">
              <div className="d-flex gap-2 align-items-center">
                <label className="text-start d-flex fs-6 fw-bold">{labelKey.workflowName}:</label>
                <p className="mb-0">{formik.values.workflowName}</p>
              </div>
            </div>
            <div className="col-md-12">
              <div className="d-flex gap-2 align-items-center">
                <label className="text-start d-flex fs-6 fw-bold">{labelKey.workflowPolicy}:</label>
                <select
                  disabled
                  className='border-0 bg-transparent form-control text-start p-0 w-auto text-dark'
                  value={formik?.values?.workflowPolicyType?.id}
                >
                  {policyMode?.result.map((option: any, index: any) => (
                    <option key={index} value={option?.id}>{option?.value}</option>
                  ))}
                </select>
              </div>
            </div>
            {String(formik.values?.workflowPolicyType?.id) !== '1' && String(formik.values?.workflowPolicyType?.id) !== '3' &&
              <div className="col-md-12 mt-4">
                <div className='table-responsive'>
                  <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                    <thead>
                      <tr>
                        <th className="text-start ps-4 rounded-start">{labelKey.level}</th>
                        <th className="text-start text-nowrap">{labelKey.maximumAmount}</th>
                        <th className="text-start rounded-end">{labelKey.approvers}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {workflowApprover?.map((item: any, index: any) => {
                        return (
                          <tr key={index}>
                            <td className="text-start ps-4 ">{item.sequence}</td>
                            <td className="text-start">
                              <CurrencyComp amount={item?.maximumAmount} />
                            </td>
                            <td className="text-start">
                              <select
                                disabled
                                className='border-0 bg-transparent form-control text-start p-0 w-auto text-dark'
                                value={item?.userAccount?.orgUserID}
                              >
                                {allManagersAndAdmin?.result.map((option: any, index: any) => (
                                  <option key={index} value={option?.id}>{option?.value}</option>
                                ))}
                              </select>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            }
          </div>
        </div>
      </div>
    </>
  )
}

export { Step5 };

