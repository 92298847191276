import axios from 'axios';
import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { KTSVG } from '../../../_metronic/helpers';
import CurrencyComp from '../../../components/currencyComponent/CurrencyComp';
import { selectAuth } from '../../../features/authSlice';
import { useAppSelector } from '../../../hooks';
import { API_END_POINTS } from '../../../services/apiEndpoints';
import labelKey from "../../localization/label.json";
import NoRecordFound from '../../../components/noRecord/NoRecordFound';
import { LoadingComponent } from '../../../components/loadingComponent/LoadingComponent';
type Props = {
  show: boolean
  handleClose: () => void
  data: any
}
const WorkFlowDetailModal = ({ show, handleClose, data }: Props) => {

  const { token, baseURL } = useAppSelector(selectAuth);
  const [detailData, setDetailData] = useState<any>(null); // Initialize with null
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      if (show && data) {
        setIsLoading(true); // Set loading state to true
        try {
          const headers = {
            Authorization: `Bearer ${token}`,
          };
          const getWorkflowDetailResponse = await axios.get(
            `${baseURL}/api/Workflow${API_END_POINTS.getWorkflowByWorkflowId}?workflowId=${data}`,
            {
              headers,
            }
          );
          const detailData = getWorkflowDetailResponse.data;
          setDetailData(detailData);
        } catch (error) {
          console.error('Error fetching data:', error);
        } finally {
          setIsLoading(false); // Set loading state to false after fetching data
        }
      }
    };
    fetchData();
  }, [show, data, token]);

  return (
    <>
      <Modal
        aria-hidden='true'
        dialogClassName='modal-dialog modal-lg modal-dialog-centered'
        show={show}
        onHide={handleClose}
      >
        <div className='modal-header py-4'>
          <h2 className='mb-0'>{labelKey.workflowDetail}</h2>
          <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
            <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
          </div>
        </div>

        <div className='modal-body py-lg-10 px-lg-10'>
          <div className="row">
            <div className="col-md-9">
              <div>
                <p className='fw-bold mb-0'>{labelKey.workflowName}: <span className='fw-semibold text-capitalize'>{detailData?.result?.workflowName}</span></p>

                <p className='fw-bold mb-0'>{labelKey.workflowPolicy}: <span className='fw-semibold'>{detailData?.result?.workflowPolicyType?.value}</span></p>
              </div>
            </div>

            <div className="col-md-3">
              <p className='fw-bold'>{labelKey.status}: {detailData?.result?.status === true ?
                <span className='badge badge-light-success fs-7 fw-semibold'>{labelKey.active}</span> :
                <span className='badge badge-light-danger fs-7 fw-semibold'>{labelKey.inActive}</span>} </p>

            </div>
            <div className="col-md-12 mt-5">
              <div className='table-responsive mh-400px'>
                <table className='table bg-light border border-2 rounded table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                  <thead>
                    <tr className='fw-bold text-muted'>
                      <th className='w-100px ps-4'>{labelKey.level}</th>
                      <th>{labelKey.approvers}</th>
                      <th className='min-w-120px'>{labelKey.maximumAmount}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {!isLoading &&
                      <>
                        {detailData?.result?.workflowApprover?.length > 0 ? (
                          <>
                            {detailData?.result?.workflowApprover?.map((approverData: any, index: any) => (
                              <>
                                <tr key={index}>
                                  <td className='ps-5'>{approverData?.sequence} </td>
                                  <td>
                                    <div className='d-flex align-items-center'>
                                      <div className='symbol symbol-50px me-5'>
                                        <div className="symbol-label fs-3 bg-light-info text-info text-capitalize"
                                        >
                                          {approverData?.userAccount?.firstName?.charAt(0)}
                                        </div>

                                      </div>
                                      <div className='d-flex justify-content-start flex-column'>
                                        <p className='text-dark fw-bold mb-1 fs-6 text-capitalize'>
                                          {approverData?.userAccount?.firstName} {approverData?.userAccount?.lastName}
                                        </p>
                                        <span className='text-muted fw-semibold text-muted d-block fs-7 text-lowercase'>
                                          {approverData?.userAccount?.email}
                                        </span>
                                      </div>
                                    </div>
                                  </td>
                                  <td><CurrencyComp amount={approverData?.maximumAmount} /></td>
                                </tr>
                              </>
                            ))}
                          </>
                        ) : (
                          <tr>
                            <td colSpan={8}>
                              <NoRecordFound />
                            </td>
                          </tr>
                        )}
                      </>
                    }

                    {isLoading && (
                      <tr>
                        <td colSpan={8} className="text-center">
                          <LoadingComponent />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className='modal-footer py-4'>
          <button className='btn btn-light-primary' onClick={handleClose}>{labelKey.close}</button>
        </div>
      </Modal>
    </>
  )
}

export default WorkFlowDetailModal