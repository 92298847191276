import ReactApexChart from 'react-apexcharts';

const AdminUsersChart = ({ getAdminDashboard }: any) => {

  // Extract data from the API response
  const allUser = getAdminDashboard?.result?.allUser || 0;
  const activeUser = getAdminDashboard?.result?.activeUser || 0;

  // Chart options
  const pieChartOptions: ApexCharts.ApexOptions = {
    chart: {
      type: 'donut' as 'donut',
    },
    labels: ['Active Users', 'In Active Users'],
    colors: ['#00945F','#0A47E6' ],
    legend: {
      show: true,
      position: 'bottom',
      horizontalAlign: 'center',
      offsetX: 25,
      offsetY: 0,
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 250,
          },
          legend: {
            position: 'bottom',
            horizontalAlign: 'center',
            offsetX: 15,
            offsetY: 0,
          },
        },
      },
    ],
    tooltip: {
      y: {
        formatter: function (value: any) {
          return value.toLocaleString('en-US');
        },
      },
    },
  };
  const inActiveUsersPercentage = (allUser - activeUser);
  // Chart series data
  const pieChartSeries = [activeUser, inActiveUsersPercentage];

  return (
    <div className='d-flex justify-content-center w-100'>
      {allUser === 0 && activeUser === 0 ? (
        <p className='mt-15'>Chart does not have any values to display.</p>
      ) : (
        <div className='legend-center'>
          <ReactApexChart options={pieChartOptions} series={pieChartSeries} type="donut" height={300} />
        </div>
      )}
    </div>
  )
}

export default AdminUsersChart