import axios from 'axios'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { KTSVG } from '../../../_metronic/helpers'
import { selectAuth } from '../../../features/authSlice'
import { useAppSelector } from '../../../hooks'
import labelKey from "../../localization/label.json"
import { baseUrl } from '../../../services/baseUrl'
import { API_END_POINTS } from '../../../services/apiEndpoints'
import DateComp from '../../../components/dateComponent/DateComp'
import DateTimeComp from '../../../components/dateComponent/DateTimeComp'
import NoRecordFound from '../../../components/noRecord/NoRecordFound'
import { LoadingComponent } from '../../../components/loadingComponent/LoadingComponent'

type Props = {
    show: boolean
    handleClose: () => void
    data: any
}
const ExpenseLogModal = ({ show, handleClose, data, }: Props) => {

    const { token, baseURL } = useAppSelector(selectAuth);
    const [getExpenseLog, setGetExpenseLog] = useState<any>(null); // Initialize with null
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        const fetchData = async () => {
            if (show && data) {
                setIsLoading(true); // Set loading state to true
                try {
                    const headers = {
                        Authorization: `Bearer ${token}`,
                    };
                    const getExpenseLogResponse = await axios.get(
                        `${baseURL}/api/Expense${API_END_POINTS.expenseLog}?expenseId=${data}`,
                        {
                            headers,
                        }
                    );
                    const getExpenseLog = getExpenseLogResponse.data;
                    setGetExpenseLog(getExpenseLog);
                } catch (error) {
                    console.error('Error fetching data:', error);
                } finally {
                    setIsLoading(false); // Set loading state to false after fetching data
                }
            }
        };
        fetchData();
    }, [show, data, token]);
    return (
        <>
            <Modal
                aria-hidden='true'
                dialogClassName='modal-dialog modal-xl modal-dialog-centered'
                show={show}
                onHide={handleClose}
            >
                <div className='modal-header py-4'>
                    <h2 className='mb-0'>{labelKey.expenseLogs}</h2>
                    <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
                        <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                    </div>
                </div>

                <div className='modal-body py-lg-5 px-lg-10'>
                    <div className='row'>
                        <div className='table-responsive'>
                            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                                <thead>
                                    <tr>
                                        {/* <th className='ps-4 rounded-start'>{labelKey.sr}</th> */}
                                        <th className='ps-4 rounded-start'>{labelKey.approver}</th>
                                        <th className='w-300'>{labelKey.commentByApprovers}</th>
                                        <th className='w-300'>{labelKey.commentByUsers}</th>
                                        <th>{labelKey.status}</th>
                                        <th className='rounded-end text-end pe-4'>{labelKey.activityDateAndTime}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {!isLoading && (<>
                                        {getExpenseLog?.result?.length > 0 ?
                                            <>
                                                {getExpenseLog?.result?.toReversed()?.map((data: any, index: any) => (
                                                    <tr key={index}>
                                                        {/* <td className='ps-4'>{index + 1}</td> */}
                                                        <td className='ps-4'>
                                                            <div className="d-flex align-items-center">
                                                                <div className="symbol symbol-circle symbol-50px overflow-hidden me-3">
                                                                    {data?.approver?.imageUrl ? <img src={data?.approver?.imageUrl} alt="" /> :
                                                                        <div className="symbol-label fs-3 bg-light-info text-info text-capitalize">{data?.approver?.firstName.charAt(0)}</div>
                                                                    }
                                                                </div>
                                                                <div className="d-flex flex-column">
                                                                    <p className="text-gray-800 fw-bold mb-1 text-capitalize">{data?.approver?.firstName} {data?.approver?.middleName} {data?.approver?.lastName}</p>
                                                                    <span className='text-lowercase'>{data?.approver?.email}</span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className='w-300 text-wrap'>{data?.commentByApprover ? data?.commentByApprover : "N/A"}</td>
                                                        <td className='w-300 text-wrap'>{data?.commentByUsers ? data?.commentByUsers : "N/A"}</td>
                                                        <td>
                                                            {data?.status?.title == "Pending" ?
                                                                <span className="badge badge-light-primary fs-7 fw-semibold">{labelKey.pending}</span> :
                                                                data?.status?.title == "Clarification" ?
                                                                    <span className="badge badge-light-warning fs-7 fw-semibold">{labelKey.clarification}</span> :
                                                                    data?.status?.title == "Approved" ?
                                                                        <span className="badge badge-light-success fs-7 fw-semibold">{labelKey.approved}</span> :
                                                                        data?.status?.title == "Rejected" ?
                                                                            <span className="badge badge-light-danger fs-7 fw-semibold">{labelKey.rejected}</span> :
                                                                            data?.status?.title == "AP Approved" ?
                                                                                <span className="badge badge-light-success fs-7 fw-semibold">{labelKey.accountsPayable}</span> : ""
                                                            }
                                                        </td>
                                                        <td className='text-end pe-4'>
                                                            <DateTimeComp formattedDate={data?.approvedDate} />
                                                        </td>
                                                    </tr>
                                                ))}
                                            </>
                                            :
                                            <tr>
                                                <td colSpan={5} className='text-center'>
                                                    <NoRecordFound />
                                                </td>
                                            </tr>
                                        }
                                    </>)}
                                    {isLoading && (
                                        <tr>
                                            <td colSpan={5} className="text-center">
                                                <LoadingComponent />
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>
                <div className='modal-footer py-4'>
                    <button className='btn btn-light-primary' onClick={handleClose}>{labelKey.close}</button>
                </div>

            </Modal>
        </>
    )
}

export default ExpenseLogModal