import { PageTitle } from '../../../../_metronic/layout/core'
import labelKey from '../../../localization/label.json'
import ExpenseTypeCodePage from './ExpenseTypeCodePage'

const ExpenseTypeCodeWrapper = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>{labelKey.expenseTypeCode}</PageTitle>
      <ExpenseTypeCodePage />
    </>
  )
}

export default ExpenseTypeCodeWrapper