import type {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react';
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { selectAuth } from "../features/authSlice";
import { RootState } from "../store";
import { API_END_POINTS } from './apiEndpoints';
import { baseUrl } from './baseUrl';

const rawBaseQuery = fetchBaseQuery({
  baseUrl: baseUrl.MAIN_BASE_URL,
})

type ExtraOptions = {
  headers?: Record<string, string>;
  // Define other optional properties here if needed
};

const dynamicBaseQuery: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError,
  ExtraOptions
> = async (args, api, extraOptions) => {
  const orgBaseUrl = selectAuth(api.getState() as RootState).baseURL;
  // gracefully handle scenarios where data to generate the URL is missing
  if (!orgBaseUrl) {
      return {
          error: {
              status: 400,
              statusText: 'Bad Request',
              data: 'dynamic route not found',
          },
      }
  }

  const urlEnd = typeof args === 'string' ? args : args.url

  // construct a dynamically generated portion of the url
  const adjustedUrl = `${orgBaseUrl}/api/Organization${urlEnd}`
  const adjustedArgs = typeof args === 'string' ? { url: adjustedUrl } : { ...args, url: adjustedUrl };
  // Get the token from localStorage
  const token = localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("user")!).token
      : null;
  // Set headers if token is available
  const headers = token ? { Authorization: `Bearer ${token}` } : {};
  // Merge headers with existing headers
  const mergedHeaders = { ...headers, ...extraOptions?.headers };
  // provide the amended url and other params to the raw base query
  return rawBaseQuery({ ...adjustedArgs, headers: mergedHeaders }, api, extraOptions)

}



export const organizationApi = createApi({
  reducerPath: 'organizationApi',
  baseQuery:dynamicBaseQuery,
  // baseQuery: fetchBaseQuery({
  //   baseUrl: baseUrl.BASE_URL + `/api/Organization/`,
  //   prepareHeaders: (headers) => {
  //     // Get the token from localStorage
  //     const token = localStorage.getItem("user")
  //       ? JSON.parse(localStorage.getItem("user")!).token
  //       : null;
  //     if (token) {
  //       headers.set('Authorization', `Bearer ${token}`);
  //     }

  //     return headers;
  //   },
  // }),
  tagTypes: ['Organization'],
  endpoints: (builder) => ({
    getAllOrganization: builder.query({
      query: ({ pageSize, pageNumber, sortBy, sortOrder, searchStr, filterByStatus, filterByOnBoardStatus }) => {
        return {
          url: `${API_END_POINTS.getAllOrganization}?pageSize=${pageSize}&pageNumber=${pageNumber}&sortBy=${sortBy}&sortOrder=${sortOrder}&searchStr=${searchStr}&filterByStatus=${filterByStatus}&filterByOnBoardStatus=${filterByOnBoardStatus}`,
          method: "GET"
        }
      },
      providesTags: ['Organization']
    }),
    getSingleOrganization: builder.query({
      query: (id) => {
        return {
          url: API_END_POINTS.getOrganizationDetailByID,
          method: "GET",
          params: { id }
        }
      },
      providesTags: ['Organization']
    }),
    createOrganization: builder.mutation({
      query: (body) => {
        return {
          url: API_END_POINTS.createOrganization,
          method: "POST",
          body,
        }
      },
    }),
    updateOrganization: builder.mutation({
      query: (body) => {
        return {
          url: API_END_POINTS.updateOrganization,
          method: "PUT",
          body,
        }
      },
    }),
    getOrganizationAllRoles: builder.query({
      query: () => {
        return {
          url: API_END_POINTS.getAllRoles,
          method: "GET"
        }
      },
      providesTags: ['Organization']
    }),
    createOrganizationRole: builder.mutation({
      query: body => {
        return {
          url: API_END_POINTS.createRole,
          method: "POST",
          body,
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          },
        }
      },
    }),
    updateOrganizationRole: builder.mutation({
      query: (rest) => {
        return {
          url: API_END_POINTS.updateRole,
          method: "POST",
          body: rest,
        }
      },
    }),
    createOrganizationWorkflow: builder.mutation({
      query: (body) => {
        return {
          url: API_END_POINTS.createOrganizationWorkflow,
          method: "POST",
          body,
        }
      },
    }),
    updateOrgOnboardingStatus: builder.mutation({
      query: (body) => {
        return {
          url: API_END_POINTS.updateOrganizationOnboardingStatus,
          method: "PUT",
          body,
        }
      },
    }),
  }),
})

export const {
  useGetAllOrganizationQuery,
  useGetSingleOrganizationQuery,
  useCreateOrganizationMutation,
  useUpdateOrganizationMutation,
  useGetOrganizationAllRolesQuery,
  useCreateOrganizationRoleMutation,
  useUpdateOrganizationRoleMutation,
  useCreateOrganizationWorkflowMutation,
  useUpdateOrgOnboardingStatusMutation,
} = organizationApi