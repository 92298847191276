import React from 'react'
import { PageTitle } from '../../../_metronic/layout/core'
import MileagePage from './MileagePage'
import labelKey from "../../localization/label.json"

const MileageWrapper = () => {
    return (
        <>
            <PageTitle breadcrumbs={[]}>{labelKey.mileageRate}</PageTitle>
            <MileagePage />
        </>
    )
}

export default MileageWrapper