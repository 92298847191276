/* eslint-disable jsx-a11y/anchor-is-valid */
import ReactInputMask from 'react-input-mask';
import labelKey from "../../../../localization/label.json"
import TextField from '../../../../../components/textFields/TextField';

type Props = {
  formik: any
}
const Step2 = ({ formik, }: Props) => {

  return (
    <div className='pb-5 pe-3 overflow-auto' style={{ height: "51vh" }} data-kt-stepper-element='content'>
      <div className='row w-100 h-min-content'>
        <div className='col-md-6 mb-5'>
          <TextField
            label={labelKey.personName}
            placeholder='Enter Person Name'
            type="text"
            {...formik.getFieldProps('contactPersonName')}
          />
        </div>
        <div className='col-md-6 mb-5'>
          <TextField
            label={labelKey.personEmail}
            required={true}
            type="email"
            className='form-control form-control-lg form-control-solid'
            {...formik.getFieldProps('contactPersonEmail')}
            placeholder='Enter Email'
          />
        </div>
        <div className='col-md-6 mb-5'>
          <label className='form-label fs-5 fw-bold'>{labelKey.contactNo}</label>
          <ReactInputMask
            mask="(999) 999-9999"
            maskChar=""
            type="text"
            className="form-control form-control-lg form-control-solid default-input"
            placeholder="Enter Contact No"
            {...formik.getFieldProps('contactPersonMobile')}
          />
        </div>
        <div className='col-md-6 mb-5'>
          <TextField
            label={labelKey.domainName}
            placeholder='Enter Domain Name'
            type="text"
            {...formik.getFieldProps('domainName')}
          />
        </div>
        <div className='col-md-6 mb-5'>
          <TextField
            label={labelKey.webUrl}
            placeholder='Enter Web Url'
            type="text"
            {...formik.getFieldProps('webURL')}
          />
        </div>
        <div className='col-md-6 mb-5'>
          <TextField
            label={labelKey.apiUrl}
            placeholder='Enter Api Url'
            type="text"
            {...formik.getFieldProps('apiurl')}
          />
        </div>
        <div className='col-md-6 mb-5'>
          <TextField
            label={labelKey.microServiceURL}
            placeholder='Enter Micro Service URL'
            type="text"
            {...formik.getFieldProps('microServiceURL')}
          />
        </div>
      </div>
    </div>
  )
}

export { Step2 };

