import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import Select from 'react-select';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { KTSVG } from '../../../../_metronic/helpers';
import TextField from '../../../../components/textFields/TextField';
import { useExpenseCategoryLookupQuery } from '../../../../services/GeneralApi';
import { useAddExpenseTypeCodesMutation, useUpdateExpenseTypeCodesMutation } from '../../../../services/OrgSettingsApi';
import labelKey from "../../../localization/label.json";
import tosterKey from "../../../localization/toster.json";

type Props = {
    show: boolean
    handleClose: () => void
    data?: any
}
const AddUpdateExpenseTypeCode = ({ show, handleClose, data }: Props) => {
    const [addExpenseTypeCode, { isLoading, isSuccess, isError, error }] = useAddExpenseTypeCodesMutation()
    const [updateExpenseTypeCode, { isLoading: updateIsLoading, isSuccess: updateIsSuccess, isError: updateIsError, error: updateError }] = useUpdateExpenseTypeCodesMutation()
    const { data: expenseCategory } = useExpenseCategoryLookupQuery('')


    const initialValues = {
        expenseTypeCodeId: 0,
        expenseTypeCode: '',
        expenseTypeName: '',
        expenseCategory: {
            id: 0,
            title: ''
        },
        isActive: true
    }

    const [updateId, setUpdateId] = useState(0);
    const [formValues, setFormValues] = useState(initialValues);
    // Store form values when the modal is closed
    const handleModalClose = () => {
        setFormValues(formik.values);
        handleClose();
    };
    useEffect(() => {
        // Restore form values when the modal reopens
        if (show && !data && Object.keys(formValues).length !== 0) {
            formik.setValues(formValues);
        }
        // Update form values based on data received
        if (data) {
            setUpdateId(data?.expenseTypeCodeId);
            formik.setValues({ ...data });
        } else if (!show) {
            // Reset form values only when closing and it's not an update action
            setUpdateId(0);
            formik.resetForm();
        }
    }, [show, data]);
    const validationSchema = Yup.object().shape({
        expenseTypeName: Yup.string().required('Expense Type is required'),
        expenseTypeCode: Yup.string().required('Expense Code is required'),
        expenseCategory: Yup.object().shape({
            id: Yup.number().min(1, 'Expense Category is required').required('Expense Category is required'),
        }),
    })
    const formik = useFormik({
        initialValues,
        validationSchema: validationSchema,
        onSubmit: (values, { resetForm }) => {
            if (updateId) {
                updateExpenseTypeCode(values)
            } else {
                addExpenseTypeCode(values)
            }
        }
    })
    const { resetForm } = formik;
    useEffect(() => {
        if (isSuccess) {
            toast.success(tosterKey.expenseTypeCodeCreatedSuccessfully);
            handleClose();
            resetForm()
        }
    }, [isSuccess]);
    useEffect(() => {
        if (updateIsSuccess) {
            toast.success(tosterKey.expenseTypeCodeUpdatedSuccessfully);
            handleClose();
        }
    }, [updateIsSuccess]);

    useEffect(() => {
        if (isError && error) {
            let errorMessage: string = 'Something went wrong';

            if ('data' in error && error.data && typeof error.data === 'object' && 'message' in error.data) {
                errorMessage = (error.data as { message: string }).message;
            }
            toast.error(errorMessage);
        }
    }, [isError, error]);
    useEffect(() => {
        if (updateIsError && updateError) {
            let errorMessage: string = 'Something went wrong';

            if ('data' in updateError && updateError.data && typeof updateError.data === 'object' && 'message' in updateError.data) {
                errorMessage = (updateError.data as { message: string }).message;
            }
            toast.error(errorMessage);
        }
    }, [updateIsError, updateError]);
    return (
        <>
            <Modal
                aria-hidden='true'
                dialogClassName='modal-dialog modal-md modal-dialog-centered'
                show={show}
                onHide={handleModalClose}
            >
                <div className='modal-header'>
                    <h2 className='mb-0'>{updateId ? <>{labelKey.updateExpenseTypeCode}</> : <>{labelKey.addExpenseTypeCode}</>}</h2>
                    {/* begin::Close */}
                    <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
                        <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                    </div>
                    {/* end::Close */}
                </div>

                <form
                    className='form w-100'
                    onSubmit={(e) => {
                        e.preventDefault();
                        formik.handleSubmit();
                        formik.setTouched({
                            expenseTypeCode: true,
                            expenseTypeName: true,
                            expenseCategory: {
                                id: true
                            },
                        }, true); // Set touched for all fields to trigger validation display
                    }}
                >
                    <div className='modal-body py-lg-10 px-lg-10'>
                        <div className="row">


                            <div className='col-md-12 mb-5'>
                                <TextField
                                    label={labelKey.expenseType}
                                    required={true}
                                    type='text'
                                    placeholder='Enter Expense Type'
                                    labelClass={`${formik.touched.expenseTypeName && formik.errors.expenseTypeName ? 'is-invalid' : ''}`}
                                    {...formik.getFieldProps('expenseTypeName')}
                                    value={formik.values.expenseTypeName || ''}

                                />
                                {formik.touched.expenseTypeName && formik.errors.expenseTypeName && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                            <span role='alert'>{formik.errors.expenseTypeName}</span>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className='col-md-12 mb-5'>
                                <TextField
                                    label={labelKey.expenseTypeCode}
                                    required={true}
                                    type='text'
                                    placeholder='Enter Expense Type Code'
                                    labelClass={`${formik.touched.expenseTypeCode && formik.errors.expenseTypeCode ? 'is-invalid' : ''}`}
                                    {...formik.getFieldProps('expenseTypeCode')}
                                    value={formik.values.expenseTypeCode || ''}
                                />
                                {formik.touched.expenseTypeCode && formik.errors.expenseTypeCode && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                            <span role='alert'>{formik.errors.expenseTypeCode}</span>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className='col-md-12 mb-5'>
                                <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                                    <span className='required'>{labelKey.category}</span>
                                </label>
                                <Select
                                    options={expenseCategory?.result?.map((option: any) => ({
                                        label: option?.value,
                                        value: option?.id
                                    }))}
                                    value={
                                        formik.values?.expenseCategory?.id
                                            ? {
                                                value: formik.values?.expenseCategory?.id,
                                                label: formik.values?.expenseCategory?.title || '',
                                            }
                                            : null
                                    }
                                    onChange={(selectedOption) => {
                                        formik.setFieldValue(`expenseCategory.id`, selectedOption?.value || null);
                                        formik.setFieldValue(`expenseCategory.title`, selectedOption?.label || null);
                                    }}
                                    placeholder='Select Category'
                                />
                                {formik.touched.expenseCategory?.id && formik.errors.expenseCategory?.id && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                            <span role='alert'>{formik.errors.expenseCategory?.id}</span>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className='modal-footer justify-content-center'>
                        <span className='btn btn-light' onClick={handleClose}>{labelKey.cancel}</span>
                        <button
                            type='submit'
                            className='btn btn-primary mb-5 mt-5'
                            disabled={isLoading}
                        >
                            {!isLoading && !updateIsLoading && <span className='indicator-label'>{updateId ? <>{labelKey.update}</> : <>{labelKey.submit}</>}</span>}

                            {updateId ?
                                <>
                                    {updateIsLoading && (
                                        <span className='indicator-progress' style={{ display: 'block' }}>
                                            {labelKey.pleaseWait}...
                                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                        </span>
                                    )}
                                </> :
                                <>
                                    {isLoading && (
                                        <span className='indicator-progress' style={{ display: 'block' }}>
                                            {labelKey.pleaseWait}...
                                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                        </span>
                                    )}
                                </>
                            }


                        </button>
                    </div>
                </form>
            </Modal>
        </>
    )
}

export default AddUpdateExpenseTypeCode