import moment from 'moment';
import { useState } from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { useParams } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import { KTSVG } from '../../../_metronic/helpers';
import { PageTitle } from '../../../_metronic/layout/core';
import CurrencyComp from '../../../components/currencyComponent/CurrencyComp';
import DateComp from '../../../components/dateComponent/DateComp';
import { useGetExpenseForReportQuery, useGetExpenseItemByExpenseIdQuery } from '../../../services/ExpenseApi';
import labelKey from "../../localization/label.json";
import ExpenseDetailModal from './ExpenseDetailModal';
import ExpenseLogModal from './ExpenseLogModal';
import { constraintConfig } from '../../../constraintConfig';
import { FaHistory } from 'react-icons/fa';
import { FaEye } from 'react-icons/fa6';
import { LoadingComponent } from '../../../components/loadingComponent/LoadingComponent';
import NoRecordFound from '../../../components/noRecord/NoRecordFound';
import CategoriesWithIcons from './CategoriesWithIcons';

interface LightboxData {
    imageURL: string;
}
const ExpenseItemDetail = () => {
    const [lightboxOpen, setLightboxOpen] = useState(false);
    const [lightboxData, setLightboxData] = useState<LightboxData>({ imageURL: '' });
    const { expenseId } = useParams();
    const { data, refetch, isLoading } = useGetExpenseForReportQuery({
        expenseId: expenseId
    })
    const { data: expenseItemData, refetch: expenseItemRefetch, isLoading: expenseItemDataIsLoading } = useGetExpenseItemByExpenseIdQuery({
        ExpenseId: expenseId,
    })
    const [showInnerTable, setShowInnerTable] = useState(null);
    const handleRowClick = (rowId: any) => {
        if (showInnerTable === rowId) {
            setShowInnerTable(null);
        } else {
            setShowInnerTable(rowId);
        }
    };

    const [showExpenseLogModal, setShowExpenseLogModal] = useState(false)
    const [expenseLogData, setExpenseLogData] = useState({});
    const expenseLogHandler = () => {
        setShowExpenseLogModal((prev) => !prev);
    };
    const [showExpenseDetailModal, setShowExpenseDetailModal] = useState(false)
    const [expenseDetailData, setExpenseDetailData] = useState({});

    const expenseDetailHandler = () => {
        setShowExpenseDetailModal((prev) => !prev);
    };
    const isPdfFile = (filePath: string | undefined) => {
        if (!filePath) return false;
        const fileExtension = filePath.toLowerCase().split('.').pop();
        return fileExtension === 'pdf';
    };
    const openPdfInBrowserOrLightbox = (attachmentFile: string | undefined) => {
        if (attachmentFile) {
            if (isPdfFile(attachmentFile)) {
                window.open(attachmentFile, '_blank');
            } else {
                setLightboxData({ imageURL: attachmentFile });
                setLightboxOpen(true);
            }
        }
    };
    return (
        <>
            <ExpenseDetailModal show={showExpenseDetailModal} handleClose={() => setShowExpenseDetailModal(false)} data={expenseDetailData} expenseItems={expenseItemData?.result} />
            <ExpenseLogModal show={showExpenseLogModal} handleClose={() => setShowExpenseLogModal(false)} data={expenseLogData} />
            <PageTitle breadcrumbs={[]}>Expense Detail</PageTitle>
            <Tooltip id="web-source" place="top" />
            <Tooltip id="mobile-source" place="top" />
            <Tooltip id="bank-source" place="top" />
            <div className='card mb-5'>
                <div className='card-body py-8'>
                    <div className="row w-100">
                        <div className="col-md-12">
                            <div className='table-responsive table-height'>
                                <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                                    <thead>
                                        <tr className='fw-bold text-muted'>
                                            <th className='ps-4 rounded-start'>{labelKey.reportId}</th>
                                            <th>{labelKey.type}</th>
                                            <th>{labelKey.title}</th>
                                            <th className='w-200px'> {labelKey.description}</th>
                                            <th>{labelKey.totalAmount}</th>
                                            <th>{labelKey.date}</th>
                                            <th>{labelKey.status}</th>
                                            <th>{labelKey.assignTo} </th>
                                            <th>{labelKey.approvalComment}</th>
                                            <th className='text-end rounded-end pe-4'>{labelKey.action}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {!isLoading &&
                                            <>

                                                <Tooltip id="row-expand" place="bottom" />
                                                <tr
                                                    data-tooltip-id="row-expand" data-tooltip-content={labelKey.clickHereToExpand}
                                                    className='cursor-pointer' onClick={() => {
                                                        handleRowClick(data?.result?.expenseId);
                                                    }}>
                                                    <td className='ps-5'>{data?.result?.trackingId}</td>
                                                    <td>{data?.result?.expenseType?.title}</td>
                                                    <td>{data?.result?.title}</td>
                                                    <td>{data?.result?.expenseDetail?.length > 60 ? `${data?.result?.expenseDetail.substring(0, 40)}...` : data?.result?.expenseDetail}</td>
                                                    <td><CurrencyComp amount={data?.result?.amount} /></td>
                                                    <td><DateComp formattedDate={data?.result?.reportDate} /></td>
                                                    <td>
                                                        {data?.result?.expenseStatus?.id === constraintConfig.expenseStatus.drafted ?
                                                            <span className="badge badge-light-primary fs-7 fw-semibold">{labelKey.draft}</span> :
                                                            data?.result?.expenseStatus?.id === constraintConfig.expenseStatus.pendingForApproval ?
                                                                <span className="badge badge-light-info fs-7 fw-semibold">{labelKey.pendingApproval}</span> :
                                                                // data?.result?.expenseStatus?.id === constraintConfig.expenseStatus.clarification ?
                                                                //     <span className="badge badge-light-warning fs-7 fw-semibold">{labelKey.clarification}</span> :
                                                                data?.result?.expenseStatus?.id === constraintConfig.expenseStatus.approved ?
                                                                    <span className="badge badge-light-success fs-7 fw-semibold">{labelKey.approved}</span> :
                                                                    data?.result?.expenseStatus?.id === constraintConfig.expenseStatus.rejected ?
                                                                        <span className="badge badge-light-danger fs-7 fw-semibold">{labelKey.rejected}</span> : ""
                                                        }
                                                    </td>
                                                    <td>
                                                        {data?.result?.expenseStatus?.id === constraintConfig.expenseStatus.pendingForApproval ? (
                                                            <div className='d-flex align-items-center'>
                                                                <div className='symbol symbol-40px symbol-circle me-3'>
                                                                    {data?.result?.manager?.imageUrl ? <img src={data?.result?.manager?.imageUrl} alt="" /> :
                                                                        <div className="symbol-label fs-3 bg-light-info text-info text-capitalize">{data?.result?.manager?.firstName?.charAt(0)}</div>
                                                                    }
                                                                </div>
                                                                <div className='d-flex justify-content-start flex-column'>
                                                                    <p className='text-capitalize mb-0'>
                                                                        {data?.result?.manager?.firstName}  {data?.result?.manager?.middleName} {data?.result?.manager?.lastName}
                                                                    </p>
                                                                    <span className='text-muted fw-semibold text-muted text-lowercase d-block fs-7'>
                                                                        {data?.result?.manager?.email}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        ) : "Not Assigned"}
                                                    </td>
                                                    <td>{data?.result?.comment && data?.result?.comment.length > 20 ? `${data?.result?.comment.substring(0, 20)}...` : data?.result?.comment}</td>
                                                    <td className='text-end' >
                                                        <div className='d-flex align-items-center justify-content-end gap-2' >
                                                            <Tooltip id="expense-log" place="bottom" />
                                                            <div
                                                                data-tooltip-id="expense-log" data-tooltip-content={labelKey.expenseLogs}
                                                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                                                                onClick={() => {
                                                                    setExpenseLogData(data?.result?.expenseId);
                                                                    expenseLogHandler();
                                                                }}
                                                            >
                                                                <FaHistory className='text-dark text-hover-primary' />
                                                            </div>
                                                            <Tooltip id="expense-detail" place="bottom" />
                                                            <div
                                                                data-tooltip-id="expense-detail" data-tooltip-content={labelKey.expenseDetail}
                                                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                                                                onClick={() => {
                                                                    setExpenseDetailData(data?.result);
                                                                    expenseDetailHandler();
                                                                }}
                                                            >
                                                                <KTSVG
                                                                    path='/media/icons/duotune/arrows/arr064.svg'
                                                                    className='svg-icon-3'
                                                                />
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                {showInnerTable === data?.result?.expenseId && (
                                                    <tr className="inner-row">
                                                        <td className='pt-0' colSpan={10}>
                                                            <div className="table-responsive">
                                                                <table className="table align-middle gs-0 gy-4">
                                                                    <thead>
                                                                        <tr className='fw-bold text-muted bg-light'>
                                                                            <th className='ps-4'>{labelKey.itemDate}</th>
                                                                            <th>{labelKey.category}</th>
                                                                            <th className='w-100px text-end pe-15'>{labelKey.amount}</th>
                                                                            <th className='w-150px pe-15'>{labelKey.reimbursableDistance}</th>
                                                                            <th>{labelKey.vendor}</th>
                                                                            <th>{labelKey.paymentMethod}</th>
                                                                            <th>{labelKey.attachment}</th>
                                                                            <th>{labelKey.description}</th>
                                                                            <th>{labelKey.source}</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {!expenseItemDataIsLoading && <>
                                                                            {expenseItemData?.result?.length > 0 ? <>
                                                                                {expenseItemData?.result?.map((itemData: any, index: any) => (
                                                                                    <tr key={itemData.expenseId}>
                                                                                        <td className='ps-4'><DateComp formattedDate={itemData.itemDate} /></td>
                                                                                        <td className='ps-5'>
                                                                                            <CategoriesWithIcons itemData={itemData} />
                                                                                        </td>
                                                                                        <td className='text-end pe-15'>
                                                                                            <CurrencyComp amount={itemData?.amount} />
                                                                                        </td>
                                                                                        <td className='text-end pe-15'>
                                                                                            {itemData?.expenseCategory?.id === constraintConfig.categoriesId.mileage ?
                                                                                                <>
                                                                                                    {itemData?.reimbursableDistance}
                                                                                                </>
                                                                                                : null
                                                                                            }
                                                                                        </td>
                                                                                        <td>{itemData?.vendor?.name !== " " ? itemData?.vendor?.name : "N/A"}</td>
                                                                                        <td>{itemData?.paymentMethod?.title}</td>
                                                                                        <td>
                                                                                            {itemData?.attachmentFile === "undefined" || itemData?.attachmentFile === "null" || itemData?.attachmentFile === null || itemData?.attachmentFile === "" ?
                                                                                                <span className='ps-9'>N/A</span> :
                                                                                                <FaEye className='ms-10' onClick={() => openPdfInBrowserOrLightbox(itemData?.attachmentFile)} />
                                                                                            }

                                                                                            {lightboxOpen && (
                                                                                                <Lightbox
                                                                                                    mainSrc={lightboxData.imageURL}
                                                                                                    onCloseRequest={() => setLightboxOpen(false)}
                                                                                                    imageCaption="Attachment"
                                                                                                    enableZoom={true}
                                                                                                    imagePadding={50}
                                                                                                />
                                                                                            )}
                                                                                        </td>
                                                                                        <td>{itemData?.itemDetail?.length > 60 ? `${itemData?.itemDetail.substring(0, 40)}...` : itemData?.itemDetail}</td>
                                                                                        <td>
                                                                                            {itemData?.recordSourceId === constraintConfig.recordSourceId.WebApp ?
                                                                                                <KTSVG path="/media/icons/figmaIcons/Web.svg" className='svg-icon-1 ms-4'
                                                                                                    svgClassName='w-20px h-20px'
                                                                                                    data-tooltip-id="web-source" data-tooltip-content='Website' />
                                                                                                :
                                                                                                itemData?.recordSourceId === constraintConfig.recordSourceId.Mobile ?
                                                                                                    <KTSVG path="/media/icons/figmaIcons/Mobile.svg" className='svg-icon-1 ms-4'
                                                                                                        data-tooltip-id="mobile-source" data-tooltip-content='Mobile' /> :
                                                                                                    itemData?.recordSourceId === constraintConfig.recordSourceId.BankFeed ?
                                                                                                        <KTSVG path="/media/icons/figmaIcons/Bank.svg" className='svg-icon-1 ms-4'
                                                                                                            data-tooltip-id="bank-source" data-tooltip-content='Bank' />
                                                                                                        : null}
                                                                                        </td>
                                                                                    </tr>
                                                                                ))}
                                                                            </>
                                                                                :
                                                                                <tr>
                                                                                    <td colSpan={10}>
                                                                                        <NoRecordFound />
                                                                                    </td>
                                                                                </tr>
                                                                            }
                                                                        </>}
                                                                        {expenseItemDataIsLoading && (
                                                                            <tr>
                                                                                <td colSpan={10} className="text-center">
                                                                                    <LoadingComponent />
                                                                                </td>
                                                                            </tr>
                                                                        )}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )}
                                            </>
                                        }
                                        {isLoading && (
                                            <tr>
                                                <td colSpan={10} className="text-center">
                                                    <LoadingComponent />
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ExpenseItemDetail