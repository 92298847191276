
import ReactApexChart from 'react-apexcharts';

const ReporteesChart = ({ getManagerDashboard }: any) => {
  // Extract data from the API response
  const allReportee = getManagerDashboard?.result?.allReportee || 0;
  const activeReportee = getManagerDashboard?.result?.activeReportee || 0;
  // Chart options
  const pieChartOptions: ApexCharts.ApexOptions = {
    chart: {
      type: 'donut',
    },
    labels: ['Active Reportees', 'In Active Reportees'],
    colors: ['#0A47E6', '#00945F'],
    legend: {
      show: true,
      position: 'bottom',
      horizontalAlign: 'center',
      offsetX: 10,
      offsetY: 0,
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 250,
          },
          legend: {
            position: 'bottom',
            horizontalAlign: 'center',
            offsetX: 0,
            offsetY: 0,
          },
        },
      },
    ],
    tooltip: {
      y: {
        formatter: function (value: any) {
          return value.toLocaleString('en-US');
        },
      },
    },
  };

  const inActiveReporteesPercentage = (allReportee - activeReportee);
  // Chart series data
  const pieChartSeries = [activeReportee, inActiveReporteesPercentage];
 
  return (
    <div className='d-flex justify-content-center w-100'>
      {allReportee === 0 && activeReportee === 0 ? (
        <p className='mt-15'>Chart does not have any values to display.</p>
      ) : (
        <div className='legend-center'>
          <ReactApexChart options={pieChartOptions} series={pieChartSeries} type="donut" height={300} />
        </div>
      )}
    </div>
  )
}

export default ReporteesChart