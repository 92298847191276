import type {
    BaseQueryFn,
    FetchArgs,
    FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react';
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { selectAuth } from "../features/authSlice";
import { RootState } from "../store";
import { API_END_POINTS } from './apiEndpoints';
import { baseUrl } from './baseUrl';

const rawBaseQuery = fetchBaseQuery({
    baseUrl: baseUrl.MAIN_BASE_URL,
})

type ExtraOptions = {
    headers?: Record<string, string>;
    // Define other optional properties here if needed
};

const dynamicBaseQuery: BaseQueryFn<
    string | FetchArgs,
    unknown,
    FetchBaseQueryError,
    ExtraOptions
> = async (args, api, extraOptions) => {
    const orgBaseUrl = selectAuth(api.getState() as RootState).baseURL;

    // gracefully handle scenarios where data to generate the URL is missing
    if (!orgBaseUrl) {
        return {
            error: {
                status: 400,
                statusText: 'Bad Request',
                data: 'dynamic route not found',
            },
        }
    }

    const urlEnd = typeof args === 'string' ? args : args.url

    // construct a dynamically generated portion of the url
    const adjustedUrl = `${orgBaseUrl}/api/Dashboard${urlEnd}`
    const adjustedArgs = typeof args === 'string' ? { url: adjustedUrl } : { ...args, url: adjustedUrl };
    // Get the token from localStorage
    const token = localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user")!).token
        : null;
    // Set headers if token is available
    const headers = token ? { Authorization: `Bearer ${token}` } : {};
    // Merge headers with existing headers
    const mergedHeaders = { ...headers, ...extraOptions?.headers };
    // provide the amended url and other params to the raw base query
    return rawBaseQuery({ ...adjustedArgs, headers: mergedHeaders }, api, extraOptions)

}

export const DashboardApi = createApi({
    reducerPath: 'DashboardApi',
    baseQuery: dynamicBaseQuery,
    // baseQuery: fetchBaseQuery({
    //     baseUrl: baseUrl.BASE_URL + '/api/Dashboard/',
    // prepareHeaders: (headers) => {
    //     // Get the token from localStorage
    //     const token = localStorage.getItem("user")
    //         ? JSON.parse(localStorage.getItem("user")!).token
    //         : null;
    //     if (token) {
    //         headers.set('Authorization', `Bearer ${token}`);
    //     }
    //     return headers;
    // },
    // }),
    tagTypes: ['Dashboard'],
    endpoints: (builder) => ({
        getTotalActiveOrg: builder.query({
            query: () => {
                return {
                    url: API_END_POINTS.totalActiveOrg,
                    method: "GET",
                }
            },
            providesTags: ['Dashboard']
        }),
        getTotalActiveUser: builder.query({
            query: () => {
                return {
                    url: API_END_POINTS.totalActiveUsers,
                    method: "GET",
                }
            },
            providesTags: ['Dashboard']
        }),
        getNewAndInprogressOrg: builder.query({
            query: () => {
                return {
                    url: API_END_POINTS.newInprogressOrg,
                    method: "GET",
                }
            },
            providesTags: ['Dashboard']
        }),

        getUserGraph: builder.query({
            query: () => {
                return {
                    url: API_END_POINTS.userGraphData,
                    method: "GET",
                }
            },
            providesTags: ['Dashboard']
        }),
        getTotalExpenseProfile: builder.query({
            query: ({ userAccountId }) => {
                return {
                    url: `${API_END_POINTS.totalExpenseProfile}?userAccountId=${userAccountId}`,
                    method: "GET",
                }
            },
            providesTags: ['Dashboard']
        }),
        getUserExpenseDataGraph: builder.query({
            query: ({ FilterBy }) => {
                return {
                    url: `${API_END_POINTS.expenseDataForUserGraph}?FilterBy=${FilterBy}`,
                    method: "GET",
                }
            },
            providesTags: ['Dashboard']
        }),
        getExpenseAmountForProfile: builder.query({
            query: ({ userAccountId }) => {
                return {
                    url: `${API_END_POINTS.expenseAmountForProfile}?userAccountId=${userAccountId}`,
                    method: "GET",
                }
            },
            providesTags: ['Dashboard']
        }),
        getManagersDashboard: builder.query({
            query: ({ startDate, endDate }) => {
                return {
                    url: `${API_END_POINTS.managerDashboardData}?startDate=${startDate}&endDate=${endDate}`,
                    method: "GET",
                }
            },
            providesTags: ['Dashboard']
        }),
        getAdminDashboard: builder.query({
            query: ({ startDate, endDate }) => {
                return {
                    url: `${API_END_POINTS.adminDashboardData}?startDate=${startDate}&endDate=${endDate}`,
                    method: "GET",
                }
            },
            providesTags: ['Dashboard']
        }),
        getUserDashboard: builder.query({
            query: ({ startDate, endDate }) => {
                return {
                    url: `${API_END_POINTS.userDashboardData}?startDate=${startDate}&endDate=${endDate}`,
                    method: "GET",
                }
            },
            providesTags: ['Dashboard']
        }),
        getAPDashboardData: builder.query({
            query: ({ FilterBy }) => {
                return {
                    url: `${API_END_POINTS.getAPDashboardData}?FilterBy=${FilterBy}`,
                    method: "GET",
                }
            },
            providesTags: ['Dashboard']
        }),
        getDashboardData: builder.query({
            query: ({ startDate, endDate }) => {
                return {
                    url: `${API_END_POINTS.getDashboardData}?startDate=${startDate}&endDate=${endDate}`,
                    method: "GET",
                }
            },
            providesTags: ['Dashboard']
        }),
        getSupperAdminDashboardData: builder.query({
            query: ({ startDate, endDate }) => {
                return {
                    url: `${API_END_POINTS.getSupperAdminDashboardData}?startDate=${startDate}&endDate=${endDate}`,
                    method: "GET",
                }
            },
            providesTags: ['Dashboard']
        }),
    }),
})


export const {
    useGetTotalActiveOrgQuery,
    useGetTotalActiveUserQuery,
    useGetNewAndInprogressOrgQuery,
    useGetUserGraphQuery,
    useGetTotalExpenseProfileQuery,
    useGetUserExpenseDataGraphQuery,
    useGetExpenseAmountForProfileQuery,
    useGetManagersDashboardQuery,
    useGetAdminDashboardQuery,
    useGetUserDashboardQuery,
    useGetAPDashboardDataQuery,
    useGetDashboardDataQuery,
    useGetSupperAdminDashboardDataQuery
} = DashboardApi