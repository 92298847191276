import React from 'react'
import VendorPage from './VendorPage'
import { PageTitle } from '../../../../_metronic/layout/core'
import labelKey from "../../../localization/label.json"

const VendorWrapper = () => {
    return (
        <>
            <PageTitle breadcrumbs={[]}>{labelKey.vendors}</PageTitle>
            <VendorPage />
        </>
    )
}

export default VendorWrapper