import React from 'react'
import { constraintConfig } from '../../../constraintConfig'

type Props = {
    itemData?: any
}

const CategoriesWithIcons = ({ itemData }: Props) => {
    return (
        <>
            {itemData?.expenseCategory?.id === constraintConfig.categoriesId.travelExpenses ?
                <div className='d-flex gap-2 align-items-center'>
                    <img
                        src="/media/category-icons/Travel Expenses.svg"
                        alt="" height="20px" width="20px" />
                    <span>
                        {constraintConfig.categoriesName.travelExpenses}
                    </span>
                </div>
                : itemData?.expenseCategory?.id === constraintConfig.categoriesId.entertainmentExpenses ?
                    <div className='d-flex gap-2 align-items-center'>
                        <img
                            src="/media/category-icons/Entertainment Expenses.svg"
                            alt="" height="20px" width="20px" />
                        <span>
                            {constraintConfig.categoriesName.entertainmentExpenses}
                        </span>
                    </div>
                    :
                    itemData?.expenseCategory?.id === constraintConfig.categoriesId.officeSuppliesAndStationery ?
                        <div className='d-flex gap-2 align-items-center'>
                            <img
                                src="/media/category-icons/Office Supplies And Stationery.svg"
                                alt="" height="20px" width="20px" />
                            <span>
                                {constraintConfig.categoriesName.officeSuppliesAndStationery}
                            </span>
                        </div>
                        : itemData?.expenseCategory?.id === constraintConfig.categoriesId.communicationExpenses ?
                            <div className='d-flex gap-2 align-items-center'>
                                <img
                                    src="/media/category-icons/Communication Expenses.svg"
                                    alt="" height="20px" width="20px" />
                                <span>
                                    {constraintConfig.categoriesName.communicationExpenses}
                                </span>
                            </div>
                            : itemData?.expenseCategory?.id === constraintConfig.categoriesId.mealsAndRefreshments ?
                                <div className='d-flex gap-2 align-items-center'>
                                    <img
                                        src="/media/category-icons/Meals And Refreshments.svg"
                                        alt="" height="20px" width="20px" />
                                    <span>
                                        {constraintConfig.categoriesName.mealsAndRefreshments}
                                    </span>
                                </div>
                                : itemData?.expenseCategory?.id === constraintConfig.categoriesId.trainingAndProfessionalDevelopment ?
                                    <div className='d-flex gap-2 align-items-center'>
                                        <img
                                            src="/media/category-icons/Training And Professional Development.svg"
                                            alt="" height="20px" width="20px" />
                                        <span>
                                            {constraintConfig.categoriesName.trainingAndProfessionalDevelopment}
                                        </span>
                                    </div>
                                    : itemData?.expenseCategory?.id === constraintConfig.categoriesId.marketingAndAdvertising ?
                                        <div className='d-flex gap-2 align-items-center'>
                                            <img
                                                src="/media/category-icons/Marketing And Advertising.svg"
                                                alt="" height="20px" width="20px" />
                                            <span>
                                                {constraintConfig.categoriesName.marketingAndAdvertising}
                                            </span>
                                        </div>
                                        : itemData?.expenseCategory?.id === constraintConfig.categoriesId.utilities ?
                                            <div className='d-flex gap-2 align-items-center'>
                                                <img
                                                    src="/media/category-icons/Utilities.svg"
                                                    alt="" height="20px" width="20px" />
                                                <span>
                                                    {constraintConfig.categoriesName.utilities}
                                                </span>
                                            </div>
                                            : itemData?.expenseCategory?.id === constraintConfig.categoriesId.maintenanceAndRepairs ?
                                                <div className='d-flex gap-2 align-items-center'>
                                                    <img
                                                        src="/media/category-icons/Maintenance And Repairs.svg"
                                                        alt="" height="20px" width="20px" />
                                                    <span>
                                                        {constraintConfig.categoriesName.maintenanceAndRepairs}
                                                    </span>
                                                </div>
                                                : itemData?.expenseCategory?.id === constraintConfig.categoriesId.professionalServices ?
                                                    <div className='d-flex gap-2 align-items-center'>
                                                        <img
                                                            src="/media/category-icons/Professional Services.svg"
                                                            alt="" height="20px" width="20px" />
                                                        <span>
                                                            {constraintConfig.categoriesName.professionalServices}
                                                        </span>
                                                    </div>
                                                    : itemData?.expenseCategory?.id === constraintConfig.categoriesId.insurancePremiums ?
                                                        <div className='d-flex gap-2 align-items-center'>
                                                            <img
                                                                src="/media/category-icons/Insurance Premiums.svg"
                                                                alt="" height="20px" width="20px" />
                                                            <span>
                                                                {constraintConfig.categoriesName.insurancePremiums}
                                                            </span>
                                                        </div>
                                                        : itemData?.expenseCategory?.id === constraintConfig.categoriesId.officeEquipmentandFurniture ?
                                                            <div className='d-flex gap-2 align-items-center'>
                                                                <img
                                                                    src="/media/category-icons/Office Equipment And Furniture.svg"
                                                                    alt="" height="20px" width="20px" />
                                                                <span>
                                                                    {constraintConfig.categoriesName.officeEquipmentandFurniture}
                                                                </span>
                                                            </div>
                                                            : itemData?.expenseCategory?.id === constraintConfig.categoriesId.miscellaneousExpenses ?
                                                                <div className='d-flex gap-2 align-items-center'>
                                                                    <img
                                                                        src="/media/category-icons/Miscellaneous Expenses.svg"
                                                                        alt="" height="20px" width="20px" />
                                                                    <span>
                                                                        {constraintConfig.categoriesName.miscellaneousExpenses}
                                                                    </span>
                                                                </div>
                                                                : itemData?.expenseCategory?.id === constraintConfig.categoriesId.mileage ?
                                                                    <div className='d-flex gap-2 align-items-center'>
                                                                        <img
                                                                            src="/media/category-icons/Mileage.svg"
                                                                            alt="" height="20px" width="20px" />
                                                                        <span>
                                                                            {constraintConfig.categoriesName.mileage}
                                                                        </span>
                                                                    </div>
                                                                    : itemData?.expenseCategory?.id === constraintConfig.categoriesId.companyEntertainment ?
                                                                        <div className='d-flex gap-2 align-items-center'>
                                                                            <img
                                                                                src="/media/category-icons/Entertainment Expenses.svg"
                                                                                alt="" height="20px" width="20px" />
                                                                            <span>
                                                                                {constraintConfig.categoriesName.companyEntertainment}
                                                                            </span>
                                                                        </div>
                                                                        : itemData?.expenseCategory?.id === constraintConfig.categoriesId.other ?
                                                                            <div className='d-flex gap-2 align-items-center'>
                                                                                <img
                                                                                    src="/media/category-icons/Entertainment Expenses.svg"
                                                                                    alt="" height="20px" width="20px" />
                                                                                <span>
                                                                                    {itemData?.expenseCategory?.title}
                                                                                </span>
                                                                            </div>
                                                                            :
                                                                            <>
                                                                                <div className='d-flex gap-2 align-items-center'>
                                                                                    <img
                                                                                        src="/media/category-icons/Entertainment Expenses.svg"
                                                                                        alt="" height="20px" width="20px" />
                                                                                    <span>
                                                                                        {itemData?.expenseCategory?.title}
                                                                                    </span>
                                                                                </div>
                                                                            </>
            }
        </>
    )
}

export default CategoriesWithIcons