

import ReactApexChart from 'react-apexcharts';

const ApprovedPieChart = ({ getUserDashboard }: any) => {

  const approvedExpense = getUserDashboard?.result?.apApprovedExpense || 0;
  const totalApprovedAmount = getUserDashboard?.result?.totalAPApprovedAmount || 0;
  // Chart options
  const pieChartOptions: ApexCharts.ApexOptions = {
    chart: {
      type: 'donut' as 'donut',
    },
    labels: ['Ap Approved Expenses', 'Ap Approved  Amount'],
    colors: ['#00945F', '#0057CB'],
    legend: {
      show: true,
      position: 'bottom',
      horizontalAlign: 'center',
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 250,
          },
          legend: {
            position: 'bottom',
            horizontalAlign: 'center',
          },
        },
      },
    ],
    tooltip: {
      y: [{},{
        formatter: function (value: any) {
          return `$${value.toLocaleString('en-US')}`;
        },
      }],
    },
  };

  // Chart series data
  const pieChartSeries = [approvedExpense, totalApprovedAmount];

  return (
    <div className='d-flex justify-content-center w-100'>
      {approvedExpense === 0 && totalApprovedAmount === 0 ? (
        <p className='mt-15'>Chart does not have any values to display.</p>
      ) : (
        <div className='legend-center'>
          <ReactApexChart options={pieChartOptions} series={pieChartSeries} type="donut" height={300} />
        </div>
      )}
    </div>
  )
}

export default ApprovedPieChart