import React from 'react'
import { Modal } from 'react-bootstrap'
import { KTSVG } from '../../../../_metronic/helpers'
type Props = {
    show: boolean
    handleClose: () => void
    handleDeleteConfirm: any
}
const DeleteItemModal = ({ show, handleClose, handleDeleteConfirm }: Props) => {
    
    return (
        <>
            <Modal
                aria-hidden='true'
                dialogClassName='modal-dialog modal-md modal-dialog-centered'
                show={show}
                onHide={handleClose}
            >
                <div className='modal-header bg-light py-4'>
                    <h2 className='mb-0'>Delete Confirmation</h2>
                    {/* begin::Close */}
                    <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
                        <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                    </div>
                    {/* end::Close */}
                </div>


                <div className='modal-body py-lg-6 px-lg-6'>

                    <p className='fs-5 fw-semibold'>Are you sure you want to delete this 
                    <span className='text-danger'> "Expense Item"</span>?</p>
                </div>
                <div className='modal-footer justify-content-center py-4'>
                    <button className='btn btn-light' onClick={handleClose}>Cancel</button>
                    <button
                        type='submit'
                        id='kt_sign_up_submit'
                        className='btn btn-danger'
                        onClick={handleDeleteConfirm}
                    >
                        Delete
                    </button>
                </div>
            </Modal>
        </>
    )
}

export default DeleteItemModal