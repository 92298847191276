import React from 'react'
import { PageTitle } from '../../../_metronic/layout/core'
import RolesPage from './RolesPage'
import labelKey from "../../localization/label.json";

const RolesPageWrapper = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>{labelKey.roles}</PageTitle>
      <RolesPage />
    </>
  )
}

export default RolesPageWrapper