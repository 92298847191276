import ReactApexChart from 'react-apexcharts';
import { FaInfoCircle } from 'react-icons/fa';
import { Tooltip } from 'react-tooltip';
import labelKey from "../../localization/label.json";

const ExpenseByUsersChart = (expenseUserData:any) => {
    const lineChartOptions: ApexCharts.ApexOptions = {
        chart: {
            type: 'line',
            toolbar: {
                show: false
            },
            height: 350,
            zoom: {
                enabled: false,
            },

        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            curve: 'smooth',
        },
        xaxis: {
            categories: expenseUserData?.expenseUserData?.result?.map((user: any) => user?.userName) || [],
            title: {
                text: 'Users',  // X-axis label
            },
            tooltip: {
                enabled: false
            }
        },
        yaxis: {
            title: {
                text: 'Expense Count',  // Y-axis label
            },
        },
        legend: {
            show: true,
            position: 'top',
            horizontalAlign: 'right', // Align legend to the right
            offsetX: 0,
            offsetY: 0,
        },
        // tooltip: {
        //     y: {
        //         formatter: function (value: any) {
        //             return `$${value.toLocaleString('en-US')}`;
        //         },
        //     },
        // },
        annotations: {
            // Vertical Line
            xaxis: [
                {
                    x: 0, // x-axis index where the line will be drawn
                    borderColor: '#8c8c8c',
                },
            ],
        },
        tooltip: {
            y: [{
                formatter: function (value: any) {
                  return `${Math.round(value)}`;
                },
              },
            {
                formatter: function (value: any) {
                    return `$${value.toLocaleString('en-US', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                    })}`;
                },
            },
            ],
        },
    };

    const lineChartSeries = [
        {
            name: 'Total Expense',
            data: expenseUserData?.expenseUserData?.result?.map((user: any) => user?.totalExpenseCount) || [],
        },
        {
            name: 'Total Expense Amount',
            data: expenseUserData?.expenseUserData?.result?.map((user: any) => user?.userExpenseAmount) || [],
        },
    ];

    return (
        <>
            <Tooltip id="chart" place="top" />
            <div className="row">
                <div className="col-md-12">
                    <div className='d-flex justify-content-between align-items-center'>
                        <h2>{labelKey.expenseByUsers}
                            <FaInfoCircle className='text-gray cursor-pointer ms-2'
                                data-tooltip-id="chart" data-tooltip-content='The chart display data by default for the last 7 days.' />
                        </h2>
                        {/* <div>
                            <button
                                type='button'
                                className='btn btn-sm btn-light-primary me-3'
                                data-kt-menu-trigger='click'
                                data-kt-menu-placement='bottom-end'
                            >
                                <KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-2' />
                                {labelKey.filter}
                            </button>
                            <div className='menu menu-sub menu-sub-dropdown w-300px w-md-325px' data-kt-menu='true'>
                                <div className='px-7 py-5'>
                                    <div className='fs-5 text-dark fw-bold'>{labelKey.filterOptions}</div>
                                </div>

                                <div className='separator border-gray-200'></div>

                                <div className='px-7 py-5' data-kt-user-table-filter='form'>
                                    <div>
                                        <TextField
                                            label={`${labelKey.startDate}:`}
                                            type="date"
                                            max="9999-12-31"
                                            fieldClass='mb-5'
                                            value={filterByStartDate}
                                            onChange={handleStartDate}
                                        />
                                        <TextField
                                            label={`${labelKey.endDate}:`}
                                            type="date"
                                            max="9999-12-31"
                                            value={filterByEndDate}
                                            onChange={handleEndDate}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
                <div className="col-md-12 mt-5">
                    <div >
                        <ReactApexChart options={lineChartOptions} series={lineChartSeries} type='line' height={400} />
                    </div>
                </div>
            </div>
        </>
    )
}

export default ExpenseByUsersChart