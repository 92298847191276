import React from 'react';
import './Button.css';

interface IPROPS{
    className:string,
    onClick?:any,
    label:string,

}

const Button:React.FC<IPROPS> = ({ className, onClick,label}) => {
    return (
        <>
            <button type="submit" className={`btn ${className}`} onClick={onClick}>{label}</button>
        </>
    );
};

export default Button