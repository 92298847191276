
import { PageTitle } from '../../../_metronic/layout/core'
import labelKey from "../../localization/label.json"
import Invoices from './Invoices'

const InvoicesWrapper = () => {
    return (
        <>
            <PageTitle breadcrumbs={[]}>{labelKey.receiptVault}</PageTitle>
            <Invoices />
        </>
    )
}

export default InvoicesWrapper