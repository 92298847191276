import React, { useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import { KTSVG } from '../../../_metronic/helpers'
import { toast } from 'react-toastify'
import { useUpdateExpenseStatusMutation } from '../../../services/ExpenseApi'
import labelKey from "../../localization/label.json";
import tosterKey from "../../localization/toster.json";

type Props = {
    show: boolean
    handleClose: () => void
    data: any
    handleCloseModals?: any
}
const ConfirmationExpenseModal = ({ show, handleClose, data, handleCloseModals }: Props) => {

    const [updateExpenseStatus, { isLoading, isSuccess, isError, error }] = useUpdateExpenseStatusMutation()

    const sendForApproval = async (expenseId: any) => {
        try {
            // Make the API call to update the expense status
            const response = await updateExpenseStatus({ expenseId, expenseStatus: { id: 2, title: "Pending for Approval" } });
        } catch (error) {
        }
        handleClose();
    };
    useEffect(() => {
        if (isSuccess) {
            toast.success(tosterKey.statusUpdatedSuccessfully);
            handleClose()
            if (handleCloseModals) {
                handleCloseModals();
            }
        }
    }, [isSuccess]);
    useEffect(() => {
        if (isError && error) {
            let errorMessage: string = 'Something went wrong';

            if ('data' in error && error.data && typeof error.data === 'object' && 'message' in error.data) {
                errorMessage = (error.data as { message: string }).message;
            }
            toast.error(errorMessage);
        }
    }, [isError, error]);
    return (
        <>
            <Modal
                aria-hidden='true'
                dialogClassName='modal-dialog modal-md modal-dialog-centered'
                show={show}
                onHide={handleClose}
            >
                <div className='modal-header bg-light py-4'>
                    <h2 className='mb-0'>{labelKey.confirmationStatus}</h2>
                    {/* begin::Close */}
                    <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
                        <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                    </div>
                    {/* end::Close */}
                </div>


                <div className='modal-body py-lg-6 px-lg-6'>

                    <p className='fs-5 fw-semibold'>{labelKey.areYoSureToProceed} <span className='text-primary'>"{labelKey.submitForApproval}"</span>?</p>
                </div>
                <div className='modal-footer justify-content-center py-4'>
                    <button className='btn btn-light' onClick={handleClose}>{labelKey.cancel}</button>
                    <button
                        type='submit'
                        id='kt_sign_up_submit'
                        className='btn btn-primary'
                        onClick={() => sendForApproval(data)}
                        disabled={isLoading}
                    >
                        {!isLoading && <span className='indicator-label'>{labelKey.submit}</span>}
                        {isLoading && (
                            <span className='indicator-progress' style={{ display: 'block' }}>
                                {labelKey.pleaseWait}...{' '}
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                        )}
                    </button>
                </div>
            </Modal>
        </>
    )
}

export default ConfirmationExpenseModal