import React, { useCallback, useEffect, useState } from 'react'
import { KTSVG } from '../../../../_metronic/helpers';
import labelKey from "../../../localization/label.json";
import { useAppSelector } from '../../../../hooks';
import { selectAuth } from '../../../../features/authSlice';
import { Tooltip } from 'react-tooltip';
import { useExpenseCategoryLookupQuery } from '../../../../services/GeneralApi';
import AddCategoryModal from './AddCategoryModal';
import { PageTitle } from '../../../../_metronic/layout/core';
import TableHeading from '../../../../components/tableHeading/TableHeading';
import TableSettingMenu from '../../../../components/tableHeading/TableSettingMenu';
import TextField from '../../../../components/textFields/TextField';
import { useGetAllExpenseCategoryQuery } from '../../../../services/CategoriesApi';
import { debounce } from 'lodash';
import Pagination from '../../../../components/pagination/Pagination';
import SearchBarComponent from '../../../../components/searchBar/SearchBarComponent';
import { LoadingComponent } from '../../../../components/loadingComponent/LoadingComponent';

const Categories = () => {
    const { roleID } = useAppSelector(selectAuth);
    const [pageNumber, setPageNumber] = useState(1)
    const [pageSize, setPageSize] = useState(10);
    const [searchStr, setSearchStr] = useState(''); // debounce search
    const [immediateSearchStr, setImmediateSearchStr] = useState('');
    const { data: getAllcatgoriesData, isLoading: getAllCatgoriesIsLoading, refetch } = useGetAllExpenseCategoryQuery({
        pageNumber: pageNumber,
        pageSize: pageSize,
        searchStr: searchStr,
    })
    // pagination
    const handlePageClick = (e: any) => {
        setPageNumber(e.selected + 1);
    };
    const handlePageSizeChange = (e: any) => {
        const newSize = parseInt(e.target.value);
        setPageSize(newSize);
        setPageNumber(1); // Reset page number to 1
    };
    //per page record
    const handlePageRecords = (e: any) => {
        setPageNumber(1); // Reset the page to 1
    };
    const handleSearchChange = (e: any) => {
        const { value } = e.target;
        setImmediateSearchStr(value); // Update immediate search state

        delayedSearch(value); // Update debounced search state
    };

    const delayedSearch = useCallback(
        debounce((searchValue) => {
            setSearchStr(searchValue);
        }, 500),
        []
    );

    useEffect(() => {
        if (searchStr === "") {
            refetch();
        }
    }, [searchStr]);
    const [showAddUpdateCategoryModal, setShowAddUpdateCategoryModal] = useState(false)
    const [showCategoryEdit, setShowCategoryEdit] = useState({})
    const categoryModalHandler = () => {
        setShowAddUpdateCategoryModal((prev) => !prev);
    };

    useEffect(() => {
        if (showAddUpdateCategoryModal === false) {
            refetch();
        }
    }, [showAddUpdateCategoryModal]);

    const [hiddenColumns, setHiddenColumns] = useState<string[]>(['sr', 'categories', 'actions', 'glCode', 'description']);
    const handleToggleColumn = (columnId: string) => {
        if (hiddenColumns.includes(columnId)) {
            setHiddenColumns(hiddenColumns.filter((id) => id !== columnId));
        } else {
            setHiddenColumns([...hiddenColumns, columnId]);
        }
    };
    return (
        <>
            <AddCategoryModal show={showAddUpdateCategoryModal}
                handleClose={() => setShowAddUpdateCategoryModal(false)} data={showCategoryEdit} />
            <PageTitle breadcrumbs={[]}>{labelKey.categories}</PageTitle>
            <div className='card mb-5'>
                <div className='card-header border-0 pt-6'>
                    <SearchBarComponent
                        placeholder='Catogory'
                        value={immediateSearchStr}
                        onChange={handleSearchChange}
                    />
                    <div className='d-flex align-items-center ms-auto'>
                        <div className='card-toolbar'>
                            <button type='button' className='btn btn-sm btn-light-primary'
                                onClick={() => {
                                    setShowCategoryEdit(true);
                                    categoryModalHandler();
                                }}>
                                <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
                                Add Category
                            </button>
                        </div>
                    </div>
                </div>

                <div className='card-body py-8'>
                    <div className="col-md-12">
                        <div className='table-responsive table-height'>
                            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                                <thead>
                                    <tr className='fw-bold text-muted'>
                                        {hiddenColumns.includes('sr') && (
                                            <TableHeading
                                                label={labelKey.sr}
                                                columnId='sr'
                                                className="ps-3 min-w-150px"
                                            />
                                        )}
                                        {hiddenColumns.includes('categories') && (
                                            <TableHeading
                                                label={labelKey.categories}
                                                columnId='categories'
                                                className='min-w-150px'
                                            />
                                        )}
                                        {hiddenColumns.includes('glCode') && (
                                            <TableHeading
                                                label={labelKey.glCode}
                                                columnId='glCode'
                                                className='min-w-150px'
                                            />
                                        )}
                                        {hiddenColumns.includes('description') && (
                                            <TableHeading
                                                label={labelKey.description}
                                                columnId='description'
                                            />
                                        )}
                                        <th className='text-end rounded-end pe-2 border-0'>
                                            {!hiddenColumns.includes('actions') && (
                                                <>
                                                    {labelKey.actions}
                                                </>
                                            )}
                                            <TableSettingMenu>
                                                <TextField
                                                    rightLabel={labelKey.sr}
                                                    type="checkbox"
                                                    onChange={() => handleToggleColumn("sr")}
                                                    checked={hiddenColumns.includes('sr')}
                                                    fieldClass='mb-4'
                                                />

                                                <TextField
                                                    rightLabel={labelKey.categories}
                                                    type="checkbox"
                                                    onChange={() => handleToggleColumn("categories")}
                                                    checked={hiddenColumns.includes('categories')}
                                                    fieldClass='mb-4'
                                                />
                                                <TextField
                                                    rightLabel={labelKey.glCode}
                                                    type="checkbox"
                                                    onChange={() => handleToggleColumn("glCode")}
                                                    checked={hiddenColumns.includes('glCode')}
                                                    fieldClass='mb-4'
                                                />
                                                <TextField
                                                    rightLabel={labelKey.description}
                                                    type="checkbox"
                                                    onChange={() => handleToggleColumn("description")}
                                                    checked={hiddenColumns.includes('description')}
                                                    fieldClass='mb-4'
                                                />
                                                <TextField
                                                    rightLabel={labelKey.actions}
                                                    type="checkbox"
                                                    onChange={() => handleToggleColumn("actions")}
                                                    checked={hiddenColumns.includes('actions')}
                                                    fieldClass='mb-4'
                                                />
                                            </TableSettingMenu>
                                        </th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {getAllcatgoriesData?.result?.data?.map((data: any, index: any) => (
                                        <tr key={index}>
                                            {hiddenColumns.includes('sr') && (
                                                <td className='ps-3'>{(pageNumber - 1) * 10 + index + 1}</td>
                                            )}
                                            {hiddenColumns.includes('categories') && (
                                                <td className='text-capitalize'>{data?.title}</td>
                                            )}
                                            {hiddenColumns.includes('glCode') && (
                                                <td>{data?.glCode}</td>
                                            )}
                                            {hiddenColumns.includes('description') && (
                                                <td>{data?.description}</td>
                                            )}
                                            {hiddenColumns.includes('actions') && (
                                                <td>
                                                    <div className='d-flex align-items-center justify-content-end gap-2' >
                                                        <Tooltip id="update-category" place="bottom" />
                                                        <div
                                                            data-tooltip-id="update-category" data-tooltip-content={labelKey.updateCategory}
                                                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                                            onClick={() => {
                                                                setShowCategoryEdit(data);
                                                                categoryModalHandler();
                                                            }}

                                                        >
                                                            <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
                                                        </div>
                                                    </div>
                                                </td>
                                            )}
                                        </tr>
                                    ))}
                                    {getAllCatgoriesIsLoading && (
                                        <tr>
                                            <td colSpan={7} className="text-center">
                                                <LoadingComponent />
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                        <Pagination
                            totalResult={getAllcatgoriesData?.result?.totalRecords === 0 ? 0 : ((getAllcatgoriesData?.result?.pageNumber - 1) * getAllcatgoriesData?.result?.pageSize) + 1}
                            toResult={getAllcatgoriesData?.result?.totalRecords === 0 ? 0 : Math.min(getAllcatgoriesData?.result?.pageNumber * getAllcatgoriesData?.result?.pageSize, getAllcatgoriesData?.result?.totalRecords)}
                            ofResult={getAllcatgoriesData?.result?.totalRecords}
                            onChange={handlePageSizeChange}
                            pageSize={pageSize}
                            onPageChange={handlePageClick}
                            pageCount={getAllcatgoriesData?.result?.totalPages || 0}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Categories