import React from 'react'
import labelKey from '../../app/localization/label.json'
const NoRecordFound = () => {
    return (
        <div className='d-flex justify-content-center align-items-center gap-2 p-2  rounded mt-4'>
            <i className="fas fa-exclamation-triangle text-white ms-2 fs-4"></i>
            <h4 className="mb-0 text-gray-600"> {labelKey.noMatchingRecordsFound}</h4>
        </div>
    )
}

export default NoRecordFound