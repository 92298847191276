import ReactApexChart from 'react-apexcharts';

const OrgPieChart = (getSuperAdminDashboard:any) => {

  // Extract data from the API response
  const totalOrg = getSuperAdminDashboard?.getSuperAdminDashboard?.result?.totalOrg || 0;
  const activeOrg = getSuperAdminDashboard?.getSuperAdminDashboard?.result?.activeOrg || 0;
  const inactiveOrg = totalOrg - activeOrg;

  // Chart options
  const pieChartOptions: ApexCharts.ApexOptions = {
    chart: {
      type: 'donut' as 'donut',
    },
    labels: ['Active Organizations','In Active Organizations'],
    colors: ['#7239EA', '#00945F'],
    legend: {
      show: true,
      position: 'bottom',
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 250,
          },
          legend: {
            position: 'bottom',
          },
        },
      },
    ],
    tooltip: {
      y: {
        formatter: function (value: any) {
          return value.toLocaleString('en-US');
        },
      },
    },
  };

  const inActiveOrgPercentage = (totalOrg - activeOrg);

  // Chart series data
  const pieChartSeries = [activeOrg,inActiveOrgPercentage];

  return (
    <div className='d-flex justify-content-center w-100 legend-center'>
      {totalOrg === 0 && activeOrg === 0 ? (
        <p className='mt-15'>Chart does not have any values to display.</p>
      ) : (
        <ReactApexChart options={pieChartOptions} series={pieChartSeries} type="donut" height={300} />
      )}
    </div>
  )
}

export default OrgPieChart