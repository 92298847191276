import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useCreateClientMutation, useUpdateClientMutation } from '../../../../services/ClientAndProjectsApi'
import { useFormik } from 'formik'
import { useAppSelector } from '../../../../hooks'
import { selectAuth } from '../../../../features/authSlice'
import ReactInputMask from 'react-input-mask'
import * as Yup from 'yup';
import { toast } from 'react-toastify'
import { KTSVG } from '../../../../_metronic/helpers'
type Props = {
    show: boolean
    handleClose: () => void
    data?: any
}
const AddClientModal = ({ show, handleClose, data }: Props) => {
    const [addClient, { isLoading, isSuccess, isError, error }] = useCreateClientMutation()
    const [addupdate, { isLoading: updateisLoading, isSuccess: updateIsSuccess, isError: updateIsError }] = useUpdateClientMutation()

    const { userAccountID } = useAppSelector(selectAuth);

    const initialValues = {
        name: '',
        email: '',
        contact: '',
        createdBy: userAccountID,
    }
    const validationSchema = Yup.object({
        name: Yup.string().required('Name is required'),
        email: Yup.string().email('Invalid email address').required('Email is required'),
        contact: Yup.string().required('Contact is required'),
    });

    const [updateId, setUpdateId] = useState(0);

    useEffect(() => {
        if (data) {
            setUpdateId(data?.clientId);
            formik.setValues({ ...data })
        } else {
            setUpdateId(0);
            formik.resetForm(); // Reset form values when no data is passed (add client mode)
        }
    }, [data]);
    const formik = useFormik({
        initialValues,
        validationSchema: validationSchema,
        onSubmit: (values, { resetForm }) => {
            

            if (updateId) {
                addupdate(values)
            } else {
                addClient(values)
                formik.resetForm();
            }
        }
    })

    useEffect(() => {
        if (isSuccess) {
            toast.success('Client Created Successfully');
            handleClose()
        }
        if (isError) {
            toast.error('Some Thing Went Wrong');
        }
        if (updateIsSuccess) {
            toast.success('Client Created Successfully');
            handleClose()
        }
        if (updateIsError) {
            toast.error('Some Thing Went Wrong');
        }

    }, [isSuccess, isError, updateIsSuccess, updateIsError]);
    return (
        <>
            <Modal
                aria-hidden='true'
                dialogClassName='modal-dialog modal-md modal-dialog-centered'
                show={show}
                onHide={handleClose}
            >
                <div className='modal-header'>
                    <h2 className='mb-0'>{updateId ? "update Client" : "Add Client"}</h2>
                    {/* begin::Close */}
                    <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
                        <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                    </div>
                    {/* end::Close */}
                </div>

                <form
                    className='form w-100'
                    onSubmit={formik.handleSubmit}
                >
                    <div className='modal-body py-lg-10 px-lg-10'>

                        <div className='col-xl-12 mb-5'>
                            <label className='class="form-label fw-bold text-dark fs-6'>Name</label>
                            <input
                                placeholder='Enter Name'
                                type='text'
                                autoComplete='off'
                                className={`form-control form-control-lg form-control-solid ${formik.touched.name && formik.errors.name ? 'is-invalid' : ''
                                    }`}
                                {...formik.getFieldProps('name')}
                                value={formik.values.name || ''}
                            />
                            {formik.touched.name && formik.errors.name && (
                                <div className='invalid-feedback'>{formik.errors.name}</div>
                            )}
                        </div>
                        <div className='col-xl-12 mb-5'>
                            <label className='class="form-label fw-bold text-dark fs-6'>Email</label>
                            <input
                                placeholder='Enter Email'
                                type='email'
                                autoComplete='off'
                                className={`form-control form-control-lg form-control-solid ${formik.touched.email && formik.errors.email ? 'is-invalid' : ''
                                    }`}
                                {...formik.getFieldProps('email')}
                                value={formik.values.email || ''}
                            />
                            {formik.touched.email && formik.errors.email && (
                                <div className='invalid-feedback'>{formik.errors.email}</div>
                            )}
                        </div>
                        <div className='col-xl-12 mb-5'>
                            <label className='class="form-label fw-bold text-dark fs-6'>Contact</label>
                            <ReactInputMask
                                mask="(999) 999-9999"
                                placeholder='Enter Number'
                                type='text'
                                autoComplete='off'
                                {...formik.getFieldProps('contact')}
                                value={formik.values.contact || ''}
                                className={`form-control form-control-lg form-control-solid ${formik.touched.contact && formik.errors.contact ? 'is-invalid' : ''
                                    }`}
                            />
                            {formik.touched.contact && formik.errors.contact && (
                                <div className='invalid-feedback'>{formik.errors.contact}</div>
                            )}
                        </div>
                    </div>
                    <div className='modal-footer justify-content-center'>
                        <span className='btn btn-light' onClick={handleClose}>Cancel</span>
                        <button
                            type='submit'
                            className='btn btn-primary mb-5 mt-5'
                            // disabled={formik.isSubmitting || !formik.isValid}
                        >
                            {updateId ?
                                <>
                                    {!updateisLoading && <span className='indicator-label'>Update</span>}
                                </> :
                                <>
                                    {!isLoading && <span className='indicator-label'>Submit</span>}
                                </>
                            }

                            {updateId ?
                                <>
                                    {updateisLoading && (
                                        <span className='indicator-progress' style={{ display: 'block' }}>
                                            Please wait...{' '}
                                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                        </span>
                                    )}
                                </> :
                                <>
                                    {isLoading && (
                                        <span className='indicator-progress' style={{ display: 'block' }}>
                                            Please wait...{' '}
                                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                        </span>
                                    )}
                                </>
                            }

                        </button>
                    </div>
                </form>
            </Modal>
        </>
    )
}

export default AddClientModal