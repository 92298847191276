import React from 'react'
import { PageTitle } from '../../../../_metronic/layout/core'
import Countries from './CountriesPage'
import labelKey from '../../../localization/label.json'
const CountriesWrapper = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>{labelKey.countries}</PageTitle>
      <Countries />
    </>
  )
}

export default CountriesWrapper