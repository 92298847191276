import labelKey from "../../localization/label.json";
import ManagerBudgetChart from './ManagerBudgetChart';
import ManagerPendingExpensesChart from './ManagerPendingExpensesChart';
import MixedStatsWidget from './MixedStatsWidget';
import ReporteesChart from './ReporteesChart';

const DashboardByManager = (getManagerDashboard:any) => {
   
    return (
        <>
            <div className="row">
                <div className="col-md-6 col-lg-4 mt-3">
                    <MixedStatsWidget className='card-xl-stretch'
                        title={labelKey.expensesForApproval}
                        toState1='/approval-queue/all'
                        toState2='/approval-queue/pending'
                        titleIcon='/media/figma-download/dollarNote.svg'
                        statImg1='/media/figma-download/allExpenses.svg'
                        statTitle1={labelKey.approvalQueueExpenses}
                        statsNum1={`${getManagerDashboard?.getManagerDashboard?.result?.allExpense ? getManagerDashboard?.getManagerDashboard?.result?.allExpense : "0"}`}
                        statImg2='/media/figma-download/pendingExpenses.svg'
                        statTitle2={labelKey.pendingExpenses}
                        statsNum2={`${getManagerDashboard?.getManagerDashboard?.result?.pendingExpense ? getManagerDashboard?.getManagerDashboard?.result?.pendingExpense : "0"}`}
                    >
                        <ManagerPendingExpensesChart getManagerDashboard={getManagerDashboard?.getManagerDashboard} />
                    </MixedStatsWidget>
                </div>
                <div className="col-md-6 col-lg-4 mt-3">
                    <MixedStatsWidget className='card-xl-stretch'
                        title={labelKey.allocatedBudget}
                        // bothLink='/budget'
                        toState1='/budget-allocation/budget'
                        toState2='/budget-allocation/budget'
                        titleIcon='/media/figma-download/bag.svg'
                        statImg1='/media/figma-download/assignedBudget.svg'
                        statTitle1={labelKey.assignedBudget}
                        statsNum1={`$${getManagerDashboard?.getManagerDashboard?.result?.assignBudget ? getManagerDashboard?.getManagerDashboard?.result?.assignBudget : "0"}`}
                        statImg2='/media/figma-download/utilizedBudget.svg'
                        statTitle2={labelKey.utilizedBudget}
                        statsNum2={`$${getManagerDashboard?.getManagerDashboard?.result?.utilizedBudget ? getManagerDashboard?.getManagerDashboard?.result?.utilizedBudget : "0"}`}
                    >
                        <ManagerBudgetChart getManagerDashboard={getManagerDashboard?.getManagerDashboard} />
                    </MixedStatsWidget>
                </div>
                <div className="col-md-6 col-lg-4 mt-3">
                    <MixedStatsWidget className='card-xl-stretch'
                        title={labelKey.reportees}
                        toState1='/all/team-management'
                        toState2='/active/team-management'
                        titleIcon='/media/figma-download/orgWhite.svg'
                        statImg1='/media/figma-download/allReportees.svg'
                        statTitle1={labelKey.allReportees}
                        statsNum1={`${getManagerDashboard?.getManagerDashboard?.result?.allReportee ? getManagerDashboard?.getManagerDashboard?.result?.allReportee : "0"}`}
                        statImg2='/media/figma-download/toggle.svg'
                        statTitle2={labelKey.activeReportees}
                        statsNum2={`${getManagerDashboard?.getManagerDashboard?.result?.activeReportee ? `${getManagerDashboard?.getManagerDashboard.result?.activeReportee}` : "0"}`}
                    >
                        <ReporteesChart getManagerDashboard={getManagerDashboard?.getManagerDashboard} />
                    </MixedStatsWidget>
                </div>
            </div>
        </>
    )
}

export default DashboardByManager