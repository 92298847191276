import axios from 'axios';
import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { KTSVG } from '../../../_metronic/helpers';
import CurrencyComp from '../../../components/currencyComponent/CurrencyComp';
import { selectAuth } from '../../../features/authSlice';
import { useAppSelector } from '../../../hooks';
import { API_END_POINTS } from '../../../services/apiEndpoints';
import labelKey from "../../localization/label.json";

type Props = {
    show: boolean
    handleClose: () => void
    data: any
}
const OrgDetailModal = ({ show, handleClose, data }: Props) => {

    const { token, baseURL } = useAppSelector(selectAuth);
    const [detailData, setDetailData] = useState<any>(null); // Initialize with null
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        const fetchData = async () => {
            if (show && data) {
                setIsLoading(true); // Set loading state to true
                try {
                    const headers = {
                        Authorization: `Bearer ${token}`,
                    };

                    const getExpenseLogResponse = await axios.get(
                        `${baseURL}/api/Organization${API_END_POINTS.getOrganizationDetailByID}?id=${data}`,
                        {
                            headers,
                        }
                    );
                    const detailData = getExpenseLogResponse.data;
                    setDetailData(detailData);
                } catch (error) {
                    console.error('Error fetching data:', error);
                } finally {
                    setIsLoading(false); // Set loading state to false after fetching data
                }
            }
        };
        fetchData();
    }, [show, data, token]);

    return (
        <>

            <Modal
                aria-hidden='true'
                dialogClassName='modal-dialog modal-lg modal-dialog-centered'
                show={show}
                onHide={handleClose}
            >
                <div className='modal-header'>
                    <h2 className='mb-0'>{labelKey.organizationDetail}</h2>
                    <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
                        <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                    </div>
                </div>
                {isLoading && <h4 className='d-flex align-items-center justify-content-center text-dark'>{labelKey.loading}...</h4>}
                <div className='modal-body py-lg-3 px-lg-10'>
                    <div className="row m-0">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-body d-flex flex-center flex-column p-9">
                                    <div className="mb-5">
                                        <div className="symbol symbol-75px symbol-circle">
                                            <div className="symbol-label fs-3 bg-light-info text-info text-capitalize"
                                            >
                                                {detailData && detailData?.result?.legalName.charAt(0)}
                                            </div>
                                        </div>
                                    </div>
                                    <p className="fs-4 text-gray-800 text-hover-primary fw-bold mb-0">
                                        {detailData && detailData?.result?.legalName}
                                    </p>
                                    <div className="fw-semibold text-gray-400 mb-6">{detailData && detailData?.result?.contactPersonEmail}</div>

                                    <div className="d-flex flex-column flex-lg-row w-100  mb-5">
                                        <div className="border border-gray-300 border-dashed rounded  w-100 py-3 px-4 mx-3 mb-3">
                                            <div className="d-flex align-items-center">
                                                <p className="fs-6 fw-bold text-gray-700 mb-0 text-start w-50">{labelKey.addressLine1}:</p>
                                                <span className="fw-semibold"> {detailData && detailData?.result?.legalAddrLine1}</span>
                                            </div>
                                            <div className="d-flex align-items-center">
                                                <p className="fs-6 fw-bold text-gray-700 mb-0 text-start w-50">{labelKey.addressLine2}:</p>
                                                <span className="fw-semibold"> {detailData && detailData?.result?.legalAddrLine2}</span>
                                            </div>
                                            <div className="d-flex align-items-center">
                                                <p className="fs-6 fw-bold text-gray-700 mb-0 text-start w-50">{labelKey.city}:</p>
                                                <span className="fw-semibold"> {detailData && detailData?.result?.legalCity}</span>
                                            </div>
                                            <div className="d-flex align-items-center">
                                                <p className="fs-6 fw-bold text-gray-700 mb-0 text-start w-50">{labelKey.state}:</p>
                                                <span className="fw-semibold"> {detailData && detailData?.result?.legalState}</span>
                                            </div>
                                            <div className="d-flex align-items-center">
                                                <p className="fs-6 fw-bold text-gray-700 mb-0 text-start w-50">{labelKey.zipCode}:</p>
                                                <span className="fw-semibold"> {detailData && detailData?.result?.legalZip}</span>
                                            </div>
                                            <div className="d-flex align-items-center">
                                                <p className="fs-6 fw-bold text-gray-700 mb-0 text-start w-50">{labelKey.status}:</p>
                                                <span className="fw-semibold"> {detailData && detailData?.result?.active == true ? <span className="badge badge-light-success fs-7 fw-semibold">Active</span> : <span className="badge badge-light-danger fs-7 fw-semibold">In Active</span>}</span>
                                            </div>
                                        </div>
                                        <div className="border border-gray-300 border-dashed rounded  w-100 py-3 px-4 mx-3 mb-3">
                                            <div className="d-flex align-items-center">
                                                <p className="fs-6 fw-bold text-gray-700 mb-0 text-start w-50">{labelKey.personName}:</p>
                                                <span className="fw-semibold"> {detailData && detailData?.result?.contactPersonName}</span>
                                            </div>
                                            <div className="d-flex align-items-center">
                                                <p className="fs-6 fw-bold text-gray-700 mb-0 text-start w-50">{labelKey.personEmail}:</p>
                                                <span className="fw-semibold"> {detailData && detailData?.result?.contactPersonEmail}</span>
                                            </div>
                                            <div className="d-flex align-items-center">
                                                <p className="fs-6 fw-bold text-gray-700 mb-0 text-start w-50">{labelKey.contactNo}:</p>
                                                <span className="fw-semibold"> {detailData && detailData?.result?.contactPersonMobile}</span>
                                            </div>
                                            <div className="d-flex align-items-center">
                                                <p className="fs-6 fw-bold text-gray-700 mb-0 text-start w-50">{labelKey.onboardingStatus}:</p>
                                                <span className="fw-semibold"> {detailData && detailData?.result?.fk_OnboardingStatusName}</span>
                                            </div>
                                            <div className="d-flex align-items-center">
                                                <p className="fs-6 fw-bold text-gray-700 mb-0 text-start w-50">{labelKey.domainName}:</p>
                                                <span className="fw-semibold"> {detailData && detailData?.result?.domainName}</span>
                                            </div>
                                            <div className="d-flex align-items-center">
                                                <p className="fs-6 fw-bold text-gray-700 mb-0 text-start w-50">{labelKey.webUrl}:</p>
                                                <span className="fw-semibold"> {detailData && detailData?.result?.webURL}</span>
                                            </div>
                                            <div>
                                                <p className="fs-6 fw-bold text-gray-700 mb-0 text-start w-50">{labelKey.apiUrl}:</p>
                                                <span className="fw-semibold"> {detailData && detailData?.result?.apiurl}</span>
                                            </div>
                                            <div>
                                                <p className="fs-6 fw-bold text-gray-700 mb-0 text-start w-50">{labelKey.microServiceURL}:</p>
                                                <span className="fw-semibold"> {detailData && detailData?.result?.microServiceURL}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="border border-gray-300 border-dashed rounded  w-100 py-3 px-4 mx-3 mb-3">
                                        {/* <div className="d-flex align-items-center">
                                            <p className="fs-6 fw-bold text-gray-700 mb-0 text-start min-w-200px">{labelKey.expAllowedOldDays}:</p>
                                            <span className="fw-semibold"> {detailData && detailData?.result?.expAllowedOldDays}</span>
                                        </div> */}
                                        <div className="d-flex align-items-center">
                                            <p className="fs-6 fw-bold text-gray-700 mb-0 text-start min-w-200px">{labelKey.receiptReqMinAmnt}:</p>
                                            <span className="fw-semibold"><CurrencyComp amount={detailData && detailData?.result?.receiptReqMinAmnt} /> </span>
                                        </div>
                                        <div className="d-flex align-items-center">
                                            <p className="fs-6 fw-bold text-gray-700 mb-0 text-start min-w-200px">{labelKey.allowedOldDaysInvoices}:</p>
                                            <span className="fw-semibold"> {detailData && detailData?.result?.allowedOldDaysInvoices}</span>
                                        </div>
                                        <div className="d-flex align-items-center">
                                            <p className="fs-6 fw-bold text-gray-700 mb-0 text-start min-w-200px">{labelKey.expPolicyMaxLimit}:</p>
                                            <span className="fw-semibold"> {detailData && detailData?.result?.expPolicyMaxLimit}</span>
                                        </div>
                                    </div>
                                    <div className="d-flex flex-column flex-lg-row w-100  mb-5">
                                        <div className="border border-gray-300 border-dashed rounded  w-100 py-3 px-4 mx-3 mb-3">
                                            <div>
                                                <p className="fs-6 fw-bold text-gray-700 mb-0 text-start w-50">{labelKey.onboardingNotes}:</p>
                                                <p className="fw-semibold overflow-auto h-100px"> {detailData && detailData?.result?.onboardingNotes}</p>
                                            </div>
                                        </div>
                                        <div className="border border-gray-300 border-dashed rounded  w-100 py-3 px-4 mx-3 mb-3">

                                            <div>
                                                <p className="fs-6 fw-bold text-gray-700 mb-0 text-start w-50">{labelKey.internalNotes}:</p>
                                                <p className="fw-semibold overflow-auto h-100px"> {detailData && detailData?.result?.internalNotes}</p>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className='modal-footer'>
                    <button className='btn btn-light-primary' onClick={handleClose}>Cancel</button>
                </div> */}

            </Modal>
        </>
    )
}

export default OrgDetailModal