import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { toast } from 'react-toastify'
import Button from '../../../components/button/Button'
import { useCreateOrganizationRoleMutation, useUpdateOrganizationRoleMutation } from '../../../services/OrganizationApi'
import { KTSVG } from '../../../_metronic/helpers'
import labelKey from "../../localization/label.json";
import tosterKey from "../../localization/toster.json";
import TextField from '../../../components/textFields/TextField'
type Props = {
    show: boolean
    handleClose: () => void
}
const AddRoleModal = ({ show, handleClose }: Props) => {
    const initialValues = {
        roleName: '',
    }
    const [formValue, setFormValue] = useState(initialValues);
    const [editMode, setEditMode] = useState(false);

    const [addRole, { isLoading, isSuccess, isError, error }] = useCreateOrganizationRoleMutation()

    const { roleName } = formValue;
    const handleInputChange = (e: any) => {
        let { name, value } = e.target;
        setFormValue({ ...formValue, [name]: value });
    };

    const handleSubmit = async (e: any) => {
        e.preventDefault();

        if (!roleName) {
            toast.error("role is required");
        } else {
            await addRole(formValue)
        }
    };

    useEffect(() => {
        if (isSuccess) {
            toast.success(tosterKey.roleAddedSuccessfully);
            handleClose()
        }
    }, [isSuccess]);

    useEffect(() => {
        if (isError && error) {
            let errorMessage: string = 'Something went wrong';

            if ('data' in error && error.data && typeof error.data === 'object' && 'message' in error.data) {
                errorMessage = (error.data as { message: string }).message;
            }
            toast.error(errorMessage);
        }
    }, [isError, error]);
    return (
        <>
            <Modal
                aria-hidden='true'
                dialogClassName='modal-dialog modal-md modal-dialog-centered'
                show={show}
                onHide={handleClose}
            >
                <div className='modal-header'>
                    <h2 className='mb-0'>{labelKey.createRole}</h2>
                    {/* begin::Close */}
                    <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
                        <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                    </div>
                    {/* end::Close */}
                </div>

                <form
                    className='form w-100'
                    onSubmit={handleSubmit}
                >
                    <div className='modal-body py-lg-10 px-lg-10'>

                        <div className='col-xl-12 mb-5'>
                            <TextField
                                label={labelKey.role}
                                required={true}
                                type='text'
                                placeholder='Enter Role Name'
                                name='roleName'
                                onChange={handleInputChange}
                            />
                        </div>
                    </div>
                    <div className='modal-footer justify-content-center'>
                        <Button label='Cancel' className='btn btn-light' onClick={handleClose} />
                        <button
                            type='submit'
                            id='kt_sign_up_submit'
                            className='btn btn-primary mb-5 mt-5'
                            disabled={isLoading}
                        >
                            {!isLoading && <span className='indicator-label'>{labelKey.submit}</span>}
                            {isLoading && (
                                <span className='indicator-progress' style={{ display: 'block' }}>
                                    {labelKey.pleaseWait}...{' '}
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                            )}
                        </button>
                    </div>
                </form>
            </Modal>
        </>
    )
}

export default AddRoleModal