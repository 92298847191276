import React, { useEffect, useState } from 'react'
import { useGetAPDashboardDataQuery } from '../../../services/DashboardApi';
import { KTSVG } from '../../../_metronic/helpers';
import MixedStatsWidget from './MixedStatsWidget';
import labelKey from "../../localization/label.json";

const DashboardByAp = () => {
    const [filterByAp, setFilterByAp] = useState("year")
    const { data: getApDashboard, refetch: getApDashboardRefetch } = useGetAPDashboardDataQuery({
        FilterBy: filterByAp
    });
    const handleFilterApClick = (filter: string) => {
        setFilterByAp(filter);
        getApDashboardRefetch();
    };
    useEffect(() => {
        getApDashboardRefetch()
    }, [])
    return (
        <>
            <div className="row">
                <div className="col-md-12 position-relative">
                    <div className='d-flex position-absolute' style={{ top: '-35px', right: '0' }}>
                        <button
                            type='button'
                            className='btn btn-sm btn-light-primary me-3 ms-auto'
                            data-kt-menu-trigger='click'
                            data-kt-menu-placement='bottom-end'
                        >
                            <KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-2' />
                            Filter
                        </button>
                        <div className='menu menu-sub menu-sub-dropdown w-300px w-md-325px' data-kt-menu='true'>
                            <div className='px-7 py-5'>
                                <div className='fs-5 text-dark fw-bold'>Filter Options</div>
                            </div>

                            <div className='separator border-gray-200'></div>

                            <div className='px-7 py-5' data-kt-user-table-filter='form'>
                                <div className='card-toolbar' data-kt-buttons='true'>
                                    <span
                                        className={`btn btn-sm btn-color-muted btn-active btn-active-primary px-4 me-1 ${filterByAp === 'year' ? 'active' : ''}`}
                                        id='kt_charts_widget_3_year_btn'
                                        onClick={() => handleFilterApClick('year')}
                                    >
                                        Year
                                    </span>

                                    <span
                                        className={`btn btn-sm btn-color-muted btn-active btn-active-primary px-4 me-1 ${filterByAp === 'month' ? 'active' : ''}`}
                                        id='kt_charts_widget_3_month_btn'
                                        onClick={() => handleFilterApClick('month')}
                                    >
                                        Month
                                    </span>

                                    <span
                                        className={`btn btn-sm btn-color-muted btn-active btn-active-primary px-4 ${filterByAp === 'week' ? 'active' : ''}`}
                                        id='kt_charts_widget_3_week_btn'
                                        onClick={() => handleFilterApClick('week')}
                                    >
                                        Week
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6 col-lg-4 mt-3">
                    <MixedStatsWidget className='card-xl-stretch'
                        title={labelKey.expensesForApproval}
                        // toState1='/approval-queue/all'
                        // toState2='/approval-queue/pending'
                        titleIcon='/media/figma-download/dollarNote.svg'
                        statImg1='/media/figma-download/allExpenses.svg'
                        statTitle1={labelKey.managerApproved}
                        statsNum1={``}
                        statImg2='/media/figma-download/pendingExpenses.svg'
                        statTitle2={labelKey.apPending}
                        statsNum2={``}
                    >
                        {/* <ManagerPendingExpensesChart getManagerDashboard={getManagerDashboard} /> */}
                    </MixedStatsWidget>
                </div>
            </div>
        </>
    )
}

export default DashboardByAp