/* eslint-disable jsx-a11y/anchor-is-valid */
import TextAreaField from "../../../../../components/textFields/TextAreaField"
import labelKey from "../../../../localization/label.json"

type Props = {
  formik: any
}
const Step3 = ({ formik, }: Props) => {

  return (
    <>
      {/*begin::Step 3 */}
      <div className='pb-5 pe-3 overflow-auto' style={{ height: "51vh" }} data-kt-stepper-element='content'>
        <div className='w-100 h-min-content'>
          <div className='fv-row mb-10'>
            <TextAreaField
              label={labelKey.onboardingNotes}
              rows={5}
              placeholder='Enter Onboarding Notes'
              {...formik.getFieldProps('onboardingNotes')}
            />
          </div>
          {/*begin::Form Group */}
          <div className='fv-row mb-10'>
            <TextAreaField
              label={labelKey.internalNotes}
              rows={5}
              placeholder='Enter Internal Notes'
              {...formik.getFieldProps('internalNotes')}
            />
          </div>
        </div>
      </div>
      {/*end::Step 3 */}
    </>
  )
}

export { Step3 };

