import ReactApexChart from 'react-apexcharts';

const NewOrgPieChart = (getSuperAdminDashboard: any) => {
  // Extract data from the API response
  const newOrg = getSuperAdminDashboard?.getSuperAdminDashboard?.result?.newOrg || 0;
  const inprogressOrg = getSuperAdminDashboard?.getSuperAdminDashboard?.result?.inprogressOrg || 0;

  // Chart options
  const pieChartOptions: ApexCharts.ApexOptions = {
    chart: {
      type: 'donut' as 'donut',
    },
    labels: ['New organizations', 'Inprogress Organizations'],
    colors: ['#0A47E6', '#00945F'],
    legend: {
      show: true,
      position: 'bottom',
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 250,
          },
          legend: {
            position: 'bottom',
          },
        },
      },
    ],
    tooltip: {
      y: {
        formatter: function (value: any) {
          return value.toLocaleString('en-US');
        },
      },
    },
  };

  // Chart series data
  const pieChartSeries = [newOrg, inprogressOrg];

  return (
    <div className='d-flex justify-content-center w-100 legend-center'>
      {newOrg === 0 && inprogressOrg === 0 ? (
        <p className='mt-15'>Chart does not have any values to display.</p>
      ) : (
        <ReactApexChart options={pieChartOptions} series={pieChartSeries} type="donut" height={300} />
      )}
    </div>
  )
}

export default NewOrgPieChart