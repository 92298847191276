import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import TextField from '../../../../components/textFields/TextField';
import tosterKey from "../../../localization/toster.json";
import labelKey from "../../../localization/label.json";
import * as Yup from 'yup'
import { Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { KTSVG } from '../../../../_metronic/helpers';
import { useAddCreditCardInfoMutation, useUpdateCreditCardInfoMutation } from '../../../../services/CreditCardApi';
import { useAddDivisionMutation, useUpdateDivisionMutation } from '../../../../services/OrgSettingsApi';


type Props = {
    show: boolean
    handleClose: () => void
    data?: any
}

const AddUpdateDivisionModal = ({ show, handleClose, data }: Props) => {
    const [addDivision, { isLoading, isSuccess, isError, error }] = useAddDivisionMutation()
    const [updateDivision, { isLoading: updateIsLoading, isSuccess: updateIsSuccess, isError: updateIsError, error: updateError }] = useUpdateDivisionMutation()


    const initialValues = {
        divisionId: 0,
        divisionName: '',
        divisionCode: '',
        isActive: true
    }

    const [updateId, setUpdateId] = useState(0);
    const [formValues, setFormValues] = useState(initialValues);
    // Store form values when the modal is closed
    const handleModalClose = () => {
        setFormValues(formik.values);
        handleClose();
    };
    useEffect(() => {
        // Restore form values when the modal reopens
        if (show && !data && Object.keys(formValues).length !== 0) {
            formik.setValues(formValues);
        }
        // Update form values based on data received
        if (data) {
            setUpdateId(data?.divisionId);
            formik.setValues({ ...data });
        } else if (!show) {
            // Reset form values only when closing and it's not an update action
            setUpdateId(0);
            formik.resetForm();
        }
    }, [show, data]);
    const validationSchema = Yup.object().shape({
        divisionName: Yup.string().required('Division Name is required'),
        divisionCode: Yup.string().required('Division Code is required'),

    })
    const formik = useFormik({
        initialValues,
        validationSchema: validationSchema,
        onSubmit: (values, { resetForm }) => {
            if (updateId) {
                updateDivision(values)
            } else {
                addDivision(values)
            }
        }
    })
    const { resetForm } = formik;
    useEffect(() => {
        if (isSuccess) {
            toast.success(tosterKey.divisionCreatedSuccessfully);
            handleClose();
            resetForm()
        }
    }, [isSuccess]);
    useEffect(() => {
        if (updateIsSuccess) {
            toast.success(tosterKey.divisionUpdatedSuccessfully);
            handleClose();
        }
    }, [updateIsSuccess]);

    useEffect(() => {
        if (isError && error) {
            let errorMessage: string = 'Something went wrong';

            if ('data' in error && error.data && typeof error.data === 'object' && 'message' in error.data) {
                errorMessage = (error.data as { message: string }).message;
            }
            toast.error(errorMessage);
        }
    }, [isError, error]);
    useEffect(() => {
        if (updateIsError && updateError) {
            let errorMessage: string = 'Something went wrong';

            if ('data' in updateError && updateError.data && typeof updateError.data === 'object' && 'message' in updateError.data) {
                errorMessage = (updateError.data as { message: string }).message;
            }
            toast.error(errorMessage);
        }
    }, [updateIsError, updateError]);
    return (
        <>
            <Modal
                aria-hidden='true'
                dialogClassName='modal-dialog modal-md modal-dialog-centered'
                show={show}
                onHide={handleModalClose}
            >
                <div className='modal-header'>
                    <h2 className='mb-0'>{updateId ? <>{labelKey.updateDivision}</> : <>{labelKey.addDivision}</>}</h2>
                    {/* begin::Close */}
                    <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
                        <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                    </div>
                    {/* end::Close */}
                </div>

                <form
                    className='form w-100'
                    onSubmit={(e) => {
                        e.preventDefault();
                        formik.handleSubmit();
                        formik.setTouched({
                            divisionName: true,
                            divisionCode: true,
                        }, true); // Set touched for all fields to trigger validation display
                    }}
                >
                    <div className='modal-body py-lg-10 px-lg-10'>
                        <div className="row">


                            <div className='col-md-12 mb-5'>
                                <TextField
                                    label={labelKey.division}
                                    required={true}
                                    type='text'
                                    placeholder='Enter Division'
                                    labelClass={`${formik.touched.divisionName && formik.errors.divisionName ? 'is-invalid' : ''}`}
                                    {...formik.getFieldProps('divisionName')}
                                    value={formik.values.divisionName || ''}

                                />
                                {formik.touched.divisionName && formik.errors.divisionName && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                            <span role='alert'>{formik.errors.divisionName}</span>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className='col-md-12 mb-5'>
                                <TextField
                                    label={labelKey.divisionCode}
                                    required={true}
                                    type='text'
                                    placeholder='Enter Division Code'
                                    labelClass={`${formik.touched.divisionCode && formik.errors.divisionCode ? 'is-invalid' : ''}`}
                                    {...formik.getFieldProps('divisionCode')}
                                    value={formik.values.divisionCode || ''}
                                />
                                {formik.touched.divisionCode && formik.errors.divisionCode && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                            <span role='alert'>{formik.errors.divisionCode}</span>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className='modal-footer justify-content-center'>
                        <span className='btn btn-light' onClick={handleClose}>{labelKey.cancel}</span>
                        <button
                            type='submit'
                            className='btn btn-primary mb-5 mt-5'
                            disabled={isLoading}
                        >
                            {!isLoading && !updateIsLoading && <span className='indicator-label'>{updateId ? <>{labelKey.update}</> : <>{labelKey.submit}</>}</span>}

                            {updateId ?
                                <>
                                    {updateIsLoading && (
                                        <span className='indicator-progress' style={{ display: 'block' }}>
                                            {labelKey.pleaseWait}...
                                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                        </span>
                                    )}
                                </> :
                                <>
                                    {isLoading && (
                                        <span className='indicator-progress' style={{ display: 'block' }}>
                                            {labelKey.pleaseWait}...
                                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                        </span>
                                    )}
                                </>
                            }


                        </button>
                    </div>
                </form>
            </Modal>
        </>
    )
}

export default AddUpdateDivisionModal