import { debounce } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { Tooltip } from 'react-tooltip';
import { KTSVG } from '../../../../_metronic/helpers';
import Pagination from '../../../../components/pagination/Pagination';
import SearchBarComponent from '../../../../components/searchBar/SearchBarComponent';
import TableHeading from '../../../../components/tableHeading/TableHeading';
import TableSettingMenu from '../../../../components/tableHeading/TableSettingMenu';
import TextField from '../../../../components/textFields/TextField';
import { useGetAllExpenseTypeCodesQuery } from '../../../../services/OrgSettingsApi';
import labelKey from '../../../localization/label.json';
import AddUpdateExpenseTypeCode from './AddUpdateExpenseTypeCode';
import ExpenseTypeCodeActiveConfirmationModal from './ExpenseTypeCodeActiveConfirmationModal';
import NoRecordFound from '../../../../components/noRecord/NoRecordFound';
import { LoadingComponent } from '../../../../components/loadingComponent/LoadingComponent';

const ExpenseTypeCodePage = () => {
    const [pageNumber, setPageNumber] = useState(1)
    const [pageSize, setPageSize] = useState(10);
    const [searchStr, setSearchStr] = useState(''); // debounce search
    const [sortBy, setSortBy] = useState("createDT");
    const [sortOrder, setSortOrder] = useState("desc");
    const [sortedColumn, setSortedColumn] = useState("createDT");
    const [filterByStatus, setFilterByStatus] = useState("");
    const [immediateSearchStr, setImmediateSearchStr] = useState('');
    const [isChecked, setIsChecked] = useState(false)

    const { data, isLoading, refetch } = useGetAllExpenseTypeCodesQuery({
        pageNumber: pageNumber,
        pageSize: pageSize,
        searchStr: searchStr,
        sortBy: sortBy,
        sortOrder: sortOrder,
        filterByStatus: filterByStatus
    })
    const [showAddUpdateExpenseTypeCodeModal, setShowAddUpdateExpenseTypeCodeModal] = useState(false)
    const [showExpenseTypeCodeEdit, setShowExpenseTypeCodeEdit] = useState({})
    const expenseTypeCodeModalHandler = () => {
        setShowAddUpdateExpenseTypeCodeModal((prev) => !prev);
    };
    const [showExpenseTypeCodeActiveConfirmationModal, setShowExpenseTypeCodeActiveConfirmationModal] = useState(false)
    const expenseTypeCodeActiveConfirmationHandler = () => {
        setShowExpenseTypeCodeActiveConfirmationModal((prev) => !prev);
    };
    const handlePageSizeChange = (e: any) => {
        const newSize = parseInt(e.target.value);
        setPageSize(newSize);
        setPageNumber(1); // Reset page number to 1
    };
    // pagination
    const handlePageClick = (e: any) => {
        setPageNumber(e.selected + 1);
    };
    //per page record
    const handlePageRecords = (e: any) => {
        setPageNumber(1); // Reset the page to 1
    };
    const handleSort = (property: string) => {
        setSortBy(property);
        if (sortedColumn === property) {
            setSortOrder(prevSortOrder => (prevSortOrder === "asc" ? "desc" : "asc"));
        } else {
            setSortedColumn(property);
            setSortOrder("asc");
        }
        // Perform additional sorting logic...
    };
    const handleSearchChange = (e: any) => {
        const { value } = e.target;
        setImmediateSearchStr(value); // Update immediate search state
        delayedSearch(value); // Update debounced search state
    };

    const delayedSearch = useCallback(
        debounce((searchValue) => {
            setSearchStr(searchValue);
        }, 500),
        []
    );
    useEffect(() => {
        if (searchStr === "" ||
            sortOrder === "" ||
            sortBy === "") {
            refetch();
        }
    }, [searchStr, sortOrder, sortBy]);

    const [prevModalState, setPrevModalState] = useState({
        addUpdateExpenseTypeCodeModal: false,
        expenseTypeCodeActiveConfirmationModal: false,
    });

    // Update previous modal state whenever the modals change
    useEffect(() => {
        setPrevModalState({
            addUpdateExpenseTypeCodeModal: showAddUpdateExpenseTypeCodeModal,
            expenseTypeCodeActiveConfirmationModal: showExpenseTypeCodeActiveConfirmationModal,
        });
    }, [showAddUpdateExpenseTypeCodeModal, showExpenseTypeCodeActiveConfirmationModal]);

    // Check for modal closing and trigger refetch
    useEffect(() => {
        if (
            (prevModalState.addUpdateExpenseTypeCodeModal && !showAddUpdateExpenseTypeCodeModal) ||
            (prevModalState.expenseTypeCodeActiveConfirmationModal && !showExpenseTypeCodeActiveConfirmationModal)
        ) {
            refetch();
        }
    }, [
        showAddUpdateExpenseTypeCodeModal,
        showExpenseTypeCodeActiveConfirmationModal,
        prevModalState.addUpdateExpenseTypeCodeModal,
        prevModalState.expenseTypeCodeActiveConfirmationModal,
        refetch,
    ]);

    const [hiddenColumns, setHiddenColumns] = useState<string[]>(['sr', 'expenseTypeName', 'expenseTypeCode', 'expenseCategory', 'isActive', 'actions']);

    const handleToggleColumn = (columnId: string) => {
        if (hiddenColumns.includes(columnId)) {
            setHiddenColumns(hiddenColumns.filter((id) => id !== columnId));
        } else {
            setHiddenColumns([...hiddenColumns, columnId]);
        }
    };
    return (
        <>
            <AddUpdateExpenseTypeCode show={showAddUpdateExpenseTypeCodeModal}
                handleClose={() => setShowAddUpdateExpenseTypeCodeModal(false)} data={showExpenseTypeCodeEdit} />
            <ExpenseTypeCodeActiveConfirmationModal
                show={showExpenseTypeCodeActiveConfirmationModal}
                handleClose={() => setShowExpenseTypeCodeActiveConfirmationModal(false)}
                data={showExpenseTypeCodeEdit}
                isChecked={isChecked}
            />

            <div className='card mb-5'>
                <div className='card-header border-0 pt-6'>
                    <SearchBarComponent
                        placeholder='Expense Type Code'
                        value={immediateSearchStr}
                        onChange={handleSearchChange}
                    />
                    <div className='d-flex align-items-center ms-auto'>
                        <div className='card-toolbar'>
                            <button className='btn btn-sm btn-light-primary'
                                onClick={() => {
                                    setShowExpenseTypeCodeEdit(true);
                                    expenseTypeCodeModalHandler();
                                }}
                            >
                                <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
                                {labelKey.addExpenseTypeCode}
                            </button>
                        </div>
                    </div>
                </div>
                <div className='card-body py-8'>
                    <div className="col-md-12">
                        <div className='table-responsive table-height'>
                            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                                <thead>
                                    <tr className='fw-bold text-muted'>
                                        {hiddenColumns.includes('sr') && (
                                            <TableHeading
                                                label={labelKey.sr}
                                                columnId='sr'
                                                className="ps-4"
                                            />
                                        )}
                                        {hiddenColumns.includes('expenseTypeName') && (
                                            <TableHeading
                                                label={labelKey.expenseType}
                                                sortedColumn={sortedColumn}
                                                sortOrder={sortOrder}
                                                handleSort={handleSort}
                                                columnId='expenseTypeName'
                                            />
                                        )}
                                        {hiddenColumns.includes('expenseTypeCode') && (
                                            <TableHeading
                                                label={labelKey.expenseTypeCode}
                                                sortedColumn={sortedColumn}
                                                sortOrder={sortOrder}
                                                handleSort={handleSort}
                                                columnId='expenseTypeCode'
                                            />
                                        )}
                                        {hiddenColumns.includes('expenseCategory') && (
                                            <TableHeading
                                                label={labelKey.expenseCategory}
                                                sortedColumn={sortedColumn}
                                                sortOrder={sortOrder}
                                                handleSort={handleSort}
                                                columnId='expenseCategory'
                                            />
                                        )}
                                        {hiddenColumns.includes('isActive') && (
                                            <TableHeading
                                                label={labelKey.status}
                                                sortedColumn={sortedColumn}
                                                sortOrder={sortOrder}
                                                handleSort={handleSort}
                                                columnId='isActive'
                                            />
                                        )}
                                        <th className='text-end rounded-end pe-2 border-0'>
                                            {hiddenColumns.includes('actions') && (
                                                <>
                                                    {labelKey.actions}
                                                </>
                                            )}
                                            <TableSettingMenu>
                                                <TextField
                                                    rightLabel={labelKey.sr}
                                                    type="checkbox"
                                                    onChange={() => handleToggleColumn("sr")}
                                                    checked={hiddenColumns.includes('sr')}
                                                    fieldClass='mb-4'
                                                />
                                                <TextField
                                                    rightLabel={labelKey.expenseType}
                                                    type="checkbox"
                                                    onChange={() => handleToggleColumn("expenseTypeName")}
                                                    checked={hiddenColumns.includes('expenseTypeName')}
                                                    fieldClass='mb-4'
                                                />
                                                <TextField
                                                    rightLabel={labelKey.expenseTypeCode}
                                                    type="checkbox"
                                                    onChange={() => handleToggleColumn("expenseTypeCode")}
                                                    checked={hiddenColumns.includes('expenseTypeCode')}
                                                    fieldClass='mb-4'
                                                />
                                                <TextField
                                                    rightLabel={labelKey.expenseCategory}
                                                    type="checkbox"
                                                    onChange={() => handleToggleColumn("expenseCategory")}
                                                    checked={hiddenColumns.includes('expenseCategory')}
                                                    fieldClass='mb-4'
                                                />
                                                <TextField
                                                    rightLabel={labelKey.status}
                                                    type="checkbox"
                                                    onChange={() => handleToggleColumn("isActive")}
                                                    checked={hiddenColumns.includes('isActive')}
                                                    fieldClass='mb-4'
                                                />
                                                <TextField
                                                    rightLabel={labelKey.actions}
                                                    type="checkbox"
                                                    onChange={() => handleToggleColumn("actions")}
                                                    checked={hiddenColumns.includes('actions')}
                                                    fieldClass='mb-4'
                                                />
                                            </TableSettingMenu>
                                        </th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {!isLoading &&
                                        <>
                                            {data?.result?.data?.length > 0 ? (
                                                <>
                                                    {data?.result?.data?.map((data: any, index: any) => (
                                                        <tr key={index}>
                                                            {hiddenColumns.includes('sr') && (
                                                                <td className='ps-4'>{(pageNumber - 1) * 10 + index + 1}</td>
                                                            )}
                                                            {hiddenColumns.includes('expenseTypeName') && (
                                                                <td>{data?.expenseTypeName}</td>
                                                            )}
                                                            {hiddenColumns.includes('expenseTypeCode') && (
                                                                <td>{data?.expenseTypeCode}</td>
                                                            )}
                                                            {hiddenColumns.includes('expenseCategory') && (
                                                                <td>{data?.expenseCategory?.title}</td>
                                                            )}
                                                            {hiddenColumns.includes('isActive') && (
                                                                <td>
                                                                    <div className='d-flex align-items-center gap-2'>
                                                                        {data?.isActive === true ? <span className="badge badge-light-success fs-7 fw-semibold">{labelKey.active}</span> :
                                                                            <span className="badge badge-light-danger fs-7 fw-semibold">{labelKey.inActive}</span>}
                                                                        <div className="form-check form-switch">
                                                                            <input
                                                                                className="form-check-input cursor-pointer"
                                                                                type="checkbox"
                                                                                role="switch"
                                                                                id={`flexSwitchCheckChecked-${data?.expenseTypeCodeId}`}
                                                                                checked={data?.isActive}
                                                                                onChange={(e) => {
                                                                                    setIsChecked(e.target.checked);
                                                                                    setShowExpenseTypeCodeEdit(data);
                                                                                    expenseTypeCodeActiveConfirmationHandler();
                                                                                }}
                                                                            />
                                                                            <label className="form-check-label" htmlFor={`flexSwitchCheckChecked-${data?.expenseTypeCodeId}`}></label>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            )}
                                                            {hiddenColumns.includes('actions') && (
                                                                <td>
                                                                    <div className='d-flex align-items-center justify-content-end gap-2' >
                                                                        <Tooltip id="update-expenseTypeCode" place="bottom" />
                                                                        <div
                                                                            data-tooltip-id="update-expenseTypeCode" data-tooltip-content={labelKey.updateExpenseTypeCode}
                                                                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                                                            onClick={() => {
                                                                                setShowExpenseTypeCodeEdit(data);
                                                                                expenseTypeCodeModalHandler();
                                                                            }}
                                                                        >
                                                                            <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            )}
                                                        </tr>
                                                    ))}
                                                </>) : (
                                                <tr>
                                                    <td colSpan={7}>
                                                        <NoRecordFound />
                                                    </td>
                                                </tr>
                                            )}
                                        </>}

                                    {isLoading && (
                                        <tr>
                                            <td colSpan={7} className="text-center">
                                                <LoadingComponent />
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                                {/* end::Table body */}
                            </table>
                        </div>
                        <Pagination
                            totalResult={data?.result?.totalRecords === 0 ? 0 : ((data?.result?.pageNumber - 1) * data?.result?.pageSize) + 1}
                            toResult={data?.result?.totalRecords === 0 ? 0 : Math.min(data?.result?.pageNumber * data?.result?.pageSize, data?.result?.totalRecords)}
                            ofResult={data?.result?.totalRecords}
                            onChange={handlePageSizeChange}
                            pageSize={pageSize}
                            onPageChange={handlePageClick}
                            pageCount={data?.result?.totalPages || 0}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default ExpenseTypeCodePage