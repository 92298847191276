import type {
    BaseQueryFn,
    FetchArgs,
    FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react';
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { selectAuth } from "../features/authSlice";
import { RootState } from "../store";
import { API_END_POINTS } from './apiEndpoints';
import { baseUrl } from './baseUrl';

const rawBaseQuery = fetchBaseQuery({
    baseUrl: baseUrl.MAIN_BASE_URL,
})

type ExtraOptions = {
    headers?: Record<string, string>;
    // Define other optional properties here if needed
};

const dynamicBaseQuery: BaseQueryFn<
    string | FetchArgs,
    unknown,
    FetchBaseQueryError,
    ExtraOptions
> = async (args, api, extraOptions) => {
    const orgBaseUrl = selectAuth(api.getState() as RootState).baseURL;
    // gracefully handle scenarios where data to generate the URL is missing
    if (!orgBaseUrl) {
        return {
            error: {
                status: 400,
                statusText: 'Bad Request',
                data: 'dynamic route not found',
            },
        }
    }

    const urlEnd = typeof args === 'string' ? args : args.url

    // construct a dynamically generated portion of the url
    const adjustedUrl = `${orgBaseUrl}/api/Vendor${urlEnd}`
    const adjustedArgs = typeof args === 'string' ? { url: adjustedUrl } : { ...args, url: adjustedUrl };
    // Get the token from localStorage
    const token = localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user")!).token
        : null;
    // Set headers if token is available
    const headers = token ? { Authorization: `Bearer ${token}` } : {};
    // Merge headers with existing headers
    const mergedHeaders = { ...headers, ...extraOptions?.headers };
    // provide the amended url and other params to the raw base query
    return rawBaseQuery({ ...adjustedArgs, headers: mergedHeaders }, api, extraOptions)

}



export const VendorApi = createApi({
    reducerPath: 'VendorApi',
    baseQuery: dynamicBaseQuery,
    // baseQuery: fetchBaseQuery({
    //     baseUrl: baseUrl.BASE_URL + `/api/Vendor/`,
    //     prepareHeaders: (headers) => {
    //         // Get the token from localStorage
    //         const token = localStorage.getItem("user")
    //             ? JSON.parse(localStorage.getItem("user")!).token
    //             : null;
    //         if (token) {
    //             headers.set('Authorization', `Bearer ${token}`);
    //         }

    //         return headers;
    //     },
    // }),
    endpoints: (builder) => ({
        getAllVendor: builder.query({
            query: ({ pageNumber, pageSize, searchStr }) => {
                return {
                    url: `${API_END_POINTS.getAllVendor}?pageNumber=${pageNumber}&pageSize=${pageSize}&searchStr=${searchStr}`,
                    method: "GET",
                }
            },
        }),
        createVendor: builder.mutation({
            query: (body) => {
                return {
                    url: API_END_POINTS.addVendor,
                    method: "POST",
                    body,
                }
            },
        }),
        updateVendor: builder.mutation({
            query: (body) => {
                return {
                    url: API_END_POINTS.updateVendor,
                    method: "PUT",
                    body,
                }
            },
        }),
        getVendorLookUp: builder.query({
            query: () => {
                return {
                    url: API_END_POINTS.getAllVendorLookUp,
                    method: "GET",
                }
            },
        }),
        changeVendorStatus: builder.mutation({
            query: ({ vendorId, status }) => {
                return {
                    url: `${API_END_POINTS.changeVendorStatus}?vendorId=${vendorId}&status=${status}`,
                    method: "PUT",
                }
            },
        }),
    }),
})


export const {
    useGetAllVendorQuery,
    useCreateVendorMutation,
    useUpdateVendorMutation,
    useGetVendorLookUpQuery,
    useChangeVendorStatusMutation,
} = VendorApi