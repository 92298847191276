/* eslint-disable jsx-a11y/anchor-is-valid */
import TextField from "../../../../../components/textFields/TextField"
import { useGetApproversPolicyModeLookupQuery } from "../../../../../services/GeneralApi"
import labelKey from "../../../../localization/label.json"

type Props = {
  formik: any
}
const Step1 = ({ formik, }: Props) => {
  const { data: approvalpolicy } = useGetApproversPolicyModeLookupQuery('')
  return (
    <div className='current overflow-auto' style={{ height: "51vh" }} data-kt-stepper-element='content'>
      <div className='w-100 row h-min-content'>
        {/*begin::Form Group */}
        <div className='col-md-12 mb-5'>
          <TextField
            label={labelKey.organizationName}
            required={true}
            type="text"
            placeholder='Enter Organization Name'
            {...formik.getFieldProps('legalName')}
          />
        </div>
        {/*begin::Form Group */}
        <div className='col-md-12 mb-5'>
          <TextField
            label={labelKey.addressLine1}
            placeholder='Enter Address'
            type="text"
            {...formik.getFieldProps('legalAddrLine1')}
          />
        </div>

        <div className='col-md-12 mb-5'>
          <TextField
            label={labelKey.addressLine2}
            placeholder='Enter Address'
            type="text"
            {...formik.getFieldProps('legalAddrLine2')}
          />
        </div>

        <div className='col-lg-4 col-md-6 mb-5'>
          <TextField
            label={labelKey.city}
            placeholder='Enter City'
            type="text"
            {...formik.getFieldProps('legalCity')}
          />
        </div>
        <div className='col-lg-4 col-md-6 mb-5'>
          <TextField
            label={labelKey.state}
            placeholder='Enter State'
            type="text"
            {...formik.getFieldProps('legalState')}
          />
        </div>
        <div className='col-lg-4 col-md-6 mb-5'>
          <TextField
            label={labelKey.zipCode}
            placeholder='Enter Zip Code'
            type="text"
            {...formik.getFieldProps('legalZip')}
          />
        </div>
      </div>
    </div>
  )
}

export { Step1 };

