import React, { useEffect, useState } from 'react'
import { KTSVG } from '../../../_metronic/helpers'
import { useAddUpdateMileageMutation } from '../../../services/ExpenseApi'
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { toast } from 'react-toastify'
import { Modal } from 'react-bootstrap';
import moment from 'moment';
import labelKey from "../../localization/label.json"
import tosterKey from "../../localization/toster.json"
import TextField from '../../../components/textFields/TextField';

type Props = {
    show: boolean
    handleClose: () => void
    data?: any
}
const AddUpdateMileageModal = ({ show, handleClose, data }: Props) => {
    const [addMileage, { isLoading, isSuccess, isError, error }] = useAddUpdateMileageMutation()

    const initialValues = {
        id: 0,
        startedDate: '',
        endDate: '',
        ratePerKM: 0,
        ratePerMile: 0
    }


    const [updateId, setUpdateId] = useState(0);
    const [formValues, setFormValues] = useState(initialValues);
    // Store form values when the modal is closed
    const handleModalClose = () => {
        setFormValues(formik.values);
        handleClose();
    };
    useEffect(() => {
        // Restore form values when the modal reopens
        if (show && !data && Object.keys(formValues).length !== 0) {
            formik.setValues(formValues);
        }
        // Update form values based on data received
        if (data) {
            setUpdateId(data?.id);
            formik.setValues({ ...data })
        } else if (!show) {
            // Reset form values only when closing and it's not an update action
            setUpdateId(0);
            formik.resetForm();
        }
    }, [show, data]);
    const validationSchema = Yup.object().shape({
        startedDate: Yup.string().required('Start date is required'),
        // endDate: Yup.string().required('Ended date is required'),
        endDate: Yup.string()
            .required('End date is required')
            .test('date-comparison', 'Ended date must be greater than or equal to the started date', function (value) {
                const { startedDate } = this.parent;
                if (!startedDate || !value) return true; // Skip validation if either field is empty
                return moment(value).isSameOrAfter(moment(startedDate));
            }),
        ratePerKM: Yup.string().required('Rate PerKM is required'),
        ratePerMile: Yup.string().required('Rate PerMile is required'),

    })
    const formik = useFormik({
        initialValues,
        validationSchema: validationSchema,
        onSubmit: (values, { resetForm }) => {
            if (updateId) {
                addMileage(values)
            } else {
                addMileage(values)
                formik.resetForm();
            }
        }
    })

    useEffect(() => {
        if (isSuccess) {
            if (updateId) {
                toast.success(tosterKey.mileageUpdatedSuccessfully);
            } else {
                toast.success(tosterKey.mileageCreatedSuccessfully);
            }
            handleClose();
        }
    }, [isSuccess]);

    useEffect(() => {
        if (isError && error) {
            let errorMessage: string = 'Something went wrong';

            if ('data' in error && error.data && typeof error.data === 'object' && 'message' in error.data) {
                errorMessage = (error.data as { message: string }).message;
            }
            toast.error(errorMessage);
        }
    }, [isError, error]);
    return (
        <>
            <Modal
                aria-hidden='true'
                dialogClassName='modal-dialog modal-md modal-dialog-centered'
                show={show}
                onHide={handleModalClose}
                backdrop="static"
            >
                <div className='modal-header'>
                    <h2 className='mb-0'>{updateId ? <>{labelKey.updateMileage}</> : <>{labelKey.addMileage}</>}</h2>
                    {/* begin::Close */}
                    <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
                        <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                    </div>
                    {/* end::Close */}
                </div>

                <form
                    className='form w-100'
                    onSubmit={(e) => {
                        e.preventDefault();
                        formik.handleSubmit();
                        formik.setTouched({
                            startedDate: true,
                            endDate: true,
                            ratePerKM: true,
                            ratePerMile: true,
                        }, true); // Set touched for all fields to trigger validation display
                    }}
                >
                    <div className='modal-body py-lg-10 px-lg-10'>
                        <div className="row">
                            <div className='col-md-6 mb-5'>
                                <TextField
                                    label={labelKey.startDate}
                                    required={true}
                                    type='date'
                                    labelClass={`${formik.touched.startedDate && formik.errors.startedDate ? 'is-invalid' : ''}`}
                                    {...formik.getFieldProps('startedDate')}
                                    value={formik.values.startedDate ? moment(formik.values.startedDate).format('YYYY-MM-DD') : ''}
                                />
                                {formik.touched.startedDate && formik.errors.startedDate && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                            <span role='alert'>{formik.errors.startedDate}</span>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className='col-md-6 mb-5'>
                                <TextField
                                    label={labelKey.endDate}
                                    required={true}
                                    type='date'
                                    labelClass={`${formik.touched.endDate && formik.errors.endDate ? 'is-invalid' : ''}`}
                                    {...formik.getFieldProps('endDate')}
                                    value={formik.values.endDate ? moment(formik.values.endDate).format('YYYY-MM-DD') : ''}
                                    min={formik.values.startedDate ? moment(formik.values.startedDate).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')}
                                    max="9999-12-31"
                                />
                                {formik.touched.endDate && formik.errors.endDate && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                            <span role='alert'>{formik.errors.endDate}</span>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className='col-xl-12 mb-5'>
                                <TextField
                                    label={labelKey.ratePerKM}
                                    required={true}
                                    type='number'
                                    placeholder='Enter Rate'
                                    labelClass={`${formik.touched.ratePerKM && formik.errors.ratePerKM ? 'is-invalid' : ''}`}
                                    {...formik.getFieldProps('ratePerKM')}
                                    min={String(0)}
                                    value={String(formik.values.ratePerKM) || ''}
                                />
                                {formik.touched.ratePerKM && formik.errors.ratePerKM && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                            <span role='alert'>{formik.errors.ratePerKM}</span>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className='col-xl-12 mb-5'>
                                <TextField
                                    label={labelKey.ratePerMi}
                                    required={true}
                                    type='number'
                                    labelClass={`${formik.touched.ratePerMile && formik.errors.ratePerMile ? 'is-invalid' : ''}`}
                                    placeholder='Enter Rate'
                                    {...formik.getFieldProps('ratePerMile')}
                                    min={String(0)}
                                    value={String(formik.values.ratePerMile) || ''}
                                />
                                {formik.touched.ratePerMile && formik.errors.ratePerMile && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                            <span role='alert'>{formik.errors.ratePerMile}</span>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className='modal-footer justify-content-center'>
                        <button className='btn btn-light' onClick={handleClose}>{labelKey.cancel}</button>
                        <button
                            type='submit'
                            className='btn btn-primary mb-5 mt-5'
                            disabled={isLoading}
                        >
                            {!isLoading && <span className='indicator-label'>{updateId ? "Update" : "Submit"}</span>}
                            {isLoading && (
                                <span className='indicator-progress' style={{ display: 'block' }}>
                                    {labelKey.pleaseWait}...{' '}
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                            )}
                        </button>
                    </div>
                </form>
            </Modal>
        </>
    )
}

export default AddUpdateMileageModal