import React, { useEffect, useState } from 'react'
import { useCreateProjectMutation } from '../../../../services/ClientAndProjectsApi'
import { useAppSelector } from '../../../../hooks'
import { selectAuth } from '../../../../features/authSlice'
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { toast } from 'react-toastify'
import { Modal, Toast } from 'react-bootstrap';
import { KTSVG } from '../../../../_metronic/helpers';
import { useGetProjectStatusLookupQuery, useGetAllClientLookupQuery } from '../../../../services/GeneralApi';
import moment from 'moment';

type Props = {
    show: boolean
    handleClose: () => void
    data?: any
}
const AddUpdateProject = ({ show, handleClose, data }: Props) => {
    const { data: projectStatusLookup } = useGetProjectStatusLookupQuery('')
    const { data: getAllClientLookup } = useGetAllClientLookupQuery('')
    const [addProject, { isLoading, isSuccess, isError, error }] = useCreateProjectMutation()
    const { userAccountID } = useAppSelector(selectAuth);

    const initialValues = {
        projectId: 0,
        name: '',
        disc: '',
        startedDt: '',
        endDt: '',
        status: {
            id: 0,
            title: ''
        },
        budget: 0,
        client: {
            clientId: 0,
            name: '',
            email: '',
            contact: '',
            createdBy: 0
        },
        createdBy: userAccountID,
        createdDt: ''
    }
    const validationSchema = Yup.object().shape({
        name: Yup.string().required('Name is required'),
        // startedDt: Yup.string().required('Started date is required'),
        // endDt: Yup.string().required('Ended date is required'),
        startedDt: Yup.string().required('Started date is required'),
        endDt: Yup.string()
            .required('Ended date is required')
            .test('date-comparison', 'Ended date must be greater than or equal to the started date', function (value) {
                const { startedDt } = this.parent;
                if (!startedDt || !value) return true; // Skip validation if either field is empty
                return moment(value).isSameOrAfter(moment(startedDt));
            }),
        budget: Yup.string().required('Budget is required'),
        client: Yup.object().shape({
            clientId: Yup.string().required('Client is required'),
        }),
        status: Yup.object().shape({
            id: Yup.string().required('Status is required'),
        }),
    })

    const [updateId, setUpdateId] = useState(0);

    useEffect(() => {
        if (data) {
            setUpdateId(data?.projectId);
            formik.setValues({ ...data })
        } else {
            setUpdateId(0);
            formik.resetForm(); // Reset form values when no data is passed (add client mode)
        }
    }, [data]);
    const formik = useFormik({
        initialValues,
        validationSchema: validationSchema,
        onSubmit: (values, { resetForm }) => {
            

            if (updateId) {
                addProject(values)
            } else {
                addProject(values)
                formik.resetForm();
            }
        }
    })

    useEffect(() => {
        if (isSuccess) {
            if (updateId) {
                toast.success('Project Updated Successfully');
            } else {
                toast.success('Project Created Successfully');
            }
            handleClose();
        }
        if (isError) {
            toast.error('Some Thing Went Wrong');
        }
    }, [isSuccess, isError, updateId]);
    return (
        <>
            <Modal
                aria-hidden='true'
                dialogClassName='modal-dialog modal-md modal-dialog-centered'
                show={show}
                onHide={handleClose}
            >
                <div className='modal-header'>
                    <h2 className='mb-0'>{updateId ? "Update Project" : "Add Project"}</h2>
                    {/* begin::Close */}
                    <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
                        <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                    </div>
                    {/* end::Close */}
                </div>

                <form
                    className='form w-100'
                    onSubmit={formik.handleSubmit}
                >
                    <div className='modal-body py-lg-10 px-lg-10'>
                        <div className="row">
                            <div className='col-xl-12 mb-5'>
                                <label className='class="form-label fw-bold text-dark fs-6'>Name</label>
                                <input
                                    placeholder='Enter Name'
                                    type='text'
                                    autoComplete='off'
                                    className={`form-control form-control-lg form-control-solid ${formik.touched.name && formik.errors.name ? 'is-invalid' : ''
                                        }`}
                                    {...formik.getFieldProps('name')}
                                    value={formik.values.name || ''}
                                />
                                {formik.touched.name && formik.errors.name && (
                                    <div className='invalid-feedback'>{formik.errors.name}</div>
                                )}
                            </div>
                            <div className='col-md-6 mb-5'>
                                <label className='class="form-label fw-bold text-dark fs-6'>Started Date</label>
                                <input
                                    type='date'
                                    autoComplete='off'
                                    className={`form-control form-control-lg form-control-solid ${formik.touched.startedDt && formik.errors.startedDt ? 'is-invalid' : ''
                                        }`}
                                    {...formik.getFieldProps('startedDt')}
                                    value={formik.values.startedDt ? moment(formik.values.startedDt).format('YYYY-MM-DD') : ''}
                                    max="9999-12-31"
                                />
                                {formik.touched.startedDt && formik.errors.startedDt && (
                                    <div className='invalid-feedback'>{formik.errors.startedDt}</div>
                                )}
                            </div>
                            <div className='col-md-6 mb-5'>
                                <label className='class="form-label fw-bold text-dark fs-6'>Ended Date</label>
                                <input
                                    type='date'
                                    autoComplete='off'
                                    max="9999-12-31"
                                    className={`form-control form-control-lg form-control-solid ${formik.touched.endDt && formik.errors.endDt ? 'is-invalid' : ''
                                        }`}
                                    {...formik.getFieldProps('endDt')}
                                    value={formik.values.endDt ? moment(formik.values.endDt).format('YYYY-MM-DD') : ''}
                                    min={formik.values.startedDt ? moment(formik.values.startedDt).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')}
                                    onChange={formik.handleChange}
                                />
                                {formik.touched.endDt && formik.errors.endDt && (
                                    <div className='invalid-feedback'>{formik.errors.endDt}</div>
                                )}
                            </div>
                            <div className='col-xl-12 mb-5'>
                                <label className='class="form-label fw-bold text-dark fs-6'>Budget</label>
                                <input
                                    placeholder='Enter Budget'
                                    type='number'
                                    autoComplete='off'
                                    className={`form-control form-control-lg form-control-solid ${formik.touched.budget && formik.errors.budget ? 'is-invalid' : ''
                                        }`}
                                    {...formik.getFieldProps('budget')}
                                    value={formik.values.budget || ''}
                                />
                                {formik.touched.budget && formik.errors.budget && (
                                    <div className='invalid-feedback'>{formik.errors.budget}</div>
                                )}
                            </div>

                            <div className='col-xl-6 mb-5'>
                                <label className='class="form-label fw-bold text-dark fs-6'>Client</label>
                                <select
                                    className='form-select form-select-solid'
                                    {...formik.getFieldProps('client.clientId')}
                                    value={formik?.values?.client?.clientId || ''}
                                >
                                    <option value="">Select Client</option>
                                    {getAllClientLookup?.result?.map((data: any, index: any) => (
                                        <option value={data?.id}>{data?.value}</option>
                                    ))}
                                </select>
                                {formik.touched.client?.clientId && formik.errors.client?.clientId && (
                                    <div className='invalid-feedback'>{formik.errors.client?.clientId}</div>
                                )}
                            </div>
                            <div className='col-xl-6 mb-5'>
                                <label className='class="form-label fw-bold text-dark fs-6'>status</label>

                                <select
                                    className='form-select form-select-solid'
                                    {...formik.getFieldProps('status.id')}
                                    value={formik?.values?.status?.id || ''}
                                >
                                    <option value="">Select Status</option>
                                    {projectStatusLookup?.result?.map((data: any, index: any) => (
                                        <option value={data?.id}>{data?.value}</option>
                                    ))}
                                </select>
                                {formik.touched.status?.id && formik.errors.status?.id && (
                                    <div className='invalid-feedback'>{formik.errors.status?.id}</div>
                                )}
                            </div>
                            <div className='col-xl-12 mb-5'>
                                <label className='class="form-label fw-bold text-dark fs-6'>Discription</label>

                                <textarea
                                    cols={5}
                                    className='form-control form-control-solid'
                                    placeholder='Enter Discription'
                                    {...formik.getFieldProps('disc')}
                                    value={formik.values.disc || ''}
                                ></textarea>
                            </div>
                        </div>
                    </div>
                    <div className='modal-footer justify-content-center'>
                        <span className='btn btn-light' onClick={handleClose}>Cancel</span>
                        <button
                            type='submit'
                            className='btn btn-primary mb-5 mt-5'
                        // disabled={formik.isSubmitting || !formik.isValid}
                        >

                            {!isLoading && <span className='indicator-label'>{updateId ? "Update" : "Submit"}</span>}
                            {isLoading && (
                                <span className='indicator-progress' style={{ display: 'block' }}>
                                    Please wait...{' '}
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                            )}


                        </button>
                    </div>
                </form>
            </Modal>
        </>
    )
}

export default AddUpdateProject