/* eslint-disable jsx-a11y/anchor-is-valid */
import Multiselect from 'multiselect-react-dropdown';
import { useEffect, useState } from 'react';
import SelectField from '../../../../../components/textFields/SelectField';
import TextField from '../../../../../components/textFields/TextField';
import { useGetAllUsersLookupFilteredWorkFlowUsersQuery } from "../../../../../services/authApi";
import { useGetApproversPolicyModeLookupQuery } from "../../../../../services/GeneralApi";
import labelKey from "../../../../localization/label.json";
import { constraintConfig } from '../../../../../constraintConfig';

type Props = {
  formik: any
  updateId?: any
}
type User = {
  value: string;
  label: string;
};
const Step1 = ({ formik, updateId }: Props) => {
  const { data } = useGetApproversPolicyModeLookupQuery('')
  // const { data: getOnlyUser, refetch: usersRefetch } = useGetOnlyUserQuery('')
  const { data: getOnlyUser } = useGetAllUsersLookupFilteredWorkFlowUsersQuery({ wokrPolicyMode: formik.values.workflowPolicyType?.id })

  const [selected, setSelected] = useState<User[]>([]);
  useEffect(() => {
    // refetch()
    // usersRefetch()
  }, [])


  let options: User[] = [];

  if (getOnlyUser?.result && Array.isArray(getOnlyUser.result)) {
    options = getOnlyUser.result.map((user: any) => ({
      value: user?.value,
      label: user?.label
    }));
  }

  useEffect(() => {
    if (formik?.values?.userAccountIds && Array.isArray(formik?.values?.userAccountIds)) {
      setSelected(
        formik.values.userAccountIds
          .map((id: any) => {
            const selectedOption = options.find((option) => {
              return option?.value === id.toString();
            });

            if (selectedOption) {
              return {
                value: selectedOption.value,
                label: selectedOption.label,
              };
            }
            return null; // Return null for values that don't match any option
          })
          .filter(Boolean)
      );
    } else {
      setSelected([]); // Set an empty array if userAccountIds is undefined or not an array
    }
  }, [options]);


  return (
    <div className='current' data-kt-stepper-element='content'>
      <div className='w-100'>
        <div className="row m-0">
          <div className='col-md-6 mb-10'>
            <TextField
              label={labelKey.workflow}
              required={true}
              type='text'
              placeholder='Enter Workflow'
              {...formik.getFieldProps('workflowName')}
            />
          </div>
          <div className='col-md-6 mb-10'>
            <SelectField
              label={labelKey.workflowPolicy}
              required={true}
              {...formik.getFieldProps('workflowPolicyType.id')}
              disabled={updateId ? true : false}
            >
              <option value=''>select option</option>
              {data?.result.map((option: any, index: any) => (
                <option key={index} value={option.id}
                >{option?.value}</option>
              ))}
              {/* {data?.result.map((option: any, index: any) => {
                if (option.id === "1") {
                  return null; // Skip rendering the option for "Heirarchy" with id equal to 1
                }
                return <option key={index} value={option.id}>{option?.value}</option>;
              })} */}
            </SelectField>
          </div>
          {/* {['2', 2, '3', 3].includes(String(formik.values?.workflowPolicyType?.id)) && ( */}
          <>
            <div className='col-md-12 mb-10'>
              <label className='d-flex align-items-center fs-5 fw-bold mb-2 required'>{labelKey.users}</label>
              <Multiselect
                options={options}
                selectedValues={selected}
                onSelect={(selectedList) => {
                  setSelected(selectedList);
                  formik.setFieldValue('userAccountIds', selectedList.map((option: any) => option.value));
                }}
                onRemove={(selectedList) => {
                  setSelected(selectedList);
                  formik.setFieldValue('userAccountIds', selectedList.map((option: any) => option.value));
                }}
                displayValue="label"
                showArrow={true} // This line shows the drop-down arrow
                disable={parseInt(formik.values.workflowPolicyType?.id) === constraintConfig.workflowPolicyType.hierarchy ? true : false}
                className='default-input rounded'
              />
            </div>
          </>
          {/* )} */}
        </div>
      </div>
    </div >
  )
}

export { Step1 };

