import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { baseUrl } from './baseUrl'
import { API_END_POINTS } from './apiEndpoints';

export const authenticationApi = createApi({
    reducerPath: 'authenticationApi',
    baseQuery: fetchBaseQuery({
        baseUrl: baseUrl.MAIN_BASE_URL + `/api/Account/`,
        prepareHeaders: (headers) => {
            // Get the token from localStorage
            const token = localStorage.getItem("user")
                ? JSON.parse(localStorage.getItem("user")!).token
                : null;
            if (token) {
                headers.set('Authorization', `Bearer ${token}`);
            }
            return headers;
        },
    }),
    tagTypes: ['Auth'],
    endpoints: (builder) => ({
        loginUser: builder.mutation({
            query: (body: { username: string, password: string }) => {
                return {
                    url: API_END_POINTS.login,
                    method: "POST",
                    body,
                }
            },
        }),
        forgotPassword: builder.mutation({
            query: (body: { email: string }) => {
                return {
                    url: API_END_POINTS.forgotPassword,
                    method: "PUT",
                    body,
                }
            },
        }),
        updatePassword: builder.mutation({
            query: ({ ...rest }) => {
              return {
                url: API_END_POINTS.updatePassword,
                method: "PUT",
                body: rest,
              }
            },
          }),
    }),
})


export const {
    useLoginUserMutation,
    useForgotPasswordMutation,
    useUpdatePasswordMutation,

} = authenticationApi