/* eslint-disable jsx-a11y/anchor-is-valid */
import { constraintConfig } from "../../../../../constraintConfig"
import labelKey from "../../../../localization/label.json"

type Props = {
  formik: any
  getAllRolesData: any
}
const Step4 = ({ formik, getAllRolesData }: Props) => {
  return (
    <>
      <div data-kt-stepper-element='content' className="overflow-auto overflow-x-hidden" style={{ height: "51vh" }}>
        <div className='w-100'>
          <h1 className='fw-bold text-gray-800 mb-15 text-center'>{labelKey.review}!</h1>

          <div className="row">
            <div className="col-md-4">
              <h3 className="mb-5">Allowed Role Item</h3>
              <div className="d-flex align-items-center gap-5 mb-3">
                <p className="fw-bold fs-6 mb-0">{labelKey.role}:</p>
                <select
                  {...formik.getFieldProps(`userRole.roleID`)}
                  disabled
                  className="border-0 p-0 remove-select-arrow text-start text-dark bg-transparent fs-7"
                >
                  {getAllRolesData?.result?.map((option: any, index: any) => (
                    <option key={option.id} value={option.id}
                    >{option?.value}</option>
                  ))}
                </select>
              </div>
              <div className="d-flex align-items-center gap-5 mb-3">
                <p className="fw-bold fs-6 mb-0">{labelKey.allowedExpenseType}:</p>
                <span className="fs-7 text-dark">{formik.values.expenseType?.value}</span>
              </div>
              <div className="d-flex align-items-center gap-5 mb-3">
                <p className="fw-bold fs-6 mb-0">{labelKey.allowedMileageType}:</p>
                <span className="fs-7 text-dark">{formik.values.locationType?.value}</span>
              </div>
              {String(formik.values.userRole.roleID) !== constraintConfig.roleID.role2.toString() && String(formik.values.userRole.roleID) !== constraintConfig.roleID.role10.toString() &&
                <div className="d-flex align-items-center gap-5 mb-3">
                  <p className="fw-bold fs-6 mb-0">{labelKey.manager}:</p>
                  <span className="fs-7 text-dark">{formik.values?.managerName}</span>
                </div>
              }
              {String(formik.values.userRole.roleID) === constraintConfig.roleID.role4.toString() &&
                <div className="d-flex align-items-center gap-5 mb-3">
                  <p className="fw-bold fs-6 mb-0">{labelKey.approval$Limit}:</p>
                  <span className="fs-7 text-dark">{formik.values?.mgrApproveMaxLimit}</span>
                </div>
              }
              <div className="d-flex align-items-center gap-5 mb-3">
                <p className="fw-bold fs-6 mb-0">{labelKey.officeLocation}:</p>
                <span className="fs-7 text-dark">{formik.values?.location?.value}</span>
              </div>
              <div className="d-flex align-items-center gap-5 mb-3">
                <p className="fw-bold fs-6 mb-0">{labelKey.department}:</p>
                <span className="fs-7 text-dark">{formik.values?.department?.title}</span>
              </div>
              <div className="d-flex align-items-center gap-5 mb-3">
                <p className="fw-bold fs-6 mb-0">{labelKey.division}:</p>
                <span className="fs-7 text-dark">{formik.values?.division?.title}</span>
              </div>
              <div className="d-flex align-items-center gap-5 mb-3">
                <p className="fw-bold fs-6 mb-0">{labelKey.designation}:</p>
                <span className="fs-7 text-dark">{formik.values?.designation?.title}</span>
              </div>
            </div>
            <div className="col-md-4">
              <h3 className="mb-5">User Detail</h3>
              <div className="d-flex align-items-center gap-5 mb-3">
                <p className="fw-bold fs-6 mb-0">{labelKey.email}:</p>
                <span className="fs-7 text-dark">{formik.values?.email}</span>
              </div>
              <div className="d-flex align-items-center gap-5 mb-3">
                <p className="fw-bold fs-6 mb-0">{labelKey.employeeId}:</p>
                <span className="fs-7 text-dark">{formik.values?.employeeId}</span>
              </div>
              <div className="d-flex align-items-center gap-5 mb-3">
                <p className="fw-bold fs-6 mb-0">{labelKey.bcEmployeeId}:</p>
                <span className="fs-7 text-dark">{formik.values?.bcEmployeeId}</span>
              </div>
              <div className="d-flex align-items-center gap-5 mb-3">
                <p className="fw-bold fs-6 mb-0">{labelKey.firstName}:</p>
                <span className="fs-7 text-dark">{formik.values?.firstName}</span>
              </div>
              <div className="d-flex align-items-center gap-5 mb-3">
                <p className="fw-bold fs-6 mb-0">{labelKey.middleName}:</p>
                <span className="fs-7 text-dark">{formik.values?.middleName}</span>
              </div>
              <div className="d-flex align-items-center gap-5 mb-3">
                <p className="fw-bold fs-6 mb-0">{labelKey.lastName}:</p>
                <span className="fs-7 text-dark">{formik.values?.lastName}</span>
              </div>
              <div className="d-flex align-items-center gap-5 mb-3">
                <p className="fw-bold fs-6 mb-0">{labelKey.cellPhone}:</p>
                <span className="fs-7 text-dark">{formik.values?.cellPhone}</span>
              </div>
              <div className="d-flex align-items-center gap-5 mb-3">
                <p className="fw-bold fs-6 mb-0">{labelKey.businessPhone}:</p>
                <span className="fs-7 text-dark">{formik.values?.businessPhone}</span>
              </div>
              <div className="d-flex align-items-center gap-5 mb-3">
                <p className="fw-bold fs-6 mb-0">{labelKey.businessExt}:</p>
                <span className="fs-7 text-dark">{formik.values?.businessExt}</span>
              </div>
            </div>
            <div className="col-md-4">
              <h3 className="mb-5">User Address Detail</h3>
              <div className="d-flex align-items-center gap-5 mb-3">
                <p className="fw-bold fs-6 mb-0">{labelKey.findAddress}:</p>
                <span className="fs-7 text-dark">{formik.values?.findAddress}</span>
              </div>
              <div className="d-flex align-items-center gap-5 mb-3">
                <p className="fw-bold fs-6 mb-0">{labelKey.addressLine1}:</p>
                <span className="fs-7 text-dark">{formik.values?.addressLine1}</span>
              </div>
              <div className="d-flex align-items-center gap-5 mb-3">
                <p className="fw-bold fs-6 mb-0">{labelKey.addressLine2}:</p>
                <span className="fs-7 text-dark">{formik.values?.addressLine2}</span>
              </div>
              <div className="d-flex align-items-center gap-5 mb-3">
                <p className="fw-bold fs-6 mb-0">{labelKey.latitude}:</p>
                <span className="fs-7 text-dark">{formik.values.latitude}</span>
              </div>
              <div className="d-flex align-items-center gap-5 mb-3">
                <p className="fw-bold fs-6 mb-0">{labelKey.longitude}:</p>
                <span className="fs-7 text-dark">{formik.values.longitude}</span>
              </div>
              <div className="d-flex align-items-center gap-5 mb-3">
                <p className="fw-bold fs-6 mb-0">{labelKey.country}:</p>
                <span className="fs-7 text-dark">{formik.values.country?.title}</span>
              </div>
              <div className="d-flex align-items-center gap-5 mb-3">
                <p className="fw-bold fs-6 mb-0">{labelKey.city}:</p>
                <span className="fs-7 text-dark">{formik.values.city}</span>
              </div>
              <div className="d-flex align-items-center gap-5 mb-3">
                <p className="fw-bold fs-6 mb-0">{labelKey.state}:</p>
                <span className="fs-7 text-dark">{formik.values?.state}</span>
              </div>
              <div className="d-flex align-items-center gap-5 mb-3">
                <p className="fw-bold fs-6 mb-0">{labelKey.postalCode}:</p>
                <span className="fs-7 text-dark">{formik.values?.postalCode}</span>
              </div>
            </div>
          </div>

        </div>
      </div>
    </>
  )
}

export { Step4 };

