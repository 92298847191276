
import { useEffect, useState } from 'react'
import ReactApexChart from 'react-apexcharts'
import { KTSVG } from '../../../_metronic/helpers'
import TextField from '../../../components/textFields/TextField'
import { useGetExpenseReportByPaymentQuery, useGetExpenseReportByStatusQuery } from '../../../services/ReportAndAnalyticsApi'
import labelKey from "../../localization/label.json"
import { Tooltip } from 'react-tooltip'
import { FaInfoCircle } from 'react-icons/fa'
const ExpensePaymentMethodChart = (reportPaymentData: any) => {
    const pieChartSeries = reportPaymentData?.reportPaymentData?.result?.map((status: any) => status?.amount) || [];
    const pieChartOptions: ApexCharts.ApexOptions = {
        chart: {
            type: 'donut',
        },
        labels: reportPaymentData?.reportPaymentData?.result?.map((status: any) => status?.paymentName) || [],
        legend: {
            show: true,
            // position: 'bottom',
        },
        // colors: ['#3498db', '#07bc0c', '#e74c3c', '#f1c40f'],
        plotOptions: {
            pie: {
                donut: {
                    labels: {
                        show: true,
                        value: {
                            show: true,
                            formatter: function (val) {
                                return `$${Number(val).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
                            }
                        },
                        total: {
                            show: true,
                            label: labelKey.total,
                            formatter: function (w) {
                                const total = w.globals.seriesTotals.reduce((a: any, b: any) => {
                                    return a + b;
                                }, 0);
                                return `${'$' + Number(total).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
                            }
                        },
                    },
                },
            },
        },
        responsive: [
            {
                breakpoint: 480,
                options: {
                    chart: {
                        width: 250,
                    },
                    legend: {
                        position: 'bottom',
                    },
                },
            },
        ],
        tooltip: {
            y: {
                formatter: function (value: any) {
                    return `$${value.toLocaleString('en-US', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                    })}`;
                },
            },
        },
    };


    // console.log("Pie Chart Series:", pieChartSeries);

    return (
        <>
            <Tooltip id="chart" place="top" />
            <div className='mb-10'>
                <h2>{labelKey.expenseByPaymentMethod}
                    <FaInfoCircle className='text-gray cursor-pointer ms-2'
                        data-tooltip-id="chart" data-tooltip-content='The chart display data by default for the last 7 days.' />
                </h2>
            </div>
            {reportPaymentData?.reportPaymentData && reportPaymentData?.reportPaymentData.result && reportPaymentData?.reportPaymentData.result.length > 0 ? (
                <div className='approver-report mt-12 mb-10 d-flex d-md-inline justify-content-center'>
                    <ReactApexChart options={pieChartOptions} series={pieChartSeries} type="donut" height={300} />
                </div>
            ) : (
                <p className='text-center mt-5'>No data available for the chart.</p>
            )}
        </>
    )
}

export default ExpensePaymentMethodChart