import { useFormik } from 'formik'
import { useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { KTSVG } from '../../../_metronic/helpers'
import CurrencyComp from '../../../components/currencyComponent/CurrencyComp'
import DateComp from '../../../components/dateComponent/DateComp'
import { selectAuth } from '../../../features/authSlice'
import { useAppSelector } from '../../../hooks'
import { useAddItemToExpenseQuery, useAssigntoExistingExpenseMutation } from '../../../services/ExpenseApi'
import labelKey from "../../localization/label.json"
import tosterKey from "../../localization/toster.json"
import ReactReadMoreReadLess from "react-read-more-read-less"


type Props = {
    show: boolean
    handleClose: () => void
    selectedExpenseItemIds: any
}
const AssignToExistingExpenseModal = ({ show, handleClose, selectedExpenseItemIds }: Props) => {
    const { userAccountID } = useAppSelector(selectAuth);
    const [existingExpense, { isLoading, isSuccess, isError, error }] = useAssigntoExistingExpenseMutation()
    const { data: existingExpsnes, refetch: existingExpenseRefetch } = useAddItemToExpenseQuery({
        userAccountId: userAccountID
    });
    const initialValues = {
        expenseId: 0,
        itemIds: [
            0
        ]

    }
    const assignToExistingExpense = async (values: any) => {
        try {
            const response = await existingExpense({
                expenseId: values.expenseId,
                itemIds: selectedExpenseItemIds
            });
            // Handle success if needed
        } catch (err) {
            console.error('API Error:', err);
            // Handle error if needed
        }
    };
    const formik = useFormik({
        initialValues,
        onSubmit: (values, { resetForm }) => {

            if (!values.expenseId) {
                toast.error(tosterKey.pleaseSelectAnExistingExpense);
            } else {
                assignToExistingExpense(values);
            }
        }
    })
    useEffect(() => {
        if (isSuccess) {
            toast.success(tosterKey.expenseAddSuccessfully);
            handleClose();
        }

    }, [isSuccess]);
    useEffect(() => {
        if (isError && error) {
            let errorMessage: string = 'Something went wrong';

            if ('data' in error && error.data && typeof error.data === 'object' && 'message' in error.data) {
                errorMessage = (error.data as { message: string }).message;
            }
            toast.error(errorMessage);
        }
    }, [isError, error]);

    useEffect(() => {
        if (show) {
            existingExpenseRefetch()
        }
    }, [show])

    return (
        <>
            <Modal
                aria-hidden='true'
                dialogClassName='modal-dialog modal-lg modal-dialog-centered'
                show={show}
                onHide={handleClose}
                backdrop="static"
            >
                <div className='modal-header'>
                    <h2 className='mb-0'>{labelKey.existingExpenses}</h2>
                    {/* begin::Close */}
                    <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
                        <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                    </div>
                    {/* end::Close */}
                </div>

                <form
                    className='form w-100'
                    onSubmit={formik.handleSubmit}
                >
                    <div className='modal-body py-lg-10 px-lg-10'>
                        <div className="row">
                            <div className='table-responsive table-height mt-5'>
                                <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th>{labelKey.sr}</th>
                                            <th>{labelKey.title}</th>
                                            <th>{labelKey.amount}</th>
                                            <th className='w-200px'>{labelKey.expenseDetail}</th>
                                            <th>{labelKey.createdDate}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {existingExpsnes?.result?.map((data: any, index: any) => (
                                            <tr key={index}>
                                                <td>
                                                    <div className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                                                        <input type="radio" className='form-check-input'
                                                            {...formik.getFieldProps('expenseId')}
                                                            value={data.expenseId} />
                                                    </div>
                                                </td>
                                                <td>{index + 1}</td>
                                                <td>{data?.title}</td>
                                                <td> <CurrencyComp amount={data?.amount} /></td>
                                                <td className='text-wrap'>
                                                    {data && data.expenseDetail && <ReactReadMoreReadLess
                                                        charLimit={20}
                                                        readMoreText={"Read more"}
                                                        readLessText={"Read less"}
                                                        readMoreClassName="readMore"
                                                        readLessClassName="readLess"
                                                    >
                                                        {data.expenseDetail}
                                                    </ReactReadMoreReadLess>}</td>
                                                <td><DateComp formattedDate={data.createdDate} /></td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className='modal-footer justify-content-center'>
                        <span className='btn btn-light' onClick={handleClose}>{labelKey.cancel}</span>
                        <button
                            type='submit'
                            className='btn btn-primary mb-5 mt-5'
                            disabled={isLoading}
                        >

                            {!isLoading && <span className='indicator-label'>{labelKey.submit}</span>}
                            {isLoading && (
                                <span className='indicator-progress' style={{ display: 'block' }}>
                                    {labelKey.pleaseWait}...{' '}
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                            )}


                        </button>
                    </div>
                </form>
            </Modal>
        </>
    )
}

export default AssignToExistingExpenseModal