import React, { useRef, useState } from 'react';
import {
    useJsApiLoader,
    GoogleMap,
    Marker,
    Autocomplete,
    DirectionsRenderer,
} from '@react-google-maps/api';

const center = { lat: 48.8584, lng: 2.2945 };

const GoogleMapComponent: React.FC = () => {
   
    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: 'AIzaSyDRamKOTuCnMPw7b6BP2qdRWfuSJnIFpfI',
        libraries: ['places'],
    });

    const [map, setMap] = useState<google.maps.Map | null>(null);
    const [directionsResponse, setDirectionsResponse] = useState<google.maps.DirectionsResult | null>(null);
    const [distance, setDistance] = useState<string>('');
    const [duration, setDuration] = useState<string>('');

    const originRef = useRef<HTMLInputElement>(null);
    const destinationRef = useRef<HTMLInputElement>(null);

    async function calculateRoute() {
        if (!originRef.current || !destinationRef.current || originRef.current.value === '' || destinationRef.current.value === '') {
            return;
        }

        const directionsService = new window.google.maps.DirectionsService();
        const results = await directionsService.route({
            origin: originRef.current.value,
            destination: destinationRef.current.value,
            travelMode: window.google.maps.TravelMode.DRIVING,
        });

        setDirectionsResponse(results);
        setDistance(results.routes[0].legs[0].distance?.text || '');
        setDuration(results.routes[0].legs[0].duration?.text || '');
    }

    function clearRoute() {
        setDirectionsResponse(null);
        setDistance('');
        setDuration('');
        if (originRef.current) originRef.current.value = '';
        if (destinationRef.current) destinationRef.current.value = '';
    }

    return (
        <div>
            <div className='w-100 h-100'>
                {isLoaded && (
                    <GoogleMap
                        center={center}
                        zoom={15}
                        mapContainerStyle={{ width: '100%', height: '100%' }}
                        options={{
                            zoomControl: false,
                            streetViewControl: false,
                            mapTypeControl: false,
                            fullscreenControl: false,
                        }}
                        onLoad={(map) => setMap(map as google.maps.Map)}
                    >
                        <Marker position={center} />
                        {directionsResponse && (
                            <DirectionsRenderer directions={directionsResponse} />
                        )}
                    </GoogleMap>
                )}
            </div>
            {isLoaded && (
                <div className='d-flex'>
                    <Autocomplete>
                        <input type='text' className='form-control' placeholder='Origin' ref={originRef} />
                    </Autocomplete>
                    <Autocomplete>
                        <input
                            type='text' className='form-control'
                            placeholder='Destination'
                            ref={destinationRef}
                        />
                    </Autocomplete>
                </div>
            )}
            <div className='mt-5 d-flex gap-5'>
                <button type='submit' className='btn btn-primary' onClick={calculateRoute}>
                    Calculate Route
                </button>
                <button onClick={clearRoute} className='btn btn-primary'>Clear</button>
                <button
                    className='btn btn-primary'
                    onClick={() => {
                        if (map) {
                            map.panTo(center);
                            map.setZoom(15);
                        }
                    }}
                >
                    Center
                </button>
            </div>
            <div className='mt-5'>
                <p>Distance: {distance} </p>
                <p>Duration: {duration} </p>
            </div>
        </div>
    );
};

export default GoogleMapComponent;
