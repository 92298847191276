import React from 'react'
import { PageTitle } from '../../../../_metronic/layout/core'
import CategoryBudgetPage from './CategoryBudgetPage'
import labelKey from '../../../localization/label.json'

const CategoryBudgetWrapper = () => {
  return (
    <>
    <PageTitle breadcrumbs={[]}>{labelKey.categoryBudgetAllocation}</PageTitle>
    <CategoryBudgetPage />
  </>
  )
}

export default CategoryBudgetWrapper