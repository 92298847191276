import clsx from 'clsx'
import { useFormik } from 'formik'
import { useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import * as Yup from 'yup'
import labelKey from "../../../localization/label.json"
import { useForgotPasswordMutation } from '../../../../services/authenticationApi'

const initialValues = {
  email: '',
}

const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
})

export function ForgotPassword() {
  const themeModeMenuValue = localStorage.getItem('kt_theme_mode_menu');
  const navigate = useNavigate();
  const [forgotPassword, { data, isError, isSuccess, error, isLoading }] = useForgotPasswordMutation();

  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: (values) => {
      forgotPassword({ ...values })
    },
  })
  useEffect(() => {
    if (isSuccess) {
      toast.success('Request send check your email')
      navigate("/");
    }

  }, [isSuccess,])
  useEffect(() => {
    if (isError && error) {
      let errorMessage: string = 'Something went wrong';

      if ('data' in error && error.data && typeof error.data === 'object' && 'message' in error.data) {
        errorMessage = (error.data as { message: string }).message;
      }
      toast.error(errorMessage);
    }
  }, [isError, error]);
  return (
    <>
      <form
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        noValidate
        id='kt_login_password_reset_form'
        onSubmit={formik.handleSubmit}
      >
        <div className='text-center mb-10'>
          {themeModeMenuValue === 'dark' ?
            <img
              alt='Logo'
              src='/media/logos/logomain-white.png'
              className='h-50px me-7'
            /> :
            <img
              alt='Logo'
              src='/media/logos/logomain.png'
              className='h-50px me-7'
            />}
          {/* begin::Title */}
          <h1 className='text-dark mb-3 mt-5'>{labelKey.forgotPassword}?</h1>
          {/* end::Title */}

          {/* begin::Link */}
          <div className='text-gray-400 fw-semibold fs-4'>
            {labelKey.enterYourEmailToResetYourPassword}
          </div>
          {/* end::Link */}
        </div>

        {/* begin::Form group */}
        <div className='fv-row'>
          <label className='form-label fw-bold text-gray-900 fs-6'>{labelKey.email}</label>
          <input
            type='email'
            placeholder='Enter Email'
            autoComplete='off'
            {...formik.getFieldProps('email')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              { 'is-invalid': formik.touched.email && formik.errors.email },
              {
                'is-valid': formik.touched.email && !formik.errors.email,
              }
            )}
          />
          {formik.touched.email && formik.errors.email && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.email}</span>
              </div>
            </div>
          )}
        </div>
        <div>
          <Link to='/auth/login'
            className='link-primary fs-6 fw-bold mt-2'
            style={{ float: 'right' }}>
            {labelKey.backToLogin}
          </Link>
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <button
          type='submit'
          id='kt_password_reset_submit'
          className='btn btn-lg btn-primary fw-bold me-4 mt-10 w-100'
          disabled={isLoading}
        >
          {!isLoading && <span className='indicator-label'>{labelKey.submit}</span>}
          {isLoading && (
            <span className='indicator-progress' style={{ display: 'block' }}>
              Please wait...{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
        {' '}
        {/* end::Form group */}
      </form>
    </>
  )
}
