/* eslint-disable jsx-a11y/anchor-is-valid */
import { useMemo, useRef, useState } from 'react'
import Select from 'react-select'
import TextField from '../../../../../components/textFields/TextField'
import { baseUrl } from '../../../../../services/baseUrl'
import labelKey from "../../../../localization/label.json"
import {
  Autocomplete,
  Libraries,
  useJsApiLoader
} from '@react-google-maps/api'


type Props = {
  formik: any
  getAllCites: any
  getAllcountries: any
}
const Step3 = ({ formik, getAllCites, getAllcountries }: Props) => {
  const libraries: Libraries = useMemo(() => ['places'], []);
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: baseUrl.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: libraries,
  });
  const originRef = useRef<HTMLInputElement>(null);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>, ref: React.RefObject<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      if (ref.current && ref.current.value !== '') {
        if (ref === originRef) {

          formik.setFieldValue(`findAddress`, ref.current.value);
          // console.log('Selected value (Start Location):', ref.current.value);
        }
      }
    }
  };

  const handleLocationChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    ref: React.RefObject<HTMLInputElement>
  ) => {
    // Update Formik values based on the location type (startLocation or endLocation)
    const updatedLocation = event.target.value;

    if (ref === originRef) {
      formik.setFieldValue(`findAddress`, updatedLocation);
    }
  };

  const [selectedAddress, setSelectedAddress] = useState('');
  const [selectedLatitude, setSelectedLatitude] = useState('');
  const [selectedLongitude, setSelectedLongitude] = useState('');
  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedCity, setSelectedCity] = useState('');
  const [selectedState, setSelectedState] = useState('');
  const [selectedPostalCode, setSelectedPostalCode] = useState('');

  const originAutocompleteRef = useRef<google.maps.places.Autocomplete>();
  const handleStartPlaceChanged = () => {
    const place = originAutocompleteRef.current?.getPlace(); // Get the selected place

    // console.log('place', place);

    if (place && place.geometry && place.geometry.location) {
      const selectedLat = place.geometry.location.lat();
      const selectedLng = place.geometry.location.lng();

      // Update state variables
      setSelectedLatitude(selectedLat.toString());
      setSelectedLongitude(selectedLng.toString());

      formik.setFieldValue('latitude', selectedLat.toString());
      formik.setFieldValue('longitude', selectedLng.toString());
      const formattedAddress = place?.formatted_address ?? '';
      formik.setFieldValue('findAddress', formattedAddress);

      if (place.address_components) {
        let city = '';
        let country = '';
        let postalCode = '';
        let state = '';
        let streetNumber = '';
        let route = '';


        place.address_components.forEach(component => {
          if (component.types.includes('street_number')) {
            streetNumber = component.long_name;
          }
          if (component.types.includes('route')) {
            route = component.long_name;
          }
          if (component.types.includes('locality')) {
            city = component.long_name;
            setSelectedCity(city)
            formik.setFieldValue(`city`, city);
          }
          if (component.types.includes('country')) {
            country = component.long_name;
            setSelectedCountry(country)
            // formik.setFieldValue(`country`, country);

            // Find the matching country in the options and set it as selected
            const matchingCountry = getAllcountries?.result.find((option: any) => option.title === country);
            if (matchingCountry) {
              formik.setFieldValue(`country.id`, matchingCountry.id);
              formik.setFieldValue(`country.title`, matchingCountry.title);
            }
          }
          if (component.types.includes('postal_code')) {
            postalCode = component.long_name;
            setSelectedPostalCode(postalCode)
            formik.setFieldValue(`postalCode`, postalCode);
            // console.log('Postal Code:', postalCode);
          }
          if (component.types.includes('administrative_area_level_1')) {
            state = component.long_name;
            setSelectedState(state)
            formik.setFieldValue(`state`, state);
            // console.log('State:', state);
          }
        });
        const address = `${streetNumber} ${route}`.trim();
        setSelectedAddress(address)
        formik.setFieldValue(`addressLine1`, address);
        // console.log('City:', city);
        // console.log('Country:', country);
      }
    }
  };
  return (
    <>
      {/*begin::Step 3 */}
      <div className='pb-5 pe-3 overflow-auto overflow-x-hidden' style={{ height: "51vh" }} data-kt-stepper-element='content'>
        <div className='w-100'>
          <div className='row'>
            <div className="col-md-12 mb-5">
              <label className='fs-5 fw-bold mb-2 required'>
                {labelKey.findAddress}
              </label>
              {isLoaded && (
                <Autocomplete
                  onLoad={(autocomplete) => {
                    originAutocompleteRef.current = autocomplete;
                    autocomplete.addListener('place_changed', handleStartPlaceChanged);
                  }}
                  onPlaceChanged={() => {
                  }}>
                  <input type='text'
                    className='form-control form-control-lg form-control-solid default-input'
                    placeholder='Find a Address'
                    ref={originRef}
                    onKeyDown={(event) => handleKeyDown(event, originRef)}
                    onChange={(event) => handleLocationChange(event, originRef)}
                    value={formik.values.findAddress}
                  />
                </Autocomplete>
              )}
            </div>
          </div>
          <div className="row">

            <div className="col-md-6 mb-5">
              <TextField
                label={labelKey.addressLine1}
                type='text'
                placeholder='Address Line 1'
                {...formik.getFieldProps('addressLine1')}
                value={formik.values.addressLine1 || selectedAddress}
                disabled={true}
              />
            </div>
            <div className="col-md-6 mb-5">
              <TextField
                label={labelKey.addressLine2}
                type='text'
                placeholder='Enter Address Line 2'
                {...formik.getFieldProps('addressLine2')}
              />
            </div>
            <div className="col-md-6 mb-5">
              <TextField
                label={labelKey.latitude}
                type='text'
                placeholder='Latitude'
                {...formik.getFieldProps('latitude')}
                value={formik.values.latitude || selectedLatitude}
                disabled={true}
              />
            </div>
            <div className="col-md-6 mb-5">
              <TextField
                label={labelKey.longitude}
                type='text'
                placeholder='Longitude'
                {...formik.getFieldProps('longitude')}
                value={formik.values.longitude || selectedLongitude}
                disabled={true}
              />
            </div>

            <div className="col-md-6 mb-5">
              <label className='form-label fs-5 fw-bold'>{labelKey.country}</label>
              <Select
                options={[
                  ...(getAllcountries?.result || []).map((option: any, index: any) => ({
                    value: option.id,
                    label: option?.title,
                  })),
                ]}
                placeholder='Country'
                value={
                  formik.values.country?.id
                    ? {
                      value: formik.values.country?.id,
                      label: formik.values.country?.title || '',
                    }
                    : null
                }
                onChange={(selectedOption: any) => {
                  formik.setFieldValue(`country.id`, selectedOption?.value || null);
                  formik.setFieldValue(`country.title`, selectedOption?.label || null);
                }}
                isDisabled={true}
              />
              {/* <TextField
                label={labelKey.country}
                type='text'
                // placeholder='Enter Longitude'
                {...formik.getFieldProps('country')}
                value={formik.values.country || selectedCountry}
                disabled={true}
              /> */}
            </div>
            <div className="col-md-6 mb-5">
              {/* <label className='form-label fs-5 fw-bold'>{labelKey.city}</label>
              <Select
                options={[
                  ...(getAllCites?.result || []).map((option: any, index: any) => ({
                    value: option.id,
                    label: option?.title,
                  })),
                ]}
                placeholder='Select City'
                value={
                  formik.values.city?.id
                    ? {
                      value: formik.values.city?.id,
                      label: formik.values.city?.title || '',
                    }
                    : null
                }
                onChange={(selectedOption: any) => {
                  formik.setFieldValue(`city.id`, selectedOption?.value || null);
                  formik.setFieldValue(`city.title`, selectedOption?.label || null);
                }}
              /> */}
              <TextField
                label={labelKey.city}
                type='text'
                placeholder='City'
                {...formik.getFieldProps('city')}
                value={formik.values.city || selectedCity}
                disabled={true}
              />
            </div>
            <div className='col-lg-6 mb-5'>
              <TextField
                label={labelKey.state}
                type='text'
                placeholder='State'
                {...formik.getFieldProps('state')}
                value={formik.values.state || selectedState}
                disabled={true}
              />

            </div>
            <div className='col-lg-6 mb-5'>
              <TextField
                label={labelKey.postalCode}
                type='text'
                placeholder='Postal Code'
                {...formik.getFieldProps('postalCode')}
                value={formik.values.postalCode || selectedPostalCode}
                disabled={true}
              />
            </div>
          </div>
        </div>
      </div>
      {/*end::Step 3 */}
    </>
  )
}

export { Step3 }

