/* eslint-disable jsx-a11y/anchor-is-valid */
import labelKey from "../../../../localization/label.json"

type Props = {
  formik: any
}
const Step5 = ({ formik, }: Props) => {
  return (
    <>
      <div data-kt-stepper-element='content' className="overflow-auto" style={{ height: "51vh" }}>
        <div className='w-100 text-center'>
          <h1 className='fw-bold text-gray-800 mb-3'>{labelKey.review}!</h1>
          <div className="row m-0 mt-5">
            <div className="col-md-12">
              <div className="card">
                <div className="card-body d-flex flex-center flex-column p-0">
                  <div className="mb-5">
                    <div className="symbol symbol-75px symbol-circle">
                      <div className="symbol-label fs-3 bg-light-info text-info text-capitalize"
                      >
                        {formik.values.legalName.charAt(0)}
                      </div>
                    </div>
                  </div>
                  <p className="fs-4 text-gray-800 text-hover-primary fw-bold mb-0">
                    {formik.values.legalName}
                  </p>
                  <div className="fw-semibold text-gray-400 mb-6">{formik.values.contactPersonEmail}</div>

                  <div className="w-100  mb-5">
                    <div className="border border-gray-300 border-dashed rounded h-175px w-100 py-3 px-4 mx-3 mb-3">
                      <div className="d-flex align-items-center">
                        <p className="fs-6 fw-bold text-gray-700 mb-0 text-start w-50">{labelKey.addressLine1}:</p>
                        <span className="fw-semibold"> {formik.values.legalAddrLine1}</span>
                      </div>
                      <div className="d-flex align-items-center">
                        <p className="fs-6 fw-bold text-gray-700 mb-0 text-start w-50">{labelKey.addressLine2}:</p>
                        <span className="fw-semibold"> {formik.values?.legalAddrLine2}</span>
                      </div>
                      <div className="d-flex align-items-center">
                        <p className="fs-6 fw-bold text-gray-700 mb-0 text-start w-50">{labelKey.city}:</p>
                        <span className="fw-semibold"> {formik.values?.legalCity}</span>
                      </div>
                      <div className="d-flex align-items-center">
                        <p className="fs-6 fw-bold text-gray-700 mb-0 text-start w-50">{labelKey.state}:</p>
                        <span className="fw-semibold"> {formik.values?.legalState}</span>
                      </div>
                      <div className="d-flex align-items-center">
                        <p className="fs-6 fw-bold text-gray-700 mb-0 text-start w-50">{labelKey.zipCode}:</p>
                        <span className="fw-semibold"> {formik.values?.legalZip}</span>
                      </div>
                      <div className="d-flex align-items-center">
                        <p className="fs-6 fw-bold text-gray-700 mb-0 text-start w-50">{labelKey.personName}:</p>
                        <span className="fw-semibold"> {formik.values.contactPersonName}</span>
                      </div>
                      <div className="d-flex align-items-center">
                        <p className="fs-6 fw-bold text-gray-700 mb-0 text-start w-50">{labelKey.contactNo}:</p>
                        <span className="fw-semibold"> {formik.values?.contactPersonMobile}</span>
                      </div>
                      <div className="d-flex align-items-center">
                        <p className="fs-6 fw-bold text-gray-700 mb-0 text-start w-50">{labelKey.domainName}:</p>
                        <span className="fw-semibold"> {formik.values?.domainName}</span>
                      </div>
                      <div className="d-flex align-items-center">
                        <p className="fs-6 fw-bold text-gray-700 mb-0 text-start w-50">{labelKey.webUrl}:</p>
                        <span className="fw-semibold"> {formik.values?.webURL}</span>
                      </div>
                    </div>

                    <div className="border border-gray-300 border-dashed rounded h-175px w-100 py-3 px-4 mx-3 mb-3">
                      <div>
                        <p className="fs-6 fw-bold text-gray-700 mb-0 text-start w-50">{labelKey.onboardingNotes}:</p>
                        <p className="fw-semibold overflow-auto text-start" style={{ maxHeight: "138px" }}> {formik.values.onboardingNotes}</p>
                      </div>
                    </div>
                    <div className="border border-gray-300 border-dashed rounded h-175px w-100 py-3 px-4 mx-3 mb-3">
                      <div>
                        <p className="fs-6 fw-bold text-gray-700 mb-0 text-start w-50">{labelKey.internalNotes}:</p>
                        <p className="fw-semibold overflow-auto text-start" style={{ maxHeight: "138px" }}> {formik.values?.internalNotes}</p>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
              <div className="card">
                <div className="card-body d-flex flex-center flex-column p-0">
                  <div className="mb-5">
                    <div className="symbol symbol-75px symbol-circle">
                      <div className="symbol-label fs-3 bg-light-info text-info text-capitalize"
                      >
                        {formik.values.orgAdminUser.firstName.charAt(0)}
                      </div>
                    </div>
                  </div>
                  <p className="fs-4 text-gray-800 text-hover-primary fw-bold mb-0">
                    {formik.values.orgAdminUser.firstName}&nbsp;
                    {formik.values.orgAdminUser.middleName}&nbsp;
                    {formik.values.orgAdminUser.lastName}
                  </p>
                  <div className="fw-semibold text-gray-400 mb-6">{formik?.values?.orgAdminUser?.email}</div>

                  <div className="w-100 mb-5">
                    <div className="border border-gray-300 border-dashed rounded h-175px w-100 py-3 px-4 mx-3 mb-3">
                      <div className="d-flex align-items-center">
                        <p className="fs-6 fw-bold text-gray-700 mb-0 text-start w-50">{labelKey.cellPhone}:</p>
                        <span className="fw-semibold"> {formik.values.orgAdminUser.cellPhone}</span>
                      </div>
                      <div className="d-flex align-items-center">
                        <p className="fs-6 fw-bold text-gray-700 mb-0 text-start w-50">{labelKey.businessPhone}:</p>
                        <span className="fw-semibold"> {formik.values.orgAdminUser.businessPhone}</span>
                      </div>
                      <div className="d-flex align-items-center">
                        <p className="fs-6 fw-bold text-gray-700 mb-0 text-start w-50">{labelKey.businessExt}:</p>
                        <span className="fw-semibold"> {formik.values.orgAdminUser.businessExt}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export { Step5 };

