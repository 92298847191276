import moment from 'moment';
import React from 'react';

interface Props {
    formattedDate?: string;
}

const DateTimeComp: React.FC<Props> = ({ formattedDate }) => {
    const formattedDateTimeString = formattedDate
        ? moment(formattedDate).format("MM-DD-YYYY HH:mm:ss")
        : '';
    return (
        <>{formattedDateTimeString}</>
    );
}

export default DateTimeComp;
