/* eslint-disable jsx-a11y/anchor-is-valid */
import ReactInputMask from 'react-input-mask';
import labelKey from "../../../../localization/label.json"
import TextField from '../../../../../components/textFields/TextField';
import clsx from 'clsx';

type Props = {
  formik: any
}
const Step2 = ({ formik, }: Props) => {

  return (
    <div className='pb-5 pe-3 overflow-auto overflow-x-hidden' style={{ height: "51vh" }} data-kt-stepper-element='content'>
      <div className='w-100'>
        <div className="row">
          <div className="col-md-4 mb-5">
            <TextField
              label={labelKey.email}
              required={true}
              type='email'
              placeholder='Enter Email'
              {...formik.getFieldProps('email')}
            />
          </div>
          <div className="col-md-4 mb-5">
            <TextField
              label={labelKey.employeeId}
              required={true}
              type='text'
              placeholder='Enter Employee ID'
              {...formik.getFieldProps('employeeId')}
            />
          </div>
          <div className="col-md-4 mb-5">
            <TextField
              label={labelKey.bcEmployeeId}
              type='text'
              placeholder='Enter Bc Employee ID'
              {...formik.getFieldProps('bcEmployeeId')}
            />
          </div>
          <div className='col-lg-4 mb-5'>
            <TextField
              label={labelKey.firstName}
              required={true}
              type='text'
              placeholder='Enter First Name'
              {...formik.getFieldProps('firstName')}
            />

          </div>
          <div className='col-lg-4 mb-5'>
            <TextField
              label={labelKey.middleName}
              type='text'
              placeholder='Enter Middle Name'
              {...formik.getFieldProps('middleName')}
            />
          </div>
          <div className='col-lg-4'>
            <div className='fv-row mb-5'>
              <TextField
                label={labelKey.lastName}
                required={true}
                type='text'
                placeholder='Enter Last Name'
                {...formik.getFieldProps('lastName')}
              />
            </div>
          </div>
          <div className='col-lg-4'>
            <div className='fv-row mb-5'>
              <label className='form-label fs-5 fw-bold'>{labelKey.cellPhone}</label>
              <ReactInputMask
                mask="(9999) 999-9999"
                placeholder='Cell Phone'
                type='text'
                autoComplete='off'
                {...formik.getFieldProps('cellPhone')}
                value={formik.values.cellPhone || ''}
                className={clsx('form-control form-control-lg form-control-solid default-input')}
              />
            </div>
          </div>
          <div className='col-lg-4'>
            <div className='fv-row mb-5'>
              <label className='form-label fs-5 fw-bold'>{labelKey.businessPhone}</label>
              <ReactInputMask
                mask="(9999) 999-9999"
                placeholder='Business Phone'
                type='text'
                autoComplete='off'
                {...formik.getFieldProps('businessPhone')}
                value={formik.values.businessPhone || ''}
                className={clsx('form-control form-control-lg form-control-solid default-input')}
              />
            </div>
          </div>
          <div className='col-lg-4'>
            <div className='fv-row mb-5'>
              <label className='form-label fs-5 fw-bold'>{labelKey.businessExt}</label>
              <ReactInputMask
                mask="9999"
                placeholder='Business Ext'
                type='text'
                autoComplete='off'
                {...formik.getFieldProps('businessExt')}
                value={formik.values.businessExt || ''}
                className='form-control form-control-lg form-control-solid default-input'
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export { Step2 };

