import axios from 'axios'
import clsx from 'clsx'
import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import InputMask from 'react-input-mask'
import Select from 'react-select'
import { toast } from 'react-toastify'
import * as Yup from 'yup'
import { KTSVG } from '../../../_metronic/helpers'
import SelectField from '../../../components/textFields/SelectField'
import TextField from '../../../components/textFields/TextField'
import { constraintConfig } from '../../../constraintConfig'
import { selectAuth } from '../../../features/authSlice'
import { useAppSelector } from '../../../hooks'
import { useDepartmentsLookupQuery, useGetAllLocationTypeQuery, useGetAllLocationsQuery, useGetAllLst_UserExpenseTypeQuery, useGetAllOrganizationLookUpQuery } from '../../../services/GeneralApi'
import { useGetOrganizationAllRolesQuery } from '../../../services/OrganizationApi'
import { useCreateUserMutation, useGetAllManagerQuery } from '../../../services/authApi'
import labelKey from "../../localization/label.json"
import tosterKey from "../../localization/toster.json"



type Props = {
    show: boolean
    handleClose: () => void
}

const AddUserModal = ({ show, handleClose }: Props) => {

    const { roleID, userInOrgId, token, baseURL } = useAppSelector(selectAuth);
    const { data: getAllOrganizationLookUp, refetch: orgLookUpRefetch } = useGetAllOrganizationLookUpQuery('');
    const { data: getAllManger, refetch: ManagersRefetch } = useGetAllManagerQuery('');
    const { data: departmentLookup, refetch: departmentRefetch } = useDepartmentsLookupQuery('');
    // const { data: designationLookup } = useDesignationLookupQuery(formik.values.role.roleID);
    const { data: getAllRolesData, refetch: rolesRefetch } = useGetOrganizationAllRolesQuery('');
    const [addContact, { isSuccess: addIsSuccess, isError, error, isLoading: addIsLoading }] = useCreateUserMutation()
    const { data: getAllExpenseType } = useGetAllLst_UserExpenseTypeQuery('')
    const { data: getAllLocationType } = useGetAllLocationTypeQuery('')
    const { data: locationsData, refetch: locationRefetch } = useGetAllLocationsQuery('')


    useEffect(() => {
        if (show) {
            orgLookUpRefetch()
            ManagersRefetch()
            departmentRefetch()
            locationRefetch()
            rolesRefetch()
        }
    }, [show, orgLookUpRefetch, ManagersRefetch, departmentRefetch, locationRefetch, rolesRefetch])
    const validationSchema = Yup.object().shape({
        role: Yup.object().shape({
            roleID: Yup.number().min(1, 'Role is required').required('Role is required'),
        }),
        expenseType: Yup.object().shape({
            id: Yup.number().min(1, 'Expense Type is required').required('Expense Type is required'),
        }),
        locationType: Yup.object().shape({
            id: Yup.number().min(1, 'Location Type is required').required('Location Type is required'),
        }),

        mgrApproveMaxLimit: Yup.number()
            .when(['role.roleID'], {
                is: (roleID: any) => {
                    // console.log("Current roleID:", roleID);
                    return roleID === constraintConfig.roleID.role4;
                },
                then: Yup.number().min(1, 'Approval Limit must be greater than 0').required('Approval Limit is required'),
                otherwise: Yup.number().nullable(), // Allow null value for other roles
            }),
        email: Yup.string().email('Wrong email format').required('Email is required'),
        // department: Yup.object().shape({
        //     id: Yup.number().min(1, 'Department is required').required('Department is required'),
        // }),
        // designation: Yup.object().shape({
        //     id: Yup.number().min(1, 'Designation is required').required('Designation is required'),
        // }),
        firstName: Yup.string().required('First Name is required'),
        lastName: Yup.string().required('Last Name is required'),
        // employeeId: Yup.string().required('Employee ID is required'),
        employeeId: Yup.string()
            .required('Employee ID is required')
            .min(1, 'Employee ID should not be empty')
            .test('is-unique', 'Employee ID should be unique', function (value) {
                if (value !== undefined && value !== null) {
                    // Check if the value is not equal to '0' or doesn't consist only of '0's
                    return value !== '0' && !/^0+$/.test(value);
                }
                return false; // Return false for undefined or null values
            }),

    });

    const initialValues = {
        orgId: userInOrgId,
        role: {
            roleID: 0,
            name: ''
        },
        expenseType: {
            id: 0,
            value: ''
        },
        locationType: {
            id: 0,
            value: ''
        },
        department: {
            id: 0,
            title: ''
        },
        designation: {
            id: 0,
            title: ''
        },
        location: {
            id: 0,
            value: ''
        },
        mgrApproveMaxLimit: 0,
        employeeId: '',
        email: '',
        firstName: '',
        middleName: '',
        lastName: '',
        cellPhone: '',
        businessPhone: '',
        businessExt: '',
        active: true,
        managerId: 0,
        managerName: '',
    }

    const formik = useFormik({
        initialValues,
        validationSchema: validationSchema,
        onSubmit: (values, { resetForm }) => {
            // addContact({ ...values });
            if (roleID === constraintConfig.roleID.role1 || roleID === constraintConfig.roleID.role2) {
                if (String(values.role.roleID) === constraintConfig.roleID.role5.toString() && !values.managerId) {
                    toast.error(tosterKey.managerIsRequired);
                }
                // else if (String(values.role.roleID) === constraintConfig.roleID.role4.toString() && !values.managerId) {
                //     toast.error(tosterKey.managerIsRequired);
                // }
                else {
                    addContact({ ...values });
                }
            }
            else {
                addContact({ ...values });
            }
        },
    })
    const { resetForm } = formik;

    useEffect(() => {
        if (addIsSuccess) {
            toast.success(tosterKey.userCreatedSuccessfully);
            resetForm()
            handleClose()
        }
    }, [addIsSuccess]);

    useEffect(() => {
        if (isError && error) {
            let errorMessage: string = 'Something went wrong';

            if ('data' in error && error.data && typeof error.data === 'object' && 'message' in error.data) {
                errorMessage = (error.data as { message: string }).message;
            }
            toast.error(errorMessage);
        }
    }, [isError, error]);

    const [designationLookup, setDesignationLookup] = useState<any>(null);
    // const { data: designationLookup } = useDesignationLookupQuery(formik.values.role.roleID);
    useEffect(() => {
        const fetchData = async () => {
            if (show && formik.values.role.roleID) {
                try {
                    const headers = {
                        Authorization: `Bearer ${token}`,
                    };

                    const getExpenseLogResponse = await axios.get(
                        `${baseURL}/api/General/GetAllDesignation?roleId=${formik.values.role.roleID}`,
                        {
                            headers,
                        }
                    );
                    const designationLookup = getExpenseLogResponse.data;
                    setDesignationLookup(designationLookup);
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            }
        };
        fetchData();
    }, [show, formik.values.role.roleID, token]);

    const handleModalClose = () => {
        resetForm();
        handleClose();
    };
    return (
        <>
            <Modal
                aria-hidden='true'
                dialogClassName='modal-dialog modal-lg modal-dialog-centered'
                show={show}
                onHide={handleModalClose}
                backdrop="static"
            >
                <div className='modal-header'>
                    <h2 className='mb-0'>{labelKey.addExpenseUser}</h2>
                    <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
                        <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                    </div>
                </div>
                <form
                    className='form w-100'
                    onSubmit={formik.handleSubmit}
                >
                    <div className='modal-body py-lg-10 px-lg-10'>

                        <div className='row fv-row'>
                            {roleID === constraintConfig.roleID.role1 &&
                                <>
                                    <div className="col-md-12 mb-5">
                                        <label className='form-label fs-5 fw-bold required '>{labelKey.organization}</label>
                                        <Select
                                            options={[
                                                ...(getAllOrganizationLookUp?.result || []).map((option: any, index: any) => ({
                                                    value: option.id,
                                                    label: option?.value,
                                                })),
                                            ]}
                                            placeholder='Select Organization'
                                            onChange={(selectedOption: any) => {
                                                formik.setFieldValue('orgId', selectedOption.value);
                                            }}
                                        />
                                    </div>

                                </>
                            }
                            {/* ${roleID === constraintConfig.roleID.role1 ? '6' : '12'} */}
                            <div className={`col-md-4 mb-5`}>
                                <SelectField
                                    label={labelKey.role}
                                    required={true}
                                    selectClass={`${formik.touched.role?.roleID && formik.errors.role?.roleID ? 'is-invalid' : ''
                                        }`}
                                    {...formik.getFieldProps('role.roleID')}
                                >
                                    <option value="">{labelKey.selectRole}</option>
                                    <>
                                        {roleID === constraintConfig.roleID.role1 && (
                                            <>
                                                {getAllRolesData?.result.map((option: any, index: any) => {
                                                    if (option.value === "Administrator" && option.id === 1 || option.value === "Manager" && option.id === 4 || option.value === "Accounts Payable" && option.id === 10 || option.value === "User" && option.id === 5) {
                                                        return null; // Skip rendering the option for "Administrator" with id equal to 1
                                                    }
                                                    return <option key={index} value={option.id}>{option.value}</option>;
                                                })}
                                            </>
                                        )}
                                        {roleID === constraintConfig.roleID.role2 && (
                                            <>
                                                {getAllRolesData?.result.map((option: any, index: any) => {
                                                    if (option.value === "Administrator" && option.id === 1 || option.value === "Org Admin" && option.id === 2) {
                                                        return null; // Skip rendering the option for "Administrator" with id equal to 1
                                                    }
                                                    return <option key={index} value={option.id}>{option.value}</option>;
                                                })}
                                            </>
                                        )}
                                        {roleID === constraintConfig.roleID.role4 && (
                                            <>
                                                {getAllRolesData?.result.map((option: any, index: any) => {
                                                    if (option.value === "Administrator" && option.id === 1 || option.value === "Org Admin" && option.id === 2 || option.value === "Manager" && option.id === 4 || option.value === "Accounts Payable" && option.id === 10) {
                                                        return null; // Skip rendering the option for "Administrator" with id equal to 1
                                                    }
                                                    return <option key={index} value={option.id}>{option.value}</option>;
                                                })}
                                            </>
                                        )}
                                    </>
                                </SelectField>
                                {formik.touched.role?.roleID && formik.errors.role?.roleID && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                            <span role='alert'>{formik.errors.role?.roleID}</span>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className='col-md-4 mb-5'>
                                <SelectField
                                    label={labelKey.allowedExpenseType}
                                    required={true}
                                    {...formik.getFieldProps(`expenseType.id`)}
                                // value={formik.values.expenseType?.id}
                                >
                                    <option value="">Select Type</option>
                                    {getAllExpenseType?.result?.map((option: any, index: any) => (
                                        <option key={option.id} value={option.id}
                                        >{option?.value}</option>
                                    ))}
                                </SelectField>
                                {formik.touched.expenseType?.id && formik.errors.expenseType?.id && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                            <span role='alert'>{formik.errors.expenseType?.id}</span>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className='col-md-4 mb-5'>
                                <SelectField
                                    label={labelKey.allowedMileageType}
                                    required={true}
                                    {...formik.getFieldProps(`locationType.id`)}
                                >
                                    <option value="">Select Type</option>
                                    {getAllLocationType?.result?.map((option: any, index: any) => (
                                        <option key={option.id} value={option.id}
                                        >{option?.value}</option>
                                    ))}
                                </SelectField>
                                {formik.touched.locationType?.id && formik.errors.locationType?.id && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                            <span role='alert'>{formik.errors.locationType?.id}</span>
                                        </div>
                                    </div>
                                )}
                            </div>

                            {['4', '5'].includes(String(formik.values?.role?.roleID)) && (
                                <>
                                    {roleID !== constraintConfig.roleID.role5 && roleID !== constraintConfig.roleID.role4 && (
                                        <div className={`${String(formik.values?.role?.roleID) === String(constraintConfig.roleID.role5) ? 'col-md-12' : 'col-md-6'} mb-5`}>
                                            <label className={`${String(formik.values?.role?.roleID) === String(constraintConfig.roleID.role5) ? 'required' : ''} form-label fs-5 fw-bold`}>
                                                {labelKey.manager}&nbsp;
                                                {String(formik.values?.role?.roleID) === String(constraintConfig.roleID.role4) ? <span className='fs-8 text-gray'>(optional)</span> : ''}
                                            </label>
                                            <Select
                                                options={(getAllManger?.result || []).map((option: any) => ({
                                                    value: option.orgUserID,
                                                    label: option.firstName + (option.middleName ? ' ' + option.middleName : '') + ' ' + option.lastName,
                                                    key: option.id,
                                                }))}
                                                placeholder='Select Manager'
                                                value={
                                                    formik.values?.managerId
                                                        ? {
                                                            value: formik.values?.managerId,
                                                            label: formik.values?.managerName || '',
                                                        }
                                                        : null
                                                }
                                                onChange={(selectedOption: any) => {
                                                    formik.setFieldValue('managerId', selectedOption.value);
                                                    formik.setFieldValue('managerName', selectedOption.label);
                                                }}
                                            />
                                        </div>
                                    )}
                                </>
                            )}
                            {String(formik.values?.role?.roleID) === '4' && (
                                <div className="col-md-6 mb-5">
                                    <TextField
                                        label={labelKey.approval$Limit}
                                        iconTooltip="Current User Approval Limit"
                                        required={true}
                                        type='number'
                                        placeholder='Enter Approval Limit'
                                        labelClass={`${formik.touched.mgrApproveMaxLimit && formik.errors.mgrApproveMaxLimit ? 'is-invalid' : ''}`}
                                        {...formik.getFieldProps('mgrApproveMaxLimit')}
                                    />
                                    {formik.touched.mgrApproveMaxLimit && formik.errors.mgrApproveMaxLimit && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>
                                                <span role='alert'>{formik.errors.mgrApproveMaxLimit}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )}

                            <div className="col-md-4 mb-5">
                                <label className='form-label fs-5 fw-bold'>{labelKey.department}</label>
                                <Select
                                    options={[
                                        ...(departmentLookup?.result || []).map((option: any, index: any) => ({
                                            value: option.id,
                                            label: option?.value,
                                        })),
                                    ]}
                                    placeholder='Select Department'
                                    onChange={(selectedOption: any) => {
                                        formik.setFieldValue('department.id', selectedOption.value);
                                    }}
                                />
                                {/* {formik.touched.department?.id && formik.errors.department?.id && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                            <span role='alert'>{formik.errors.department?.id}</span>
                                        </div>
                                    </div>
                                )} */}
                            </div>
                            <div className="col-md-4 mb-5">
                                <label className='form-label fs-5 fw-bold'>{labelKey.designation}</label>
                                <Select
                                    options={[
                                        ...(designationLookup?.result || []).map((option: any, index: any) => ({
                                            value: option.id,
                                            label: option?.value,
                                        })),
                                    ]}
                                    placeholder='Select Designation'
                                    onChange={(selectedOption: any) => {
                                        formik.setFieldValue('designation.id', selectedOption.value);
                                    }}
                                />
                                {/* {formik.touched.designation?.id && formik.errors.designation?.id && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                            <span role='alert'>{formik.errors.designation?.id}</span>
                                        </div>
                                    </div>
                                )} */}
                            </div>
                            <div className='col-md-4 b-5'>
                                <label className='fs-5 fw-bold mb-2 required'>
                                    {labelKey.location}
                                </label>
                                <Select
                                    options={(locationsData?.result || []).map((option: any, index: any) => ({
                                        value: option.id,
                                        label: option?.value,
                                        key: option.id,
                                    }))}
                                    value={
                                        formik.values.location?.id
                                            ? {
                                                value: formik.values.location?.id,
                                                label: formik.values.location?.value || '',
                                            }
                                            : null
                                    }
                                    onChange={(selectedOption: any) => {
                                        formik.setFieldValue(`location.id`, selectedOption?.value || null);
                                        formik.setFieldValue(`location.value`, selectedOption?.label || null);
                                    }}
                                />

                            </div>
                            <div className="col-md-6 mb-5">
                                <TextField
                                    label={labelKey.email}
                                    required={true}
                                    type='email'
                                    placeholder='Enter Email'
                                    labelClass={`${formik.touched.email && formik.errors.email ? 'is-invalid' : ''}`}
                                    {...formik.getFieldProps('email')}
                                />
                                {formik.touched.email && formik.errors.email && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                            <span role='alert'>{formik.errors.email}</span>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className="col-md-6 mb-5">
                                <TextField
                                    label={labelKey.employeeId}
                                    required={true}
                                    type='text'
                                    placeholder='Enter Employee ID'
                                    labelClass={`${formik.touched.employeeId && formik.errors.employeeId ? 'is-invalid' : ''}`}
                                    {...formik.getFieldProps('employeeId')}
                                />
                                {formik.touched.employeeId && formik.errors.employeeId && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                            <span role='alert'>{formik.errors.employeeId}</span>
                                        </div>
                                    </div>
                                )}
                            </div>

                        </div>
                        <div className='row fv-row'>

                            <div className='col-lg-4 mb-5'>
                                <TextField
                                    label={labelKey.firstName}
                                    required={true}
                                    type='text'
                                    placeholder='Enter First Name'
                                    labelClass={`${formik.touched.firstName && formik.errors.firstName ? 'is-invalid' : ''}`}
                                    {...formik.getFieldProps('firstName')}
                                />
                                {formik.touched.firstName && formik.errors.firstName && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                            <span role='alert'>{formik.errors.firstName}</span>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className='col-lg-4 mb-5'>
                                <TextField
                                    label={labelKey.middleName}
                                    type='text'
                                    placeholder='Enter Middle Name'
                                    {...formik.getFieldProps('middleName')}
                                />
                            </div>
                            <div className='col-lg-4'>
                                <div className='fv-row mb-5'>
                                    <TextField
                                        label={labelKey.lastName}
                                        required={true}
                                        type='text'
                                        placeholder='Enter Last Name'
                                        labelClass={`${formik.touched.lastName && formik.errors.lastName ? 'is-invalid' : ''}`}
                                        {...formik.getFieldProps('lastName')}
                                    />
                                    {formik.touched.lastName && formik.errors.lastName && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>
                                                <span role='alert'>{formik.errors.lastName}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className='col-lg-4'>
                                <div className='fv-row mb-5'>
                                    <label className='form-label fs-5 fw-bold'>{labelKey.cellPhone}</label>
                                    <InputMask
                                        mask="(9999) 999-9999"
                                        placeholder='Cell Phone'
                                        type='text'
                                        autoComplete='off'
                                        {...formik.getFieldProps('cellPhone')}
                                        value={formik.values.cellPhone || ''}
                                        className={clsx('form-control form-control-lg form-control-solid default-input')}
                                    />
                                </div>
                            </div>
                            <div className='col-lg-4'>
                                <div className='fv-row mb-5'>
                                    <label className='form-label fs-5 fw-bold'>{labelKey.businessPhone}</label>
                                    <InputMask
                                        mask="(9999) 999-9999"
                                        placeholder='Business Phone'
                                        type='text'
                                        autoComplete='off'
                                        {...formik.getFieldProps('businessPhone')}
                                        value={formik.values.businessPhone || ''}
                                        className={clsx('form-control form-control-lg form-control-solid default-input')}
                                    />
                                </div>
                            </div>
                            <div className='col-lg-4'>
                                <div className='fv-row mb-5'>
                                    <label className='form-label fs-5 fw-bold'>{labelKey.businessExt}</label>
                                    <InputMask
                                        mask="9999"
                                        placeholder='Business Ext'
                                        type='text'
                                        autoComplete='off'
                                        {...formik.getFieldProps('businessExt')}
                                        value={formik.values.businessExt || ''}
                                        className='form-control form-control-lg form-control-solid default-input'
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='modal-footer justify-content-center'>
                        <button
                            type='button'
                            className='btn btn-light'
                            onClick={handleClose}
                        >
                            {labelKey.cancel}
                        </button>
                        <button
                            type='submit'
                            className='btn btn-lg btn-primary'
                            disabled={addIsLoading}
                        >
                            {!addIsLoading && <span className='indicator-label'>{labelKey.submit}</span>}
                            {addIsLoading && (
                                <span className='indicator-progress' style={{ display: 'block' }}>
                                    {labelKey.pleaseWait}...{' '}
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                            )}
                        </button>
                    </div>
                </form>
            </Modal>
        </>
    )
}

export default AddUserModal