import React from 'react'
import ReactPaginate from 'react-paginate'
import labelKey from "../../app/localization/label.json"

interface props {
    totalResult: any
    toResult: any
    ofResult: any
    onChange: any
    pageSize: any
    onPageChange: any
    pageCount: any
}
const Pagination = ({ totalResult, toResult, ofResult, onChange, pageSize, onPageChange, pageCount }: props) => {
    return (
        <>
            <div className="d-flex flex-stack flex-wrap pt-10 align-items-center">
                <div className="fs-6 fw-bold text-gray-700 d-flex gap-2 mt-2">
                    <span>{labelKey.showing}</span>
                    <span>{totalResult}</span>
                    <span>{labelKey.to}</span>
                    <span>{toResult}</span>
                    <span>{labelKey.of}</span>
                    <span>{ofResult}</span>
                    <span>{labelKey.entries}</span>
                </div>


                <div className='d-flex align-items-center gap-3'>
                    <div className='d-flex align-items-center'>
                        <label className='fs-6 text-gray-700 fw-semibold me-2'>{labelKey.perPage}</label>
                        <select value={pageSize} className='form-select form-select-solid h-40px w-80px' onChange={onChange}>
                            <option value="10">10</option>
                            <option value="15">15</option>
                            <option value="20">20</option>
                            <option value="25">25</option>
                            <option value="30">30</option>
                            <option value="100">100</option>
                        </select>
                    </div>
                    <ReactPaginate
                        breakLabel={"..."}
                        nextLabel="Next"
                        onPageChange={(e) => onPageChange(e)}
                        pageRangeDisplayed={5}
                        marginPagesDisplayed={1}
                        pageCount={pageCount}
                        previousLabel="Previous"
                        containerClassName='pagination'
                        pageClassName='page-item'
                        previousClassName='page-item'
                        nextClassName='page-item'
                        breakClassName='page-item'
                        pageLinkClassName='page-link'
                        previousLinkClassName='page-link'
                        nextLinkClassName='page-link'
                        breakLinkClassName='page-link'
                        activeClassName='active'
                    />
                </div>
            </div>
        </>
    )
}

export default Pagination