
import ReactApexChart from 'react-apexcharts';

const AdminManagerPieChart = ({ getAdminDashboard }: any) => {

  // Extract data from the API response
  const allManager = getAdminDashboard?.result?.allManager || 0;
  const activeManager = getAdminDashboard?.result?.activeManager || 0;

  // Chart options
  const pieChartOptions: ApexCharts.ApexOptions = {
    chart: {
      type: 'donut' as 'donut',
    },
    labels: ['Active Managers', 'In Active Managers'],
    colors: ['#00945F', '#0A47E6'],
    legend: {
      show: true,
      position: 'bottom',
      horizontalAlign: 'center',
      offsetX: 10,
      offsetY: 0,
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 250,
          },
          legend: {
            position: 'bottom',
            horizontalAlign: 'center',
            offsetX: 0,
            offsetY: 0,
          },
        },
      },
    ],
    tooltip: {
      y: {
        formatter: function (value: any) {
          return value.toLocaleString('en-US');
        },
      },
    },
  };

  const inActiveManagerPercentage = (allManager - activeManager);

  // Chart series data
  const pieChartSeries = [activeManager, inActiveManagerPercentage];

  return (
    <div className='d-flex justify-content-center w-100'>
      {allManager === 0 && activeManager === 0 ? (
        <p className='mt-15'>Chart does not have any values to display.</p>
      ) : (
        <div className='legend-center'>
          <ReactApexChart options={pieChartOptions} series={pieChartSeries} type="donut" height={300} />
        </div>
      )}
    </div>
  )
}

export default AdminManagerPieChart