/* eslint-disable jsx-a11y/anchor-is-valid */

import ReactInputMask from 'react-input-mask';
import TextField from '../../../../../components/textFields/TextField';
import labelKey from "../../../../localization/label.json";


type Props = {
    formik: any
}
const Step4 = ({ formik, }: Props) => {
    return (
        <>
            {/*begin::Step 3 */}
            <div className='pb-5 pe-3 overflow-auto' style={{ height: "51vh" }} data-kt-stepper-element='content'>
                <div className='w-100 row h-min-content'>
                    <div className='col-md-12 mb-5'>
                        <TextField
                            label={labelKey.email}
                            required={true}
                            placeholder='Enter Email'
                            type="email"
                            {...formik.getFieldProps('orgAdminUser.email')}
                        />
                    </div>

                    <div className='col-md-6 mb-5'>
                        <TextField
                            label={labelKey.firstName}
                            required={true}
                            placeholder='Enter First Name'
                            type="text"
                            {...formik.getFieldProps('orgAdminUser.firstName')}
                        />
                    </div>

                    <div className='col-md-6 mb-5'>
                        <TextField
                            label={labelKey.middleName}
                            placeholder='Enter Middle Name'
                            type="text"
                            {...formik.getFieldProps('orgAdminUser.middleName')}
                        />
                    </div>

                    <div className='col-md-6 mb-5'>
                        <TextField
                            label={labelKey.lastName}
                            placeholder='Enter Last Name'
                            type="text"
                            {...formik.getFieldProps('orgAdminUser.lastName')}
                        />
                    </div>

                    <div className='col-md-6 mb-5'>
                        <label className='form-label fs-5 fw-bold'>{labelKey.cellPhone}</label>
                        <ReactInputMask
                            mask="(999) 999-9999"
                            maskChar=""
                            type="text"
                            className="form-control form-control-lg form-control-solid"
                            placeholder="Enter Cell Phone"
                            {...formik.getFieldProps('orgAdminUser.cellPhone')}
                        />
                    </div>

                    <div className='col-md-6 mb-5'>
                        <label className='form-label fs-5 fw-bold'>{labelKey.businessPhone}</label>
                        <ReactInputMask
                            mask="(999) 999-9999"
                            maskChar=""
                            type="text"
                            className="form-control form-control-lg form-control-solid"
                            placeholder="Enter Business Phone"
                            {...formik.getFieldProps('orgAdminUser.businessPhone')}
                        />
                    </div>

                    <div className='col-md-6 mb-5'>
                        <label className='form-label fs-5 fw-bold'>{labelKey.businessExt}</label>
                        <ReactInputMask
                            mask="9999"
                            maskChar=""
                            type="text"
                            className="form-control form-control-lg form-control-solid"
                            placeholder="Enter Business Ext"
                            {...formik.getFieldProps('orgAdminUser.businessExt')}
                        />
                    </div>
                </div>
            </div>
            {/*end::Step 3 */}
        </>
    )
}

export { Step4 };

