import 'bootstrap-daterangepicker/daterangepicker.css'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { DateRangePicker } from 'react-bootstrap-daterangepicker'
import { KTSVG } from '../../../_metronic/helpers'
import { constraintConfig } from '../../../constraintConfig'
import { selectAuth } from '../../../features/authSlice'
import { useAppSelector } from '../../../hooks'
import { useExpenseSummaryQuery, useGetExpenseReportByDepartmentWithAmountQuery, useGetExpenseReportByPaymentQuery, useGetExpenseReportByUserWithAmountQuery } from '../../../services/ReportAndAnalyticsApi'
import labelKey from "../../localization/label.json"
import ExpenseCategoryChart from './ExpenseCategoryChart'
import ExpenseDepartmentChart from './ExpenseDepartmentChart'
import ExpensePaymentMethodChart from './ExpensePaymentMethodChart'
import ExpenseReportByUser from './ExpenseReportByUser'
import ExpensesAmountChart from './ExpensesAmountChart'
import { debounce } from 'lodash'
import { Dropdown, Spinner } from 'react-bootstrap'

const ExpenseSummary = () => {
    const { userAccountID, roleID } = useAppSelector(selectAuth)
    const [filterByStartDate, setFilterByStartDate] = useState("");
    const [filterByEndDate, setFilterByEndDate] = useState("");
    const [localFilterByStartDate, setLocalFilterByStartDate] = useState("");
    const [localFilterByEndDate, setLocalFilterByEndDate] = useState("");
    const [isApplyingFilters, setIsApplyingFilters] = useState(false);

    const { data, refetch,isSuccess } = useExpenseSummaryQuery({
        userAccountId: userAccountID,
        startDate: filterByStartDate,
        endDate: filterByEndDate,
    })
    const { data: reportPaymentData, refetch: reportPaymentDataRefetch } = useGetExpenseReportByPaymentQuery({
        startDate: filterByStartDate,
        endDate: filterByEndDate,
    })
    const { data: reportByUsersData, refetch: reportByUsersDataRefetch } = useGetExpenseReportByUserWithAmountQuery({
        startDate: filterByStartDate,
        endDate: filterByEndDate,
    })
    const { data: reportByDeptData, refetch: reportByDeptDataRefetch } = useGetExpenseReportByDepartmentWithAmountQuery({
        startDate: filterByStartDate,
        endDate: filterByEndDate,
    })

    useEffect(() => {
        refetch();
        reportPaymentDataRefetch();
        if (roleID === constraintConfig?.roleID?.role4 && filterByStartDate && filterByEndDate) {
            reportByUsersDataRefetch();
            reportByDeptDataRefetch();
        }
        if (filterByStartDate || filterByEndDate) {
            refetch();
            reportPaymentDataRefetch();
            // reportByUsersDataRefetch();
            // reportByDeptDataRefetch();
        }

    }, [roleID, filterByStartDate, filterByEndDate]);

    const handleDateRangeChange = (event: any, picker: any) => {
        const startDateFormatted = moment(picker.startDate).format('MM-DD-YYYY');
        const endDateFormatted = moment(picker.endDate).format('MM-DD-YYYY');

        setLocalFilterByStartDate(startDateFormatted);
        setLocalFilterByEndDate(endDateFormatted);
    };
    const handleCancelDateSelection = (event: any, picker: any) => {
        // Reset the state to empty strings
        setFilterByStartDate("");
        setFilterByEndDate("");
        setLocalFilterByStartDate("");
        setLocalFilterByEndDate("")
    };

    const applyFilters = () => {
        setIsApplyingFilters(true);
        setFilterByStartDate(localFilterByStartDate);
        setFilterByEndDate(localFilterByEndDate);
    };
    const resetFilters = () => {
        setFilterByStartDate('')
        setFilterByEndDate('')
        setLocalFilterByStartDate("");
        setLocalFilterByEndDate("")
        closeMenu();
    }
    const debouncedRefetch = debounce(() => {
        refetch().finally(() => {
            setIsApplyingFilters(false);
        });
    }, 300);
    useEffect(() => {
        if (isApplyingFilters) {
            debouncedRefetch();
        }
        if (isSuccess) {
            closeMenu();
        }
    }, [isApplyingFilters,isSuccess]);

    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(prevState => !prevState);
    };
    const closeMenu = () => {
        setIsMenuOpen(false);
    };
    return (
        <>
            <div className='card mb-5 mb-xl-10'>
                <div className='card-body p-9 collapse show' id='expenseSummary' >
                    <div className="row">
                        <div className="col-md-12">
                            <div className='d-flex justify-content-end'>
                                <div className='position-relative'>
                                    <button
                                        type='button'
                                        className='btn btn-sm btn-light-primary me-3 d-flex justify-content-end'
                                        // data-kt-menu-trigger='click'
                                        data-kt-menu-placement='bottom-end'
                                        onClick={toggleMenu}
                                    >
                                        <KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-2' />
                                        {labelKey.filter}
                                    </button>
                                    <div className={`menu menu-sub menu-sub-dropdown w-300px w-md-325px' ${isMenuOpen ? 'show position-absolute mt-3' : ''}`} style={{right:"5px"}}>
                                        <div className='px-7 py-5'>
                                            <div className='fs-5 text-dark fw-bold'>{labelKey.filterOptions}</div>
                                        </div>

                                        <div className='separator border-gray-200'></div>

                                        <div className='px-7 py-5' data-kt-user-table-filter='form'>
                                            <div>
                                                <label className='form-label fw-bold text-dark fs-6'>Date</label>
                                                <DateRangePicker onApply={handleDateRangeChange} onCancel={handleCancelDateSelection}>
                                                    <input type="text"
                                                        className="form-control form-control-lg form-control-solid default-input"
                                                        value={localFilterByStartDate && localFilterByEndDate ? `${localFilterByStartDate} - ${localFilterByEndDate}` : "mm/dd/yyyy"} />
                                                </DateRangePicker>
                                            </div>
                                        </div>
                                        <div className='separator border-gray-200'></div>
                                        <div className='px-7 py-5'>
                                            <button className='btn btn-primary pull-right' onClick={applyFilters}>
                                                {isApplyingFilters ? <>
                                                    <span>loading...</span>
                                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                                </> : "Apply Filters"}
                                            </button>
                                            <button className='btn btn-danger pull-right me-3' onClick={resetFilters}>Reset Filters</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <ExpensesAmountChart data={data} />
                        <ExpenseCategoryChart data={data} />
                    </div>
                    {/* <div className="row">
                        <div className="col-lg-6">
                            <div className='p-9' style={{ boxShadow: "0px 1px 4px 0px #d6e1e6", borderRadius: "10px" }}>
                                <ExpensePaymentMethodChart />
                            </div>
                        </div>
                    </div> */}

                    <div className="row">

                        <div className="col-lg-6 mb-10">
                            <div className='p-9 h-100' style={{ boxShadow: "0px 1px 4px 0px #d6e1e6", borderRadius: "10px" }}>
                                <ExpensePaymentMethodChart reportPaymentData={reportPaymentData} />
                            </div>
                        </div>
                        {roleID === constraintConfig?.roleID?.role4 &&
                            <>
                                <div className="col-lg-6 mb-10">
                                    <div className='p-9' style={{ boxShadow: "0px 1px 4px 0px #d6e1e6", borderRadius: "10px" }}>
                                        <ExpenseReportByUser reportByUsersData={reportByUsersData} />
                                    </div>
                                </div>
                                <div className="col-lg-6 mb-10">
                                    <div className='p-9 h-100' style={{ boxShadow: "0px 1px 4px 0px #d6e1e6", borderRadius: "10px" }}>
                                        <ExpenseDepartmentChart reportByDeptData={reportByDeptData} />
                                    </div>
                                </div>
                            </>}
                    </div>


                    {/* {roleID !== constraintConfig?.roleID?.role5 &&
                        <div className="row">
                            <div className="col-md-12 col-lg-6 mb-5 mb-xl-10 mt-10">
                                <div className='p-3' style={{ boxShadow: "0px 1px 4px 0px #d6e1e6", borderRadius: "10px" }}>
                                    <ExpenseReportStatusChart />
                                </div>
                            </div>
                        </div>
                    } */}
                </div>
            </div>

        </>
    )
}

export default ExpenseSummary