import type {
    BaseQueryFn,
    FetchArgs,
    FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react';
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { selectAuth } from "../features/authSlice";
import { RootState } from "../store";
import { API_END_POINTS } from './apiEndpoints';
import { baseUrl } from './baseUrl';

const rawBaseQuery = fetchBaseQuery({
    baseUrl: baseUrl.MAIN_BASE_URL,
})

type ExtraOptions = {
    headers?: Record<string, string>;
    // Define other optional properties here if needed
};

const dynamicBaseQuery: BaseQueryFn<
    string | FetchArgs,
    unknown,
    FetchBaseQueryError,
    ExtraOptions
> = async (args, api, extraOptions) => {
    const orgBaseUrl = selectAuth(api.getState() as RootState).baseURL;
    // gracefully handle scenarios where data to generate the URL is missing
    if (!orgBaseUrl) {
        return {
            error: {
                status: 400,
                statusText: 'Bad Request',
                data: 'dynamic route not found',
            },
        }
    }

    const urlEnd = typeof args === 'string' ? args : args.url

    // construct a dynamically generated portion of the url
    const adjustedUrl = `${orgBaseUrl}/api/General${urlEnd}`
    const adjustedArgs = typeof args === 'string' ? { url: adjustedUrl } : { ...args, url: adjustedUrl };
    // Get the token from localStorage
    const token = localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user")!).token
        : null;
    // Set headers if token is available
    const headers = token ? { Authorization: `Bearer ${token}` } : {};
    // Merge headers with existing headers
    const mergedHeaders = { ...headers, ...extraOptions?.headers };
    // provide the amended url and other params to the raw base query
    return rawBaseQuery({ ...adjustedArgs, headers: mergedHeaders }, api, extraOptions)

}

export const GeneralApi = createApi({
    reducerPath: 'GeneralApi',
    baseQuery: dynamicBaseQuery,
    // baseQuery: fetchBaseQuery({
    //     baseUrl: baseUrl.BASE_URL + `/api/General/`,
    //     prepareHeaders: (headers) => {
    //         // Get the token from localStorage
    //         const token = localStorage.getItem("user")
    //             ? JSON.parse(localStorage.getItem("user")!).token
    //             : null;
    //         if (token) {
    //             headers.set('Authorization', `Bearer ${token}`);
    //         }
    //         return headers;
    //     },
    // }),
    endpoints: (builder) => ({
        familyMemberRealtionshipLookup: builder.query({
            query: () => {
                return {
                    url: API_END_POINTS.getAllFamilyRelationship,
                    method: "GET"
                }
            },
        }),
        familyMemberStatusLookup: builder.query({
            query: () => {
                return {
                    url: API_END_POINTS.getAllFamilyMemberStatus,
                    method: "GET"
                }
            },
        }),

        expenseCategoryLookup: builder.query({
            query: () => {
                return {
                    url: API_END_POINTS.getAllExpenseCategoryLookup,
                    method: "GET"
                }
            },
        }),
        expenseSubCategoryLookup: builder.query({
            query: ({ categoryId }) => {
                return {
                    url: `${API_END_POINTS.getExpenseSubCategory}?categoryId=${categoryId}`,
                    method: "GET"
                }
            },
        }),
        allCurrencyLookup: builder.query({
            query: () => {
                return {
                    url: API_END_POINTS.getAllCurrency,
                    method: "GET"
                }
            },
        }),
        expenseStatusLookup: builder.query({
            query: () => {
                return {
                    url: API_END_POINTS.getAllExpenseStatus,
                    method: "GET"
                }
            },
        }),
        departmentsLookup: builder.query({
            query: () => {
                return {
                    url: API_END_POINTS.getAllDepartment,
                    method: "GET"
                }
            },
        }),
        designationLookup: builder.query({
            query: (roleId) => {
                return {
                    url: `${API_END_POINTS.getAllDesignation}?roleId=${roleId}`,
                    method: "GET"
                }
            },
        }),
        ExpenseItemType: builder.query({
            query: (categoryId) => {
                return {
                    url: `${API_END_POINTS.getAllExpenseItemType}?categoryId=${categoryId}`,
                    method: "GET"
                }
            },
        }),
        workflowApproverStatus: builder.query({
            query: () => {
                return {
                    url: API_END_POINTS.getAllWorkflowApproverStatus,
                    method: "GET"
                }
            },
        }),
        getManagerTeamLookup: builder.query({
            query: ({ managerId }) => {
                return {
                    url: `${API_END_POINTS.getManagerTeamLookup}?managerId=${managerId}`,
                    method: "GET"
                }
            },
        }),
        getPaymentMethodLookup: builder.query({
            query: ({ managerId }) => {
                return {
                    url: API_END_POINTS.getAllExpensePaymentMethods,
                    method: "GET"
                }
            },
        }),
        getProjectStatusLookup: builder.query({
            query: () => {
                return {
                    url: API_END_POINTS.getAllProjectStatus,
                    method: "GET"
                }
            },
        }),
        getAllClientLookup: builder.query({
            query: () => {
                return {
                    url: API_END_POINTS.getAllClientsLookup,
                    method: "GET"
                }
            },
        }),
        getAllCategory: builder.query({
            query: () => {
                return {
                    url: API_END_POINTS.getAllCategory,
                    method: "GET"
                }
            },
        }),
        updateAllCategory: builder.mutation({
            query: (body) => {
                return {
                    url: API_END_POINTS.updateAllCategory,
                    method: "PUT",
                    body
                }
            },
        }),
        getAllExpenseType: builder.query({
            query: () => {
                return {
                    url: API_END_POINTS.getAllExpenseType,
                    method: "GET",
                }
            },
        }),
        getAllDesignationWithoutRole: builder.query({
            query: () => {
                return {
                    url: API_END_POINTS.getAllDesignationWithoutRole,
                    method: "GET",
                }
            },
        }),
        configCategoryUserLevel: builder.mutation({
            query: (body) => {
                return {
                    url: API_END_POINTS.configCategoryUserLevel,
                    method: "POST",
                    body
                }
            },
        }),
        getConfigCategoryWithUser: builder.query({
            query: ({ userAccountId }) => {
                return {
                    url: `${API_END_POINTS.getConfigCategoryByUser}?userAccountId=${userAccountId}`,
                    method: "GET",
                }
            },
        }),
        updateUserCategory: builder.mutation({
            query: (body) => {
                return {
                    url: API_END_POINTS.updateUserCategory,
                    method: "PUT",
                    body
                }
            },
        }),
        getAllNotificationPreferences: builder.query({
            query: () => {
                return {
                    url: API_END_POINTS.getAllNotificationPreferences,
                    method: "GET",
                }
            },
        }),
        getAllDistancePreferences: builder.query({
            query: () => {
                return {
                    url: API_END_POINTS.getAllDistancePreferences,
                    method: "GET",
                }
            },
        }),
        getApproversPolicyModeLookup: builder.query({
            query: () => {
                return {
                    url: API_END_POINTS.getDefaultApproversPolicyModeLookup,
                    method: "GET",
                }
            },
        }),
        getAllOrganizationLookUp: builder.query({
            query: () => {
                return {
                    url: API_END_POINTS.getAllOganizationLookup,
                    method: "GET"
                }
            },
        }),
        getOnboardingStatusLookup: builder.query({
            query: () => {
                return {
                    url: API_END_POINTS.getOnboardingStatusLookup,
                    method: "GET"
                }
            },
        }),
        // createExpenseCategory: builder.mutation({
        //     query: (body) => {
        //         return {
        //             url: API_END_POINTS.createExpenseCategory,
        //             method: "POST",
        //             body
        //         }
        //     },
        // }),
        // updateExpenseCategory: builder.mutation({
        //     query: (body) => {
        //         return {
        //             url: API_END_POINTS.updateExpenseCategory,
        //             method: "PUT",
        //             body
        //         }
        //     },
        // }),
        getAllLst_UserExpenseType: builder.query({
            query: () => {
                return {
                    url: API_END_POINTS.getAllLst_UserExpenseType,
                    method: "GET"
                }
            },
        }),
        getAllLocations: builder.query({
            query: () => {
                return {
                    url: API_END_POINTS.getAllLocations,
                    method: "GET"
                }
            },
        }),
        getAllLocationType: builder.query({
            query: () => {
                return {
                    url: API_END_POINTS.getAllLocationType,
                    method: "GET"
                }
            },
        }),
        getAllTripType: builder.query({
            query: () => {
                return {
                    url: API_END_POINTS.getAllTripType,
                    method: "GET"
                }
            },
        }),
        getDistanceByTripType: builder.query({
            query: ({ source, destinaiton, tripTypeid }) => {
                return {
                    url: `${API_END_POINTS.getDistanceByTripType}?source=${source}&destinaiton=${destinaiton}&tripTypeid=${tripTypeid}`,
                    method: "GET",
                }
            },
        }),
        getAllCity: builder.query({
            query: () => {
                return {
                    url: `${API_END_POINTS.getAllCity}`,
                    method: "GET",
                }
            },
        }),
        getAllCountry: builder.query({
            query: () => {
                return {
                    url: `${API_END_POINTS.getAllCountries}`,
                    method: "GET",
                }
            },
        }),
        getAllDivision: builder.query({
            query: () => {
                return {
                    url: `${API_END_POINTS.getAllDivisionLookup}`,
                    method: "GET",
                }
            },
        }),
        getViolationPolicyTypeLookup: builder.query({
            query: () => {
                return {
                    url: `${API_END_POINTS.getViolationPolicyTypeLookup}`,
                    method: "GET",
                }
            },
        }),

    }),
})

export const {
    useFamilyMemberRealtionshipLookupQuery,
    useFamilyMemberStatusLookupQuery,
    useExpenseCategoryLookupQuery,
    useAllCurrencyLookupQuery,
    useExpenseStatusLookupQuery,
    useDepartmentsLookupQuery,
    useDesignationLookupQuery,
    useExpenseItemTypeQuery,
    useWorkflowApproverStatusQuery,
    useGetManagerTeamLookupQuery,
    useExpenseSubCategoryLookupQuery,
    useGetPaymentMethodLookupQuery,
    useGetProjectStatusLookupQuery,
    useGetAllClientLookupQuery,
    useGetAllCategoryQuery,
    useUpdateAllCategoryMutation,
    useGetAllExpenseTypeQuery,
    useGetAllDesignationWithoutRoleQuery,
    useConfigCategoryUserLevelMutation,
    useGetConfigCategoryWithUserQuery,
    useUpdateUserCategoryMutation,
    useGetAllNotificationPreferencesQuery,
    useGetAllDistancePreferencesQuery,
    useGetApproversPolicyModeLookupQuery,
    useGetAllOrganizationLookUpQuery,
    useGetOnboardingStatusLookupQuery,
    // useCreateExpenseCategoryMutation,
    // useUpdateExpenseCategoryMutation,
    useGetAllLst_UserExpenseTypeQuery,
    useGetAllLocationsQuery,
    useGetAllLocationTypeQuery,
    useGetAllTripTypeQuery,
    useGetDistanceByTripTypeQuery,
    useGetAllCityQuery,
    useGetAllCountryQuery,
    useGetAllDivisionQuery,
    useGetViolationPolicyTypeLookupQuery
} = GeneralApi