import moment from 'moment'
import { Modal } from 'react-bootstrap'
import { KTSVG } from '../../../_metronic/helpers'
import { useAppSelector } from '../../../hooks'
import { selectAuth } from '../../../features/authSlice'
import { log } from 'console'

type Props = {
    show: boolean
    handleClose: () => void
    data: any
    expenseItems?: any
}
const ImageModal = ({ show, handleClose, data, expenseItems }: Props) => {
    return (
        <>
            <Modal
                aria-hidden='true'
                dialogClassName='modal-dialog modal-lg modal-dialog-centered'
                show={show}
                onHide={handleClose}
            >


                <div className='modal-body py-lg-10 px-lg-10'>
                    <div className='row'>
                        <div className="col-md-12 d-flex">
                            <div className='btn btn-sm btn-icon btn-active-color-primary ms-auto' onClick={handleClose}>
                                <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                            </div>
                        </div>
                        <div className='col-md-12'>
                            <div className='d-flex justify-content-center'>
                                <img src={data} alt="" className='mh-500px' style={{ objectFit: "contain" }} />
                            </div>
                        </div>
                        {expenseItems?.length > 0 && (
                            <img
                                src={expenseItems[0]?.attachmentFile}
                                height={500}
                                style={{ objectFit: "contain" }}
                                alt=""
                            />
                        )}

                    </div>
                </div>
                {/* <div className='modal-footer py-4'>
                    <button className='btn btn-light-primary' onClick={handleClose}>Close</button>
                </div> */}

            </Modal>
        </>
    )
}

export default ImageModal