import labelKey from "../../localization/label.json";
import MixedStatsWidget from './MixedStatsWidget';
import NewOrgPieChart from './NewOrgPieChart';
import OrgPieChart from './OrgPieChart';

const DashboardBySuperAdmin = (getSuperAdminDashboard: any) => {
  return (
    <>
      <div className="row">
        <div className="col-md-6 col-lg-4 mt-3">
          <MixedStatsWidget className='card-xl-stretch'
            title={labelKey.organizations}
            toState1='/organization'
            toState2='/organization/active'
            titleIcon='/media/figma-download/orgWhite.svg'
            statImg1='/media/figma-download/allOrg.svg'
            statTitle1={labelKey.totalOrganizations}
            statsNum1={`${getSuperAdminDashboard?.getSuperAdminDashboard?.result?.totalOrg}`}
            statImg2='/media/figma-download/activeOrg.svg'
            statTitle2={labelKey.activeOrganizations}
            statsNum2={`${getSuperAdminDashboard?.getSuperAdminDashboard?.result?.activeOrg}`}
          >
            <OrgPieChart getSuperAdminDashboard={getSuperAdminDashboard?.getSuperAdminDashboard} />
          </MixedStatsWidget>
        </div>
        <div className="col-md-6 col-lg-4 mt-3">
          <MixedStatsWidget className='card-xl-stretch'
            title={labelKey.newOrganizations}
            toState1='/organization/new'
            toState2='/organization/inprogress'
            titleIcon='/media/figma-download/orgWhite.svg'
            statImg1='/media/figma-download/allOrg.svg'
            statTitle1={labelKey.newOrganizations}
            statsNum1={`${getSuperAdminDashboard?.getSuperAdminDashboard?.result?.newOrg}`}
            statImg2='/media/figma-download/activeOrg.svg'
            statTitle2={labelKey.inProgressOrganizations}
            statsNum2={`${getSuperAdminDashboard?.getSuperAdminDashboard?.result?.inprogressOrg}`}
          >
            <NewOrgPieChart getSuperAdminDashboard={getSuperAdminDashboard?.getSuperAdminDashboard} />
          </MixedStatsWidget>
        </div>
      </div>
    </>
  )
}

export default DashboardBySuperAdmin