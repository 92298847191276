import { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { FaInfoCircle } from 'react-icons/fa';
import { Tooltip } from 'react-tooltip';
import { KTSVG } from '../../../_metronic/helpers';
import TextField from '../../../components/textFields/TextField';
import { useGetExpenseReportByDepartmentWithAmountQuery } from '../../../services/ReportAndAnalyticsApi';
import labelKey from "../../localization/label.json";

const ExpenseDepartmentChart = (reportByDeptData:any) => {
  const [filterByStartDate, setFilterByStartDate] = useState("01/01/0001");
  const [filterByEndDate, setFilterByEndDate] = useState("01/01/0001");
  const { data, refetch } = useGetExpenseReportByDepartmentWithAmountQuery({
    startDate: filterByStartDate,
    endDate: filterByEndDate,
  })

  const handleStartDate = (e: any) => {
    // setFilterByStartDate(e.target.value);
    const newValue = e.target.value;
    if (newValue === '') {
      setFilterByStartDate('0001-01-01');
    } else {
      setFilterByStartDate(newValue);
    }
  };
  const handleEndDate = (e: any) => {
    // setFilterByEndDate(e.target.value);
    const newValue = e.target.value;
    if (newValue === '') {
      setFilterByEndDate('0001-01-01');
    } else {
      setFilterByEndDate(newValue);
    }
  };
  useEffect(() => {
    refetch();
    if (filterByStartDate === "" || filterByEndDate === "") {
      refetch();
    }

  }, [filterByStartDate, filterByEndDate]);


  const barChartOptions: ApexCharts.ApexOptions = {
    chart: {
      type: 'bar',
      toolbar: {
        show: false
      },
      height: 350,
      zoom: {
        enabled: false,
      },
    },
    colors: ['#F6C000', '#323268'],
    // legend: {
    //   show: true,
    // },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
    },
    xaxis: {
      categories:reportByDeptData?.reportByDeptData?.result?.map((department: any) => department?.departmentName) || [],
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '10%',
        borderRadius: 8,
        dataLabels: {
          position: 'top', // Display labels on top of each bar
        },
      },

    },
    legend: {
      show: true,
      position: 'top',
      horizontalAlign: 'right', // Align legend to the right
      offsetX: 0,
      offsetY: 0,
    },
    // tooltip: {
    //   shared: true,
    //   intersect: false,
    // },
    annotations: {
      // Vertical Line
      xaxis: [
        {
          x: 0, // x-axis index where the line will be drawn
          borderColor: '#8c8c8c',
        },
      ],
    },
    tooltip: {
      shared: true,
      intersect: false,
      y: [{},
      {
        formatter: function (value: any) {
          return `$${value.toLocaleString('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`;
        },
      },
      ],
    },
  };

  const barChartSeries = [
    {
      name: 'No of Expenses',
      data:reportByDeptData?.reportByDeptData?.result?.map((department: any) => department?.departmentExpenseCount) || [],
    },
    {
      name: 'Total Expense Amount',
      data:reportByDeptData?.reportByDeptData?.result?.map((department: any) => department?.departmentTotalExpenseAmount) || [],
    },
  ];
  return (
    <>
      <Tooltip id="chart" place="top" />
      <div>
        <h3>{labelKey.expenseByDepartment} <span data-tooltip-id="chart" data-tooltip-content='The chart display data by default for the last 7 days.'>
          <FaInfoCircle className='text-gray cursor-pointer' />
        </span> </h3>
      </div>
      <div className="mt-5">
        <div className='d-flex d-md-inline justify-content-center'>
          <ReactApexChart options={barChartOptions} series={barChartSeries} type='bar' height={400} />
        </div>
      </div>
    </>
  )
}

export default ExpenseDepartmentChart