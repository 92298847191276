import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { KTSVG } from '../../../_metronic/helpers'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useUpdateOrgOnboardingStatusMutation } from '../../../services/OrganizationApi'
import Button from '../../../components/button/Button'
import labelKey from "../../localization/label.json";
import tosterKey from "../../localization/toster.json";
import { useGetOnboardingStatusLookupQuery } from '../../../services/GeneralApi'


type Props = {
    show: boolean
    handleClose: () => void
    data: any
}
const initialState = {
    organizationId: "",
    orgOnboardingStatusId: "",
    internalNotes: "",
};
const UpdateOrgOnboardingModal = ({ show, handleClose, data }: Props) => {

    const [formValue, setFormValue] = useState(initialState);
    const [editMode, setEditMode] = useState(false);
    const [updateOrgOnboardingStatus, { data: updateOrgOnboardData, isLoading: updateOrgOnboardingIsLoading, isSuccess: updateOrgOnboardIsSuccess, isError: updateOrgOnboardIsError, error: updateOrgOnboardError }] = useUpdateOrgOnboardingStatusMutation()
    const { data: getStatusLookup,
        isSuccess: getStatusLookupIsSuccess,
        isError: getStatusLookupIsError,
        error: getStatusLookupError } = useGetOnboardingStatusLookupQuery('')

    const { organizationId, orgOnboardingStatusId, internalNotes } = formValue;
    const [id, setId] = useState(0);

    useEffect(() => {
        if (data) {
            setId(data?.organizationID);
            setEditMode(true);
            // setFormValue(data)
            setFormValue({
                ...initialState,
                organizationId: data?.organizationID,
                orgOnboardingStatusId: data?.fk_OnboardingStatusID,
                internalNotes: data?.internalNotes
            });
        }
    }, [data]);

    const handleInputChange = (e: any) => {
        let { name, value } = e.target;
        setFormValue({ ...formValue, [name]: value });
    };

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        await updateOrgOnboardingStatus(formValue);
    };

    useEffect(() => {

        if (updateOrgOnboardIsSuccess) {
            toast.success(tosterKey.organizationUpdateSuccessfully);
            handleClose();
        }
    }, [updateOrgOnboardIsSuccess]);


    useEffect(() => {
        if (updateOrgOnboardIsError) {
            toast.error('Something went wrong')
        }
    }, [updateOrgOnboardIsError])
    return (
        <>
            <Modal
                aria-hidden='true'
                dialogClassName='modal-dialog modal-md modal-dialog-centered'
                show={show}
                onHide={handleClose}
            >
                <div className='modal-header'>
                    <h2 className='mb-0'>{labelKey.updateOnboardingStatus}</h2>
                    <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
                        <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                    </div>
                </div>

                <form onSubmit={handleSubmit}>
                    <div className='modal-body py-lg-10 px-lg-10'>
                        <input className='form-control'
                            type="hidden"
                            name="organizationId"
                            value={organizationId || ""}
                        />
                        <div className="row">
                            <div className="col-md-12 mt-4">
                                <label className='form-label fw-bold text-dark fs-6' htmlFor="legalName">{labelKey.internalNotes}</label>
                                <textarea
                                    name="internalNotes"
                                    value={internalNotes || ""}
                                    onChange={handleInputChange}
                                    className='form-control form-control-solid'
                                ></textarea>

                            </div>
                            <div className="col-md-12 mt-4">
                                <label className='form-label fw-bold text-dark fs-6' htmlFor="legalName">{labelKey.onboardingStatus}</label>
                                <select className='form-control form-control-solid' onChange={handleInputChange} name="orgOnboardingStatusId" value={orgOnboardingStatusId || ""}>
                                    {getStatusLookup?.result?.map((option: any, index: any) => (
                                        <option key={option?.id} value={option?.id}>{option?.value}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className='modal-footer'>
                        <Button label='Cancel' className='mt-3 px-10' onClick={handleClose} />
                        <button
                            type='submit'
                            id='kt_sign_up_submit'
                            className='btn btn-lg btn-primary mb-5 mt-5'
                            disabled={updateOrgOnboardingIsLoading}
                        >
                            {!updateOrgOnboardingIsLoading && <span className='indicator-label'>{labelKey.update}</span>}
                            {updateOrgOnboardingIsLoading && (
                                <span className='indicator-progress' style={{ display: 'block' }}>
                                    {labelKey.pleaseWait}...{' '}
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                            )}
                        </button>
                    </div>
                </form>

            </Modal>
        </>
    )
}

export default UpdateOrgOnboardingModal