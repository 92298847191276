import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ToastState {
  showToast: boolean;
}

// const initialState: ToastState = {
//   showToast: true,
// };
// Retrieve the initial state from localStorage, if available
const savedValue = localStorage.getItem('showToast');
const initialState: ToastState = {
  showToast: savedValue ? JSON.parse(savedValue) : true,
};

const toastSlice = createSlice({
  name: 'toast',
  initialState,
  reducers: {
    toggleToast: (state) => {
      state.showToast = !state.showToast;
    },
  },
});

export const { toggleToast } = toastSlice.actions;

export default toastSlice.reducer;
