import React from 'react'
import ExpenseSubmission from './ExpenseSubmission'
import { PageTitle } from '../../../_metronic/layout/core'
import LatestBudget from '../budget/LatestBudget'
import labelKey from "../../localization/label.json";

const ExpenseSubmissionWrapper = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>{labelKey.expenseSubmission}</PageTitle>
      <ExpenseSubmission />
    </>
  )
}

export default ExpenseSubmissionWrapper