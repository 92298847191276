import React, { useEffect, useState } from 'react'
import { KTSVG } from '../../../../_metronic/helpers';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';
import moment from 'moment';
import { useGetAllProjectsQuery } from '../../../../services/ClientAndProjectsApi';
import AddUpdateProject from './AddUpdateProject';

const ProjectPage = () => {
    const [page, setPage] = useState(1)
    const [pageSize, setPageSize] = useState(10);
    const [searchStr, setSearchStr] = useState('');
    const [sortBy, setSortBy] = useState("");
    const [sortOrder, setSortOrder] = useState("desc");
    const { data, isLoading, refetch } = useGetAllProjectsQuery(
        {
            pageNumber: page,
            pageSize: pageSize,
            sortBy: sortBy,
            sortOrder: sortOrder,
            searchStr: searchStr,
        }
    )
    const handlePageSizeChange = (e: any) => {
        const newSize = parseInt(e.target.value);
        setPageSize(newSize);
        setPage(1); // Reset page number to 1
    };
    // pagination
    const handlePageClick = (e: any) => {
        setPage(e.selected + 1);
    };
    const handleSort = (property: string) => {
        setSortBy(property);
        setSortOrder(prevSortOrder => (prevSortOrder === "asc" ? "desc" : "asc"));
        // Perform additional sorting logic...
    };
    const handlePageRecords = (e: any) => {
        setPage(1); // Reset the page to 1
    };
    const handleSearchChange = (e: any) => {
        setSearchStr(e.target.value);
    }
    useEffect(() => {
        if (searchStr === "" || sortOrder === "" || sortBy === "") {
            refetch();
        }
    }, [searchStr, sortOrder, sortBy]);

    const [showAddUpdateProjectModal, setShowAddUpdateProjectModal] = useState(false)
    const [showProjectEdit, setShowProjectEdit] = useState({})
    const eidtProjectModalHandler = () => {
        setShowAddUpdateProjectModal((prev) => !prev);
    };

    useEffect(() => {
        if (showAddUpdateProjectModal === false) {
            refetch();
        }
    }, [showAddUpdateProjectModal]);
    return (
        <>
            <AddUpdateProject show={showAddUpdateProjectModal}
                handleClose={() => setShowAddUpdateProjectModal(false)} data={showProjectEdit} />

            <div className={`card`}>
                {/* begin::Header */}
                <div className='card-header border-0 pt-5'>
                    <div className='d-flex align-items-center position-relative my-1'>
                        <KTSVG
                            path='/media/icons/duotune/general/gen021.svg'
                            className='svg-icon-1 position-absolute ms-6'
                        />
                        <input
                            type='text'
                            data-kt-user-table-filter='search'
                            className='form-control form-control-solid w-250px ps-14'
                            placeholder='Search Projects...'
                            value={searchStr}
                            onChange={handleSearchChange}
                        />
                    </div>
                    <div className='d-flex align-items-center'>
                        <div className='card-toolbar'>
                            <button className='btn btn-sm btn-light-primary'
                                onClick={() => {
                                    setShowProjectEdit(true);
                                    eidtProjectModalHandler();
                                }}
                            >
                                <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
                                Add Project
                            </button>
                        </div>
                    </div>
                </div>
                <div className='card-body py-8'>
                    <div className='table-responsive table-height'>
                        <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                            <thead>
                                <tr className='fw-bold text-muted'>
                                    <th className='ps-4 rounded-start'>ID</th>
                                    <th className='w-200px' onClick={() => handleSort("name")}>
                                        <div className='table-arrow'>
                                            Name
                                            {sortOrder === "desc" ? (
                                                <i className="fa fa-arrow-down"></i>
                                            ) : (
                                                <i className="fa fa-arrow-up"></i>
                                            )}
                                        </div>
                                    </th>
                                    <th onClick={() => handleSort("disc")}>
                                        <div className='table-arrow'>
                                            description
                                            {sortOrder === "desc" ? (
                                                <i className="fa fa-arrow-down"></i>
                                            ) : (
                                                <i className="fa fa-arrow-up"></i>
                                            )}
                                        </div>
                                    </th>
                                    <th onClick={() => handleSort("startedDt")}>
                                        <div className='table-arrow'>
                                            started
                                            {sortOrder === "desc" ? (
                                                <i className="fa fa-arrow-down"></i>
                                            ) : (
                                                <i className="fa fa-arrow-up"></i>
                                            )}
                                        </div>
                                    </th>
                                    <th onClick={() => handleSort("endDt")}>
                                        <div className='table-arrow'>ended Date
                                            {sortOrder === "desc" ? (
                                                <i className="fa fa-arrow-down"></i>
                                            ) : (
                                                <i className="fa fa-arrow-up"></i>
                                            )}
                                        </div>
                                    </th>
                                    <th onClick={() => handleSort("status")}>
                                        <div className='table-arrow'>status
                                            {sortOrder === "desc" ? (
                                                <i className="fa fa-arrow-down"></i>
                                            ) : (
                                                <i className="fa fa-arrow-up"></i>
                                            )}
                                        </div>
                                    </th>
                                    <th onClick={() => handleSort("budget")}>
                                        <div className='table-arrow'>
                                            budget
                                            {sortOrder === "desc" ? (
                                                <i className="fa fa-arrow-down"></i>
                                            ) : (
                                                <i className="fa fa-arrow-up"></i>
                                            )}
                                        </div>
                                    </th>
                                    <th onClick={() => handleSort("client")}>
                                        <div className='table-arrow'>
                                            client
                                            {sortOrder === "desc" ? (
                                                <i className="fa fa-arrow-down"></i>
                                            ) : (
                                                <i className="fa fa-arrow-up"></i>
                                            )}
                                        </div>
                                    </th>
                                    <th className='text-end rounded-end pe-4'>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data?.result?.data?.map((data: any, index: any) => (
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{data?.name}</td>
                                        <td>{data?.disc}</td>
                                        <td>{moment(data.startedDt).format("DD MMM YYYY")}</td>
                                        <td>{moment(data.endDt).format("DD MMM YYYY")}</td>
                                        <td>{data?.status?.title}</td>
                                        <td>{data?.budget}</td>
                                        <td>{data?.client?.name}</td>

                                        <td>
                                            <div className='d-flex align-items-center justify-content-end gap-2' >
                                                <OverlayTrigger
                                                    delay={{ show: 250, hide: 350 }}
                                                    overlay={
                                                        <Tooltip>
                                                            Update Project
                                                        </Tooltip>
                                                    }>
                                                    <div className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                                        onClick={() => {
                                                            setShowProjectEdit(data);
                                                            eidtProjectModalHandler();
                                                        }}
                                                    >
                                                        <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
                                                    </div>
                                                </OverlayTrigger>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                                {isLoading && (
                                    <tr>
                                        <td colSpan={9} className="text-center">Loading ....</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                    {/* end::Table container */}

                    <div className="d-flex flex-stack flex-wrap pt-10">
                        <div className="fs-6 fw-bold text-gray-700">
                            Showing {((data?.result?.pageNumber - 1) * data?.result?.pageSize) + 1} to {Math.min(data?.result?.pageNumber * data?.result?.pageSize, data?.result?.totalRecords)} of <span>{data?.result?.totalRecords}</span> entries
                        </div>
                        <div className='d-flex align-items-center gap-3'>
                            <div className='d-flex align-items-center'>
                                <label className='fs-6 text-gray-700 fw-semibold me-2'>Per page</label>
                                <select value={pageSize} className='form-select form-select-solid h-40px w-80px' onChange={handlePageSizeChange}>
                                    <option value="10">10</option>
                                    <option value="15">15</option>
                                    <option value="20">20</option>
                                    <option value="25">25</option>
                                    <option value="30">30</option>
                                    <option value="100">100</option>
                                </select>
                            </div>
                            <ReactPaginate
                                breakLabel={"..."}
                                nextLabel="Next"
                                onPageChange={(e: any) => handlePageClick(e)}
                                pageRangeDisplayed={5}
                                marginPagesDisplayed={1}
                                pageCount={data?.result?.totalPages}
                                previousLabel="Previous"
                                // renderOnZeroPageCount={null}


                                containerClassName='pagination'
                                pageClassName='page-item'
                                previousClassName='page-item'
                                nextClassName='page-item'
                                breakClassName='page-item'

                                pageLinkClassName='page-link'
                                previousLinkClassName='page-link'
                                nextLinkClassName='page-link'
                                breakLinkClassName='page-link'
                                activeClassName='active'
                            />
                        </div>
                    </div>
                </div>
                {/* begin::Body */}
            </div>
        </>
    )
}

export default ProjectPage