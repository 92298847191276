/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react";
import CurrencyComp from "../../../../../components/currencyComponent/CurrencyComp";
import DateComp from "../../../../../components/dateComponent/DateComp";
import { constraintConfig } from "../../../../../constraintConfig";
import { selectAuth } from "../../../../../features/authSlice";
import { useAppSelector } from "../../../../../hooks";
import { useGetMileageByDateQuery } from "../../../../../services/ExpenseApi";
import { useExpenseCategoryLookupQuery, useGetPaymentMethodLookupQuery } from "../../../../../services/GeneralApi";
import { useGetVendorLookUpQuery } from "../../../../../services/VendorApi";
import labelKey from "../../../../localization/label.json";
import ReactReadMoreReadLess from "react-read-more-read-less"
import { API_END_POINTS } from "../../../../../services/apiEndpoints";
import {baseUrl} from '../../../../../services/baseUrl';
import { toast } from "react-toastify";
import axios from "axios";

type Props = {
  formik: any
  fileName: any
}
const Step5 = ({ formik, fileName }: Props) => {
 
  const { roleID, token,baseURL } = useAppSelector(selectAuth);
  const { data: vendor } = useGetVendorLookUpQuery('')
  const { data: getPaymentMethod } = useGetPaymentMethodLookupQuery('')
  const { data: expenseCategory } = useExpenseCategoryLookupQuery({
    roleId: roleID
  })
  // const { data: getMileageByDate } = useGetMileageByDateQuery({
  //   date: formik?.values?.itemDate
  // })
  const [getMileageByDate, setGetMileageByDate] = useState<any>(null); // Initialize with null
  const [milageRateIsError, setMilageRateIsError] = useState(false); // Initialize error state

  // const mileageRefetch = async () => {
  //   if (parseInt(formik.values?.expenseCategory?.id) === constraintConfig.expenseCategoryID?.mileage) {
  //     try {
  //       const headers = {
  //         Authorization: `Bearer ${token}`,
  //       };
  //       const getExpenseLogResponse = await axios.get(
  //         `${baseURL}/api/Expense${API_END_POINTS.getMileagebyDate}?date=${formik.values?.itemDate || ''}`,
  //         {
  //           headers,
  //         }
  //       );
  //       const getMileageByDate = getExpenseLogResponse.data;
  //       setGetMileageByDate(getMileageByDate);
  //     } catch (error) {
  //       console.error(`Error fetching data for : `, error);
  //       setMilageRateIsError(true);
  //     } finally {
  //     }
  //   }
  // };
  // useEffect(() => {
  //   if (formik.values?.expenseCategory?.id === constraintConfig.expenseCategoryID?.mileage || formik.values?.itemDate) {
  //     mileageRefetch();
  //     if (milageRateIsError) {
  //       toast.error('Mileage rates not found for specific date range ');
  //     }
  //   }
  // }, [milageRateIsError, formik.values?.itemDate, formik.values?.expenseCategory?.id])

  const kmRate = getMileageByDate?.result?.ratePerKM * formik.values?.milage;
  const mileRate = getMileageByDate?.result?.ratePerMile * formik.values?.milage;
  return (
    <>
      <div data-kt-stepper-element='content' className="overflow-auto" style={{ height: "51vh" }}>
        <div className='w-100 text-center'>
          <h1 className='fw-bold text-gray-800 mb-3'>{labelKey.review}!</h1>

          <div className="row align-items-center justify-content-center h-75 m-0">
            <div className="col-md-12 col-lg-8 mt-4">
              <div className="d-flex align-items-center gap-3 mb-1">
                <label className="fs-5 fw-bold text-start w-50">{labelKey.category}:</label>
                <select
                  className='form-control form-control-solid bg-transparent border-0 p-0 text-start w-50 text-dark'
                  {...formik.getFieldProps(`expenseCategory.id`)}
                  disabled
                >
                  {expenseCategory?.result.map((option: any, index: any) => (
                    <option key={index} value={option.id}
                    >{option?.value}</option>
                  ))}
                </select>
              </div>
              <div className="d-flex align-items-center gap-3 mb-1">
                <label className="fs-5 fw-bold text-start w-50">{labelKey.paymentMethod}:</label>
                <select
                  className='form-control form-control-solid bg-transparent border-0 p-0 text-start w-50 text-dark'
                  {...formik.getFieldProps(`paymentMethod.id`)}
                  value={formik?.values?.paymentMethod?.id || ''}
                  disabled
                >
                  {getPaymentMethod?.result.map((option: any, index: any) => (
                    <option key={index} value={option.id}
                    >{option?.value}</option>
                  ))}
                </select>
              </div>
              <div className="d-flex align-items-center gap-3 mb-1">
                <label className="fs-5 fw-bold text-start w-50">{labelKey.date}:</label>
                <span className="text-start w-50"><DateComp formattedDate={formik?.values?.itemDate} /> </span>
              </div>
              <div className="d-flex align-items-center gap-3 mb-1">
                <label className="fs-5 fw-bold text-start w-50">{labelKey.amount}:</label>
                <span className="text-start w-50">
                  {parseInt(formik?.values?.expenseCategory?.id) === constraintConfig.expenseCategoryID?.mileage ?
                    (<CurrencyComp amount={kmRate ? kmRate.toString() : mileRate.toString()} />) :
                    (
                      <CurrencyComp amount={formik?.values?.amount} />
                    )
                  }
                </span>
              </div>
              {parseInt(formik.values.expenseCategory?.id) === constraintConfig.expenseCategoryID.mileage &&
                <div className="d-flex align-items-center gap-3 mb-1">
                  <label className="fs-5 fw-bold text-start w-50">{labelKey.distance}:</label>
                  <span className="text-start w-50">{formik.values.milage}</span>
                </div>
              }
              {parseInt(formik.values?.expenseCategory?.id) !== constraintConfig.expenseCategoryID.mileage &&
                <div className="d-flex align-items-center gap-2 mb-1">
                  <label className="fs-5 fw-bold text-start w-50">{labelKey.vendor}:</label>
                  <select
                    className='border-0 p-0 remove-select-arrow text-start text-dark bg-transparent'
                    value={formik?.values?.vendor?.vendorId || ''}
                    disabled
                  >
                    {vendor?.result.map((option: any, index: any) => (
                      <option key={index} value={option.id}>{option?.value}</option>
                    ))}
                  </select>
                </div>}


              {parseInt(formik.values?.expenseCategory?.id) !== constraintConfig.expenseCategoryID.mileage ?
                <>
                  <div className="d-flex align-items-center gap-2 mb-1">
                    <label className="fs-5 fw-bold text-start w-50">{labelKey.location}:</label>
                    <p className="mb-0">{formik?.values?.expItemLocation?.title || ''}</p>
                  </div>
                  <div className="d-flex align-items-center gap-3 mb-1">
                    <label className="fs-5 fw-bold text-start w-50">{labelKey.receiptDocument}:</label>
                    <span className="text-start w-50">
                      {/* {fileName && fileName?.length > 20
                    ? fileName.substring(0, 20) + '...'
                    : fileName} */}
                      {fileName && <ReactReadMoreReadLess
                        charLimit={50}
                        readMoreText={"Read more"}
                        readLessText={"Read less"}
                        readMoreClassName="readMore"
                        readLessClassName="readLess"
                      >
                        {fileName}
                      </ReactReadMoreReadLess>}
                    </span>
                  </div>
                </> :
                <>
                  <div className="d-flex align-items-center gap-2 mb-1">
                    <label className="fs-5 fw-bold text-start w-50">{labelKey.startLocation}:</label>
                    <p className="mb-0">{formik?.values?.startLocation || ''}</p>
                  </div>
                  <div className="d-flex align-items-center gap-2 mb-1">
                    <label className="fs-5 fw-bold text-start w-50">{labelKey.endLocation}:</label>
                    <p className="mb-0">{formik?.values?.endLocation || ''}</p>
                  </div>
                </>
              }
              <div>
                <label className="fs-5 fw-bold d-flex text-start">{labelKey.description}:</label>
                <p className="fs-7 text-start mh-200px overflow-auto mt-2">{formik.values.itemDetail}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export { Step5 };

