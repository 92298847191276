import React from 'react'
import { PageTitle } from '../../../_metronic/layout/core'
import ReportAnalyticsPage from './ReportAnalyticsPage'
import ExpenseApproverSummary from './ExpenseApproverSummary'
import { useAppSelector } from '../../../hooks'
import { selectAuth } from '../../../features/authSlice'
import labelKey from "../../localization/label.json"
import { constraintConfig } from '../../../constraintConfig'

const ReportAnalyticsWrapper = () => {
    const { roleID } = useAppSelector(selectAuth)

    return (
        <>
            <PageTitle breadcrumbs={[]}>{labelKey.reportAnalytics}</PageTitle>
            {/* {roleID === constraintConfig.roleID.role4 &&
                <ExpenseApproverSummary />
            } */}
            {/* {roleID === constraintConfig.roleID.role10 &&
                <ExpenseApproverSummary />
            } */}
            {/* {roleID === constraintConfig.roleID.role5 || roleID === constraintConfig.roleID.role2 && */}
                <ReportAnalyticsPage />
            {/* } */}
        </>
    )
}

export default ReportAnalyticsWrapper