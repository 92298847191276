import React from 'react'
import { PageTitle } from '../../../../_metronic/layout/core'
import ClientPage from './ClientPage'

const ClientWrapper = () => {
    return (
        <>
            <PageTitle breadcrumbs={[]}>Clients</PageTitle>
            <ClientPage />
        </>
    )
}

export default ClientWrapper