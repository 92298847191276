/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
// import {useAuth} from '../../../../app/modules/auth'
import axios from 'axios'; // Add axios for making HTTP requests
import { toast } from 'react-toastify';
import { logout, selectAuth } from '../../../../features/authSlice';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { useGetSingleUserQuery, useLogoutApiMutation } from '../../../../services/authApi';
import {baseUrl} from '../../../../services/baseUrl';

const HeaderUserMenu: FC = () => {
  const { token, userAccountID } = useAppSelector(selectAuth);
  const { data: userDetail, refetch, } = useGetSingleUserQuery(userAccountID);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [logoutUser, { isSuccess, isError, error }] = useLogoutApiMutation();
  const initialValues = {
    id: 0,
  }
  const handleLogout = () => {
    // Create a Broadcast Channel to broadcast the logout message
    const logoutChannel = new BroadcastChannel('logoutChannel');
    // Broadcast the logout message to all tabs
    logoutChannel.postMessage('logout');
    // Perform the local logout action (if needed)
    // Example: dispatch(logout());
    // Send a request to the server to invalidate the session (if needed)
    axios.put(`${baseUrl.MAIN_BASE_URL}/api/Account/Logout`, null, {
      headers: {
        Authorization: `Bearer ${token}` // Include the token in the Authorization header
      }
    })
      .then((response) => {
        if (response.status === 200) {
          // toast.success('User Logout Successful');
          navigate('/');
          // Additional local logout actions here
          dispatch(logout());
        }
      })
      .catch((error) => {
        toast.error('Logout failed. Please try again.');
      });
  };
  useEffect(() => {
    if (isError && error) {
      let errorMessage: string = 'Something went wrong';
      if ('data' in error && error.data && typeof error.data === 'object' && 'message' in error.data) {
        errorMessage = (error.data as { message: string }).message;
      }
      toast.error(errorMessage);
    }
  }, [isError, error]);
  const handleError = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
    event.currentTarget.src = '/media/avatars/blank.png';
  };
  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-semibold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='symbol symbol-50px me-5'>
            <img src={userDetail?.result?.imageUrl ? userDetail?.result?.imageUrl : '/media/avatars/blank.png'} alt='' onError={handleError} />
          </div>

          <div className='d-flex flex-column'>
            <div className='fw-bold d-flex align-items-center fs-5'>
              {userDetail && userDetail.result.firstName} &nbsp;
              {userDetail && userDetail.result.lastName}
            </div>
            <p className='fw-semibold text-muted text-hover-primary fs-7 mb-0'>
              {userDetail && userDetail.result.email}
            </p>
          </div>
        </div>
      </div>

      {/* <div className='separator my-2'></div> */}

      {/* <div className='menu-item px-5'>
        <Link to={'profile'} className='menu-link px-5'>
          My Profile
        </Link>
      </div> */}

      {/* <div className='menu-item px-5'>
        <a href='#' className='menu-link px-5'>
          <span className='menu-text'>My Projects</span>
          <span className='menu-badge'>
            <span className='badge badge-light-danger badge-circle fw-bold fs-7'>3</span>
          </span>
        </a>
      </div> */}

      {/* <div
        className='menu-item px-5'
        data-kt-menu-trigger='hover'
        data-kt-menu-placement='left-start'
        data-kt-menu-flip='bottom'
      >
        <a href='#' className='menu-link px-5'>
          <span className='menu-title'>My Subscription</span>
          <span className='menu-arrow'></span>
        </a>

        <div className='menu-sub menu-sub-dropdown w-175px py-4'>
          <div className='menu-item px-3'>
            <a href='#' className='menu-link px-5'>
              Referrals
            </a>
          </div>

          <div className='menu-item px-3'>
            <a href='#' className='menu-link px-5'>
              Billing
            </a>
          </div>

          <div className='menu-item px-3'>
            <a href='#' className='menu-link px-5'>
              Payments
            </a>
          </div>

          <div className='menu-item px-3'>
            <a href='#' className='menu-link d-flex flex-stack px-5'>
              Statements
              <i
                className='fas fa-exclamation-circle ms-2 fs-7'
                data-bs-toggle='tooltip'
                title='View your statements'
              ></i>
            </a>
          </div>

          <div className='separator my-2'></div>

          <div className='menu-item px-3'>
            <div className='menu-content px-3'>
              <label className='form-check form-switch form-check-custom form-check-solid'>
                <input
                  className='form-check-input w-30px h-20px'
                  type='checkbox'
                  value='1'
                  defaultChecked={true}
                  name='notifications'
                />
                <span className='form-check-label text-muted fs-7'>Notifications</span>
              </label>
            </div>
          </div>
        </div>
      </div> */}

      {/* <div className='menu-item px-5'>
        <a href='#' className='menu-link px-5'>
          My Statements
        </a>
      </div> */}

      <div className='separator my-2'></div>

      {/* <Languages /> */}


      <div className='menu-item px-5 my-1'>
        <Link to='/account/settings' className='menu-link px-5'>
          Account Settings
        </Link>
      </div>
      <div className='separator my-2'></div>

      <div className='menu-item px-5'>
        {/* <form onSubmit={formik.handleSubmit}> */}
        <button
          onClick={handleLogout}
          type='submit'
          className='btn btn-transparent menu-link px-5'>
          Sign Out
        </button>
        {/* </form> */}

      </div>
    </div>
  )
}

export { HeaderUserMenu };

