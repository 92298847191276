import moment from 'moment';
import React from 'react'
interface props {
    formattedDate?: string,
}
const DateComp = ({ formattedDate  }: props) => {
    const formattedDateString = formattedDate
        ? moment(formattedDate).format("MM-DD-YYYY")
        : '';
    return (
        <>{formattedDateString}</>
    )
}

export default DateComp