import React, { useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import labelKey from "../../../localization/label.json";
import { toast } from 'react-toastify';
import { KTSVG } from '../../../../_metronic/helpers';
import { useChangeVendorStatusMutation } from '../../../../services/VendorApi';

type Props = {
    show: boolean
    handleClose: () => void
    data: any
    isChecked: boolean
}
const VendorActiveConfirmationModal = ({ show, handleClose, data, isChecked }: Props) => {
    const [updateVendorStatus, { isLoading, isSuccess, isError, error }] = useChangeVendorStatusMutation();
    const handleConfirmation = async () => {
        await updateVendorStatus({ vendorId: data?.vendorId, status: !data?.active });
        handleClose();
    };

    useEffect(() => {
        if (isSuccess) {
            toast.success('Status Update Successfully');
            handleClose();
        }
    }, [isSuccess]);

    useEffect(() => {
        if (isError && error) {
            let errorMessage: string = 'Something went wrong';

            if ('data' in error && error.data && typeof error.data === 'object' && 'message' in error.data) {
                errorMessage = (error.data as { message: string }).message;
            }
            toast.error(errorMessage);
        }
    }, [isError, error]);
    return (
        <>
            <Modal
                aria-hidden='true'
                dialogClassName='modal-dialog modal-md modal-dialog-centered'
                show={show}
                onHide={handleClose}
            >
                <div className='modal-header bg-light py-4'>
                    <h2 className='mb-0'>{labelKey.updateVendorStatus}</h2>
                    {/* begin::Close */}
                    <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
                        <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                    </div>
                    {/* end::Close */}
                </div>
                <div className='modal-body py-lg-6 px-lg-6'>
                    <p className='fs-5 fw-semibold'>Are you sure you want to Update the Vendor Status.
                        {isChecked === true ? <span className='fw-bold fs-5 text-success'> "Active" </span> : <span className='fw-bold fs-5 text-danger'> "In Active" </span>}
                    </p>
                </div>
                <div className='modal-footer justify-content-center py-4'>
                    <button className='btn btn-light' onClick={handleClose}>{labelKey.cancel}</button>
                    <button
                        type='submit'
                        id='kt_sign_up_submit'
                        className='btn btn-primary'
                        onClick={handleConfirmation}
                        disabled={isLoading}
                    >
                        {!isLoading && <span className='indicator-label'>{labelKey.yes}</span>}
                        {isLoading && (
                            <span className='indicator-progress' style={{ display: 'block' }}>
                                {labelKey.pleaseWait}{' '}
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                        )}
                    </button>
                </div>
            </Modal>
        </>
    )
}

export default VendorActiveConfirmationModal