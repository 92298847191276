import React, { useEffect, useState } from 'react'
import { KTSVG } from '../../../../_metronic/helpers'
import { useGetAllClientQuery, useUpdateClientStatusMutation } from '../../../../services/ClientAndProjectsApi'
import ReactPaginate from 'react-paginate'
import moment from 'moment'
import AddClientModal from './AddClientModal'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { toast } from 'react-toastify'

const ClientPage = () => {
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(10);
  const [searchStr, setSearchStr] = useState('');
  const [sortBy, setSortBy] = useState("");
  const [sortOrder, setSortOrder] = useState("desc");
  const { data, isLoading, refetch } = useGetAllClientQuery(
    {
      pageNumber: page,
      pageSize: pageSize,
      sortBy: sortBy,
      sortOrder: sortOrder,
      searchStr: searchStr,
    }
  )
  const [updateClientStatus, { isSuccess: updateStatusIsSuccess }] = useUpdateClientStatusMutation();
  const [activeStatus, setActiveStatus] = useState(true);
  const [loadingStatus, setLoadingStatus] = useState<{ [key: number]: boolean }>({});
  const handleToggle = async (clientId: any, active: boolean) => {
    // Set the loading status for the specific clientId to true
    setLoadingStatus((prevState) => ({ ...prevState, [clientId]: true }));
    const newActiveStatus = !active;
    setActiveStatus(newActiveStatus);
    await updateClientStatus({ clientId: clientId, status: newActiveStatus });
    setLoadingStatus((prevState) => ({ ...prevState, [clientId]: false }));
    refetch();
  };
  const handlePageSizeChange = (e: any) => {
    const newSize = parseInt(e.target.value);
    setPageSize(newSize);
    setPage(1); // Reset page number to 1
  };
  // pagination
  const handlePageClick = (e: any) => {
    setPage(e.selected + 1);
  };
  const handleSort = (property: string) => {
    setSortBy(property);
    setSortOrder(prevSortOrder => (prevSortOrder === "asc" ? "desc" : "asc"));
    // Perform additional sorting logic...
  };
  const handlePageRecords = (e: any) => {
    setPage(1); // Reset the page to 1
  };
  const handleSearchChange = (e: any) => {
    setSearchStr(e.target.value);
  }
  useEffect(() => {
    if (searchStr === "" || sortOrder === "" || sortBy === "") {
      refetch();
    }
    if (updateStatusIsSuccess) {
      toast.success("Status Updated Successfully")
      refetch();
    }
  }, [searchStr, sortOrder, sortBy,updateStatusIsSuccess]);

  const [showAddUpdateClientModal, setShowAddUpdateClientModal] = useState(false)
  const [showClientEdit, setShowClientEdit] = useState({})
  const eidtRoleModalHandler = () => {
    setShowAddUpdateClientModal((prev) => !prev);
  };

  useEffect(() => {
    if (showAddUpdateClientModal === false) {
      refetch();
    }
  }, [showAddUpdateClientModal]);
  return (
    <>
      <AddClientModal show={showAddUpdateClientModal}
        handleClose={() => setShowAddUpdateClientModal(false)} data={showClientEdit} />

      <div className={`card`}>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <div className='d-flex align-items-center position-relative my-1'>
            <KTSVG
              path='/media/icons/duotune/general/gen021.svg'
              className='svg-icon-1 position-absolute ms-6'
            />
            <input
              type='text'
              data-kt-user-table-filter='search'
              className='form-control form-control-solid w-250px ps-14'
              placeholder='Search Client...'
              value={searchStr}
              onChange={handleSearchChange}
            />
          </div>
          <div className='d-flex align-items-center'>
            <div className='card-toolbar'>
              <button className='btn btn-sm btn-light-primary' onClick={() => {
                setShowClientEdit(true);
                eidtRoleModalHandler();
              }}>
                <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
                Add Client
              </button>
            </div>
          </div>
        </div>
        <div className='card-body py-8'>
          <div className='table-responsive table-height'>
            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
              <thead>
                <tr className='fw-bold text-muted'>
                  <th className='ps-4 rounded-start'>ID</th>
                  <th className='w-200px' onClick={() => handleSort("name")}>
                    <div className='table-arrow'>
                      Name
                      {sortOrder === "desc" ? (
                        <i className="fa fa-arrow-down"></i>
                      ) : (
                        <i className="fa fa-arrow-up"></i>
                      )}
                    </div>
                  </th>
                  <th onClick={() => handleSort("email")}>
                    <div className='table-arrow'>
                      email
                      {sortOrder === "desc" ? (
                        <i className="fa fa-arrow-down"></i>
                      ) : (
                        <i className="fa fa-arrow-up"></i>
                      )}
                    </div>
                  </th>
                  <th onClick={() => handleSort("contact")}>
                    <div className='table-arrow'>
                      contact
                      {sortOrder === "desc" ? (
                        <i className="fa fa-arrow-down"></i>
                      ) : (
                        <i className="fa fa-arrow-up"></i>
                      )}
                    </div>
                  </th>
                  <th onClick={() => handleSort("status")}>
                    <div className='table-arrow'>Status
                      {sortOrder === "desc" ? (
                        <i className="fa fa-arrow-down"></i>
                      ) : (
                        <i className="fa fa-arrow-up"></i>
                      )}
                    </div>
                  </th>
                  <th onClick={() => handleSort("createdDt")}>
                    <div className='table-arrow'>
                      Created date
                      {sortOrder === "desc" ? (
                        <i className="fa fa-arrow-down"></i>
                      ) : (
                        <i className="fa fa-arrow-up"></i>
                      )}
                    </div>
                  </th>
                  <th className='text-end rounded-end pe-4'>Action</th>
                </tr>
              </thead>
              <tbody>
                {data?.result?.data?.map((data: any, index: any) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{data?.name}</td>
                    <td>{data?.email}</td>
                    <td>{data?.contact}</td>
                    <td>
                      <div className='d-flex align-items-center gap-2'>
                        {data?.active === true ? <span className="badge badge-light-success fs-7 fw-semibold">Active</span> :
                          <span className="badge badge-light-danger fs-7 fw-semibold">In Active</span>}
                        {loadingStatus[data?.clientId] ? (
                          <div className="spinner-border spinner-border-sm" role="status">
                            <span className="sr-only">Loading...</span>
                          </div>
                        ) : (
                          <div className="form-check form-switch">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              role="switch"
                              id={`flexSwitchCheckChecked-${data?.clientId}`}
                              checked={data?.active}
                              onChange={() => handleToggle(data?.clientId, data?.active)}
                            />
                            <label className="form-check-label" htmlFor={`flexSwitchCheckChecked-${data?.clientId}`}></label>
                          </div>
                        )}
                      </div>
                    </td>
                    <td>{moment(data.createdDt).format("DD MMM YYYY")}</td>
                    <td>
                      <div className='d-flex align-items-center justify-content-end gap-2' >
                        <OverlayTrigger
                          delay={{ show: 250, hide: 350 }}
                          overlay={
                            <Tooltip>
                              Update Client
                            </Tooltip>
                          }>
                          <div className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                            onClick={() => {
                              setShowClientEdit(data);
                              eidtRoleModalHandler();
                            }}
                          >
                            <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
                          </div>
                        </OverlayTrigger>
                      </div>
                    </td>
                  </tr>
                ))}
                {isLoading && (
                  <tr>
                    <td colSpan={7} className="text-center">Loading ....</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          {/* end::Table container */}

          <div className="d-flex flex-stack flex-wrap pt-10">
            <div className="fs-6 fw-bold text-gray-700">
              Showing {((data?.result?.pageNumber - 1) * data?.result?.pageSize) + 1} to {Math.min(data?.result?.pageNumber * data?.result?.pageSize, data?.result?.totalRecords)} of <span>{data?.result?.totalRecords}</span> entries
            </div>
            <div className='d-flex align-items-center gap-3'>
              <div className='d-flex align-items-center'>
                <label className='fs-6 text-gray-700 fw-semibold me-2'>Per page</label>
                <select value={pageSize} className='form-select form-select-solid h-40px w-80px' onChange={handlePageSizeChange}>
                  <option value="10">10</option>
                  <option value="15">15</option>
                  <option value="20">20</option>
                  <option value="25">25</option>
                  <option value="30">30</option>
                  <option value="100">100</option>
                </select>
              </div>
              <ReactPaginate
                breakLabel={"..."}
                nextLabel="Next"
                onPageChange={(e: any) => handlePageClick(e)}
                pageRangeDisplayed={5}
                marginPagesDisplayed={1}
                pageCount={data?.result?.totalPages}
                previousLabel="Previous"
                // renderOnZeroPageCount={null}


                containerClassName='pagination'
                pageClassName='page-item'
                previousClassName='page-item'
                nextClassName='page-item'
                breakClassName='page-item'

                pageLinkClassName='page-link'
                previousLinkClassName='page-link'
                nextLinkClassName='page-link'
                breakLinkClassName='page-link'
                activeClassName='active'
              />
            </div>
          </div>
        </div>
        {/* begin::Body */}
      </div>
    </>
  )
}

export default ClientPage