import ExpenseVoilationPage from './ExpenseVoilationPage'
import { PageTitle } from '../../../../_metronic/layout/core'
import labelKey from '../../../localization/label.json'
const ExpenseVoilationWrapper = () => {
    return (
        <>
            <PageTitle breadcrumbs={[]}>{labelKey.expenseViolation}</PageTitle>
            <ExpenseVoilationPage />
        </>
    )
}

export default ExpenseVoilationWrapper