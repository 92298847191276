import { debounce } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { FaEye } from 'react-icons/fa6';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { KTSVG } from '../../../_metronic/helpers';
import CurrencyComp from '../../../components/currencyComponent/CurrencyComp';
import DateComp from '../../../components/dateComponent/DateComp';
import Pagination from '../../../components/pagination/Pagination';
import SearchBarComponent from '../../../components/searchBar/SearchBarComponent';
import TableHeading from '../../../components/tableHeading/TableHeading';
import TableSettingMenu from '../../../components/tableHeading/TableSettingMenu';
import SelectField from '../../../components/textFields/SelectField';
import TextField from '../../../components/textFields/TextField';
import { selectAuth } from '../../../features/authSlice';
import { useAppSelector } from '../../../hooks';
import { useExpenseCategoryLookupQuery } from '../../../services/GeneralApi';
import { useExpenseByCategoryQuery } from '../../../services/ReportAndAnalyticsApi';
import labelKey from "../../localization/label.json";
import CategoriesWithIcons from '../expenseItems/CategoriesWithIcons';
import { DateRangePicker } from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import moment from 'moment';
import { constraintConfig } from '../../../constraintConfig';
import { Spinner } from 'react-bootstrap';
import { LoadingComponent } from '../../../components/loadingComponent/LoadingComponent';
import NoRecordFound from '../../../components/noRecord/NoRecordFound';
import { Tooltip } from 'react-tooltip';

interface LightboxData {
    imageURL: string;
}
const ExpenseByCategory = () => {
    const [lightboxOpen, setLightboxOpen] = useState(false);
    const [lightboxData, setLightboxData] = useState<LightboxData>({ imageURL: '' });

    const [filterByCategory, setFilterByCategory] = useState("0");
    const [filterByStartDate, setFilterByStartDate] = useState("");
    const [filterByEndDate, setFilterByEndDate] = useState("");
    const [searchStr, setSearchStr] = useState(''); // debounce search
    const [immediateSearchStr, setImmediateSearchStr] = useState('');
    const [page, setPage] = useState(1)
    const [pageSize, setPageSize] = useState(10);

    const [localFilterByStartDate, setLocalFilterByStartDate] = useState("");
    const [localFilterByEndDate, setLocalFilterByEndDate] = useState("");
    const [localFilterByCategory, setLocalFilterByCategory] = useState("0");
    const [isApplyingFilters, setIsApplyingFilters] = useState(false);

    const { userAccountID, roleID } = useAppSelector(selectAuth)
    const { data: expenseCategory } = useExpenseCategoryLookupQuery('')
    const { data, isLoading, refetch, isSuccess } = useExpenseByCategoryQuery(
        {
            userAccountId: userAccountID,
            categoryId: filterByCategory,
            startDate: filterByStartDate,
            endDate: filterByEndDate,
            pageNumber: page,
            pageSize: pageSize,
            searchstr: searchStr,
        })
    const handleCategoryChange = (e: any) => {
        setLocalFilterByCategory(e.target.value);
    };
    const handleDateRangeChange = (event: any, picker: any) => {
        const startDateFormatted = moment(picker.startDate).format('MM-DD-YYYY');
        const endDateFormatted = moment(picker.endDate).format('MM-DD-YYYY');

        setLocalFilterByStartDate(startDateFormatted);
        setLocalFilterByEndDate(endDateFormatted);
    };
    const handleCancelDateSelection = (event: any, picker: any) => {
        // Reset the state to empty strings
        setFilterByStartDate("");
        setFilterByEndDate("");
        setLocalFilterByStartDate("");
        setLocalFilterByEndDate("");
    };
    const applyFilters = () => {
        setIsApplyingFilters(true);
        setFilterByStartDate(localFilterByStartDate);
        setFilterByEndDate(localFilterByEndDate);
        setFilterByCategory(localFilterByCategory);
        // closeMenu();
    };
    const resetFilters = () => {
        setFilterByStartDate('')
        setFilterByEndDate('')
        setFilterByCategory("0")
        setLocalFilterByStartDate("");
        setLocalFilterByEndDate("");
        setLocalFilterByCategory("0")
        closeMenu();

    }
    const debouncedRefetch = debounce(() => {
        refetch().finally(() => {
            setIsApplyingFilters(false);
        });
    }, 300);
    useEffect(() => {
        if (isApplyingFilters) {
            debouncedRefetch();
        }
        if (isSuccess) {
            closeMenu();
        }
    }, [isApplyingFilters, isSuccess]);

    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(prevState => !prevState);
    };
    const closeMenu = () => {
        setIsMenuOpen(false);
    };

    const handlePageSizeChange = (e: any) => {
        const newSize = parseInt(e.target.value);
        setPageSize(newSize);
        setPage(1); // Reset page number to 1
    };
    // pagination
    const handlePageClick = (e: any) => {
        setPage(e.selected + 1);
    };
    //per page record
    const handlePageRecords = (e: any) => {
        setPage(1); // Reset the page to 1
    };
    const handleSearchChange = (e: any) => {
        const { value } = e.target;
        setImmediateSearchStr(value); // Update immediate search state

        delayedSearch(value); // Update debounced search state
    };
    const delayedSearch = useCallback(
        debounce((searchValue) => {
            setSearchStr(searchValue);
        }, 500),
        []
    );
    useEffect(() => {
        refetch();
        if (searchStr === "") {
            refetch();
        }
    }, [searchStr]);
    const isPdfFile = (filePath: string | undefined) => {
        if (!filePath) return false;
        const fileExtension = filePath.toLowerCase().split('.').pop();
        return fileExtension === 'pdf';
    };
    const openPdfInBrowserOrLightbox = (attachmentFile: string | undefined) => {
        if (attachmentFile) {
            if (isPdfFile(attachmentFile)) {
                // For PDF files, open in the browser
                window.open(attachmentFile, '_blank');
            } else {
                // For image files, open in the Lightbox
                setLightboxData({ imageURL: attachmentFile });
                setLightboxOpen(true);
            }
        }
    };
    const [hiddenColumns, setHiddenColumns] = useState<string[]>(['sr', 'category', 'currency', 'amount', 'vendor', 'paymentMethod', 'itemDate', 'attachment', 'source', 'description']);
    const handleToggleColumn = (columnId: string) => {
        if (hiddenColumns.includes(columnId)) {
            setHiddenColumns(hiddenColumns.filter((id) => id !== columnId));
        } else {
            setHiddenColumns([...hiddenColumns, columnId]);
        }
    };

    return (
        <>
            <div className='card'>
                <div className='card-header border-0  align-items-center'>
                    <SearchBarComponent
                        className='me-4'
                        placeholder='Expense by category'
                        value={immediateSearchStr}
                        onChange={handleSearchChange}
                    />
                    <div className='position-relative'>
                        <button
                            type='button'
                            className='btn btn-sm btn-light-primary me-3 d-flex justify-content-end'
                            data-kt-menu-placement='bottom-end'
                            onClick={toggleMenu}
                        >
                            <KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-2' />
                            {labelKey.filter}
                        </button>
                        <div className={`menu menu-sub menu-sub-dropdown w-300px w-md-325px' ${isMenuOpen ? 'show position-absolute mt-3' : ''}`} style={{ right: "5px" }}>
                            <div className='px-7 py-5'>
                                <div className='fs-5 text-dark fw-bold'>{labelKey.filterOptions}</div>
                            </div>
                            <div className='separator border-gray-200'></div>
                            <div className='px-7 py-5' data-kt-user-table-filter='form'>
                                <div className='mb-5'>
                                    <label className='form-label fw-bold text-dark fs-6'>Date</label>
                                    <DateRangePicker onApply={handleDateRangeChange} onCancel={handleCancelDateSelection}>
                                        <input type="text"
                                            className="form-control form-control-lg form-control-solid default-input"
                                            value={localFilterByStartDate && localFilterByEndDate ? `${localFilterByStartDate} - ${localFilterByEndDate}` : "mm/dd/yyyy"} />
                                    </DateRangePicker>
                                </div>
                                <div>
                                    <SelectField
                                        label={`${labelKey.categoryFilter}:`}
                                        value={localFilterByCategory}
                                        onChange={handleCategoryChange}>
                                        <option value={0}>{labelKey.selectCategory}</option>
                                        {expenseCategory?.result?.map((data: any, index: any) => (
                                            <option key={data?.id} value={data?.id}>{data?.value}</option>
                                        ))}
                                    </SelectField>
                                </div>
                            </div>
                            <div className='separator border-gray-200'></div>
                            <div className='px-7 py-5'>
                                <button className='btn btn-primary pull-right' onClick={applyFilters}>
                                    {isApplyingFilters ? <>
                                        <span>loading...</span>
                                        <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                    </> : "Apply Filters"}
                                </button>
                                <button className='btn btn-danger pull-right me-3' onClick={resetFilters}>Reset Filters</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='card-body py-3'>
                    <div className="row">
                        <div className="col-md-12">
                            <div className='table-responsive table-height'>
                                <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                                    <thead>
                                        <tr className='fw-bold text-muted'>
                                            {hiddenColumns.includes('sr') && (
                                                <TableHeading
                                                    label={labelKey.sr}
                                                    columnId='sr'
                                                    className="ps-4 w-60px"
                                                />
                                            )}
                                            {hiddenColumns.includes('category') && (
                                                <TableHeading
                                                    label={labelKey.category}
                                                    columnId='category'
                                                />
                                            )}
                                            {hiddenColumns.includes('currency') && (
                                                <TableHeading
                                                    label={labelKey.currency}
                                                    columnId='currency'
                                                    className="w-200px"
                                                />
                                            )}
                                            {hiddenColumns.includes('amount') && (
                                                <TableHeading
                                                    label={labelKey.amount}
                                                    columnId='amount'
                                                />
                                            )}
                                            {hiddenColumns.includes('vendor') && (
                                                <TableHeading
                                                    label={labelKey.vendor}
                                                    columnId='vendor'
                                                />
                                            )}
                                            {hiddenColumns.includes('paymentMethod') && (
                                                <TableHeading
                                                    label={labelKey.paymentMethod}
                                                    columnId='paymentMethod'
                                                />
                                            )}
                                            {hiddenColumns.includes('itemDate') && (
                                                <TableHeading
                                                    label={labelKey.itemDate}
                                                    columnId='itemDate'
                                                />
                                            )}
                                            {hiddenColumns.includes('attachment') && (
                                                <TableHeading
                                                    label={labelKey.attachment}
                                                    columnId='attachment'
                                                />
                                            )}
                                            {hiddenColumns.includes('source') && (
                                                <TableHeading
                                                    label={labelKey.source}
                                                    columnId='source'
                                                />
                                            )}
                                            {hiddenColumns.includes('description') && (
                                                <TableHeading
                                                    label={labelKey.description}
                                                    columnId='description'
                                                />
                                            )}
                                            <TableHeading className='text-end rounded-end pe-2 border-0' columnId='menu'>
                                                <TableSettingMenu>
                                                    <TextField
                                                        rightLabel={labelKey.sr}
                                                        type="checkbox"
                                                        onChange={() => handleToggleColumn("sr")}
                                                        checked={hiddenColumns.includes('sr')}
                                                        fieldClass='mb-4'
                                                    />

                                                    <TextField
                                                        rightLabel={labelKey.category}
                                                        type="checkbox"
                                                        onChange={() => handleToggleColumn("category")}
                                                        checked={hiddenColumns.includes('category')}
                                                        fieldClass='mb-4'
                                                    />
                                                    <TextField
                                                        rightLabel={labelKey.currency}
                                                        type="checkbox"
                                                        onChange={() => handleToggleColumn("currency")}
                                                        checked={hiddenColumns.includes('currency')}
                                                        fieldClass='mb-4'
                                                    />
                                                    <TextField
                                                        rightLabel={labelKey.amount}
                                                        type="checkbox"
                                                        onChange={() => handleToggleColumn("amount")}
                                                        checked={hiddenColumns.includes('amount')}
                                                        fieldClass='mb-4'
                                                    />
                                                    <TextField
                                                        rightLabel={labelKey.vendor}
                                                        type="checkbox"
                                                        onChange={() => handleToggleColumn("vendor")}
                                                        checked={hiddenColumns.includes('vendor')}
                                                        fieldClass='mb-4'
                                                    />
                                                    <TextField
                                                        rightLabel={labelKey.paymentMethod}
                                                        type="checkbox"
                                                        onChange={() => handleToggleColumn("paymentMethod")}
                                                        checked={hiddenColumns.includes('paymentMethod')}
                                                        fieldClass='mb-4'
                                                    />
                                                    <TextField
                                                        rightLabel={labelKey.itemDate}
                                                        type="checkbox"
                                                        onChange={() => handleToggleColumn("itemDate")}
                                                        checked={hiddenColumns.includes('itemDate')}
                                                        fieldClass='mb-4'
                                                    />
                                                    <TextField
                                                        rightLabel={labelKey.attachment}
                                                        type="checkbox"
                                                        onChange={() => handleToggleColumn("attachment")}
                                                        checked={hiddenColumns.includes('attachment')}
                                                        fieldClass='mb-4'
                                                    />
                                                    <TextField
                                                        rightLabel={labelKey.source}
                                                        type="checkbox"
                                                        onChange={() => handleToggleColumn("source")}
                                                        checked={hiddenColumns.includes('source')}
                                                        fieldClass='mb-4'
                                                    />
                                                    <TextField
                                                        rightLabel={labelKey.description}
                                                        type="checkbox"
                                                        onChange={() => handleToggleColumn("description")}
                                                        checked={hiddenColumns.includes('description')}
                                                        fieldClass='mb-4'
                                                    />
                                                </TableSettingMenu>
                                            </TableHeading>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {!isLoading &&
                                            <>
                                                {data?.result?.data?.length > 0 ? (
                                                    <>
                                                        {data?.result?.data?.map((itemData: any, index: any) => (
                                                            <tr key={index}>
                                                                {hiddenColumns.includes('sr') && (
                                                                    <td className='text-center'>
                                                                        {(page - 1) * pageSize + index + 1}
                                                                    </td>
                                                                )}
                                                                {hiddenColumns.includes('category') && (
                                                                    <td className='ps-5'>
                                                                        {/* {itemData?.expenseCategory?.title} */}
                                                                        <CategoriesWithIcons itemData={itemData} />
                                                                    </td>
                                                                )}
                                                                {hiddenColumns.includes('currency') && (
                                                                    <td>{itemData?.currency?.title}</td>
                                                                )}
                                                                {hiddenColumns.includes('amount') && (
                                                                    <td><CurrencyComp amount={itemData?.amount} /></td>
                                                                )}
                                                                {hiddenColumns.includes('vendor') && (
                                                                    <td>{itemData?.vendor?.name !== " " ? itemData?.vendor?.name : "N/A"}</td>
                                                                )}
                                                                {hiddenColumns.includes('paymentMethod') && (
                                                                    <td>{itemData?.paymentMethod?.title}</td>
                                                                )}
                                                                {hiddenColumns.includes('itemDate') && (
                                                                    <td><DateComp formattedDate={itemData.itemDate} /></td>
                                                                )}
                                                                {hiddenColumns.includes('attachment') && (
                                                                    <td>
                                                                        {itemData?.attachmentFile === "undefined" || itemData?.attachmentFile === "" || itemData?.attachmentFile === "null" || itemData?.attachmentFile === null ?
                                                                            <span className='ps-9'>N/A</span> :
                                                                            <FaEye className='ms-10' onClick={() => openPdfInBrowserOrLightbox(itemData?.attachmentFile)} />
                                                                        }
                                                                        {lightboxOpen && (
                                                                            <Lightbox
                                                                                mainSrc={lightboxData.imageURL}
                                                                                onCloseRequest={() => setLightboxOpen(false)}
                                                                                imageCaption="Attachment"
                                                                                enableZoom={true}
                                                                                imagePadding={50}
                                                                            />
                                                                        )}
                                                                    </td>
                                                                )}
                                                                {hiddenColumns.includes('source') && (
                                                                    <td>
                                                                        <Tooltip id="web-source" place="top" />
                                                                        <Tooltip id="mobile-source" place="top" />
                                                                        <Tooltip id="bank-source" place="top" />
                                                                        {itemData?.recordSourceId === constraintConfig.recordSourceId.WebApp ?
                                                                            <KTSVG path="/media/icons/figmaIcons/Web.svg" className='svg-icon-1 ms-4'
                                                                                svgClassName='w-20px h-20px'
                                                                                data-tooltip-id="web-source" data-tooltip-content='Website' />
                                                                            :
                                                                            itemData?.recordSourceId === constraintConfig.recordSourceId.Mobile ?
                                                                                <KTSVG path="/media/icons/figmaIcons/Mobile.svg" className='svg-icon-1 ms-4'
                                                                                    data-tooltip-id="mobile-source" data-tooltip-content='Mobile' /> :
                                                                                itemData?.recordSourceId === constraintConfig.recordSourceId.BankFeed ?
                                                                                    <KTSVG path="/media/icons/figmaIcons/Bank.svg" className='svg-icon-1 ms-4'
                                                                                        data-tooltip-id="bank-source" data-tooltip-content='Bank' />
                                                                                    : null}
                                                                    </td>
                                                                )}
                                                                {hiddenColumns.includes('description') && (
                                                                    <td>{itemData?.itemDetail?.length > 60 ? `${itemData?.itemDetail.substring(0, 40)}...` : itemData?.itemDetail}</td>
                                                                )}
                                                            </tr>
                                                        ))}
                                                    </>
                                                ) :
                                                    (
                                                        <tr>
                                                            <td colSpan={10}>
                                                                <NoRecordFound />
                                                            </td>
                                                        </tr>
                                                    )
                                                }
                                            </>
                                        }
                                        {isLoading && (
                                            <tr>
                                                <td colSpan={10} className="text-center">
                                                    <LoadingComponent />
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <Pagination
                            totalResult={data?.result?.totalRecords === 0 ? 0 : ((data?.result?.pageNumber - 1) * data?.result?.pageSize) + 1}
                            toResult={data?.result?.totalRecords === 0 ? 0 : Math.min(data?.result?.pageNumber * data?.result?.pageSize, data?.result?.totalRecords)}
                            ofResult={data?.result?.totalRecords}
                            onChange={handlePageSizeChange}
                            pageSize={pageSize}
                            onPageChange={handlePageClick}
                            pageCount={data?.result?.totalPages || 0}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default ExpenseByCategory