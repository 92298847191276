import type {
    BaseQueryFn,
    FetchArgs,
    FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react';
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { selectAuth } from '../features/authSlice';
import { RootState } from "../store";
import { API_END_POINTS } from './apiEndpoints';
import { baseUrl } from './baseUrl';

const rawBaseQuery = fetchBaseQuery({
    baseUrl: baseUrl.MAIN_BASE_URL,
})

type ExtraOptions = {
    headers?: Record<string, string>;
    // Define other optional properties here if needed
};

const dynamicBaseQuery: BaseQueryFn<
    string | FetchArgs,
    unknown,
    FetchBaseQueryError,
    ExtraOptions
> = async (args, api, extraOptions) => {
    const orgBaseUrl = selectAuth(api.getState() as RootState).baseURL;
    // gracefully handle scenarios where data to generate the URL is missing
    if (!orgBaseUrl) {
        return {
            error: {
                status: 400,
                statusText: 'Bad Request',
                data: 'dynamic route not found',
            },
        }
    }

    const urlEnd = typeof args === 'string' ? args : args.url

    // construct a dynamically generated portion of the url
    const adjustedUrl = `${orgBaseUrl}/api/OrgSettings${urlEnd}`
    const adjustedArgs = typeof args === 'string' ? { url: adjustedUrl } : { ...args, url: adjustedUrl };
    // Get the token from localStorage
    const token = localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user")!).token
        : null;
    // Set headers if token is available
    const headers = token ? { Authorization: `Bearer ${token}` } : {};
    // Merge headers with existing headers
    const mergedHeaders = { ...headers, ...extraOptions?.headers };
    // provide the amended url and other params to the raw base query
    return rawBaseQuery({ ...adjustedArgs, headers: mergedHeaders }, api, extraOptions)

}

export const OrgSettingsApi = createApi({
    reducerPath: 'OrgSettingsApi',
    baseQuery: dynamicBaseQuery,
    // baseQuery: fetchBaseQuery({
    //     baseUrl: baseUrl.BASE_URL + `/api/OrgSettings/`,
    //     prepareHeaders: (headers) => {
    //         // Get the token from localStorage
    //         const token = localStorage.getItem("user")
    //             ? JSON.parse(localStorage.getItem("user")!).token
    //             : null;
    //         if (token) {
    //             headers.set('Authorization', `Bearer ${token}`);
    //         }

    //         return headers;
    //     },
    // }),
    endpoints: (builder) => ({
        getAllCountry: builder.query({
            query: ({ pageNumber, pageSize, sortBy, sortOrder, filterByStatus, searchStr }) => {
                return {
                    url: `${API_END_POINTS.getAllCountry}?pageSize=${pageSize}&pageNumber=${pageNumber}&sortBy=${sortBy}&sortOrder=${sortOrder}&searchStr=${searchStr}&filterByStatus=${filterByStatus}`,
                    method: "GET",
                }
            },
        }),
        addCountry: builder.mutation({
            query: (body) => {
                return {
                    url: API_END_POINTS.addCountry,
                    method: "POST",
                    body,
                }
            },
        }),
        updateCountry: builder.mutation({
            query: (body) => {
                return {
                    url: API_END_POINTS.updateCountry,
                    method: "PUT",
                    body,
                }
            },
        }),
        getCountryById: builder.query({
            query: ({ CountryId }) => {
                return {
                    url: `${API_END_POINTS.getCountryById}?CountryId=${CountryId}`,
                    method: "GET",
                }
            },
        }),
        changeCountryStatus: builder.mutation({
            query: ({ countryId, status }) => {
                return {
                    url: `${API_END_POINTS.changeCountryStatus}?countryId=${countryId}&status=${status}`,
                    method: "PUT",
                }
            },
        }),

        getAllDepartmentLocation: builder.query({
            query: ({ pageNumber, pageSize, sortBy, sortOrder, filterByStatus, searchStr }) => {
                return {
                    url: `${API_END_POINTS.getAllDepartmentLocation}?pageSize=${pageSize}&pageNumber=${pageNumber}&sortBy=${sortBy}&sortOrder=${sortOrder}&searchStr=${searchStr}&filterByStatus=${filterByStatus}`,
                    method: "GET",
                }
            },
        }),
        addDepartmentLocation: builder.mutation({
            query: (body) => {
                return {
                    url: API_END_POINTS.addDepartmentLocation,
                    method: "POST",
                    body,
                }
            },
        }),
        updateDepartmentLocation: builder.mutation({
            query: (body) => {
                return {
                    url: API_END_POINTS.updateDepartmentLocation,
                    method: "PUT",
                    body,
                }
            },
        }),
        getDepartmentLocationById: builder.query({
            query: ({ departmentLocationId }) => {
                return {
                    url: `${API_END_POINTS.getDepartmentLocationById}?departmentLocationId=${departmentLocationId}`,
                    method: "GET",
                }
            },
        }),
        changeDepartmentLocationStatus: builder.mutation({
            query: ({ deptLocId, status }) => {
                return {
                    url: `${API_END_POINTS.changeDepartmentLocationStatus}?deptLocId=${deptLocId}&status=${status}`,
                    method: "PUT",
                }
            },
        }),


        getAllDivision: builder.query({
            query: ({ pageNumber, pageSize, sortBy, sortOrder, filterByStatus, searchStr }) => {
                return {
                    url: `${API_END_POINTS.getAllDivision}?pageSize=${pageSize}&pageNumber=${pageNumber}&sortBy=${sortBy}&sortOrder=${sortOrder}&searchStr=${searchStr}&filterByStatus=${filterByStatus}`,
                    method: "GET",
                }
            },
        }),
        addDivision: builder.mutation({
            query: (body) => {
                return {
                    url: API_END_POINTS.addDivision,
                    method: "POST",
                    body,
                }
            },
        }),
        updateDivision: builder.mutation({
            query: (body) => {
                return {
                    url: API_END_POINTS.updateDivision,
                    method: "PUT",
                    body,
                }
            },
        }),
        getDivisionById: builder.query({
            query: ({ divisionId }) => {
                return {
                    url: `${API_END_POINTS.getDivisionById}?divisionId=${divisionId}`,
                    method: "GET",
                }
            },
        }),
        changeDivisionStatus: builder.mutation({
            query: ({ divisionId, status }) => {
                return {
                    url: `${API_END_POINTS.changeDivisionStatus}?divisionId=${divisionId}&status=${status}`,
                    method: "PUT",
                }
            },
        }),

        getAllExpenseTypeCodes: builder.query({
            query: ({ pageNumber, pageSize, sortBy, sortOrder, filterByStatus, searchStr }) => {
                return {
                    url: `${API_END_POINTS.getAllExpenseTypeCodes}?pageSize=${pageSize}&pageNumber=${pageNumber}&sortBy=${sortBy}&sortOrder=${sortOrder}&searchStr=${searchStr}&filterByStatus=${filterByStatus}`,
                    method: "GET",
                }
            },
        }),
        addExpenseTypeCodes: builder.mutation({
            query: (body) => {
                return {
                    url: API_END_POINTS.addExpenseTypeCodes,
                    method: "POST",
                    body,
                }
            },
        }),
        updateExpenseTypeCodes: builder.mutation({
            query: (body) => {
                return {
                    url: API_END_POINTS.updateExpenseTypeCodes,
                    method: "PUT",
                    body,
                }
            },
        }),
        getExpenseTypeCodesById: builder.query({
            query: ({ expenseTypeCodesId }) => {
                return {
                    url: `${API_END_POINTS.getExpenseTypeCodesById}?expenseTypeCodesId=${expenseTypeCodesId}`,
                    method: "GET",
                }
            },
        }),
        changeExpenseTypeCodesStatus: builder.mutation({
            query: ({ expenseTypeCodeId, status }) => {
                return {
                    url: `${API_END_POINTS.changeExpenseTypeCodesStatus}?expenseTypeCodeId=${expenseTypeCodeId}&status=${status}`,
                    method: "PUT",
                }
            },
        }),

        getAllActiveExpenseViolations: builder.query({
            query: ({ pageNumber, pageSize, sortBy, sortOrder, filterByStatus, searchStr }) => {
                return {
                    url: `${API_END_POINTS.getAllActiveExpenseViolations}?pageSize=${pageSize}&pageNumber=${pageNumber}&sortBy=${sortBy}&sortOrder=${sortOrder}&searchStr=${searchStr}&filterByStatus=${filterByStatus}`,
                    method: "GET",
                }
            },
        }),
        addActiveExpenseViolations: builder.mutation({
            query: (body) => {
                return {
                    url: API_END_POINTS.addActiveExpenseViolations,
                    method: "POST",
                    body,
                }
            },
        }),
        updateActiveExpenseViolations: builder.mutation({
            query: (body) => {
                return {
                    url: API_END_POINTS.updateActiveExpenseViolations,
                    method: "PUT",
                    body,
                }
            },
        }),
        getActiveExpenseViolationsById: builder.query({
            query: ({ activeExpenseViolationsId }) => {
                return {
                    url: `${API_END_POINTS.getActiveExpenseViolationsById}?activeExpenseViolationsId=${activeExpenseViolationsId}`,
                    method: "GET",
                }
            },
        }),
        changeActiveExpenseViolationsStatus: builder.mutation({
            query: ({ activeExpenseViolationId, status }) => {
                return {
                    url: `${API_END_POINTS.changeActiveExpenseViolationsStatus}?activeExpenseViolationId=${activeExpenseViolationId}&status=${status}`,
                    method: "PUT",
                }
            },
        }),

        getActiveExpenseViolationsByExpenseId: builder.query({
            query: ({ expenseId }) => {
                return {
                    url: `${API_END_POINTS.getActiveExpenseViolationsByExpenseId}?expenseId=${expenseId}`,
                    method: "GET",
                }
            },
        }),

        getAllLocation: builder.query({
            query: ({ pageNumber, pageSize, sortBy, sortOrder, filterByStatus, searchStr }) => {
                return {
                    url: `${API_END_POINTS.getAllLocation}?pageSize=${pageSize}&pageNumber=${pageNumber}&sortBy=${sortBy}&sortOrder=${sortOrder}&searchStr=${searchStr}&filterByStatus=${filterByStatus}`,
                    method: "GET",
                }
            },
        }),
        addLocation: builder.mutation({
            query: (body) => {
                return {
                    url: API_END_POINTS.addLocation,
                    method: "POST",
                    body,
                }
            },
        }),
        updateLocation: builder.mutation({
            query: (body) => {
                return {
                    url: API_END_POINTS.updateLocation,
                    method: "PUT",
                    body,
                }
            },
        }),
        changeLocationStatus: builder.mutation({
            query: ({ locationId, status }) => {
                return {
                    url: `${API_END_POINTS.changeLocationStatus}?locationId=${locationId}&status=${status}`,
                    method: "PUT",
                }
            },
        }),

        getAllDepartmentList: builder.query({
            query: ({ pageNumber, pageSize, sortBy, sortOrder, filterByStatus, searchStr }) => {
                return {
                    url: `${API_END_POINTS.getAllDepartmentList}?pageSize=${pageSize}&pageNumber=${pageNumber}&sortBy=${sortBy}&sortOrder=${sortOrder}&searchStr=${searchStr}&filterByStatus=${filterByStatus}`,
                    method: "GET",
                }
            },
        }),
        addDepartment: builder.mutation({
            query: (body) => {
                return {
                    url: API_END_POINTS.addDepartment,
                    method: "POST",
                    body,
                }
            },
        }),
        updateDepartment: builder.mutation({
            query: (body) => {
                return {
                    url: API_END_POINTS.updateDepartment,
                    method: "PUT",
                    body,
                }
            },
        }),
        getDepartmentById: builder.query({
            query: ({ departmentId }) => {
                return {
                    url: `${API_END_POINTS.getDepartmentById}?departmentId=${departmentId}`,
                    method: "GET",
                }
            },
        }),
        changeDepartmentStatus: builder.mutation({
            query: ({ deptLocId, status }) => {
                return {
                    url: `${API_END_POINTS.changeDepartmentStatus}?deptLocId=${deptLocId}&status=${status}`,
                    method: "PUT",
                }
            },
        }),

       
    }),
})


export const {
    useGetAllCountryQuery,
    useAddCountryMutation,
    useUpdateCountryMutation,
    useGetCountryByIdQuery,
    useChangeCountryStatusMutation,
    useAddDepartmentLocationMutation,
    useUpdateDepartmentLocationMutation,
    useGetAllDepartmentLocationQuery,
    useLazyGetDepartmentLocationByIdQuery,
    useChangeDepartmentLocationStatusMutation,
    useGetAllDivisionQuery,
    useAddDivisionMutation,
    useUpdateDivisionMutation,
    useGetDivisionByIdQuery,
    useChangeDivisionStatusMutation,
    useGetAllExpenseTypeCodesQuery,
    useAddExpenseTypeCodesMutation,
    useUpdateExpenseTypeCodesMutation,
    useGetExpenseTypeCodesByIdQuery,
    useChangeExpenseTypeCodesStatusMutation,
    useGetAllActiveExpenseViolationsQuery,
    useAddActiveExpenseViolationsMutation,
    useUpdateActiveExpenseViolationsMutation,
    useGetActiveExpenseViolationsByIdQuery,
    useChangeActiveExpenseViolationsStatusMutation,
    useGetActiveExpenseViolationsByExpenseIdQuery,
    useGetAllLocationQuery,
    useAddLocationMutation,
    useUpdateLocationMutation,
    useChangeLocationStatusMutation,
    useGetAllDepartmentListQuery,
    useAddDepartmentMutation,
    useUpdateDepartmentMutation,
    useGetDepartmentByIdQuery,
    useChangeDepartmentStatusMutation,
} = OrgSettingsApi