/* eslint-disable jsx-a11y/anchor-is-valid */
import moment from 'moment';
import { useEffect, useState } from 'react';
import SelectField from '../../../../../components/textFields/SelectField';
import TextAreaField from '../../../../../components/textFields/TextAreaField';
import TextField from '../../../../../components/textFields/TextField';
import { useGetRemaningBudgetQuery } from '../../../../../services/BudgetApi';
import { useGetAllExpenseTypeQuery } from '../../../../../services/GeneralApi';
import labelKey from "../../../../localization/label.json";
import { constraintConfig } from '../../../../../constraintConfig';
import { useAppSelector } from '../../../../../hooks';
import { selectAuth } from '../../../../../features/authSlice';

type Props = {
  formik: any
}
const Step1 = ({ formik }: Props) => {
  const currentDate = moment().format('YYYY-MM-DD')
  const [wordCount, setWordCount] = useState(0);
  const { roleID, userAccountID } = useAppSelector(selectAuth)
  const { data: getAllExpenseType, refetch: expenseTypeRefetch } = useGetAllExpenseTypeQuery('')
  const { data, refetch } = useGetRemaningBudgetQuery({
    userAccountId: userAccountID,
    expenseDate: formik.values.reportDate,
  })
  useEffect(() => {
    refetch()
    expenseTypeRefetch()
  }, [])
  const handleTextAreaChange = (event: any) => {
    const text = event.target.value;
    const words = text.trim().split("");
    const wordCount = words.reduce((count: any, word: any) => count + word.length, 0);
    setWordCount(wordCount);
  };
  return (
    <div className='current' data-kt-stepper-element='content'>
      <div className='w-100'>

        {/*begin::Form Group */}
        <div className="row m-0">
          <div className='col-md-6 mb-10'>
            <SelectField
              label={labelKey.expenseType}
              required={true}
              {...formik.getFieldProps(`expenseType.id`)}
              value={formik.values.expenseType?.id}
            >
              <option value="">Select Type</option>
              {Array.isArray(getAllExpenseType?.result) && getAllExpenseType?.result?.map((option: any, index: any) => (
                <option key={option.id} value={option.id}
                >{option?.value}</option>
              ))}
            </SelectField>
          </div>
          <div className='col-md-6 mb-10'>
            <TextField
              label={labelKey.reportName}
              required={true}
              type='text'
              placeholder='Enter Report Name'
              {...formik.getFieldProps('title')}
              value={formik.values.title}
            />
          </div>
          <div className='col-md-6 mb-10'>
            <TextField
              label={labelKey.reportingPeriod}
              required={true}
              type='date'
              {...formik.getFieldProps('reportDate')}
              value={formik.values.reportDate ? moment(formik.values.reportDate).format('YYYY-MM-DD') : ''}
              max={currentDate}
            />
          </div>
          {roleID !== constraintConfig.roleID.role2 &&
            <>
              {parseInt(formik.values?.expenseType?.id) === constraintConfig.expenseType.flex && (
                <div className='col-md-6'>
                  <label className='d-flex align-items-center fs-5 fw-bold mb-2'>{data?.result === 0 ? <>{labelKey.budget}</> : <>{labelKey.remainingBudget}</>}</label>
                  <p className='fs-4 fw-bold mb-0 form-control form-control-lg form-control-solid'>${data?.result ? data?.result : "0"}</p>
                </div>
              )}
            </>
          }
          <div className='col-md-12 mb-10'>
            <div>
              <TextAreaField
                label={labelKey.purposeOfExpense}
                {...formik.getFieldProps('expenseDetail')}
                cols={20}
                rows={7}
                placeholder='Enter Expense Detail'
                value={formik.values.expenseDetail || ''}
                onChange={(e) => {
                  formik.handleChange(e);
                  handleTextAreaChange(e);
                }}
              />
              <p className="text-muted text-end">Characters: ({wordCount}/500)</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export { Step1 };

