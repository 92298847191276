import { PageTitle } from '../../../../_metronic/layout/core'
import labelKey from '../../../localization/label.json'
import DivisionPage from './DivisionPage'

const DivisionWrapper = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>{labelKey.division}</PageTitle>
      <DivisionPage />
    </>
  )
}

export default DivisionWrapper