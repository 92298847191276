import type {
    BaseQueryFn,
    FetchArgs,
    FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react';
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { selectAuth } from "../features/authSlice";
import { RootState } from "../store";
import { API_END_POINTS } from './apiEndpoints';
import { baseUrl } from './baseUrl';

const rawBaseQuery = fetchBaseQuery({
    baseUrl: baseUrl.MAIN_THIRD_PARTY_BASE_URL,
})

type ExtraOptions = {
    headers?: Record<string, string>;
    // Define other optional properties here if needed
};

const dynamicBaseQuery: BaseQueryFn<
    string | FetchArgs,
    unknown,
    FetchBaseQueryError,
    ExtraOptions
> = async (args, api, extraOptions) => {
    // const orgBaseUrl = selectAuth(api.getState() as RootState) state.auth.baseURL;
    const orgBaseUrl = selectAuth(api.getState() as RootState).microServiceURL;

    console.log('orgBaseUrl', orgBaseUrl);

    // gracefully handle scenarios where data to generate the URL is missing
    if (!orgBaseUrl) {
        return {
            error: {
                status: 400,
                statusText: 'Bad Request',
                data: 'dynamic route not found',
            },
        }
    }

    const urlEnd = typeof args === 'string' ? args : args.url

    // construct a dynamically generated portion of the url
    const adjustedUrl = `${orgBaseUrl}/api/BackgroundJob${urlEnd}`
    const adjustedArgs = typeof args === 'string' ? { url: adjustedUrl } : { ...args, url: adjustedUrl };
    // Get the token from localStorage
    const token = localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user")!).token
        : null;
    // Set headers if token is available
    const headers = token ? { Authorization: `Bearer ${token}` } : {};
    // Merge headers with existing headers
    const mergedHeaders = { ...headers, ...extraOptions?.headers };
    // provide the amended url and other params to the raw base query
    return rawBaseQuery({ ...adjustedArgs, headers: mergedHeaders }, api, extraOptions)

}


export const BackgroundJobApi = createApi({
    reducerPath: 'BackgroundJobApi',
    baseQuery: dynamicBaseQuery,
    // baseQuery: fetchBaseQuery({
    //     baseUrl: baseUrl.THIRD_PARTY_BASE_URL + `/api/BackgroundJob/`,
    //     prepareHeaders: (headers) => {
    //         // Get the token from localStorage
    //         const token = localStorage.getItem("user")
    //             ? JSON.parse(localStorage.getItem("user")!).token
    //             : null;
    //         if (token) {
    //             headers.set('Authorization', `Bearer ${token}`);
    //         }
    //         return headers;
    //     },
    // }),
    tagTypes: ['BackgroundJobApi'],
    endpoints: (builder) => ({
        uploadOCRInvoice: builder.mutation({
            query: (body) => {
                return {
                    url: API_END_POINTS.uploadOCRInvoice,
                    method: "POST",
                    body,
                }
            },
        }),
        getOCRvalueByfileURL: builder.query({
            query: ({ file }) => {
                return {
                    url: `${API_END_POINTS.getOCRvalueByfileURL}?file=${file}`,
                    method: "GET",
                }
            },
        }),
        uploadOCRInvoiceWithItemsId: builder.mutation({
            query: (body) => {
                return {
                    url: API_END_POINTS.uploadOCRInvoiceWithItemsId,
                    method: "POST",
                    body,
                }
            },
        }),

    }),
})

export const {
    useGetOCRvalueByfileURLQuery,
    useUploadOCRInvoiceMutation,
    useUploadOCRInvoiceWithItemsIdMutation
} = BackgroundJobApi