import clsx from 'clsx'
import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { FaEye, FaEyeSlash } from 'react-icons/fa6'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import * as Yup from 'yup'
import labelKey from "../../../localization/label.json"
import { useUpdatePasswordMutation } from '../../../../services/authenticationApi'

const setPasswordSchema = Yup.object().shape({
    password: Yup.string()
        .min(8, 'Minimum 8 symbols')
        .max(16, 'Maximum 16 symbols')
        .test('uppercase', 'Password must contain at least one capital letter', (value) => {
            return /[A-Z]/.test(value || '');
        })
        .test('specialCharacter', 'Password must contain at least one special character', (value) => {
            return /[\W_]/.test(value || '');
        })
        .test('number', 'Password must contain at least one number', (value) => {
            return /[0-9]/.test(value || '');
        })
        .test('lowercase', 'Password must contain at least one lowercase letter', (value) => {
            return /[a-z]/.test(value || '');
        })
        .required('Password is required'),
    confirmPassword: Yup.string()
        .required('Password confirmation is required')
        .when('password', {
            is: (val: string) => (val && val.length > 0 ? true : false),
            then: Yup.string().oneOf([Yup.ref('password')], "Password and Confirm Password didn't match"),
        }),
})

const SetPassword = () => {
    const themeModeMenuValue = localStorage.getItem('kt_theme_mode_menu');
    const [showPassword, setShowPassword] = useState(true)
    const [showConfPassword, setShowConfPassword] = useState(true)

    const { token } = useParams();
    const [setPassword, { data, isError, isSuccess, error, isLoading }] = useUpdatePasswordMutation();
    // const { data: tokenValidateData, isError: isErrorValidateData, error: errorValidateData } = usePasswordTokenValidateQuery(token);
    const initialValues = {
        token: token,
        password: "",
        confirmPassword: "",
    }
    const navigate = useNavigate();
    const formik = useFormik({
        initialValues,
        validationSchema: setPasswordSchema,
        onSubmit: (values) => {
            setPassword({ ...values })
        },
    })
    useEffect(() => {
        if (isSuccess) {
            toast.success("Password Updated Succesfully");
            navigate("/")
        }
    }, [isSuccess]);

    useEffect(() => {
        if (isError && error) {
            let errorMessage: string = 'Something went wrong';

            if ('data' in error && error.data && typeof error.data === 'object' && 'message' in error.data) {
                errorMessage = (error.data as { message: string }).message;
            }
            toast.error(errorMessage);
        }
    }, [isError, error]);
    return (
        <>
            <form
                className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
                noValidate
                id='kt_login_password_reset_form'
                onSubmit={formik.handleSubmit}
            >
                <div className='text-center mb-10'>
                    {/* begin::Title */}
                    {themeModeMenuValue === 'dark' ?
                        <img
                            alt='Logo'
                            src='/media/logos/logomain-white.png'
                            className='h-50px me-7'
                        /> :
                        <img
                            alt='Logo'
                            src='/media/logos/logomain.png'
                            className='h-50px me-7'
                        />}
                    <h1 className='text-dark mb-3'>{labelKey.setPassword}</h1>
                    {/* end::Title */}
                </div>

                <div className='fv-row'>
                    <input
                        type='hidden'
                        placeholder=''
                        autoComplete='off'
                        {...formik.getFieldProps('token')}
                    />
                </div>
                <div className='fv-row mb-5'>
                    <label className='form-label fw-bold text-gray-900 fs-6'>{labelKey.password}</label>
                    <input
                        type={showPassword ? "password" : "text"}
                        placeholder='Enter password'
                        autoComplete='off'
                        {...formik.getFieldProps('password')}
                        className={clsx(
                            'form-control form-control-lg form-control-solid',
                            { 'is-invalid': formik.touched.password && formik.errors.password },
                            {
                                'is-valid': formik.touched.password && !formik.errors.password,
                            }
                        )}
                    />
                    <div style={{ float: "right" }}>
                        {showPassword ?
                            <FaEye
                                className='position-absolute fs-4'
                                onClick={() => setShowPassword(!showPassword)}
                                style={{ marginLeft: "-50px", marginTop: "-26px", cursor: "pointer" }} /> :
                            <FaEyeSlash
                                className='position-absolute fs-4'
                                onClick={() => setShowPassword(!showPassword)}
                                style={{ marginLeft: "-50px", marginTop: "-26px", cursor: "pointer" }} />
                        }
                    </div>
                    {formik.touched.password && formik.errors.password && (
                        <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                                <span role='alert'>{formik.errors.password}</span>
                            </div>
                        </div>
                    )}
                </div>
                <div className='fv-row'>
                    <label className='form-label fw-bold text-gray-900 fs-6'>{labelKey.confirmPassword}</label>
                    <input
                        type={showConfPassword ? "password" : "text"}
                        placeholder='Enter confirm password'
                        autoComplete='off'
                        {...formik.getFieldProps('confirmPassword')}
                        className={clsx(
                            'form-control form-control-lg form-control-solid',
                            { 'is-invalid': formik.touched.confirmPassword && formik.errors.confirmPassword },
                            {
                                'is-valid': formik.touched.confirmPassword && !formik.errors.confirmPassword,
                            }
                        )}
                    />
                    <div style={{ float: "right" }}>
                        {showConfPassword ?
                            <FaEye
                                className='position-absolute fs-4'
                                onClick={() => setShowConfPassword(!showConfPassword)}
                                style={{ marginLeft: "-50px", marginTop: "-26px", cursor: "pointer" }} /> :
                            <FaEyeSlash
                                className='position-absolute fs-4'
                                onClick={() => setShowConfPassword(!showConfPassword)}
                                style={{ marginLeft: "-50px", marginTop: "-26px", cursor: "pointer" }} />
                        }
                    </div>
                    {formik.touched.confirmPassword && formik.errors.confirmPassword && (
                        <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                                <span role='alert'>{formik.errors.confirmPassword}</span>
                            </div>
                        </div>
                    )}
                </div>
                <Link to='/auth/login'
                    className='link-primary fs-6 fw-bold mt-2'
                    style={{ float: 'right' }}>
                    {labelKey.backToLogin}
                </Link>
                {/* end::Form group */}

                {/* begin::Form group */}
                <button
                    type='submit'
                    className='btn btn-lg btn-primary fw-bold me-4 mt-10 w-100'
                    disabled={isLoading}
                >
                    {!isLoading && <span className='indicator-label'>{labelKey.submit}</span>}
                    {isLoading && (
                        <span className='indicator-progress' style={{ display: 'block' }}>
                            {labelKey.pleaseWait}
                            {' '}
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                    )}
                </button>
                {/* end::Form group */}
            </form>
        </>
    )
}

export default SetPassword