import axios from 'axios';
import { debounce } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { FaFile } from 'react-icons/fa';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { KTSVG } from '../../../_metronic/helpers';
import CurrencyComp from '../../../components/currencyComponent/CurrencyComp';
import DateComp from '../../../components/dateComponent/DateComp';
import Pagination from '../../../components/pagination/Pagination';
import SearchBarComponent from '../../../components/searchBar/SearchBarComponent';
import TableHeading from '../../../components/tableHeading/TableHeading';
import TableSettingMenu from '../../../components/tableHeading/TableSettingMenu';
import SelectField from '../../../components/textFields/SelectField';
import TextField from '../../../components/textFields/TextField';
import { constraintConfig } from '../../../constraintConfig';
import { selectAuth } from '../../../features/authSlice';
import { useAppSelector } from '../../../hooks';
import { useExpenseStatusLookupQuery, useGetAllExpenseTypeQuery } from '../../../services/GeneralApi';
import { useGetExpensesReportByUserQuery } from '../../../services/ReportAndAnalyticsApi';
import { API_END_POINTS } from '../../../services/apiEndpoints';
import labelKey from "../../localization/label.json";
import { Tooltip } from 'react-tooltip';
import { DateRangePicker } from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import moment from 'moment';
import { FaDownload } from 'react-icons/fa6';
import { Spinner } from 'react-bootstrap';
import NoRecordFound from '../../../components/noRecord/NoRecordFound';
import { LoadingComponent } from '../../../components/loadingComponent/LoadingComponent';

const ExpenseDetail = () => {
    const { userAccountID, token, baseURL } = useAppSelector(selectAuth);
    const [page, setPage] = useState(1)
    const [pageSize, setPageSize] = useState(10);
    const [searchStr, setSearchStr] = useState(''); // debounce search
    const [immediateSearchStr, setImmediateSearchStr] = useState('');
    const [sortBy, setSortBy] = useState("createdDate");
    const [sortOrder, setSortOrder] = useState("desc");
    // const [filterByMonth, setfilterByMonth] = useState("");
    const [filterByStartDate, setFilterByStartDate] = useState("");
    const [filterByEndDate, setFilterByEndDate] = useState("");
    const [filterByStatus, setFilterByStatus] = useState("");
    const [filterByExpType, setFilterByExpType] = useState("0");
    const [sortedColumn, setSortedColumn] = useState("createdDate");


    const [localFilterByStartDate, setLocalFilterByStartDate] = useState("");
    const [localFilterByEndDate, setLocalFilterByEndDate] = useState("");
    const [localFilterByStatus, setLocalFilterByStatus] = useState("");
    const [localFilterByExpType, setLocalFilterByExpType] = useState("0");
    const [isApplyingFilters, setIsApplyingFilters] = useState(false);

    const { data: ExpenseStatusLookup } = useExpenseStatusLookupQuery('')
    const { data: getAllExpenseType } = useGetAllExpenseTypeQuery('')
    const { data, isLoading, refetch, isSuccess } = useGetExpensesReportByUserQuery({
        userAccountId: userAccountID,
        pageNumber: page,
        pageSize: pageSize,
        sortBy: sortBy,
        sortOrder: sortOrder,
        searchStr: searchStr,
        filterByExpType: filterByExpType,
        startDate: filterByStartDate,
        endDate: filterByEndDate,
        filterByStatus: filterByStatus
    })
    const handlePageSizeChange = (e: any) => {
        const newSize = parseInt(e.target.value);
        setPageSize(newSize);
        setPage(1); // Reset page number to 1
    };
    // pagination
    const handlePageClick = (e: any) => {
        setPage(e.selected + 1);
    };

    const handleSort = (property: string) => {
        setSortBy(property);
        if (sortedColumn === property) {
            setSortOrder(prevSortOrder => (prevSortOrder === "asc" ? "desc" : "asc"));
        } else {
            setSortedColumn(property);
            setSortOrder("asc");
        }
    };

    //per page record
    const handlePageRecords = (e: any) => {
        setPage(1); // Reset the page to 1
    };
    const handleSearchChange = (e: any) => {
        const { value } = e.target;
        setImmediateSearchStr(value); // Update immediate search state

        delayedSearch(value); // Update debounced search state
    };

    const delayedSearch = useCallback(
        debounce((searchValue) => {
            setSearchStr(searchValue);
        }, 500),
        []
    );

    const handleDateRangeChange = (event: any, picker: any) => {
        const startDateFormatted = moment(picker.startDate).format('MM-DD-YYYY');
        const endDateFormatted = moment(picker.endDate).format('MM-DD-YYYY');

        setLocalFilterByStartDate(startDateFormatted);
        setLocalFilterByEndDate(endDateFormatted);
    };
    const handleCancelDateSelection = (event: any, picker: any) => {
        // Reset the state to empty strings
        setFilterByStartDate("");
        setFilterByEndDate("");
        setLocalFilterByStartDate("");
        setLocalFilterByEndDate("");
    };

    const handleStatusChange = (e: any) => {
        setLocalFilterByStatus(e.target.value);
    };
    const handleFilterByExpType = (selectedOption: any) => {
        setLocalFilterByExpType(selectedOption.value);
    };

    const applyFilters = () => {
        setIsApplyingFilters(true);
        setFilterByStartDate(localFilterByStartDate);
        setFilterByEndDate(localFilterByEndDate);
        setFilterByStatus(localFilterByStatus);
        setFilterByExpType(localFilterByExpType);
    };
    const resetFilters = () => {
        setFilterByStartDate('');
        setFilterByEndDate('');
        setFilterByExpType("0");
        setFilterByStatus('');
        setLocalFilterByStartDate('');
        setLocalFilterByEndDate('');
        setLocalFilterByExpType("0");
        setLocalFilterByStatus('');
        closeMenu();
    }

    const debouncedRefetch = debounce(() => {
        refetch().finally(() => {
            setIsApplyingFilters(false);
        });
    }, 300);
    useEffect(() => {
        if (isApplyingFilters) {
            debouncedRefetch();
        }
        if (isSuccess) {
            closeMenu();
        }
    }, [isApplyingFilters, isSuccess]);

    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(prevState => !prevState);
    };
    const closeMenu = () => {
        setIsMenuOpen(false);
    };

    useEffect(() => {
        if (
            searchStr === "" ||
            sortOrder === "" ||
            sortBy === "") {
            refetch();
        }
    }, [searchStr, sortOrder, sortBy]);

    const [hiddenColumns, setHiddenColumns] = useState<string[]>(['reportId', 'expenseType', 'title', 'amount', 'createdDate', 'expenseStatus', 'manager', 'comment', 'actions']);

    const handleToggleColumn = (columnId: string) => {
        if (hiddenColumns.includes(columnId)) {
            setHiddenColumns(hiddenColumns.filter((id) => id !== columnId));
        } else {
            setHiddenColumns([...hiddenColumns, columnId]);
        }
    };

    const [isLoadingPdf, setIsLoadingPdf] = useState<Record<string, boolean>>({});
    const pdfRefetchAndDownload = async (data: any) => {
        setIsLoadingPdf((prevLoading) => ({ ...prevLoading, [data.expenseId]: true }));

        try {
            const headers = {
                Authorization: `Bearer ${token}`,
            };
            const getReportIronePDFResponse = await axios.get(
                `${baseURL}/api/ReportAndAnalytics${API_END_POINTS.getReport}?expenseId=${data.expenseId}`,
                {
                    headers,
                }
            );
            const getReportIronePDF = getReportIronePDFResponse.data;
            downloadPdf(getReportIronePDF.result, data); // Download PDF directly
            toast.success('Report downloaded successfully!');
        } catch (error) {
            toast.error('Report Not downloaded');
            console.error('Error fetching data:', error);
        } finally {
            setIsLoadingPdf((prevLoading) => ({ ...prevLoading, [data.expenseId]: false }));
        }
    };

    const handleDownloadPdf = (data: any) => {
        // Initiate PDF refetch
        pdfRefetchAndDownload(data);
    };

    // Function to convert base64 to Blob
    const base64toBlob = (base64Data: string, contentType: string) => {
        const byteCharacters = atob(base64Data);
        const byteArrays = [];
        for (let offset = 0; offset < byteCharacters.length; offset += 512) {
            const slice = byteCharacters.slice(offset, offset + 512);
            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }
        return new Blob(byteArrays, { type: contentType });
    };

    const downloadPdf = (base64Data: string, data: any) => {
        const blob = base64toBlob(base64Data, 'application/pdf');
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${data?.title}.pdf`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url); // Release the blob URL
    };



    return (
        <>
            <div className='card'>
                <div className='card-header border-0  align-items-center'>
                    <SearchBarComponent
                        className='me-4'
                        placeholder='Expense'
                        value={immediateSearchStr}
                        onChange={handleSearchChange}
                    />
                    <div className='position-relative'>
                        <button
                            type='button'
                            className='btn btn-sm btn-light-primary me-3 d-flex justify-content-end'
                            data-kt-menu-placement='bottom-end'
                            onClick={toggleMenu}
                        >
                            <KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-2' />
                            {labelKey.filter}
                        </button>
                        <div className={`menu menu-sub menu-sub-dropdown w-300px w-md-325px' ${isMenuOpen ? 'show position-absolute mt-3' : ''}`} style={{ right: "5px" }}>
                            <div className='px-7 py-5'>
                                <div className='fs-5 text-dark fw-bold'>{labelKey.filterOptions}</div>
                            </div>
                            <div className='separator border-gray-200'></div>
                            <div className='px-7 py-5' data-kt-user-table-filter='form'>
                                <div className='mb-5'>
                                    <label className='form-label fw-bold text-dark fs-6'>Date</label>
                                    <DateRangePicker onApply={handleDateRangeChange} onCancel={handleCancelDateSelection}>
                                        <input type="text"
                                            className="form-control form-control-lg form-control-solid default-input"
                                            value={filterByStartDate && filterByEndDate ? `${filterByStartDate} - ${filterByEndDate}` : "mm/dd/yyyy"} />
                                    </DateRangePicker>
                                </div>
                                <div>
                                    <label className='form-label fs-5 fw-bold'>{labelKey.expenseType}:</label>
                                    <Select
                                        options={[
                                            { value: '0', label: 'All' }, // Add the empty option
                                            ...(getAllExpenseType?.result || []).map((option: any) => ({
                                                value: option.id,
                                                label: option?.value,
                                            })),
                                        ]}
                                        placeholder={
                                            localFilterByExpType
                                                ? getAllExpenseType?.result.find((option: any) => option.id === localFilterByExpType)?.value
                                                : 'Select Type'
                                        }
                                        value={localFilterByExpType}
                                        onChange={handleFilterByExpType}
                                    />
                                </div>
                                <div>
                                    <SelectField
                                        label={`${labelKey.statusFilter}:`}
                                        labelClass='mt-5'
                                        value={localFilterByStatus}
                                        onChange={handleStatusChange}>
                                        <option value="">{labelKey.selectStatus}</option>
                                        {ExpenseStatusLookup?.result?.map((option: any, index: any) => (
                                            <option key={option.id} value={option.value}
                                            >{option?.value}</option>
                                        ))}
                                    </SelectField>
                                </div>
                            </div>
                            <div className='separator border-gray-200'></div>
                            <div className='px-7 py-5'>
                                <button className='btn btn-primary pull-right' onClick={applyFilters}>
                                    {isApplyingFilters ? <>
                                        <span>loading...</span>
                                        <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                    </> : "Apply Filters"}
                                </button>
                                <button className='btn btn-danger pull-right me-3' onClick={resetFilters}>Reset Filters</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='card-body py-5'>
                    <div className="row">
                        <div className="col-md-12">
                            <div className='table-responsive table-height'>
                                <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                                    <thead>
                                        <tr className='fw-bold text-muted'>
                                            {hiddenColumns.includes('reportId') && (
                                                <TableHeading
                                                    label={labelKey.reportId}
                                                    columnId='reportId'
                                                    className="ps-4"
                                                />
                                            )}
                                            {hiddenColumns.includes('expenseType') && (
                                                <TableHeading
                                                    label={labelKey.type}
                                                    sortedColumn={sortedColumn}
                                                    sortOrder={sortOrder}
                                                    handleSort={handleSort}
                                                    columnId='expenseType'
                                                />
                                            )}
                                            {hiddenColumns.includes('title') && (
                                                <TableHeading
                                                    label={labelKey.title}
                                                    sortedColumn={sortedColumn}
                                                    sortOrder={sortOrder}
                                                    handleSort={handleSort}
                                                    columnId='title'
                                                />
                                            )}
                                            {hiddenColumns.includes('expenseDetail') && (
                                                <TableHeading
                                                    label={labelKey.expenseDetail}
                                                    sortedColumn={sortedColumn}
                                                    sortOrder={sortOrder}
                                                    handleSort={handleSort}
                                                    columnId='expenseDetail'
                                                />
                                            )}
                                            {hiddenColumns.includes('amount') && (
                                                <TableHeading
                                                    label={labelKey.totalAmount}
                                                    sortedColumn={sortedColumn}
                                                    sortOrder={sortOrder}
                                                    handleSort={handleSort}
                                                    columnId='amount'
                                                />
                                            )}
                                            {hiddenColumns.includes('createdDate') && (
                                                <TableHeading
                                                    label={labelKey.date}
                                                    sortedColumn={sortedColumn}
                                                    sortOrder={sortOrder}
                                                    handleSort={handleSort}
                                                    columnId='createdDate'
                                                />
                                            )}
                                            {hiddenColumns.includes('expenseStatus') && (
                                                <TableHeading
                                                    label={labelKey.status}
                                                    sortedColumn={sortedColumn}
                                                    sortOrder={sortOrder}
                                                    handleSort={handleSort}
                                                    columnId='expenseStatus'
                                                />
                                            )}
                                            {hiddenColumns.includes('manager') && (
                                                <TableHeading
                                                    label={labelKey.assignTo}
                                                    sortedColumn={sortedColumn}
                                                    sortOrder={sortOrder}
                                                    handleSort={handleSort}
                                                    columnId='manager'
                                                />
                                            )}
                                            {hiddenColumns.includes('comment') && (
                                                <TableHeading
                                                    label={labelKey.comment}
                                                    columnId='comment'
                                                />
                                            )}
                                            <th className='text-end rounded-end pe-2 border-0'>
                                                {hiddenColumns.includes('actions') && (
                                                    <>
                                                        {labelKey.actions}
                                                    </>
                                                )}
                                                <TableSettingMenu>
                                                    <TextField
                                                        rightLabel={labelKey.reportId}
                                                        type="checkbox"
                                                        onChange={() => handleToggleColumn("reportId")}
                                                        checked={hiddenColumns.includes('reportId')}
                                                        fieldClass='mb-4'
                                                    />

                                                    <TextField
                                                        rightLabel={labelKey.type}
                                                        type="checkbox"
                                                        onChange={() => handleToggleColumn("expenseType")}
                                                        checked={hiddenColumns.includes('expenseType')}
                                                        fieldClass='mb-4'
                                                    />
                                                    <TextField
                                                        rightLabel={labelKey.title}
                                                        type="checkbox"
                                                        onChange={() => handleToggleColumn("title")}
                                                        checked={hiddenColumns.includes('title')}
                                                        fieldClass='mb-4'
                                                    />
                                                    <TextField
                                                        rightLabel={labelKey.totalAmount}
                                                        type="checkbox"
                                                        onChange={() => handleToggleColumn("amount")}
                                                        checked={hiddenColumns.includes('amount')}
                                                        fieldClass='mb-4'
                                                    />
                                                    <TextField
                                                        rightLabel={labelKey.date}
                                                        type="checkbox"
                                                        onChange={() => handleToggleColumn("createdDate")}
                                                        checked={hiddenColumns.includes('createdDate')}
                                                        fieldClass='mb-4'
                                                    />
                                                    <TextField
                                                        rightLabel={labelKey.status}
                                                        type="checkbox"
                                                        onChange={() => handleToggleColumn("expenseStatus")}
                                                        checked={hiddenColumns.includes('expenseStatus')}
                                                        fieldClass='mb-4'
                                                    />
                                                    <TextField
                                                        rightLabel={labelKey.assignTo}
                                                        type="checkbox"
                                                        onChange={() => handleToggleColumn("manager")}
                                                        checked={hiddenColumns.includes('manager')}
                                                        fieldClass='mb-4'
                                                    />

                                                    <TextField
                                                        rightLabel={labelKey.comment}
                                                        type="checkbox"
                                                        onChange={() => handleToggleColumn("comment")}
                                                        checked={hiddenColumns.includes('comment')}
                                                        fieldClass='mb-4'
                                                    />
                                                    <TextField
                                                        rightLabel={labelKey.actions}
                                                        type="checkbox"
                                                        onChange={() => handleToggleColumn("actions")}
                                                        checked={hiddenColumns.includes('actions')}
                                                        fieldClass='mb-4'
                                                    />
                                                </TableSettingMenu>
                                            </th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {!isLoading &&
                                            <>
                                                {data?.result?.data?.length > 0 ? (
                                                    <>
                                                        {data?.result?.data?.map((data: any, index: any) => (
                                                            <tr key={index}>
                                                                {/* <td className='ps-5'>{(page - 1) * pageSize + index + 1}</td> */}
                                                                {hiddenColumns.includes('reportId') && (
                                                                    <td className='ps-5'>{data?.trackingId}</td>
                                                                )}
                                                                {hiddenColumns.includes('expenseType') && (
                                                                    <td>{data?.expenseType?.title}</td>
                                                                )}
                                                                {hiddenColumns.includes('title') && (
                                                                    <td>{data?.title}</td>
                                                                )}
                                                                {hiddenColumns.includes('expenseDetail') && (
                                                                    <td>{data?.expenseDetail?.length > 60 ? `${data?.expenseDetail.substring(0, 40)}...` : data?.expenseDetail}</td>
                                                                )}
                                                                {hiddenColumns.includes('amount') && (
                                                                    <td><CurrencyComp amount={data?.amount} /></td>
                                                                )}
                                                                {hiddenColumns.includes('createdDate') && (
                                                                    <td><DateComp formattedDate={data.reportDate} /></td>
                                                                )}
                                                                {hiddenColumns.includes('expenseStatus') && (
                                                                    <td>
                                                                        {data?.expenseStatus.id === constraintConfig.expenseStatus.drafted ?
                                                                            <span className="badge badge-light-primary fs-7 fw-semibold">{labelKey.draft}</span> :
                                                                            data?.expenseStatus.id === constraintConfig.expenseStatus.pendingForApproval ?
                                                                                <span className="badge badge-light-info fs-7 fw-semibold">{labelKey.pendingForApproval}</span> :
                                                                                // data?.expenseStatus.id === constraintConfig.expenseStatus.clarification ?
                                                                                //     <span className="badge badge-light-warning fs-7 fw-semibold">{labelKey.clarification}</span> :
                                                                                data?.expenseStatus.id === constraintConfig.expenseStatus.approved ?
                                                                                    <span className="badge badge-light-success fs-7 fw-semibold">{labelKey.approved}</span> :
                                                                                    data?.expenseStatus.id === constraintConfig.expenseStatus.rejected ?
                                                                                        <span className="badge badge-light-danger fs-7 fw-semibold">{labelKey.rejected}</span> :
                                                                                        data?.expenseStatus.id === constraintConfig.expenseStatus.apApproved ?
                                                                                            <span className="badge badge-light-success fs-7 fw-semibold">{labelKey.aPApproved}</span> :
                                                                                            data?.expenseStatus.id === constraintConfig.expenseStatus.processing ?
                                                                                                <span className="badge badge-light-primary fs-7 fw-semibold">{labelKey.processing}</span> :
                                                                                                ""
                                                                        }
                                                                    </td>
                                                                )}
                                                                {hiddenColumns.includes('manager') && (
                                                                    <td>
                                                                        {data?.expenseStatus?.title === "Pending for Approval" ? (
                                                                            <div className='d-flex align-items-center'>
                                                                                <div className='symbol symbol-40px symbol-circle me-3'>
                                                                                    {data?.manager?.imageUrl ? <img src={data?.manager?.imageUrl} alt="" /> :
                                                                                        <div className="symbol-label fs-3 bg-light-info text-info text-capitalize">{data?.manager?.firstName?.charAt(0)}</div>
                                                                                    }
                                                                                </div>
                                                                                <div className='d-flex justify-content-start flex-column'>
                                                                                    <p className='text-capitalize mb-0'>
                                                                                        {data?.manager?.firstName}  {data?.manager?.middleName} {data?.manager?.lastName}
                                                                                    </p>
                                                                                    <span className='text-muted fw-semibold text-muted d-block fs-7 text-lowercase'>
                                                                                        {data?.manager?.email}
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        ) : "Not Assigned"}
                                                                    </td>
                                                                )}
                                                                {hiddenColumns.includes('comment') && (
                                                                    <td>{data?.comment && data?.comment.length > 20 ? `${data?.comment.substring(0, 20)}...` : data?.comment}</td>
                                                                )}
                                                                {hiddenColumns.includes('actions') && (
                                                                    <td className='text-end' >
                                                                        <div className='d-flex align-items-center justify-content-end gap-2' >
                                                                            {/* <OverlayTrigger
                                                                                delay={{ show: 250, hide: 350 }}
                                                                                overlay={
                                                                                    <Tooltip>
                                                                                        {labelKey.report}
                                                                                    </Tooltip>
                                                                                }>
                                                                                <Link to={`/report/${data?.expenseId}`}>
                                                                                    <div className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'>
                                                                                        <FaFile />
                                                                                    </div>
                                                                                </Link>
                                                                            </OverlayTrigger> */}
                                                                            <Tooltip id="report-download" />
                                                                            <div
                                                                                data-tooltip-id="report-download" data-tooltip-content='Report Download'
                                                                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                                                                onClick={() => {
                                                                                    handleDownloadPdf(data);
                                                                                }}  >
                                                                                {!isLoadingPdf[data.expenseId] ?
                                                                                    <FaDownload className='text-dark text-hover-primary' />
                                                                                    :
                                                                                    <div className="spinner-border spinner-border-sm" role="status">
                                                                                        <span className="sr-only">{labelKey.loading}...</span>
                                                                                    </div>
                                                                                }

                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                )}
                                                            </tr>
                                                        ))}
                                                    </>
                                                ) :
                                                    (
                                                        <tr>
                                                            <td colSpan={10}>
                                                                <NoRecordFound />
                                                            </td>
                                                        </tr>
                                                    )
                                                }
                                            </>
                                        }
                                        {isLoading && (
                                            <tr>
                                                <td colSpan={10} className="text-center">
                                                    <LoadingComponent />
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <Pagination
                            totalResult={data?.result?.totalRecords === 0 ? 0 : ((data?.result?.pageNumber - 1) * data?.result?.pageSize) + 1}
                            toResult={data?.result?.totalRecords === 0 ? 0 : Math.min(data?.result?.pageNumber * data?.result?.pageSize, data?.result?.totalRecords)}
                            ofResult={data?.result?.totalRecords}
                            onChange={handlePageSizeChange}
                            pageSize={pageSize}
                            onPageChange={handlePageClick}
                            pageCount={data?.result?.totalPages || 0}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default ExpenseDetail