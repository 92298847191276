import { useEffect, useState } from 'react';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import { KTSVG } from '../../../_metronic/helpers';
import { useGetOrganizationAllRolesQuery } from '../../../services/OrganizationApi';
import labelKey from "../../localization/label.json";
import AddRoleModal from './AddRoleModal';
import UpdateRoleModal from './UpdateRoleModal';
import { LoadingComponent } from '../../../components/loadingComponent/LoadingComponent';

const RolesPage = () => {

    const [showAddRoleModal, setShowAddRoleModal] = useState(false)
    const { data: getAllRolesData, isLoading: getAllRolesIsLoading, refetch } = useGetOrganizationAllRolesQuery('');

    const [showRoleEditModal, setShowRoleEditModal] = useState(false)
    const [showRoleEdit, setShowRoleEdit] = useState({})
    const eidtRoleModalHandler = () => {
        setShowRoleEditModal((prev) => !prev);
    };

    useEffect(() => {
        refetch()
        if (showRoleEditModal && showAddRoleModal == false) {
            refetch()
        }
    }, [refetch, showRoleEditModal, showAddRoleModal])

    return (
        <>
            <div className='card mb-5'>
                <div className='card-header border-0 pt-6'>
                    <div className='card-toolbar w-100'>
                        <div className='d-flex justify-content-end w-100' data-kt-user-table-toolbar='base'>
                            <AddRoleModal show={showAddRoleModal} handleClose={() => setShowAddRoleModal(false)} />
                            <button type='button' className='btn btn-primary ms-auto' disabled onClick={() => setShowAddRoleModal(true)}>
                                <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
                                Add Role
                            </button>
                        </div>
                    </div>
                </div>
                <div className='card-body py-8'>
                    <div className="row w-100">
                        <div className="col-md-12">
                            <div className='table-responsive'>
                                <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                                    {/* begin::Table head */}
                                    <thead>
                                        <tr className='fw-bold text-muted'>
                                            <th className='min-w-150px ps-3 rounded-start'>{labelKey.sr}</th>
                                            <th className='min-w-150px'>{labelKey.roles}</th>
                                            <th className='min-w-150px text-end rounded-end pe-4'>{labelKey.action}</th>
                                        </tr>
                                    </thead>
                                    {/* end::Table head */}
                                    {/* begin::Table body */}
                                    <tbody>
                                        {getAllRolesData?.result?.map((data: any, index: any) => (
                                            <tr key={index}>
                                                <td className='ps-3'>{index + 1}</td>
                                                <td className='text-capitalize'>{data?.value}</td>
                                                <td>
                                                    {showRoleEditModal && (
                                                        <UpdateRoleModal show={showRoleEditModal}
                                                            handleClose={() => setShowRoleEditModal(false)} data={showRoleEdit} />
                                                    )}
                                                    <div className='d-flex align-items-center justify-content-end gap-2' >
                                                        <Tooltip id="update-role" place="bottom" />
                                                        <div
                                                            data-tooltip-id="update-role" data-tooltip-content={labelKey.updateRole}
                                                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                                            onClick={() => {
                                                                setShowRoleEdit(data);
                                                                eidtRoleModalHandler();
                                                            }}
                                                        >
                                                            <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                        {getAllRolesIsLoading && (
                                            <tr>
                                                <td colSpan={7} className="text-center">
                                                    <LoadingComponent />
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                    {/* end::Table body */}
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default RolesPage