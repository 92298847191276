import React from 'react'
import { PageTitle } from '../../../../_metronic/layout/core'
import Card from './Card'
import labelKey from "../../../localization/label.json"

const CardWrapper = () => {
    return (
        <>
            <PageTitle breadcrumbs={[]}>{labelKey.cards}</PageTitle>
            <Card />
        </>
    )
}

export default CardWrapper