/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useFormik } from 'formik'
import { useEffect, useRef, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { createPortal } from 'react-dom'
import { toast } from 'react-toastify'
import { StepperComponent } from '../../../../_metronic/assets/ts/components'
import { KTSVG } from '../../../../_metronic/helpers'
import { useUpdateOrganizationMutation } from '../../../../services/OrganizationApi'
import labelKey from "../../../localization/label.json"
import tosterKey from "../../../localization/toster.json"
import { Step1 } from './steps/Step1'
import { Step2 } from './steps/Step2'
import { Step3 } from './steps/Step3'
import { Step5 } from './steps/Step5'
import { constraintConfig } from '../../../../constraintConfig'
import { useAppSelector } from '../../../../hooks'
import { selectAuth } from '../../../../features/authSlice'
import Step4 from './steps/Step4'
type Props = {
  show: boolean
  handleClose: () => void
  data: any
}


const modalsRoot = document.getElementById('root-modals') || document.body

const UpdateOrgModal = ({ show, handleClose, data }: Props) => {
  const { roleID } = useAppSelector(selectAuth);
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }


  const [updateOrganization, { isLoading, isSuccess, isError, error }] = useUpdateOrganizationMutation()

  const initialValues = {
    legalName: "",
    legalAddrLine1: "",
    legalAddrLine2: "",
    legalCity: "",
    legalState: "",
    legalZip: "",
    contactPersonName: "",
    contactPersonEmail: "",
    contactPersonMobile: "",
    domainName: '',
    webURL: '',
    // expAllowedOldDays: 0,
    receiptReqMinAmnt: 0,
    expPolicyMaxLimit: 0,
    allowedOldDaysInvoices: 0,
    onboardingNotes: "",
    internalNotes: "",
    organizationID: "",
    apiurl: '',
    microServiceURL: '',
    active: true,
  };
  const [id, setId] = useState(0);
  const [formValues, setFormValues] = useState(initialValues);
  // Store form values when the modal is closed
  const handleModalClose = () => {
    setFormValues(formik.values);
    handleClose();
  };
  useEffect(() => {
    // Restore form values when the modal reopens
    if (show && !data && Object.keys(formValues).length !== 0) {
      formik.setValues(formValues);
    }
    // Update form values based on data received
    if (data) {
      setId(data?.organizationID);
      formik.setValues({ ...data })
    } else if (!show) {
      // Reset form values only when closing and it's not an update action
      setId(0);
      formik.resetForm();
    }
  }, [show, data]);

  const formik = useFormik({
    initialValues,
    onSubmit: (values, { resetForm }) => {
      updateOrganization({ ...values })
    },
  })



  useEffect(() => {
    if (isSuccess) {
      toast.success(tosterKey.organizationUpdateSuccessfully);
      handleClose()
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError && error) {
      let errorMessage: string = 'Something went wrong';

      if ('data' in error && error.data && typeof error.data === 'object' && 'message' in error.data) {
        errorMessage = (error.data as { message: string }).message;
      }
      toast.error(errorMessage);
    }
  }, [isError, error]);

  const prevStep = () => {
    if (!stepper.current) {
      return
    }

    stepper.current.goPrev()
  }

  const nextStep = () => {
    if (!stepper.current) {
      return;
    }

    if (stepper.current.currentStepIndex === 1) {
      // Check if Step 1 fields are valid
      if (!formik.values?.legalName || formik.values.legalName.trim() === "") {
        toast.error(tosterKey.organizationNameIsRequired);
        return;
      }
      else if (formik.values?.legalName.length < 3) {
        toast.error(tosterKey.organizationAtLeast3Letters);
        return;
      }
    }
    else if (stepper.current.currentStepIndex === 2) {
      if (!formik.values?.contactPersonEmail) {
        toast.error(tosterKey.emailIsRequired);
        return;
      }
      const emailRegex = /^\S+@\S+\.\S+$/;
      if (!emailRegex.test(formik.values?.contactPersonEmail)) {
        toast.error(tosterKey.invalidEmailFormat);
        return;
      }
    }
    else if (roleID === constraintConfig.roleID.role2) {
      if (stepper.current.currentStepIndex === 3) {
        if (!formik.values?.receiptReqMinAmnt) {
          toast.error(tosterKey.receiptReqMinAmntRequired);
          return;
        }
        else if (!formik.values?.allowedOldDaysInvoices) {
          toast.error(tosterKey.allowedOldDaysInvoicesRequired);
          return;
        }
        else if (!formik.values?.expPolicyMaxLimit) {
          toast.error(tosterKey.expPolicyMaxLimitRequired);
          return;
        }
      }
    }
    else if (roleID === constraintConfig.roleID.role1) {
      if (stepper.current.currentStepIndex === 4) {
        if (!formik.values?.receiptReqMinAmnt) {
          toast.error(tosterKey.receiptReqMinAmntRequired);
          return;
        }
        else if (!formik.values?.allowedOldDaysInvoices) {
          toast.error(tosterKey.allowedOldDaysInvoicesRequired);
          return;
        }
        else if (!formik.values?.expPolicyMaxLimit) {
          toast.error(tosterKey.expPolicyMaxLimitRequired);
          return;
        }
      }
    }
    stepper.current.goNext();
  };

  return createPortal(
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-xl modal-dialog-centered'
      show={show}
      onHide={handleClose}
      onEntered={loadStepper}
      backdrop="static"
    >
      <div className='modal-header'>
        <h2>{labelKey.updateOrganization}</h2>
        {/* begin::Close */}
        <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
          <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
        </div>
        {/* end::Close */}
      </div>

      <div className='modal-body py-lg-10 px-lg-10'>
        {/*begin::Stepper */}
        <div
          ref={stepperRef}
          className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'
          id='kt_modal_create_app_stepper'
        >
          {/* begin::Aside*/}
          <div className='d-flex justify-content-center justify-content-xl-start flex-row-auto w-100 w-xl-300px'>
            {/* begin::Nav*/}
            <div className='stepper-nav ps-lg-10'>
              {/* begin::Step 1*/}
              <div className='stepper-item current' data-kt-stepper-element='nav'>
                {/* begin::Wrapper*/}
                <div className='stepper-wrapper'>
                  {/* begin::Icon*/}
                  <div className='stepper-icon w-40px h-40px'>
                    <i className='stepper-check fas fa-check'></i>
                    <span className='stepper-number'>1</span>
                  </div>
                  {/* end::Icon*/}

                  {/* begin::Label*/}
                  <div className='stepper-label'>
                    <h3 className='stepper-title'>{labelKey.organizationDetails}</h3>
                    <div className='stepper-desc'>{labelKey.defineYourOrganizationDetail}</div>
                  </div>
                  {/* end::Label*/}
                </div>
                {/* end::Wrapper*/}

                {/* begin::Line*/}
                <div className='stepper-line h-40px'></div>
                {/* end::Line*/}
              </div>
              {/* end::Step 1*/}

              {/* begin::Step 2*/}
              <div className='stepper-item' data-kt-stepper-element='nav'>
                {/* begin::Wrapper*/}
                <div className='stepper-wrapper'>
                  {/* begin::Icon*/}
                  <div className='stepper-icon w-40px h-40px'>
                    <i className='stepper-check fas fa-check'></i>
                    <span className='stepper-number'>2</span>
                  </div>
                  {/* begin::Icon*/}

                  {/* begin::Label*/}
                  <div className='stepper-label'>
                    <h3 className='stepper-title'>{labelKey.contactDetail}</h3>
                    <div className='stepper-desc'>{labelKey.defineYourContactDetail}</div>
                  </div>
                  {/* begin::Label*/}
                </div>
                {/* end::Wrapper*/}

                {/* begin::Line*/}
                <div className='stepper-line h-40px'></div>
                {/* end::Line*/}
              </div>
              {/* end::Step 2*/}

              {/* begin::Step 3*/}
              {(roleID === constraintConfig.roleID.role1) && (
                <div className='stepper-item' data-kt-stepper-element='nav'>
                  {/* begin::Wrapper*/}
                  <div className='stepper-wrapper'>
                    {/* begin::Icon*/}
                    <div className='stepper-icon w-40px h-40px'>
                      <i className='stepper-check fas fa-check'></i>
                      <span className='stepper-number'>3</span>
                    </div>
                    {/* end::Icon*/}

                    {/* begin::Label*/}
                    <div className='stepper-label'>
                      <h3 className='stepper-title'>{labelKey.orgNotes}</h3>

                      <div className='stepper-desc'>{labelKey.defineYourOrgNotes}</div>
                    </div>
                    {/* end::Label*/}
                  </div>
                  {/* end::Wrapper*/}

                  {/* begin::Line*/}
                  <div className='stepper-line h-40px'></div>
                  {/* end::Line*/}
                </div>
              )}
              {/* end::Step 3*/}

              {/* begin::Step 4*/}
              <div className='stepper-item' data-kt-stepper-element='nav'>
                {/* begin::Wrapper*/}
                <div className='stepper-wrapper'>
                  {/* begin::Icon*/}
                  <div className='stepper-icon w-40px h-40px'>
                    <i className='stepper-check fas fa-check'></i>
                    <span className='stepper-number'>{(roleID === constraintConfig.roleID.role1) ? "4" : "3"}</span>
                  </div>
                  {/* end::Icon*/}

                  {/* begin::Label*/}
                  <div className='stepper-label'>
                    <h3 className='stepper-title'>{labelKey.expensePolicy}</h3>
                    <div className='stepper-desc'>{labelKey.defineYourExpensePolicy}</div>
                  </div>
                  {/* end::Label*/}
                </div>
                {/* end::Wrapper*/}

                {/* begin::Line*/}
                <div className='stepper-line h-40px'></div>
                {/* end::Line*/}
              </div>


              {/* begin::Step 5*/}
              <div className='stepper-item' data-kt-stepper-element='nav'>
                {/* begin::Wrapper*/}
                <div className='stepper-wrapper'>
                  {/* begin::Icon*/}
                  <div className='stepper-icon w-40px h-40px'>
                    <i className='stepper-check fas fa-check'></i>
                    <span className='stepper-number'>{(roleID === constraintConfig.roleID.role1) ? "5" : "4"}</span>
                  </div>
                  {/* end::Icon*/}

                  {/* begin::Label*/}
                  <div className='stepper-label'>
                    <h3 className='stepper-title'>{labelKey.completed}</h3>

                    <div className='stepper-desc'>{labelKey.reviewAndSubmit}</div>
                  </div>
                  {/* end::Label*/}
                </div>
                {/* end::Wrapper*/}
              </div>
              {/* end::Step 5*/}
            </div>
            {/* end::Nav*/}
          </div>
          {/* begin::Aside*/}

          {/*begin::Content */}
          <div className='flex-row-fluid py-lg-5 px-lg-15'>
            {/*begin::Form */}
            <form noValidate id='kt_modal_create_app_form' onSubmit={formik.handleSubmit}>
              <Step1 formik={formik} />
              <Step2 formik={formik} />
              {(roleID === constraintConfig.roleID.role1) && (
                <Step3 formik={formik} />
              )}
              <Step4 formik={formik} />
              <Step5 formik={formik} />

              {/*begin::Actions */}
              <div className='d-flex flex-stack pt-10'>
                <div className='me-2'>
                  <button
                    type='button'
                    className='btn btn-lg btn-light-primary me-3'
                    data-kt-stepper-action='previous'
                    onClick={prevStep}
                  >
                    <KTSVG
                      path='/media/icons/duotune/arrows/arr063.svg'
                      className='svg-icon-3 me-1'
                    />{' '}
                    {labelKey.previous}
                  </button>
                </div>
                <div>
                  <button
                    type='submit'
                    className='btn btn-lg btn-primary'
                    data-kt-stepper-action='submit'
                    disabled={isLoading}
                  >
                    {!isLoading && <span className='indicator-label'>{labelKey.submit} <KTSVG
                      path='/media/icons/duotune/arrows/arr064.svg'
                      className='svg-icon-3 ms-2 me-0'
                    /></span>}
                    {isLoading && (
                      <span className='indicator-progress' style={{ display: 'block' }}>
                        {labelKey.pleaseWait}...{' '}
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                  <button
                    type='button'
                    className='btn btn-lg btn-primary'
                    data-kt-stepper-action='next'
                    onClick={nextStep}
                  >
                    {labelKey.nextStep}{' '}
                    <KTSVG
                      path='/media/icons/duotune/arrows/arr064.svg'
                      className='svg-icon-3 ms-1 me-0'
                    />
                  </button>
                </div>
              </div>
              {/*end::Actions */}
            </form>
            {/*end::Form */}
          </div>
          {/*end::Content */}
        </div>
        {/* end::Stepper */}
      </div>
    </Modal>,
    modalsRoot
  )
}

export { UpdateOrgModal }

