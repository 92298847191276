import React from 'react'
import { PageTitle } from '../../../../_metronic/layout/core'
import DepartmentPage from './DepartmentPage'
import labelKey from '../../../localization/label.json'

const DepartmentWrapper = () => {
  return (
    <>
    <PageTitle breadcrumbs={[]}>{labelKey.departments}</PageTitle>
    <DepartmentPage />
</>
  )
}

export default DepartmentWrapper