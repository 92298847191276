import 'bootstrap-daterangepicker/daterangepicker.css';
import { debounce } from 'lodash';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { DateRangePicker } from 'react-bootstrap-daterangepicker';
import { FaEye, FaUpload } from 'react-icons/fa6';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import ReactReadMoreReadLess from "react-read-more-read-less";
import { Tooltip } from 'react-tooltip';
import { KTSVG } from '../../../_metronic/helpers';
import { PageTitle } from '../../../_metronic/layout/core';
import CurrencyComp from '../../../components/currencyComponent/CurrencyComp';
import DateComp from '../../../components/dateComponent/DateComp';
import Pagination from '../../../components/pagination/Pagination';
import SearchBarComponent from '../../../components/searchBar/SearchBarComponent';
import TableHeading from '../../../components/tableHeading/TableHeading';
import TableSettingMenu from '../../../components/tableHeading/TableSettingMenu';
import SelectField from '../../../components/textFields/SelectField';
import TextField from '../../../components/textFields/TextField';
import { selectAuth } from '../../../features/authSlice';
import { useAppSelector } from '../../../hooks';
import { useGetCardExpenseItemQuery } from '../../../services/ExpenseApi';
import { useExpenseCategoryLookupQuery } from '../../../services/GeneralApi';
import labelKey from "../../localization/label.json";
import AssignToExistingExpenseModal from './AssignToExistingExpenseModal';
import AssignToNewExpenseModal from './AssignToNewExpenseModal';
import CategoriesWithIcons from './CategoriesWithIcons';
import { AddExpenseItemModal } from './update-single-Item/AddExpenseItemModal';
import { UpdateExpenseItemModal } from './update-single-Item/UpdateExpenseItemModal';
import DeleteExpenseModal from './DeleteExpenseModal';
import { constraintConfig } from '../../../constraintConfig';
import { Spinner } from 'react-bootstrap';
import UploadDirectReceipt from './UploadDirectReceipt';
import NoRecordFound from '../../../components/noRecord/NoRecordFound';
import { LoadingComponent } from '../../../components/loadingComponent/LoadingComponent';

interface LightboxData {
    imageURL: string;
}

const DraftItemsPage = () => {
    const [lightboxOpen, setLightboxOpen] = useState(false);
    const [lightboxData, setLightboxData] = useState<LightboxData>({ imageURL: '' });


    const { userAccountID } = useAppSelector(selectAuth);
    const [page, setPage] = useState(1)
    const [pageSize, setPageSize] = useState(10);
    const [searchStr, setSearchStr] = useState(''); // debounce search
    const [immediateSearchStr, setImmediateSearchStr] = useState('');
    const [sortBy, setSortBy] = useState("itemDate");
    const [sortOrder, setSortOrder] = useState("desc");
    const [filterByCategory, setFilterByCategory] = useState("");
    const [filterByStartDate, setFilterByStartDate] = useState("");
    const [filterByEndDate, setFilterByEndDate] = useState("");
    const [filterBySubCategory, setFilterBySubCategory] = useState("");
    const [selectedExpenseItemIds, setSelectedExpenseItemIds] = useState<number[]>([]);
    const [sortedColumn, setSortedColumn] = useState("itemDate");
    const [isTableHeadChecked, setIsTableHeadChecked] = useState(false);

    const [localFilterByStartDate, setLocalFilterByStartDate] = useState("");
    const [localFilterByEndDate, setLocalFilterByEndDate] = useState("");
    const [localFilterByCategory, setlocalFilterByCategory] = useState("");
    const [isApplyingFilters, setIsApplyingFilters] = useState(false);

    const { data: expenseCategory } = useExpenseCategoryLookupQuery('')

    const { data, isLoading, refetch, isFetching, isSuccess } = useGetCardExpenseItemQuery({
        userAccountId: userAccountID,
        pageNumber: page,
        pageSize: pageSize,
        sortBy: sortBy,
        sortOrder: sortOrder,
        searchStr: searchStr,
        filterByCategory: filterByCategory,
        startDate: filterByStartDate,
        endDate: filterByEndDate,
        filterBySubCategory: filterBySubCategory,
    })

    // pagination
    const handlePageClick = (e: any) => {
        setPage(e.selected + 1);
    };
    const handlePageSizeChange = (e: any) => {
        const newSize = parseInt(e.target.value);
        setPageSize(newSize);
        setPage(1); // Reset page number to 1
    };

    const handleSort = (property: string) => {
        setSortBy(property);
        if (sortedColumn === property) {
            setSortOrder(prevSortOrder => (prevSortOrder === "asc" ? "desc" : "asc"));
        } else {
            setSortedColumn(property);
            setSortOrder("asc");
        }
        // Perform additional sorting logic...
    };


    //per page record
    const handlePageRecords = (e: any) => {
        setPage(1); // Reset the page to 1
    };
    const handleSearchChange = (e: any) => {
        const { value } = e.target;
        setImmediateSearchStr(value); // Update immediate search state
        delayedSearch(value); // Update debounced search state
    };

    const delayedSearch = useCallback(
        debounce((searchValue) => {
            setSearchStr(searchValue);
        }, 500),
        []
    );
    const handleCategoryChange = (e: any) => {
        setlocalFilterByCategory(e.target.value);
    };
    const handleDateRangeChange = (event: any, picker: any) => {
        const startDateFormatted = moment(picker.startDate).format('MM-DD-YYYY');
        const endDateFormatted = moment(picker.endDate).format('MM-DD-YYYY');

        setLocalFilterByStartDate(startDateFormatted);
        setLocalFilterByEndDate(endDateFormatted);
    };
    const handleCancelDateSelection = (event: any, picker: any) => {
        // Reset the state to empty strings
        setFilterByStartDate("");
        setFilterByEndDate("");
        setLocalFilterByStartDate("");
        setLocalFilterByEndDate("");
    };

    const applyFilters = () => {
        setIsApplyingFilters(true);
        setFilterByStartDate(localFilterByStartDate);
        setFilterByEndDate(localFilterByEndDate);
        setFilterByCategory(localFilterByCategory);
    };
    const resetFilters = () => {
        setFilterByStartDate('');
        setFilterByEndDate('');
        setFilterByCategory("");
        setLocalFilterByStartDate('');
        setLocalFilterByEndDate('');
        setlocalFilterByCategory('');
        closeMenu();
    }
    const debouncedRefetch = debounce(() => {
        refetch().finally(() => {
            setIsApplyingFilters(false);
        });
    }, 300);
    useEffect(() => {
        if (isApplyingFilters) {
            debouncedRefetch();
        }
        if (isSuccess) {
            closeMenu();
        }
    }, [isApplyingFilters, isSuccess]);

    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(prevState => !prevState);
    };
    const closeMenu = () => {
        setIsMenuOpen(false);
    };
    useEffect(() => {
        if (searchStr === "" ||
            sortOrder === "asc" ||
            sortBy === ""
        ) {
            refetch();
        }
    }, [sortOrder, sortBy, searchStr]);
    const [showAddExpenseItemModal, setShowAddExpenseItemModal] = useState(false)

    const [showUpdateExpenseItemModal, setShowUpdateExpenseItemModal] = useState(false)
    const [showExpenseItemData, setShowExpenseItemData] = useState({})
    const expenseItemModalHandler = () => {
        setShowUpdateExpenseItemModal((prev) => !prev);
    };


    // Function to handle the table head checkbox change
    // const handleTableHeadChange = (e: any) => {
    //     const isChecked = e.target.checked;
    //     setIsTableHeadChecked(isChecked);

    //     // If the table head checkbox is checked, set all checkboxes in the table body to be checked
    //     if (isChecked) {
    //         const allExpenseItemIds = data?.result?.data?.map((data: any) => data.expenseItemId) || [];

    //         // setSelectedExpenseItemIds(allExpenseItemIds);
    //         setSelectedExpenseItemIds((prevIds) => {
    //             // Combine the previous selected items with the current page items
    //             const updatedIds = Array.from(new Set([...prevIds, ...allExpenseItemIds]));
    //             return updatedIds;
    //         });
    //     } else {
    //         // If the table head checkbox is unchecked, clear the selection in the table body
    //         setSelectedExpenseItemIds([]);
    //     }
    //     // Update isTableHeadChecked state
    //     setIsTableHeadChecked(isChecked);
    // };
    const handleTableHeadChange = (e: any) => {
        const isChecked = e.target.checked;
        // console.log(`Table head checked: ${isChecked}`); 
        setIsTableHeadChecked(isChecked);
        const allExpenseItems = data?.result?.data || [];
        // console.log('All expense items:', allExpenseItems); 

        const filteredExpenseItems = allExpenseItems.filter((item: any) => item?.paymentMethod?.id !== 3);
        // console.log('Filtered expense items:', filteredExpenseItems);
        if (isChecked) {
            const allExpenseItemIds = filteredExpenseItems.map((item: any) => item.expenseItemId);
            // console.log('Expense item IDs to be checked:', allExpenseItemIds);

            setSelectedExpenseItemIds((prevIds) => {
                const updatedIds = Array.from(new Set([...prevIds, ...allExpenseItemIds]));
                // console.log('Updated selected expense item IDs:', updatedIds);
                return updatedIds;
            });
        } else {
            setSelectedExpenseItemIds([]);
        }

        // Update isTableHeadChecked state
        //  setIsTableHeadChecked(isChecked);

        setIsTableHeadChecked(isChecked && filteredExpenseItems.length === allExpenseItems.length);

    };



    // handleCheckbox
    const handleCheckItem = (e: any, itemId: any) => {
        const isChecked = e.target.checked;

        setSelectedExpenseItemIds((prevItems) => {
            // Create a copy of the previous selectedExpenseItemIds array
            const updatedIds = [...prevItems];

            if (isChecked) {
                // If the checkbox is checked, add the itemId to the selectedExpenseItemIds array
                updatedIds.push(itemId);
            } else {
                // If the checkbox is unchecked, remove the itemId from the selectedExpenseItemIds array
                const index = updatedIds.indexOf(itemId);
                if (index !== -1) {
                    updatedIds.splice(index, 1);
                }
            }

            // Update the table head checkbox state based on the current state of the checkboxes in the body
            // setIsTableHeadChecked(updatedIds.length === data?.result?.data?.length);
            setIsTableHeadChecked(updatedIds.length === pageSize * (page - 1) + data?.result?.data?.length);

            return updatedIds;
        });
    };

    useEffect(() => {
        setIsTableHeadChecked(false); // Reset isTableHeadChecked when page changes
    }, [page]);

    const resetCheckboxState = () => {
        setIsTableHeadChecked(false);
        setSelectedExpenseItemIds([]);
    };
    // console.log('selectedExpenseItemIds', selectedExpenseItemIds);

    // Whether any checkbox in the table body is checked
    const isAnyCheckboxChecked = selectedExpenseItemIds.length > 0 || isTableHeadChecked;

    const [showAddNewExpenseModal, setShowAddNewExpenseModal] = useState(false)
    const addNewExpenseModalHandler = () => {
        setShowAddNewExpenseModal((prev) => !prev);
    };

    const [showAddExistingExpensetModal, setShowAddExistingExpenseModal] = useState(false)
    const eidtProjectModalHandler = () => {
        setShowAddExistingExpenseModal((prev) => !prev);
    };

    const [showExpenseDeleteModal, setShowExpenseDeleteModal] = useState(false)
    const [expenseDeleteData, setExpenseDeleteData] = useState({});

    const deleteHandler = () => {
        setShowExpenseDeleteModal((prev) => !prev);
    };

    const isPdfFile = (filePath: string | undefined) => {
        if (!filePath) return false;
        const fileExtension = filePath.toLowerCase().split('.').pop();
        return fileExtension === 'pdf';
    };
    const openPdfInBrowserOrLightbox = (attachmentFile: string | undefined) => {
        if (attachmentFile) {
            if (isPdfFile(attachmentFile)) {
                // For PDF files, open in the browser
                window.open(attachmentFile, '_blank');
            } else {
                // For image files, open in the Lightbox
                setLightboxData({ imageURL: attachmentFile });
                setLightboxOpen(true);
            }
        }
    };

    const [prevModalState, setPrevModalState] = useState({
        updateExpenseItemModal: false,
        addExpenseItemModal: false,
        addNewExpenseModal: false,
        addExistingExpenseModal: false,
        showExpenseDeleteModal: false
    });

    useEffect(() => {
        setPrevModalState({
            updateExpenseItemModal: showUpdateExpenseItemModal,
            addExpenseItemModal: showAddExpenseItemModal,
            addNewExpenseModal: showAddNewExpenseModal,
            addExistingExpenseModal: showAddExistingExpensetModal,
            showExpenseDeleteModal: showExpenseDeleteModal
        });
    }, [
        showUpdateExpenseItemModal,
        showAddExpenseItemModal,
        showAddNewExpenseModal,
        showAddExistingExpensetModal,
        showExpenseDeleteModal
    ]);

    useEffect(() => {
        if (
            (prevModalState.updateExpenseItemModal && !showUpdateExpenseItemModal) ||
            (prevModalState.addExpenseItemModal && !showAddExpenseItemModal) ||
            (prevModalState.addNewExpenseModal && !showAddNewExpenseModal) ||
            (prevModalState.addExistingExpenseModal && !showAddExistingExpensetModal) ||
            (prevModalState.showExpenseDeleteModal && !showExpenseDeleteModal)

        ) {
            refetch();
            resetCheckboxState()
        }
    }, [
        showUpdateExpenseItemModal,
        showAddExpenseItemModal,
        showAddNewExpenseModal,
        showAddExistingExpensetModal,
        prevModalState.updateExpenseItemModal,
        prevModalState.addExpenseItemModal,
        prevModalState.addNewExpenseModal,
        prevModalState.addExistingExpenseModal,
        prevModalState.showExpenseDeleteModal,
        refetch,
        resetCheckboxState
    ]);

    const [hiddenColumns, setHiddenColumns] = useState<string[]>(['checkbox', 'sr', 'expenseCategory', 'currency', 'amount', 'vander', 'paymentMethod', 'itemDate', 'location', 'attachment', 'description', 'source', 'reimbursableDistance', 'actions',]);
    const handleToggleColumn = (columnId: string) => { // Specify the type of columnId as string
        if (hiddenColumns.includes(columnId)) {
            setHiddenColumns(hiddenColumns.filter((id) => id !== columnId));
        } else {
            setHiddenColumns([...hiddenColumns, columnId]);
        }
    };
    return (
        <>
            <AddExpenseItemModal
                show={showAddExpenseItemModal}
                handleClose={() => setShowAddExpenseItemModal(false)} />
            <UpdateExpenseItemModal
                show={showUpdateExpenseItemModal}
                handleClose={() => setShowUpdateExpenseItemModal(false)}
                data={showExpenseItemData} />
            <AssignToNewExpenseModal
                show={showAddNewExpenseModal}
                handleClose={() => setShowAddNewExpenseModal(false)}
                selectedExpenseItemIds={selectedExpenseItemIds}
            />
            <AssignToExistingExpenseModal
                show={showAddExistingExpensetModal}
                handleClose={() => setShowAddExistingExpenseModal(false)}
                selectedExpenseItemIds={selectedExpenseItemIds}
            />
            <DeleteExpenseModal
                show={showExpenseDeleteModal}
                handleClose={() => setShowExpenseDeleteModal(false)}
                data={expenseDeleteData} />
            <PageTitle breadcrumbs={[]}>{labelKey.expenseLineItems}</PageTitle>
            <div className={`card`}>
                {/* begin::Header */}
                <div className='card-header border-0 pt-5'>
                    <SearchBarComponent
                        placeholder='Expense Item'
                        value={immediateSearchStr}
                        onChange={handleSearchChange}
                    />
                    <div className='d-md-flex align-items-center'>
                        {/* {isAnyCheckboxChecked && ( */}
                        <div className="d-flex align-items-center gap-2 mt-2 mt-lg-0">
                            <button className={`btn btn-sm btn-light-primary me-3`}
                                onClick={() => {
                                    eidtProjectModalHandler();
                                }}
                                disabled={isAnyCheckboxChecked === false}
                            >
                                <KTSVG path='/media/icons/duotune/arrows/arr075.svg' />
                                {labelKey.addExistingExpense}
                            </button>
                            <button className={`btn btn-sm btn-light-primary me-3`}
                                onClick={() => {
                                    addNewExpenseModalHandler();
                                }}
                                disabled={isAnyCheckboxChecked === false}
                            >
                                <KTSVG path='/media/icons/duotune/arrows/arr075.svg' />
                                {labelKey.addNewExpense}
                            </button>
                        </div>
                        {/* )} */}
                        <div className='d-flex align-items-center gap-2 mt-2 mt-lg-0'>
                            <div className='position-relative'>
                                <button
                                    type='button'
                                    className='btn btn-sm btn-light-primary me-3 d-flex justify-content-end'
                                    data-kt-menu-placement='bottom-end'
                                    onClick={toggleMenu}
                                >
                                    <KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-2' />
                                    {labelKey.filter}
                                </button>
                                <div className={`menu menu-sub menu-sub-dropdown w-300px w-md-325px' ${isMenuOpen ? 'show position-absolute mt-3' : ''}`} style={{ right: "5px" }}>
                                    <div className='px-7 py-5'>
                                        <div className='fs-5 text-dark fw-bold'>{labelKey.filterOptions}</div>
                                    </div>
                                    <div className='separator border-gray-200'></div>
                                    <div className='px-7 py-5'>
                                        <SelectField
                                            label={`${labelKey.categoryFilter}:`}
                                            value={localFilterByCategory}
                                            selectClass='mb-5'
                                            onChange={handleCategoryChange}>
                                            <option value={''}>{labelKey.selectCategory}</option>
                                            {expenseCategory?.result?.map((data: any, index: any) => (
                                                <option key={data?.id} value={data?.id}>{data?.value}</option>
                                            ))}
                                        </SelectField>
                                        <label className='form-label fw-bold text-dark fs-6'>Date:</label>
                                        <DateRangePicker onApply={handleDateRangeChange} onCancel={handleCancelDateSelection}>
                                            <input type="text"
                                                className="form-control form-control-lg form-control-solid default-input"
                                                value={localFilterByStartDate && localFilterByEndDate ? `${localFilterByStartDate} - ${localFilterByEndDate}` : "mm/dd/yyyy"} />
                                        </DateRangePicker>
                                    </div>
                                    <div className='separator border-gray-200'></div>
                                    <div className='px-7 py-5'>
                                        <button className='btn btn-primary pull-right' onClick={applyFilters}>
                                            {isApplyingFilters ? <>
                                                <span>loading...</span>
                                                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                            </> : "Apply Filters"}
                                        </button>
                                        <button className='btn btn-danger pull-right me-3' onClick={resetFilters}>Reset Filters</button>
                                    </div>
                                </div>
                            </div>

                            <div className='card-toolbar'>
                                <button className='btn btn-sm btn-light-primary'
                                    onClick={() => setShowAddExpenseItemModal(true)}>
                                    <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
                                    {labelKey.addNewItem}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='card-body py-3'>
                    <div className='table-responsive table-height'>
                        <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                            <thead>
                                <tr className='fw-bold text-muted'>
                                    {hiddenColumns.includes('checkbox') && (
                                        <TableHeading
                                            columnId='checkbox'
                                            className="ps-4"
                                        >
                                            {/* {data?.result?.data?.ocrPassed && data?.result?.data?.ocrPassed !== false ? ( */}
                                            <div className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                                                <input
                                                    type="checkbox"
                                                    className='form-check-input cursor-pointer'
                                                    checked={isTableHeadChecked} onChange={handleTableHeadChange}
                                                // disabled={data?.ocrPassed === false}
                                                />
                                            </div>
                                            {/* ) : null} */}
                                        </TableHeading>
                                    )}
                                    {/* {hiddenColumns.includes('sr') && (
                                        <TableHeading
                                            label={labelKey.sr}
                                            columnId='sr'
                                        />
                                    )} */}
                                    {hiddenColumns.includes('itemDate') && (
                                        <TableHeading
                                            label={labelKey.date}
                                            sortedColumn={sortedColumn}
                                            sortOrder={sortOrder}
                                            handleSort={handleSort}
                                            columnId='itemDate'
                                        />
                                    )}
                                    {hiddenColumns.includes('expenseCategory') && (
                                        <TableHeading
                                            label={labelKey.category}
                                            sortedColumn={sortedColumn}
                                            sortOrder={sortOrder}
                                            handleSort={handleSort}
                                            columnId='expenseCategory'
                                        />
                                    )}
                                    {/* {hiddenColumns.includes('currency') && (
                                        <TableHeading
                                            label={labelKey.currency}
                                            sortedColumn={sortedColumn}
                                            sortOrder={sortOrder}
                                            handleSort={handleSort}
                                            columnId='currency'
                                        />
                                    )} */}
                                    {hiddenColumns.includes('amount') && (
                                        <TableHeading
                                            label={labelKey.amount}
                                            sortedColumn={sortedColumn}
                                            sortOrder={sortOrder}
                                            handleSort={handleSort}
                                            columnId='amount'
                                            labelClassName="justify-content-end"
                                        />
                                    )}
                                    {hiddenColumns.includes('reimbursableDistance') && (
                                        <TableHeading
                                            label={labelKey.reimbursableDistance}
                                            sortedColumn={sortedColumn}
                                            sortOrder={sortOrder}
                                            handleSort={handleSort}
                                            columnId='reimbursableDistance'
                                            labelClassName="justify-content-end"
                                        />
                                    )}
                                    {hiddenColumns.includes('vander') && (
                                        <TableHeading
                                            label={labelKey.vendor}
                                            sortedColumn={sortedColumn}
                                            sortOrder={sortOrder}
                                            handleSort={handleSort}
                                            columnId='vander'
                                        />
                                    )}
                                    {hiddenColumns.includes('paymentMethod') && (
                                        <TableHeading
                                            label={labelKey.paymentMethod}
                                            sortedColumn={sortedColumn}
                                            sortOrder={sortOrder}
                                            handleSort={handleSort}
                                            columnId='paymentMethod'
                                        />
                                    )}

                                    {/* {hiddenColumns.includes('location') && (
                                        <TableHeading
                                            label={labelKey.location}
                                            columnId='location'
                                        />
                                    )} */}
                                    {hiddenColumns.includes('attachment') && (
                                        <TableHeading
                                            label={labelKey.attachment}
                                            columnId='attachment'
                                        />
                                    )}
                                    {hiddenColumns.includes('description') && (
                                        <TableHeading
                                            label={labelKey.description}
                                            columnId='description'
                                            className='w-400px'
                                        />
                                    )}
                                    {hiddenColumns.includes('source') && (
                                        <TableHeading
                                            label={labelKey.source}
                                            columnId='source'
                                        />
                                    )}

                                    {/* <th>{labelKey.status}</th> */}
                                    <th className='text-end rounded-end pe-2 border-0'>
                                        {hiddenColumns.includes('actions') && (
                                            <>
                                                {labelKey.actions}
                                            </>
                                        )}
                                        <TableSettingMenu>
                                            <TextField
                                                rightLabel={labelKey.checkbox}
                                                type="checkbox"
                                                onChange={() => handleToggleColumn("checkbox")}
                                                checked={hiddenColumns.includes('checkbox')}
                                                fieldClass='mb-4'
                                            />
                                            {/* <TextField
                                                rightLabel={labelKey.sr}
                                                type="checkbox"
                                                onChange={() => handleToggleColumn("sr")}
                                                checked={hiddenColumns.includes('sr')}
                                                fieldClass='mb-4'
                                            /> */}
                                            <TextField
                                                rightLabel={labelKey.itemDate}
                                                type="checkbox"
                                                onChange={() => handleToggleColumn("itemDate")}
                                                checked={hiddenColumns.includes('itemDate')}
                                                fieldClass='mb-4'
                                            />
                                            <TextField
                                                rightLabel={labelKey.category}
                                                type="checkbox"
                                                onChange={() => handleToggleColumn("expenseCategory")}
                                                checked={hiddenColumns.includes('expenseCategory')}
                                                fieldClass='mb-4'
                                            />
                                            {/* <TextField
                                                rightLabel={labelKey.currency}
                                                type="checkbox"
                                                onChange={() => handleToggleColumn("currency")}
                                                checked={hiddenColumns.includes('currency')}
                                                fieldClass='mb-4'
                                            /> */}
                                            <TextField
                                                rightLabel={labelKey.amount}
                                                type="checkbox"
                                                onChange={() => handleToggleColumn("amount")}
                                                checked={hiddenColumns.includes('amount')}
                                                fieldClass='mb-4'
                                            />
                                            <TextField
                                                rightLabel={labelKey.reimbursableDistance}
                                                type="checkbox"
                                                onChange={() => handleToggleColumn("reimbursableDistance")}
                                                checked={hiddenColumns.includes('reimbursableDistance')}
                                                fieldClass='mb-4'
                                            />
                                            <TextField
                                                rightLabel={labelKey.vendor}
                                                type="checkbox"
                                                onChange={() => handleToggleColumn("vander")}
                                                checked={hiddenColumns.includes('vander')}
                                                fieldClass='mb-4'
                                            />
                                            <TextField
                                                rightLabel={labelKey.paymentMethod}
                                                type="checkbox"
                                                onChange={() => handleToggleColumn("paymentMethod")}
                                                checked={hiddenColumns.includes('paymentMethod')}
                                                fieldClass='mb-4'
                                            />

                                            {/* <TextField
                                                rightLabel={labelKey.location}
                                                type="checkbox"
                                                onChange={() => handleToggleColumn("location")}
                                                checked={hiddenColumns.includes('location')}
                                                fieldClass='mb-4'
                                            /> */}
                                            <TextField
                                                rightLabel={labelKey.attachment}
                                                type="checkbox"
                                                onChange={() => handleToggleColumn("attachment")}
                                                checked={hiddenColumns.includes('attachment')}
                                                fieldClass='mb-4'
                                            />
                                            <TextField
                                                rightLabel={labelKey.description}
                                                type="checkbox"
                                                onChange={() => handleToggleColumn("description")}
                                                checked={hiddenColumns.includes('description')}
                                                fieldClass='mb-4'
                                            />
                                            <TextField
                                                rightLabel={labelKey.source}
                                                type="checkbox"
                                                onChange={() => handleToggleColumn("source")}
                                                checked={hiddenColumns.includes('source')}
                                                fieldClass='mb-4'
                                            />
                                            <TextField
                                                rightLabel={labelKey.actions}
                                                type="checkbox"
                                                onChange={() => handleToggleColumn("actions")}
                                                checked={hiddenColumns.includes('actions')}
                                                fieldClass='mb-4'
                                            />
                                        </TableSettingMenu>
                                    </th>

                                </tr>
                            </thead>
                            <tbody>
                                {!isLoading &&
                                    <>
                                        {data?.result?.data?.length > 0 ? (
                                            <>
                                                {data?.result?.data?.map((data: any, index: any) => (
                                                    <tr key={data?.expenseItemId}>
                                                        {hiddenColumns.includes('checkbox') && (
                                                            <td className='ps-4'>
                                                                <TextField
                                                                    type="checkbox"
                                                                    fieldClass='form-check-input cursor-pointer'
                                                                    checked={isTableHeadChecked || selectedExpenseItemIds.includes(data.expenseItemId)}
                                                                    onChange={(e) => handleCheckItem(e, data.expenseItemId)}
                                                                    disabled={data?.paymentMethod?.id === 3}
                                                                // disabled={data?.ocrPassed === false}
                                                                />
                                                            </td>
                                                        )}
                                                        {/* {hiddenColumns.includes('sr') && (
                                                            <td>
                                                                {(page - 1) * pageSize + index + 1}
                                                            </td>
                                                        )} */}
                                                        {hiddenColumns.includes('itemDate') && (
                                                            <td><DateComp formattedDate={data.itemDate} /></td>
                                                        )}
                                                        {hiddenColumns.includes('expenseCategory') && (
                                                            <td className='ps-5'><CategoriesWithIcons itemData={data} /></td>
                                                        )}
                                                        {/* {hiddenColumns.includes('currency') && (
                                                            <td className='ps-10'>{data?.currency?.title}</td>
                                                        )} */}
                                                        {hiddenColumns.includes('amount') && (
                                                            <td className='text-end'>
                                                                <CurrencyComp amount={data?.amount} />
                                                            </td>
                                                        )}
                                                        {hiddenColumns.includes('reimbursableDistance') && (
                                                            <td className='text-end'>
                                                                {data?.expenseCategory?.id === constraintConfig.categoriesId.mileage ?
                                                                    <>
                                                                        {data?.reimbursableDistance}
                                                                    </>
                                                                    : null
                                                                }
                                                            </td>
                                                        )}
                                                        {hiddenColumns.includes('vander') && (
                                                            <td>{data?.vendor?.name !== " " ? data?.vendor?.name : "N/A"}</td>
                                                        )}
                                                        {hiddenColumns.includes('paymentMethod') && (
                                                            <td>{data?.paymentMethod?.title}</td>
                                                        )}

                                                        {/* {hiddenColumns.includes('location') && (
                                                            <td>{data?.expItemLocation?.title ? data?.expItemLocation?.title : "N/A"}</td>
                                                        )} */}
                                                        {hiddenColumns.includes('attachment') && (
                                                            <td>
                                                                <Tooltip id="upload-reciept" place="top" />
                                                                {data?.expenseCategory?.id === constraintConfig.categoriesId.mileage ? '' :
                                                                    data?.attachmentFile === null || data?.attachmentFile === "null" || data?.attachmentFile === "undefined" || data?.attachmentFile === "" ?
                                                                        <div className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm ms-7'
                                                                            data-tooltip-id="upload-reciept" data-tooltip-content='Upload Reciept'
                                                                            onClick={() => setShowExpenseItemData(data)}
                                                                        >
                                                                            <UploadDirectReceipt data={showExpenseItemData} refetch={refetch} />
                                                                        </div> :

                                                                        <FaEye className='ms-10' onClick={() => openPdfInBrowserOrLightbox(data?.attachmentFile)} />
                                                                }
                                                                {lightboxOpen && (
                                                                    <Lightbox
                                                                        mainSrc={lightboxData.imageURL}
                                                                        onCloseRequest={() => setLightboxOpen(false)}
                                                                        imageCaption="Attachment"
                                                                        enableZoom={true}
                                                                        imagePadding={50}
                                                                    />
                                                                )}
                                                            </td>
                                                        )}
                                                        {/* <td>
                                                            {data?.ocrPassed === true ?
                                                                <span className="badge badge-light-success fs-7 fw-semibold">Passed</span> :
                                                                <span className="badge badge-light-danger fs-7 fw-semibold">Failed</span>
                                                            }
                                                        </td> */}
                                                        {hiddenColumns.includes('description') && (
                                                            <td className='text-wrap'>
                                                                {/* {data?.itemDetail?.length > 60 ? `${data?.itemDetail.substring(0, 40)}...` : data?.itemDetail} */}
                                                                {data && data.itemDetail && <ReactReadMoreReadLess
                                                                    charLimit={30}
                                                                    readMoreText={"Read more"}
                                                                    readLessText={"Read less"}
                                                                    readMoreClassName="readMore"
                                                                    readLessClassName="readLess"
                                                                >
                                                                    {data.itemDetail}
                                                                </ReactReadMoreReadLess>}
                                                            </td>
                                                        )}
                                                        {hiddenColumns.includes('source') && (
                                                            <td>
                                                                <Tooltip id="web-source" place="top" />
                                                                <Tooltip id="mobile-source" place="top" />
                                                                <Tooltip id="bank-source" place="top" />

                                                                {data?.recordSourceId === constraintConfig.recordSourceId.WebApp ?
                                                                    <KTSVG path="/media/icons/figmaIcons/Web.svg" className='svg-icon-1 ms-4'
                                                                        data-tooltip-id="web-source" data-tooltip-content='Website' />
                                                                    :
                                                                    data?.recordSourceId === constraintConfig.recordSourceId.Mobile ?
                                                                        <KTSVG path="/media/icons/figmaIcons/Mobile.svg" className='svg-icon-1 ms-4'
                                                                            data-tooltip-id="mobile-source" data-tooltip-content='Mobile' /> :
                                                                        data?.recordSourceId === constraintConfig.recordSourceId.BankFeed ?
                                                                            <KTSVG path="/media/icons/figmaIcons/Bank.svg" className='svg-icon-1 ms-4'
                                                                                data-tooltip-id="bank-source" data-tooltip-content='Bank' />
                                                                            : null}
                                                            </td>
                                                        )}
                                                        {hiddenColumns.includes('actions') && (
                                                            <td>
                                                                <div className='d-flex align-items-center justify-content-end gap-2 pe-2' >
                                                                    <Tooltip id="Update-Item" place="top" />
                                                                    <Tooltip id="Delete-Item" place="top" />

                                                                    {constraintConfig.paymentMethod.cash === data?.paymentMethod?.id ||
                                                                        constraintConfig.paymentMethod.personalCreditCard === data?.paymentMethod?.id ?
                                                                        <div
                                                                            data-tooltip-id="Delete-Item" data-tooltip-content='Delete Expense'
                                                                            className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm'

                                                                            onClick={() => {
                                                                                setExpenseDeleteData(data?.expenseItemId);
                                                                                deleteHandler();
                                                                            }}
                                                                        >
                                                                            <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3 text-hover-danger' svgClassName='svg-danger' />
                                                                        </div>
                                                                        : null}
                                                                    <div
                                                                        data-tooltip-id="Update-Item" data-tooltip-content='Update Item'
                                                                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                                                                        onClick={() => {
                                                                            setShowExpenseItemData(data);
                                                                            expenseItemModalHandler();
                                                                        }}
                                                                    >
                                                                        <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        )}
                                                    </tr>
                                                ))}
                                            </>
                                        ) :
                                            (
                                                <tr>
                                                    <td colSpan={15}>
                                                        <NoRecordFound />
                                                    </td>
                                                </tr>
                                            )
                                        }
                                    </>
                                }
                                {isLoading && (
                                    <tr>
                                        <td colSpan={15} className="text-center">
                                            <LoadingComponent />
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table >
                    </div >
                    <Pagination
                        totalResult={data?.result?.totalRecords === 0 ? 0 : ((data?.result?.pageNumber - 1) * data?.result?.pageSize) + 1}
                        toResult={data?.result?.totalRecords === 0 ? 0 : Math.min(data?.result?.pageNumber * data?.result?.pageSize, data?.result?.totalRecords)}
                        ofResult={data?.result?.totalRecords}
                        onChange={handlePageSizeChange}
                        pageSize={pageSize}
                        onPageChange={handlePageClick}
                        pageCount={data?.result?.totalPages || 0}
                    />
                    {/* end::Table container */}
                </div >


                {/* begin::Body */}
            </div >
        </>
    )
}

export default DraftItemsPage