import { PageTitle } from '../../../_metronic/layout/core'
import ExpenseItemPage from './ExpenseItemPage'

const ExpenseItemWrapper = () => {
    return (
        <>
            <PageTitle breadcrumbs={[]}>Expense History</PageTitle>
            <ExpenseItemPage />
        </>
    )
}

export default ExpenseItemWrapper