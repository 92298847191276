/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useFormik } from 'formik'
import { useEffect, useRef, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { createPortal } from 'react-dom'
import { toast } from 'react-toastify'
import { StepperComponent } from '../../../../_metronic/assets/ts/components'
import { KTSVG } from '../../../../_metronic/helpers'
import { useUpdateWorkflowWithApproversMutation } from '../../../../services/WorkFlowApi'
import labelKey from "../../../localization/label.json"
import tosterKey from "../../../localization/toster.json"
import { Step1 } from '../create-app-stepper/steps/Step1'
import { Step2 } from '../create-app-stepper/steps/Step2'
import { Step5 } from '../create-app-stepper/steps/Step5'

type Props = {
  show: boolean
  handleClose: () => void
  data: any
}

const modalsRoot = document.getElementById('root-modals') || document.body

const UpdateWorkflowModal = ({ show, handleClose, data }: Props) => {
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }

  const [updateWorkflow, { isLoading, isError, error, isSuccess }] = useUpdateWorkflowWithApproversMutation();
  const currentDate = new Date();

  const initialValues = {
    workflowId: 0,
    workflowPolicyType: {
      id: 0,
      value: ''
    },
    workflowName: '',
    status: true,
    startdate: currentDate,
    endDate: '2099-12-31',
    userAccountIds: [
      0
    ],
    workflowApprover: [
      {
        workflowApproverId: 0,
        workflowId: 0,
        minimumAmount: 0,
        maximumAmount: 0,
        userAccount: {
          orgUserID: 0,
          firstName: '',
          middleName: '',
          lastName: '',
          email: '',
          imageUrl: ''
        },
        sequence: 1
      }
    ]
  }

  const [id, setId] = useState(0);
  const [formValues, setFormValues] = useState(initialValues);
  // Store form values when the modal is closed
  const handleModalClose = () => {
    setFormValues(formik.values);
    handleClose();
  };
  useEffect(() => {
    // Restore form values when the modal reopens
    if (show && !data && Object.keys(formValues).length !== 0) {
      formik.setValues(formValues);
    }
    // Update form values based on data received
    if (data) {
      setId(data?.workflowId);
      formik.setValues({ ...data });
    } else if (!show) {
      // Reset form values only when closing and it's not an update action
      setId(0);
      formik.resetForm();
    }
  }, [show, data]);

  const formik = useFormik({
    initialValues,
    onSubmit: (values, { resetForm }) => {
      updateWorkflow({ ...values })
    },
  })


  useEffect(() => {
    if (isSuccess) {
      toast.success(tosterKey.workflowUpdateSuccessfully);
      handleClose()
    }
  }, [isSuccess]);


  useEffect(() => {
    if (isError && error) {
      let errorMessage: string = 'Something went wrong';

      if ('data' in error && error.data && typeof error.data === 'object' && 'message' in error.data) {
        errorMessage = (error.data as { message: string }).message;
      }
      toast.error(errorMessage);
    }
  }, [isError, error]);

  const prevStep = () => {
    if (!stepper.current) {
      return
    }

    stepper.current.goPrev()
  }
  const rows = formik.values?.workflowApprover || [];
  const validateStep2Data = () => {
    let previousMaximumAmount = -1; // Set an initial value to compare the first maximumAmount
    // Loop through all items and check for validation errors
    for (let rowIndex = 0; rowIndex < rows.length; rowIndex++) {
      const maximumAmount = formik.values?.workflowApprover?.[rowIndex]?.maximumAmount;
      const userId = formik.values?.workflowApprover?.[rowIndex]?.userAccount?.orgUserID;
      if (!maximumAmount) {
        toast.error(tosterKey.maximumAmountIsrequired + ` for workflow ${rowIndex + 1}`);
        return;
      }
      else if (maximumAmount <= previousMaximumAmount) {
        toast.error(tosterKey.maximumAmountGreaterThanPrevious + ` for workflow ${rowIndex + 1}`);
        return false;
      }
      else if (!userId) {
        toast.error(tosterKey.userIsRequired + ` for workflow ${rowIndex + 1}`);
        return false;
      }
      // Update the previousMaximumAmount for the next iteration
      previousMaximumAmount = maximumAmount;
    }
    // All items passed validation
    return true;
  };
  const nextStep = () => {
    if (!stepper.current) {
      return;
    }

    if (stepper.current.currentStepIndex === 1) {
      if (!formik.values?.workflowPolicyType.id) {
        toast.error(tosterKey.approverPolicyRequired);
        return;
      }
      else if (!formik.values?.workflowName) {
        toast.error(tosterKey.workflowNameRequired);
        return;
      }
      else if (formik.values?.workflowPolicyType.id === 2) {
        // if (!formik.values?.startdate) {
        //   toast.error(tosterKey.startDateRequired);
        //   return;
        // }
        // else if (!formik.values?.endDate) {
        //   toast.error(tosterKey.endDateRequired);
        //   return;
        // }
        if (!formik.values?.userAccountIds[0]) {
          toast.error(tosterKey.userIsRequired);
          return;
        }
      }
    } else if (stepper.current.currentStepIndex === 2) {
      const step2Valid = validateStep2Data();
      if (!step2Valid) {
        return; // Validation failed, return early
      }
    }

    stepper.current.goNext();
  };


  return createPortal(
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-900px'
      show={show}
      backdrop="static"
      onHide={handleModalClose}
      onEntered={loadStepper}
    >
      <div className='modal-header'>
        <h2>{labelKey.updateWorkflow}</h2>
        {/* begin::Close */}
        <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
          <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
        </div>
        {/* end::Close */}
      </div>

      <div className='modal-body py-lg-10 px-lg-10'>
        {/*begin::Stepper */}
        <div
          ref={stepperRef}
          className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'
          id='kt_modal_create_app_stepper'
        >
          {/* begin::Aside*/}
          <div className='d-flex justify-content-center justify-content-xl-start flex-row-auto w-100 w-xl-300px'>
            {/* begin::Nav*/}
            <div className='stepper-nav ps-lg-10'>
              {/* begin::Step 1*/}
              <div className='stepper-item current' data-kt-stepper-element='nav'>
                {/* begin::Wrapper*/}
                <div className='stepper-wrapper'>
                  {/* begin::Icon*/}
                  <div className='stepper-icon w-40px h-40px'>
                    <i className='stepper-check fas fa-check'></i>
                    <span className='stepper-number'>1</span>
                  </div>
                  {/* end::Icon*/}

                  {/* begin::Label*/}
                  <div className='stepper-label'>
                    <h3 className='stepper-title'>{labelKey.workflowDetails}</h3>

                    <div className='stepper-desc'>{labelKey.defineYourWorkflowDetail}</div>
                  </div>
                  {/* end::Label*/}
                </div>
                {/* end::Wrapper*/}

                {/* begin::Line*/}
                {String(formik.values?.workflowPolicyType?.id) === '2' &&
                  <div className='stepper-line h-40px'></div>
                }
                {/* end::Line*/}
              </div>
              {/* end::Step 1*/}

              {/* begin::Step 2*/}
              {String(formik.values?.workflowPolicyType?.id) === '2' &&
                <>
                  <div className='stepper-item' data-kt-stepper-element='nav'>
                    <div className='stepper-wrapper'>
                      <div className='stepper-icon w-40px h-40px'>
                        <i className='stepper-check fas fa-check'></i>
                        <span className='stepper-number'>2</span>
                      </div>
                      <div className='stepper-label'>
                        <h3 className='stepper-title'>{labelKey.workflowApprovers}</h3>

                        <div className='stepper-desc'>{labelKey.defineApproversDetail}</div>
                      </div>
                    </div>
                    <div className='stepper-line h-40px'></div>
                  </div>
                  {/* begin::Step 5*/}
                  <div className='stepper-item' data-kt-stepper-element='nav'>
                    <div className='stepper-wrapper'>
                      <div className='stepper-icon w-40px h-40px'>
                        <i className='stepper-check fas fa-check'></i>
                        <span className='stepper-number'>{String(formik.values?.workflowPolicyType?.id) !== '1' ? '3' : '2'}</span>
                      </div>

                      <div className='stepper-label'>
                        <h3 className='stepper-title'>{labelKey.completed}</h3>

                        <div className='stepper-desc'>{labelKey.reviewAndSubmit}</div>
                      </div>
                    </div>
                  </div>
                </>}
            </div>
            {/* end::Nav*/}
          </div>
          {/* begin::Aside*/}

          {/*begin::Content */}
          <div className='flex-row-fluid px-lg-5'>
            {/*begin::Form */}
            <form noValidate id='kt_modal_create_app_form' onSubmit={formik.handleSubmit}>
              <Step1 formik={formik} updateId={id} />
              {String(formik.values?.workflowPolicyType?.id) === '2' &&
                <>
                  <Step2 formik={formik} data={id} />
                  <Step5 formik={formik} />
                </>
              }
              {/*begin::Actions */}
              {String(formik.values?.workflowPolicyType?.id) !== '2' ?
                <div className='d-flex justify-content-end flex-stack pt-10'>
                  <button
                    type='submit'
                    className='btn btn-lg btn-primary'
                    disabled={isLoading}
                  >
                    {!isLoading && <span className='indicator-label'>{labelKey.submit} <KTSVG
                      path='/media/icons/duotune/arrows/arr064.svg'
                      className='svg-icon-3 ms-2 me-0'
                    /></span>}
                    {isLoading && (
                      <span className='indicator-progress' style={{ display: 'block' }}>
                        {labelKey.pleaseWait}...{' '}
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                </div> :
                <div className='d-flex flex-stack pt-10'>
                  <div className='me-2'>
                    <button
                      type='button'
                      className='btn btn-lg btn-light-primary me-3'
                      data-kt-stepper-action='previous'
                      onClick={prevStep}
                    >
                      <KTSVG
                        path='/media/icons/duotune/arrows/arr063.svg'
                        className='svg-icon-3 me-1'
                      />{' '}
                      {labelKey.previous}
                    </button>
                  </div>
                  <div>
                    <button
                      type='submit'
                      className='btn btn-lg btn-primary'
                      data-kt-stepper-action='submit'
                      disabled={isLoading}
                    >
                      {!isLoading && <span className='indicator-label'>{labelKey.submit} <KTSVG
                        path='/media/icons/duotune/arrows/arr064.svg'
                        className='svg-icon-3 ms-2 me-0'
                      /></span>}
                      {isLoading && (
                        <span className='indicator-progress' style={{ display: 'block' }}>
                          {labelKey.pleaseWait}...{' '}
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      )}
                    </button>

                    <button
                      type='button'
                      className='btn btn-lg btn-primary'
                      data-kt-stepper-action='next'
                      onClick={nextStep}
                    >
                      {labelKey.nextStep}{' '}
                      <KTSVG
                        path='/media/icons/duotune/arrows/arr064.svg'
                        className='svg-icon-3 ms-1 me-0'
                      />
                    </button>
                  </div>
                </div>}
              {/*end::Actions */}
            </form>
            {/*end::Form */}
          </div>
          {/*end::Content */}
        </div>
        {/* end::Stepper */}
      </div>
    </Modal>,
    modalsRoot
  )
}

export { UpdateWorkflowModal }

