import React from 'react'
import { KTSVG } from '../../../_metronic/helpers'
import { Link } from 'react-router-dom'

type Props = {
    bgColor?: string
    className: string
    color?: string
    title?: string
    titleIcon: string
    stateIcon1?: string
    statImg1?: string
    statTitle1: string
    statsNum1: string
    stateIcon2?: string
    statImg2?: string
    statTitle2?: string
    statsNum2?: string
    toState1?: string
    toState2?: string
    bothLink?: string
    children?: any
}
const MixedStatsWidget: React.FC<Props> = ({
    bgColor,
    className,
    color,
    title,
    titleIcon,
    statTitle1,
    statsNum1,
    statTitle2 = '',
    statsNum2 = '',
    stateIcon1 = '',
    stateIcon2 = '',
    statImg1,
    statImg2 = '',
    toState1 = '',
    toState2 = '',
    bothLink = '',
    children
}) => {
    return (
        // <div className={`card ${className}`}>
        //     <div className='card-body p-0'>
        //         <div className={`px-9 pt-7 card-rounded h-200px w-100 bg-${color} ${bgColor}`}>
        //             <div className='d-flex flex-stack'>
        //                 <h3 className='m-0 text-white fw-bold fs-3'>{title}</h3>
        //                 <div className='ms-1'>
        //                     <img src={titleIcon} alt="" className='img-fluid' />
        //                 </div>
        //             </div>
        //         </div>
        <div
            className='shadow-xs card-rounded  mb-9 px-6 py-9 position-relative z-index-1 bg-body h-100'
        // style={{ marginTop: '-100px' }}
        >
            <div className={bothLink ? 'bg-light rounded py-2' : ''}>
                <Link to={bothLink}>
                    <Link to={toState1}>
                        <div className={`d-flex align-items-center mb-4 bg-light rounded px-3 py-2 ${bothLink ? 'py-0' : 'py-2'}`} >
                            <div className='symbol symbol-45px w-40px me-5'>
                                <span className='symbol-label bg-lighten'>
                                    <img src={statImg1} alt="" />
                                    <KTSVG path={stateIcon1} className='svg-icon-1 text-primary' />
                                </span>
                            </div>
                            <div className='d-flex align-items-center flex-wrap w-100'>
                                <div className='mb-1 pe-3 flex-grow-1'>
                                    {/* <Link to={toState1}> */}
                                    <span className='fs-5 text-gray-800 text-hover-primary fw-bold'>
                                        {statTitle1}
                                    </span>
                                    {/* </Link> */}
                                </div>
                                <div className='d-flex align-items-center'>
                                    <div className='fw-bold fs-5 text-gray-800 pe-1'>{statsNum1}</div>
                                </div>
                            </div>
                        </div>
                    </Link>
                    <Link to={toState2}>
                        <div className={`d-flex align-items-center bg-light rounded px-3 ${bothLink ? 'py-0' : 'py-2'}`}>
                            <div className='symbol symbol-45px w-40px me-5'>
                                <span className='symbol-label bg-lighten'>
                                    <img src={statImg2} alt="" />
                                    <KTSVG path={stateIcon2} className='svg-icon-1 text-primary' />
                                </span>
                            </div>
                            <div className='d-flex align-items-center flex-wrap w-100'>
                                <div className='mb-1 pe-3 flex-grow-1'>
                                    {/* <Link to={toState2}> */}
                                    <span className={`fs-5 text-gray-800 ${toState2 ? 'text-hover-primary' : ''}  fw-bold`}>
                                        {statTitle2}
                                    </span>
                                    {/* </Link> */}
                                </div>
                                <div className='d-flex align-items-center'>
                                    <div className='fw-bold fs-5 text-gray-800 pe-1'>{statsNum2}</div>
                                </div>
                            </div>
                        </div>
                    </Link>
                </Link>
            </div>
            <>
                {children}
            </>
        </div>
        //     </div>
        // </div>
    )
}

export default MixedStatsWidget