import TextField from '../../../../../components/textFields/TextField'
import labelKey from "../../../../localization/label.json"

type Props = {
    formik: any
}
const Step4 = ({ formik, }: Props) => {
    return (
        <>
            {/*begin::Step 3 */}
            <div className='pb-5 pe-3 overflow-auto' style={{ height: "51vh" }} data-kt-stepper-element='content'>
                <div className='row w-100 h-min-content'>
                    <div className='col-md-12 mb-5 w-100'>
                        <TextField
                            label={labelKey.receiptReqMinAmnt}
                            placeholder='Enter Receipt Amount'
                            fieldClass='w-100'
                            type="number"
                            {...formik.getFieldProps('receiptReqMinAmnt')}
                            min={0}
                        />
                    </div>
                    <div className='col-md-12 mb-5'>
                        <TextField
                            label={labelKey.allowedOldDaysInvoices}
                            placeholder='Enter Invoice Allowed Days'
                            type="number"
                            {...formik.getFieldProps('allowedOldDaysInvoices')}
                            min={0}
                        />
                    </div>
                    <div className='col-md-12 mb-5'>
                        <TextField
                            label={labelKey.expPolicyMaxLimit}
                            placeholder='Enter Expense Policy Amount'
                            type="number"
                            {...formik.getFieldProps('expPolicyMaxLimit')}
                            min={0}
                        />
                    </div>
                </div>
            </div>
            {/*end::Step 3 */}
        </>
    )
}

export default Step4