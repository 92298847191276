import React from 'react';
import Select from 'react-select';
import './TextField.css';

interface Option {
    value: string | number;
    label: string;
}

interface Props {
    labelClass?: string;
    leftLabel?: string;
    required?: boolean;
    label: string;
    selectClass?: string;
    onChange: (selectedOption: Option | null) => void;
    disabled?: boolean;
    onBlur?: () => void;
    value?: Option | null;
    name?: string;
    options: Option[];
    placeholder?: string;
}

const SearchSelect = ({
    labelClass,
    leftLabel,
    required,
    label,
    selectClass,
    onChange,
    disabled,
    name,
    onBlur,
    value,
    options,
    placeholder,
    ...fieldProps
}: Props) => {
    return (
        <div className={`${leftLabel ? 'd-md-flex w-100 gap-2' : ''}`}>
            <label className={`form-label fw-bold text-dark fs-6 text-nowrap ${labelClass}`}>
                {label}{leftLabel}
                {required && <span className="required-star text-danger ms-1">*</span>}
            </label>
            <Select
                className={`${selectClass}`}
                onChange={onChange}
                isDisabled={disabled}
                onBlur={onBlur}
                value={value}
                name={name}
                options={options}
                placeholder={placeholder}
                {...fieldProps}
            />
        </div>
    );
};

export default SearchSelect;
