import React from 'react'
import { PageTitle } from '../../../_metronic/layout/core'
import OrganizationPage from './OrganizationPage'
import labelKey from "../../localization/label.json";

const OrganizationPageWrapper = () => {
  return (
    <>
     <PageTitle breadcrumbs={[]}>{labelKey.organizationList}</PageTitle>
     <OrganizationPage/>
    </>
  )
}

export default OrganizationPageWrapper