import { useCallback, useEffect, useRef, useState } from 'react';
import { DownloadTableExcel } from "react-export-table-to-excel";
import { KTSVG } from '../../../_metronic/helpers';
import { useGetAllOrganizationQuery } from '../../../services/OrganizationApi';
import OrgDetailModal from './OrgDetailModal';
// import UpdateOrgModal from './UpdateOrgModal';
import { UpdateOrgModal } from './update-org-stepper/UpdateOrgModal';

import { debounce } from 'lodash';
import { FaArrowDown, FaArrowUp } from 'react-icons/fa';
import { useLocation } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import DateComp from '../../../components/dateComponent/DateComp';
import Pagination from '../../../components/pagination/Pagination';
import SearchBarComponent from '../../../components/searchBar/SearchBarComponent';
import SelectField from '../../../components/textFields/SelectField';
import { useGetOnboardingStatusLookupQuery } from '../../../services/GeneralApi';
import labelKey from "../../localization/label.json";
import UpdateOrgOnboardingModal from './UpdateOrgOnboardingModal';
import { CreateOrgModal } from './create-org-stepper/CreateOrgModal';
import NoRecordFound from '../../../components/noRecord/NoRecordFound';
import { LoadingComponent } from '../../../components/loadingComponent/LoadingComponent';

const OrganizationPage = () => {
    const location = useLocation();
    const [page, setPage] = useState(1)
    const [pageSize, setPageSize] = useState(10);
    const [searchStr, setSearchStr] = useState(''); // debounce search
    const [immediateSearchStr, setImmediateSearchStr] = useState('');
    const [sortBy, setSortBy] = useState("createDT");
    const [sortOrder, setSortOrder] = useState("desc");
    const [filterByStatus, setFilterByStatus] = useState("");
    const [filterByOnBoardStatus, setFilterByOnBoardStatus] = useState("");
    const [sortedColumn, setSortedColumn] = useState("createDT");
    const { data: getStatusLookup } = useGetOnboardingStatusLookupQuery('')

    const { data: getAllOrganizationData, isLoading: getAllOrganizationIsLoading, refetch: getAllOrganizationDataRefetch } = useGetAllOrganizationQuery({
        pageNumber: page,
        pageSize: pageSize,
        sortBy: sortBy,
        sortOrder: sortOrder,
        searchStr: searchStr,
        filterByStatus: filterByStatus,
        filterByOnBoardStatus: filterByOnBoardStatus,
    });
    const handlePageSizeChange = (e: any) => {
        const newSize = parseInt(e.target.value);
        setPageSize(newSize);
        setPage(1); // Reset page number to 1
    };
    const handleSearchChange = (e: any) => {
        const { value } = e.target;
        setImmediateSearchStr(value); // Update immediate search state

        delayedSearch(value); // Update debounced search state
    };

    const delayedSearch = useCallback(
        debounce((searchValue) => {
            setSearchStr(searchValue);
        }, 500),
        []
    );
    const handleOnboardingStatusChange = (e: any) => {
        setFilterByOnBoardStatus(e.target.value);
    };
    const handleOrgStatusChange = (e: any) => {
        setFilterByStatus(e.target.value);
    };

    // pagination
    const handlePageClick = (e: any) => {
        setPage(e.selected + 1);
    };
    const handleSort = (property: string) => {
        setSortBy(property);
        if (sortedColumn === property) {
            setSortOrder(prevSortOrder => (prevSortOrder === "asc" ? "desc" : "asc"));
        } else {
            setSortedColumn(property);
            setSortOrder("asc");
        }
        // Perform additional sorting logic...
    };
    //per page record
    const handlePageRecords = (e: any) => {
        setPage(1); // Reset the page to 1
    };
    useEffect(() => {
        if (filterByOnBoardStatus === "" || searchStr === "" || sortOrder === "" || sortBy === "") {
            getAllOrganizationDataRefetch();
        }
    }, [filterByOnBoardStatus, searchStr, sortOrder, sortBy]);
    const [showCreateOrgModal, setShowCreateOrgModal] = useState<boolean>(false)

    const [showOrgDetailModal, setShowUserOrgModal] = useState(false)
    const [orgDetailData, setOrgDetailData] = useState({});

    const orgDetailHandler = () => {
        setShowUserOrgModal((prev) => !prev);
    };
    const [showUpdateOrgModal, setShowUpdateOrgModal] = useState(false)
    const [editOrgData, setEditOrgData] = useState({});
    const editOrgHandler = () => {
        setShowUpdateOrgModal((prev) => !prev);
    };

    const [showUpdateOnboardingModal, setShowUpdateOnboardingModal] = useState(false)
    const [editOboardingData, setEditOnboardingData] = useState({});
    const editOnboardingHandler = () => {
        setShowUpdateOnboardingModal((prev) => !prev);
    };

    useEffect(() => {
        if (showUpdateOrgModal === false || showUpdateOnboardingModal === false || showCreateOrgModal === false || showOrgDetailModal === false) {
            getAllOrganizationDataRefetch();
        }
    }, [showUpdateOrgModal, showUpdateOnboardingModal, showCreateOrgModal, showOrgDetailModal])

    const tableRef = useRef(null);
    useEffect(() => {
        // Check the current route and set the selected value accordingly
        if (location.pathname === '/organization/active') {
            setFilterByOnBoardStatus('Active');
        }
        else if (location.pathname === '/organization/new') {
            setFilterByOnBoardStatus('New');
        }
        else if (location.pathname === '/organization/inprogress') {
            setFilterByOnBoardStatus('InProgress');
        }
        else {
            // Set the default value here if needed
            setFilterByOnBoardStatus('')
            setFilterByStatus('');
        }
    }, [location.pathname]);
    return (
        <>
            <CreateOrgModal show={showCreateOrgModal} handleClose={() => setShowCreateOrgModal(false)} />
            <OrgDetailModal show={showOrgDetailModal} handleClose={() => setShowUserOrgModal(false)} data={orgDetailData} />
            <UpdateOrgModal show={showUpdateOrgModal} handleClose={() => setShowUpdateOrgModal(false)} data={editOrgData} />
            <UpdateOrgOnboardingModal show={showUpdateOnboardingModal} handleClose={() => setShowUpdateOnboardingModal(false)} data={editOboardingData} />

            <div className='card mb-5'>
                <div className='card-header border-0 pt-6'>
                    <div className='card-title'>
                        <SearchBarComponent
                            placeholder='Organization'
                            value={immediateSearchStr}
                            onChange={handleSearchChange}
                        />
                    </div>
                    <div className='card-toolbar'>
                        <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
                            <div>
                                <button
                                    type='button'
                                    className='btn btn-light-primary me-3'
                                    data-kt-menu-trigger='click'
                                    data-kt-menu-placement='bottom-end'
                                >
                                    <KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-2' />
                                    {labelKey.filter}
                                </button>
                                <div className='menu menu-sub menu-sub-dropdown w-300px w-md-325px' data-kt-menu='true'>
                                    <div className='px-7 py-5'>
                                        <div className='fs-5 text-dark fw-bold'>{labelKey.filterOptions}</div>
                                    </div>

                                    <div className='separator border-gray-200'></div>

                                    <div className='px-7 pb-5' data-kt-user-table-filter='form'>
                                        <div>
                                            <label className='form-label fs-6 fw-bold mt-5'>{labelKey.orgStatusFilter}:</label>
                                            <select className='form-select form-select-solid fw-semi bold'
                                                value={filterByStatus}
                                                onChange={handleOrgStatusChange}>
                                                <option value="">{labelKey.selectStatus}</option>
                                                <option value="Active">{labelKey.active}</option>
                                                <option value="InActive">{labelKey.inActive}</option>
                                            </select>
                                        </div>
                                        <div>
                                            <SelectField
                                                label={`${labelKey.onboardingStatusFilter}:`}
                                                labelClass='mt-5'
                                                value={filterByOnBoardStatus}
                                                onChange={handleOnboardingStatusChange}
                                            >
                                                <option value="">{labelKey.selectStatus}</option>
                                                {getStatusLookup?.result?.map((option: any, index: any) => (
                                                    <option key={option?.id} value={option?.value}>{option?.value}</option>
                                                ))}
                                            </SelectField>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <DownloadTableExcel
                                filename="Organization table"
                                sheet="Organization"
                                currentTableRef={tableRef.current}
                            >
                                <button type='button' className='btn btn-light-primary me-3'>
                                    <KTSVG path='/media/icons/duotune/arrows/arr078.svg' className='svg-icon-2' />
                                    {labelKey.export}
                                </button>
                            </DownloadTableExcel>
                            <button type='button' className='btn btn-primary' onClick={() => setShowCreateOrgModal(true)}>
                                <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
                                {labelKey.addOnboarding}
                            </button>
                        </div>
                    </div>
                </div>
                <div className='card-body py-8'>
                    <div className="row w-100">
                        <div className="col-md-12">
                            <div className='table-responsive table-height'>
                                <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4' ref={tableRef}>
                                    <thead>
                                        <tr className='fw-bold text-muted'>
                                            <th className='min-w-40px text-center rounded-start'>SR</th>
                                            <th className='min-w-180px' onClick={() => handleSort("legalName")}>
                                                <div className='table-arrow'>
                                                    {labelKey.organizations}
                                                    {sortedColumn === "legalName" ? (
                                                        <>
                                                            {sortOrder === "desc" ? <FaArrowDown /> : <FaArrowUp />}
                                                        </>
                                                    ) :
                                                        ''
                                                    }
                                                </div>
                                            </th>
                                            <th className='min-w-120px' onClick={() => handleSort("contactPersonMobile")}>
                                                <div className='table-arrow'>
                                                    {labelKey.contactNo}
                                                    {sortedColumn === "contactPersonMobile" ? (
                                                        <>
                                                            {sortOrder === "desc" ? <FaArrowDown /> : <FaArrowUp />}
                                                        </>
                                                    ) :
                                                        ''
                                                    }
                                                </div>
                                            </th>
                                            <th className='min-w-120px' onClick={() => handleSort("fk_OnboardingStatusName")}>
                                                <div className='table-arrow'>
                                                    {labelKey.onboardingStatus}
                                                    {sortedColumn === "fk_OnboardingStatusName" ? (
                                                        <>
                                                            {sortOrder === "desc" ? <FaArrowDown /> : <FaArrowUp />}
                                                        </>
                                                    ) :
                                                        ''
                                                    }
                                                </div>
                                            </th>
                                            <th className='min-w-120px' onClick={() => handleSort("createDT")}>
                                                <div className='table-arrow'>
                                                    {labelKey.createdDate}
                                                    {sortedColumn === "createDT" ? (
                                                        <>
                                                            {sortOrder === "desc" ? <FaArrowDown /> : <FaArrowUp />}
                                                        </>
                                                    ) :
                                                        ''
                                                    }
                                                </div>
                                            </th>

                                            <th className='min-w-100px text-end rounded-end pe-4'>{labelKey.action}</th>
                                        </tr>
                                    </thead>
                                    <tbody >
                                        {!getAllOrganizationIsLoading &&
                                            <>
                                                {getAllOrganizationData?.result?.data?.length > 0 ? (
                                                    <>
                                                        {getAllOrganizationData?.result?.data?.map((data: any, index: any) => (
                                                            <tr key={index}>
                                                                <td className='text-center'>{(page - 1) * pageSize + index + 1}</td>
                                                                <td>
                                                                    <div className="d-flex align-items-center">
                                                                        <div className="symbol symbol-circle symbol-50px overflow-hidden me-3">
                                                                            <div className='mb-0'>
                                                                                <div className="symbol-label fs-3 bg-light-info text-info text-capitalize"
                                                                                >{data?.legalName.charAt(0)}</div></div>
                                                                        </div>
                                                                        <div className="d-flex flex-column">
                                                                            <p className="text-gray-800 fw-bold text-hover-primary mb-1 text-capitalize mb-0">{data?.legalName}</p>
                                                                            <span className='text-lowercase'>{data?.contactPersonEmail}</span>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td>{data?.contactPersonMobile}</td>
                                                                <td>{data?.fk_OnboardingStatusName ? data?.fk_OnboardingStatusName : "_"}</td>
                                                                <td><DateComp formattedDate={data.createDT} /></td>
                                                                <td>
                                                                    <div className='d-flex align-items-center justify-content-end gap-2' >
                                                                        <Tooltip id="onboarding" place="bottom" />
                                                                        <div
                                                                            data-tooltip-id="onboarding" data-tooltip-content={labelKey.updateOnboardingStatus}
                                                                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                                                            onClick={() => {
                                                                                setEditOnboardingData(data);
                                                                                editOnboardingHandler();
                                                                            }}
                                                                        >
                                                                            <KTSVG path='/media/icons/duotune/coding/cod001.svg' className='svg-icon-3' />
                                                                        </div>
                                                                        <Tooltip id="update-org" place="bottom" />
                                                                        <div
                                                                            data-tooltip-id="update-org" data-tooltip-content={labelKey.updateOrganization}
                                                                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                                                            onClick={() => {
                                                                                setEditOrgData(data);
                                                                                editOrgHandler();
                                                                            }}
                                                                        >
                                                                            <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
                                                                        </div>
                                                                        <Tooltip id="org-detail" place="bottom" />
                                                                        <div
                                                                            data-tooltip-id="org-detail" data-tooltip-content={labelKey.organizationDetail}
                                                                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                                                            onClick={() => {
                                                                                setOrgDetailData(data?.organizationID);
                                                                                orgDetailHandler();
                                                                            }}
                                                                        >
                                                                            <KTSVG
                                                                                path='/media/icons/duotune/arrows/arr064.svg'
                                                                                className='svg-icon-3'
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </>
                                                ) : (
                                                    <tr>
                                                        <td colSpan={7}>
                                                            <NoRecordFound />
                                                        </td>
                                                    </tr>
                                                )}
                                            </>
                                        }
                                        {getAllOrganizationIsLoading && (
                                            <tr>
                                                <td colSpan={7} className="text-center">
                                                    <LoadingComponent />
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                    {/* end::Table body */}
                                </table>
                                {/* end::Table */}
                            </div>
                            <Pagination
                                totalResult={getAllOrganizationData?.result?.totalRecords === 0 ? 0 : ((getAllOrganizationData?.result?.pageNumber - 1) * getAllOrganizationData?.result?.pageSize) + 1}
                                toResult={getAllOrganizationData?.result?.totalRecords === 0 ? 0 : Math.min(getAllOrganizationData?.result?.pageNumber * getAllOrganizationData?.result?.pageSize, getAllOrganizationData?.result?.totalRecords)}
                                ofResult={getAllOrganizationData?.result?.totalRecords}
                                onChange={handlePageSizeChange}
                                pageSize={pageSize}
                                onPageChange={handlePageClick}
                                pageCount={getAllOrganizationData?.result?.totalPages || 0}
                            />
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}

export default OrganizationPage