import React from 'react'
import { PageTitle } from '../../../../_metronic/layout/core'
import ProjectPage from './ProjectPage'

const ProjectsWrapper = () => {
    return (
        <>
            <PageTitle breadcrumbs={[]}>Projects</PageTitle>
            <ProjectPage />
        </>
    )
}

export default ProjectsWrapper