import React from 'react'
import LocationPage from './LocationPage'
import { PageTitle } from '../../../../_metronic/layout/core'
import labelKey from '../../../localization/label.json'

const LocationWrapper = () => {
    return (
        <>
            <PageTitle breadcrumbs={[]}>{labelKey.locations}</PageTitle>
            <LocationPage />
        </>
    )
}

export default LocationWrapper