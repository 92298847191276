import axios from 'axios'
import clsx from 'clsx'
import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import InputMask from 'react-input-mask'
import Select from 'react-select'
import { toast } from 'react-toastify'
import * as Yup from 'yup'
import { KTSVG } from '../../../_metronic/helpers'
import SelectField from '../../../components/textFields/SelectField'
import TextField from '../../../components/textFields/TextField'
import { constraintConfig } from '../../../constraintConfig'
import { selectAuth } from '../../../features/authSlice'
import { useAppSelector } from '../../../hooks'
import { useDepartmentsLookupQuery, useGetAllLocationTypeQuery, useGetAllLocationsQuery, useGetAllLst_UserExpenseTypeQuery, useGetAllOrganizationLookUpQuery } from '../../../services/GeneralApi'
import { useGetOrganizationAllRolesQuery } from '../../../services/OrganizationApi'
import { useGetAllManagerQuery, useUpdateUserMutation } from '../../../services/authApi'
import labelKey from "../../localization/label.json"
import tosterKey from "../../localization/toster.json"

type Props = {
    show: boolean
    handleClose: () => void
    data: any
}


const validationSchema = Yup.object().shape({
    email: Yup.string().email('Wrong email format').required('Email is required'),
    userRole: Yup.object().shape({
        roleID: Yup.number().min(1, 'Role is required').required('Role is required'),
    }),
    expenseType: Yup.object().shape({
        id: Yup.number().min(1, 'Expense Type is required').required('Expense Type is required'),
    }),
    locationType: Yup.object().shape({
        id: Yup.number().min(1, 'Location Type is required').required('Location Type is required'),
    }),
    mgrApproveMaxLimit: Yup.number()
        .when(['userRole.roleID'], {
            is: (roleID: any) => roleID === constraintConfig.roleID.role4,
            then: Yup.number()
                .typeError('Approval Limit is required')
                .transform((value, originalValue) => {
                    // Convert non-numeric values to 0 or keep the numeric value
                    return isNaN(originalValue) || originalValue === '' ? 0 : value;
                })
                .min(1, 'Approval Limit must be greater than 0')
                .required('Approval Limit is required'),
            otherwise: Yup.number().nullable(), // Allow null value for other roles
        }),
    // department: Yup.object().shape({
    //     id: Yup.number().min(1, 'Department is required').required('Department is required'),
    // }),
    // designation: Yup.object().shape({
    //     id: Yup.number().min(1, 'Designation is required').required('Designation is required'),
    // }),
    firstName: Yup.string().required('First Name is required'),
    lastName: Yup.string().required('Last Name is required'),
    // managerId: Yup.number().min(1, 'Manager is required').required('Manager is required'),
    // employeeId: Yup.string().nullable().required('Employee ID is required'),
    employeeId: Yup.string()
        .required('Employee ID is required')
        .min(1, 'Employee ID should not be empty')
        .test('is-unique', 'Employee ID should be unique', function (value) {
            if (value !== undefined && value !== null) {
                // Check if the value is not equal to '0' or doesn't consist only of '0's
                return value !== '0' && !/^0+$/.test(value);
            }
            return false; // Return false for undefined or null values
        }),
});
const initialValues = {
    active: true,
    orgId: 0,
    role: {
        roleID: 0,
        name: ''
    },
    expenseType: {
        id: '',
        value: ''
    },
    locationType: {
        id: 0,
        value: ''
    },
    location: {
        id: 0,
        value: ''
    },
    userAccountID: 0,
    employeeId: '',
    firstName: '',
    middleName: '',
    lastName: '',
    cellPhone: '',
    businessPhone: '',
    businessExt: '',
    email: '',
    mgrApproveMaxLimit: 0,
    department: {
        id: 0,
        title: ''
    },
    designation: {
        id: 0,
        title: ''
    },
    userRole: {
        fk_UserAccountID: 0,
        roleID: 0,
        roleName: ''
    },
    userOrg: {
        userInOrgId: 0,
        userInOrgName: ''
    },
    managerId: 0,
    managerName: '',
}

const UpdateUserModal = ({ show, handleClose, data }: Props) => {

    const { roleID, token, baseURL } = useAppSelector(selectAuth);
    const { data: getAllRolesData, refetch: rolesRefetch } = useGetOrganizationAllRolesQuery('');
    const { data: getAllManger, refetch: ManagersRefetch } = useGetAllManagerQuery('');
    const { data: departmentLookup, refetch: departmentRefetch } = useDepartmentsLookupQuery('');
    const { data: getAllOrganizationLookUp, refetch: orgLookUpRefetch } = useGetAllOrganizationLookUpQuery('');
    const { data: getAllExpenseType } = useGetAllLst_UserExpenseTypeQuery('')
    const { data: getAllLocationType } = useGetAllLocationTypeQuery('')
    const [updateContact, { isSuccess: updateIsSuccess, isError, error, isLoading: updateIsLoading }] = useUpdateUserMutation()
    const { data: locationsData, refetch: locationRefetch } = useGetAllLocationsQuery('')
    const [id, setId] = useState(0);
    const [formValues, setFormValues] = useState(initialValues);
    // Store form values when the modal is closed

    useEffect(() => {
        if (show) {
            orgLookUpRefetch()
            ManagersRefetch()
            departmentRefetch()
            locationRefetch()
            rolesRefetch()
        }
    }, [show, orgLookUpRefetch, ManagersRefetch, departmentRefetch, locationRefetch, rolesRefetch])

    const handleModalClose = () => {
        setFormValues(formik.values);
        handleClose();
    };
    useEffect(() => {
        // Restore form values when the modal reopens
        if (show && !data && Object.keys(formValues).length !== 0) {
            formik.setValues(formValues);
        }
        // Update form values based on data received
        if (data) {
            setId(data?.userAccountID);
            formik.setValues({
                ...data,
                orgId: data?.userOrg?.userInOrgId,
                userRole: {
                    fk_UserAccountID: data?.userAccountID,
                    roleID: data.userRole?.roleID,
                    roleName: data.userRole?.roleName
                }
            })
        } else if (!show) {
            // Reset form values only when closing and it's not an update action
            setId(0);
            formik.resetForm();
        }
    }, [show, data]);

    const formik = useFormik({
        initialValues,
        validationSchema: validationSchema,
        onSubmit: (values, { resetForm }) => {
            // const orgId = values?.userOrg?.userInOrgId
            if (roleID === constraintConfig.roleID.role1 || roleID === constraintConfig.roleID.role2) {
                if (String(values.userRole?.roleID) === String(constraintConfig.roleID?.role5) && !values.managerId) {
                    toast.error(tosterKey.managerIsRequired);
                }
                else {
                    updateContact(values)
                }
            }
            else {
                updateContact(values)
            }
        },
    })

    useEffect(() => {
        if (updateIsSuccess) {
            toast.success(tosterKey.userUpdatedSuccessfully);
            handleClose()
        }
    }, [updateIsSuccess]);

    useEffect(() => {
        if (isError && error) {
            let errorMessage: string = 'Something went wrong';

            if ('data' in error && error.data && typeof error.data === 'object' && 'message' in error.data) {
                errorMessage = (error.data as { message: string }).message;
            }
            toast.error(errorMessage);
        }
    }, [isError, error]);
    const [designationLookup, setDesignationLookup] = useState<any>(null);
    useEffect(() => {
        const fetchData = async () => {
            if (show && formik.values.userRole.roleID) {
                try {
                    const headers = {
                        Authorization: `Bearer ${token}`,
                    };
                    const getExpenseLogResponse = await axios.get(
                        `${baseURL}/api/General/GetAllDesignation?roleId=${formik?.values?.userRole?.roleID}`,
                        {
                            headers,
                        }
                    );
                    const designationLookup = getExpenseLogResponse.data;
                    setDesignationLookup(designationLookup);
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            }
        };
        fetchData();
    }, [show, formik?.values?.userRole?.roleID, token]);
    return (
        <>
            <Modal
                aria-hidden='true'
                dialogClassName='modal-dialog modal-lg modal-dialog-centered'
                show={show}
                onHide={handleModalClose}
                backdrop="static"
            >
                <div className='modal-header'>
                    <h2 className='mb-0'>{labelKey.updateExpenseUser}</h2>
                    <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
                        <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                    </div>
                </div>

                <form
                    className='form w-100'
                    onSubmit={formik.handleSubmit}
                >
                    <div className='modal-body py-lg-4 px-lg-10'>
                        <div className='row fv-row'>
                            <>
                                {roleID === constraintConfig.roleID.role1 &&
                                    <div className="col-md-12 mb-5">
                                        <label className='form-label fs-5 fw-bold required '>{labelKey.organization}</label>
                                        <Select
                                            options={(getAllOrganizationLookUp?.result || []).map((option: any) => ({
                                                value: option.id,
                                                label: option.value,
                                                key: option.id,
                                            }))}
                                            placeholder='Select Organization'
                                            value={
                                                formik.values.orgId
                                                    ? {
                                                        value: formik.values.orgId,
                                                        label: formik.values.userOrg.userInOrgName || '',
                                                    }
                                                    : null
                                            }
                                            onChange={(selectedOption: any) => {
                                                formik.setFieldValue('orgId', selectedOption.value);
                                                formik.setFieldValue('userOrg.userInOrgName', selectedOption.label);
                                            }}
                                        />
                                    </div>
                                }
                                {/* ${roleID === constraintConfig.roleID.role1 ? '6' : '12'} */}
                                <div className={`col-md-4 mb-5`}>
                                    <SelectField
                                        label={labelKey.role}
                                        required={true}
                                        selectClass={`${formik.touched.userRole?.roleID && formik.errors.userRole?.roleID ? 'is-invalid' : ''
                                            }`}
                                        {...formik.getFieldProps('userRole.roleID')}
                                    >
                                        <option value="">{labelKey.selectRole}</option>
                                        <>
                                            {roleID === constraintConfig.roleID.role1 && (
                                                <>
                                                    {getAllRolesData?.result.map((option: any, index: any) => {
                                                        if (option.value === "Administrator" && option.id === 1) {
                                                            return null; // Skip rendering the option for "Administrator" with id equal to 1
                                                        }
                                                        return <option key={index} value={option.id}>{option.value}</option>;
                                                    })}
                                                </>
                                            )}
                                            {roleID === constraintConfig.roleID.role2 && (
                                                <>
                                                    {getAllRolesData?.result.map((option: any, index: any) => {
                                                        if (option.value === "Administrator" && option.id === 1 || option.value === "Org Admin" && option.id === 2) {
                                                            return null; // Skip rendering the option for "Administrator" with id equal to 1
                                                        }
                                                        return <option key={index} value={option.id}>{option.value}</option>;
                                                    })}
                                                </>
                                            )}
                                            {roleID === constraintConfig.roleID.role4 && (
                                                <>
                                                    {getAllRolesData?.result.map((option: any, index: any) => {
                                                        if (option.value === "Administrator" && option.id === 1 || option.value === "Org Admin" && option.id === 2 || option.value === "Manager" && option.id === 4 || option.value === "Accounts Payable" && option.id === 10) {
                                                            return null; // Skip rendering the option for "Administrator" with id equal to 1
                                                        }
                                                        return <option key={index} value={option.id}>{option.value}</option>;
                                                    })}
                                                </>
                                            )}
                                        </>
                                    </SelectField>
                                    {formik.touched.userRole?.roleID && formik.errors.userRole?.roleID && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>
                                                <span role='alert'>{formik.errors.userRole?.roleID}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </>
                            <div className='col-md-4 mb-5'>
                                <SelectField
                                    label={labelKey.allowedExpenseType}
                                    required={true}
                                    {...formik.getFieldProps('expenseType.id')}
                                // value={formik.values.expenseType?.id}
                                >
                                    <option value="">Select Type</option>
                                    {getAllExpenseType?.result?.map((option: any, index: any) => (
                                        <option key={option.id} value={option.id}
                                        >{option?.value}</option>
                                    ))}
                                </SelectField>
                                {formik.touched.expenseType?.id && formik.errors.expenseType?.id && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                            <span role='alert'>{formik.errors.expenseType?.id}</span>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className='col-md-4 mb-5'>
                                <SelectField
                                    label={labelKey.allowedMileageType}
                                    required={true}
                                    {...formik.getFieldProps(`locationType.id`)}
                                // value={formik.values.locationType?.id}
                                >
                                    <option value="">Select Type</option>
                                    {getAllLocationType?.result?.map((option: any, index: any) => (
                                        <option key={option.id} value={option.id}
                                        >{option?.value}</option>
                                    ))}
                                </SelectField>
                                {formik.touched.locationType?.id && formik.errors.locationType?.id && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                            <span role='alert'>{formik.errors.locationType?.id}</span>
                                        </div>
                                    </div>
                                )}
                            </div>
                            {['4', '5'].includes(String(formik.values?.userRole?.roleID)) && (
                                <>
                                    {roleID !== constraintConfig.roleID.role5 && roleID !== constraintConfig.roleID.role4 && (
                                        <div className={`${String(formik.values?.userRole?.roleID) === String(constraintConfig.roleID.role5) ? 'col-md-12' : 'col-md-6'} mb-5`}>
                                            <label className={`${String(formik.values?.userRole?.roleID) === String(constraintConfig.roleID.role5) ? 'required' : ''} form-label fs-5 fw-bold`}>
                                                {labelKey.manager}&nbsp;
                                                {String(formik.values?.userRole?.roleID) === String(constraintConfig.roleID.role4) ? <span className='fs-8 text-gray'>(optional)</span> : ''}
                                            </label>
                                            <Select
                                                options={(getAllManger?.result || []).map((option: any) => ({
                                                    value: option.orgUserID,
                                                    label: option.firstName + (option.middleName ? ' ' + option.middleName : '') + ' ' + option.lastName,
                                                    key: option.id,
                                                }))}
                                                placeholder='Select Manager'
                                                value={
                                                    formik.values?.managerId
                                                        ? {
                                                            value: formik.values?.managerId,
                                                            label: formik.values?.managerName || '',
                                                        }
                                                        : null
                                                }
                                                onChange={(selectedOption: any) => {
                                                    formik.setFieldValue('managerId', selectedOption.value);
                                                    formik.setFieldValue('managerName', selectedOption.label);
                                                }}
                                            />
                                        </div>
                                    )}
                                </>
                            )}
                            {String(formik.values?.userRole?.roleID) === '4' && (
                                <div className="col-md-6 mb-5">
                                    <TextField
                                        label={labelKey.approval$Limit}
                                        iconTooltip="Current User Approval Limit"
                                        required={true}
                                        type='number'
                                        placeholder='Enter Approval Limit'
                                        labelClass={`${formik.touched.mgrApproveMaxLimit && formik.errors.mgrApproveMaxLimit ? 'is-invalid' : ''}`}
                                        {...formik.getFieldProps('mgrApproveMaxLimit')}
                                    />
                                    {formik.touched.mgrApproveMaxLimit && formik.errors.mgrApproveMaxLimit && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>
                                                <span role='alert'>{formik.errors.mgrApproveMaxLimit}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )}
                            <div className="col-md-4 mb-5">
                                <label className='form-label fs-5 fw-bold'>{labelKey.department}</label>
                                <Select
                                    options={(departmentLookup?.result || []).map((option: any) => ({
                                        value: option.id,
                                        label: option.value,
                                        key: option.id,
                                    }))}
                                    placeholder='Select Department'
                                    value={
                                        formik.values.department?.id
                                            ? {
                                                value: formik.values.department?.id,
                                                label: formik.values.department?.title || '',
                                            }
                                            : null
                                    }
                                    onChange={(selectedOption: any) => {
                                        formik.setFieldValue('department.id', selectedOption.value);
                                        formik.setFieldValue('department.title', selectedOption.label);
                                    }}
                                />
                                {/* {formik.touched.department?.id && formik.errors.department?.id && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                            <span role='alert'>{formik.errors.department?.id}</span>
                                        </div>
                                    </div>
                                )} */}
                            </div>
                            <div className="col-md-4 mb-5">
                                <label className='form-label fs-5 fw-bold'>{labelKey.designation}</label>
                                <Select
                                    options={(designationLookup?.result || []).map((option: any) => ({
                                        value: option.id,
                                        label: option.value,
                                        key: option.id,
                                    }))}
                                    placeholder='Select Designation'
                                    value={
                                        formik.values.designation?.id
                                            ? {
                                                value: formik.values.designation?.id,
                                                label: formik.values.designation?.title || '',
                                            }
                                            : null
                                    }
                                    onChange={(selectedOption: any) => {
                                        formik.setFieldValue('designation.id', selectedOption.value);
                                        formik.setFieldValue('designation.title', selectedOption.label);
                                    }}
                                />
                                {/* {formik.touched.designation?.id && formik.errors.designation?.id && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                            <span role='alert'>{formik.errors.designation?.id}</span>
                                        </div>
                                    </div>
                                )} */}
                            </div>
                            <div className='col-md-4 b-5'>
                                <label className='fs-5 fw-bold mb-2 required'>
                                    {labelKey.location}
                                </label>
                                <Select
                                    options={(locationsData?.result || []).map((option: any, index: any) => ({
                                        value: option.id,
                                        label: option?.value,
                                        key: option.id,
                                    }))}
                                    value={
                                        formik.values.location?.id
                                            ? {
                                                value: formik.values.location?.id,
                                                label: formik.values.location?.value || '',
                                            }
                                            : null
                                    }
                                    onChange={(selectedOption: any) => {
                                        formik.setFieldValue(`location.id`, selectedOption?.value || null);
                                        formik.setFieldValue(`location.value`, selectedOption?.label || null);
                                    }}
                                />

                            </div>
                            <div className="col-md-6 mb-5">
                                <TextField
                                    label={labelKey.email}
                                    required={true}
                                    disabled={true}
                                    type='email'
                                    placeholder='Enter Email'
                                    labelClass={`${formik.touched.email && formik.errors.email ? 'is-invalid' : ''}`}
                                    {...formik.getFieldProps('email')}
                                />
                                {formik.touched.email && formik.errors.email && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                            <span role='alert'>{formik.errors.email}</span>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className="col-md-6">
                                <TextField
                                    label={labelKey.employeeId}
                                    required={true}
                                    disabled={true}
                                    type='text'
                                    placeholder='Enter Employee ID'
                                    labelClass={`${formik.touched.employeeId && formik.errors.employeeId ? 'is-invalid' : ''}`}
                                    {...formik.getFieldProps('employeeId')}
                                />
                                {formik.touched.employeeId && formik.errors.employeeId && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                            <span role='alert'>{formik.errors.employeeId}</span>
                                        </div>
                                    </div>
                                )}
                            </div>



                            <div className='col-lg-4 mb-5'>
                                <TextField
                                    label={labelKey.firstName}
                                    required={true}
                                    type='text'
                                    placeholder='Enter First Name'
                                    labelClass={`${formik.touched.firstName && formik.errors.firstName ? 'is-invalid' : ''}`}
                                    {...formik.getFieldProps('firstName')}
                                />
                                {formik.touched.firstName && formik.errors.firstName && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                            <span role='alert'>{formik.errors.firstName}</span>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className='col-lg-4 mb-5'>
                                <TextField
                                    label={labelKey.middleName}
                                    type='text'
                                    placeholder='Enter Middle Name'
                                    {...formik.getFieldProps('middleName')}
                                />
                            </div>
                            <div className='col-lg-4'>
                                <div className='fv-row mb-5'>
                                    <TextField
                                        label={labelKey.lastName}
                                        required={true}
                                        type='text'
                                        placeholder='Enter Last Name'
                                        labelClass={`${formik.touched.lastName && formik.errors.lastName ? 'is-invalid' : ''}`}
                                        {...formik.getFieldProps('lastName')}
                                    />
                                    {formik.touched.lastName && formik.errors.lastName && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>
                                                <span role='alert'>{formik.errors.lastName}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className='col-lg-4'>
                                <div className='fv-row mb-5'>
                                    <label className='form-label fs-5 fw-bold'>{labelKey.cellPhone}</label>
                                    <InputMask
                                        mask="(999) 999-9999"
                                        placeholder='Cell Phone'
                                        type='text'
                                        autoComplete='off'
                                        {...formik.getFieldProps('cellPhone')}
                                        value={formik.values.cellPhone || ''}
                                        className={clsx('form-control form-control-lg form-control-solid default-input')}
                                    />
                                </div>
                            </div>
                            <div className='col-lg-4'>
                                <div className='fv-row'>
                                    <label className='form-label fs-5 fw-bold'>{labelKey.businessPhone}</label>
                                    <InputMask
                                        mask="(999) 999-9999"
                                        placeholder='Business Phone'
                                        type='text'
                                        autoComplete='off'
                                        {...formik.getFieldProps('businessPhone')}
                                        value={formik.values.businessPhone || ''}
                                        className={clsx('form-control form-control-lg form-control-solid default-input')}
                                    />
                                </div>
                            </div>
                            <div className='col-lg-4'>
                                <div className='fv-row'>
                                    <label className='form-label fs-5 fw-bold'>{labelKey.businessExt}</label>
                                    <InputMask
                                        mask="9999"
                                        placeholder='Business Ext'
                                        type='text'
                                        autoComplete='off'
                                        {...formik.getFieldProps('businessExt')}
                                        value={formik.values.businessExt || ''}
                                        className='form-control form-control-lg form-control-solid default-input'
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='modal-footer justify-content-center'>
                        <button
                            type='button'
                            className='btn btn-lg btn-light'
                            onClick={handleClose}
                        >
                            {labelKey.cancel}
                        </button>
                        <button
                            type='submit'
                            className='btn btn-lg btn-primary'
                            disabled={updateIsLoading}
                        >
                            {!updateIsLoading && <span className='indicator-label'>{labelKey.update}</span>}
                            {updateIsLoading && (
                                <span className='indicator-progress' style={{ display: 'block' }}>
                                    {labelKey.pleaseWait}...{' '}
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                            )}
                        </button>
                    </div>
                </form>
            </Modal>
        </>
    )
}

export default UpdateUserModal