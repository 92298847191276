import React, { useEffect, useState } from 'react'
import { KTSVG } from '../../../_metronic/helpers'
import { Modal } from 'react-bootstrap'
import { useUpdateOrganizationRoleMutation } from '../../../services/OrganizationApi'
import Button from '../../../components/button/Button'
import { toast } from 'react-toastify'
import { useFormik } from 'formik'
import labelKey from "../../localization/label.json";
import tosterKey from "../../localization/toster.json";
import TextField from '../../../components/textFields/TextField'

type Props = {
    show: boolean
    handleClose: () => void
    data: any
}

const initialValues = {
    roleID: '',
    name: '',

}
const UpdateRoleModal = ({ show, handleClose, data }: Props) => {

    const [updateRole, { isLoading, isSuccess, isError, error }] = useUpdateOrganizationRoleMutation()

    const [id, setId] = useState(0);

    useEffect(() => {
        if (data) {
            setId(data?.id);
            // formik.setValues(data)
            formik.setValues({
                ...initialValues,
                roleID: data?.id,
                name: data?.value,
            });
        }
    }, [data]);
    const formik = useFormik({
        initialValues,
        onSubmit: (values, { resetForm }) => {

            updateRole({ ...values })
        },
    })


    useEffect(() => {

        if (isSuccess) {
            toast.success(tosterKey.roleUpdatedSuccessfully);
            handleClose();
        }
    }, [isSuccess]);


    useEffect(() => {
        if (isError && error) {
            let errorMessage: string = 'Something went wrong';

            if ('data' in error && error.data && typeof error.data === 'object' && 'message' in error.data) {
                errorMessage = (error.data as { message: string }).message;
            }
            toast.error(errorMessage);
        }
    }, [isError, error]);
    return (
        <>
            <Modal
                aria-hidden='true'
                dialogClassName='modal-dialog modal-md modal-dialog-centered'
                show={show}
                onHide={handleClose}
            >
                <div className='modal-header'>
                    <h2 className='mb-0'>{labelKey.updateRole}</h2>
                    {/* begin::Close */}
                    <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
                        <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                    </div>
                    {/* end::Close */}
                </div>

                <form
                    className='form w-100'
                    onSubmit={formik.handleSubmit}
                >
                    <div className='modal-body py-lg-10 px-lg-10'>
                        <div className='col-xl-12'>
                            <TextField
                                label={labelKey.role}
                                required={true}
                                type='text'
                                placeholder='Enter Role Name'
                                {...formik.getFieldProps('name')}
                            />
                        </div>
                    </div>

                    <div className='modal-footer justify-content-center'>
                        <Button label='Cancel' className='btn btn-light' onClick={handleClose} />
                        <button
                            type='submit'
                            id='kt_sign_up_submit'
                            className='btn btn-lg btn-primary mb-5 mt-5'
                            disabled={isLoading}
                        >
                            {!isLoading && <span className='indicator-label'>{labelKey.update}</span>}
                            {isLoading && (
                                <span className='indicator-progress' style={{ display: 'block' }}>
                                    {labelKey.pleaseWait}...{' '}
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                            )}
                        </button>
                    </div>
                </form>
            </Modal>
        </>
    )
}

export default UpdateRoleModal