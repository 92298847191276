import React from 'react'
import CurrencyComp from '../../../components/currencyComponent/CurrencyComp';
import ReactApexChart from 'react-apexcharts';
import labelKey from "../../localization/label.json"
import { Tooltip } from 'react-tooltip';
import { FaInfoCircle } from 'react-icons/fa';


type props = {
    data: any
}
const ExpensesAmountChart = ({ data }: props) => {
    const pieChartAmountData = {
        series: [
            data?.result?.totalPendingExpenseAmount,
            data?.result?.totalApprovedExpenseAmount,
            data?.result?.totalApApprovedExpenseAmount,
            // data?.result?.totalRejectedExpenseAmount,
            // data?.result?.totalClarificationExpenseAmount,
            // data?.result?.totalDraftExpenseAmount,
        ],
        options: {
            labels: [
                'Total pending for approval Expense',
                'Total Manager Approved Expense',
                'Total AP Approved Expense',
                // 'Total Rejected Expense',
                // 'Total Clarification Expense',
                // 'Total Draft Expense',
            ],

            legend: {
                show: true,
                // position: 'right' as 'right',
            },
            colors: ['#7239EA', '#50CD89', '#50CD89'],
            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                        width: 250
                    },
                    legend: {
                        position: 'bottom',
                        offsetY: 50,
                    }
                }
            }],
            tooltip: {
                y: {
                    // formatter: function (value: any) {
                    //     return `$${value.toLocaleString('en-US')}`;
                    // }
                    formatter: function (value: any) {
                        return `$${value.toLocaleString('en-US', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                        })}`;
                    },
                }
            },
        }
    };
    const isDataAvailable = data && data.result;
    return (
        <>
            <Tooltip id="chart" place="top" />
            <div className="col-md-12 col-lg-6 mt-5 mb-5 mb-xl-10 h-100">
                <div className='p-9 h-100' style={{ boxShadow: "0px 1px 4px 0px #d6e1e6", borderRadius: "10px" }}>
                    <h3>{labelKey.expenseByStatus}
                        <FaInfoCircle className='text-gray cursor-pointer ms-2'
                            data-tooltip-id="chart" data-tooltip-content='The chart display data by default for the last 7 days..' />
                    </h3>
                    {data?.result?.totalPendingExpenseAmount === 0 &&
                        data?.result?.totalApApprovedExpenseAmount === 0 &&
                        data?.result?.totalApprovedExpenseAmount === 0  ? (
                        <p className='text-center mt-5'>No data available for the chart.</p>
                    ) : (
                        <>
                            <div className="d-lg-flex align-items-center gap-3">
                                <div className='d-flex align-items-center gap-2'>
                                    <p className='fs-5 fw-bold'>{labelKey.totalExpenses}:</p>
                                    <p className='fs-5 fw-semibold'>{data?.result?.totalExpenseCount?.toLocaleString('en-US')}</p>
                                </div>
                                <div className='d-flex align-items-center gap-2'>
                                    <p className='fs-5 fw-bold'>{labelKey.totalExpenseAmount}:</p>
                                    <p className='fs-5 fw-semibold'>
                                        <CurrencyComp amount={data?.result?.totalExpenseAmount} />
                                    </p>
                                </div>
                            </div>
                            {isDataAvailable && (
                                <div className='d-flex d-md-inline justify-content-center'>
                                    <ReactApexChart options={pieChartAmountData?.options} series={pieChartAmountData.series} type="pie" height={300} />
                                </div>
                            )}
                        </>
                    )}

                </div>
            </div>
        </>
    )
}

export default ExpensesAmountChart