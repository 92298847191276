import labelKey from "../../localization/label.json";
import AdminManagerPieChart from './AdminManagerPieChart';
import AdminUserExpensesChart from './AdminUserExpensesChart';
import AdminUsersChart from './AdminUsersChart';
import MixedStatsWidget from './MixedStatsWidget';

const DashboardByAdmin = (getAdminDashboard: any) => {
    return (
        <>
            <div className="row">
                <div className="col-md-6 col-lg-4 mb-5 mb-xl-10">
                    <MixedStatsWidget className='card-xl-stretch'
                        title={labelKey.managers}
                        toState1='/expense-users/managers'
                        toState2='/active/managers'
                        titleIcon='/media/figma-download/managersWhite.svg'
                        statImg1='/media/figma-download/allManagers.svg'
                        statTitle1={labelKey.allManagers}
                        statsNum1={`${getAdminDashboard?.getAdminDashboard?.result?.allManager}`}
                        statImg2='/media/figma-download/activeManagers.svg'
                        statTitle2={labelKey.activeManagers}
                        statsNum2={`${getAdminDashboard?.getAdminDashboard?.result?.activeManager}`}
                    >
                        <AdminManagerPieChart getAdminDashboard={getAdminDashboard?.getAdminDashboard} />
                    </MixedStatsWidget>
                </div>
                <div className="col-md-6 col-lg-4 mb-5 mb-xl-10">
                    <MixedStatsWidget className='card-xl-stretch'
                        title={labelKey.users}
                        toState1='/all/expense-users'
                        toState2='/active/expense-users'
                        titleIcon='/media/figma-download/users.svg'
                        statImg1='/media/figma-download/allUsers.svg'
                        statTitle1={labelKey.allUsers}
                        statsNum1={`${getAdminDashboard?.getAdminDashboard?.result?.allUser}`}
                        statImg2='/media/figma-download/activeUsers.svg'
                        statTitle2={labelKey.activeUsers}
                        statsNum2={`${getAdminDashboard?.getAdminDashboard?.result?.activeUser}`}
                    >
                        <AdminUsersChart getAdminDashboard={getAdminDashboard?.getAdminDashboard} />
                    </MixedStatsWidget>
                </div>
                <div className="col-md-6 col-lg-4 mb-5 mb-xl-10">
                    <MixedStatsWidget className='card-xl-stretch'
                        title={labelKey.userExpenses}
                        toState1='/expense-history'
                        titleIcon='/media/figma-download/userExpenses.svg'
                        statImg1='/media/figma-download/allExpenses.svg'
                        statTitle1={labelKey.numberOfExpenses}
                        statsNum1={`${getAdminDashboard?.getAdminDashboard?.result?.allExpenses}`}
                        statImg2='/media/figma-download/$.svg'
                        statTitle2={labelKey.expensesAmount}
                        statsNum2={`$${getAdminDashboard?.getAdminDashboard?.result?.expenseAmount}`}
                    >
                        <AdminUserExpensesChart getAdminDashboard={getAdminDashboard?.getAdminDashboard} />
                    </MixedStatsWidget>
                </div>
            </div>
        </>
    )
}

export default DashboardByAdmin