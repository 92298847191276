import React from 'react'
interface IPROPS {
    amount?: string,
}
const CurrencyComp = ({ amount }: IPROPS) => {
    // const formattedAmount = amount ? parseFloat(amount).toLocaleString('en-US') : '';
    const formattedAmount = amount !== undefined && amount !== null
        ? parseFloat(amount).toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2, // Show at least 2 decimal places
            maximumFractionDigits: 2, // Show at most 2 decimal places
        })
        : '';

    return (
        <>
            {formattedAmount}
        </>
    )
}

export default CurrencyComp