import React from 'react'
import ExpenseSummary from './ExpenseSummary'
import ExpenseDetail from './ExpenseDetail'
import ExpenseByCategory from './ExpenseByCategory'
import ExpenseByPayment from './ExpenseByPayment'
import { Col, Nav, Row, Tab } from 'react-bootstrap';
import labelKey from "../../localization/label.json"

const ReportAnalyticsPage = () => {

    return (
        <>

            <div className='card'>
                {/* <div className='card-body pb-0' id='expenseDetail'> */}
                    <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                        <Row>
                            <Col sm={12}>
                                <Nav variant="pills" className='px-9 py-3' style={{background:"#f1faff",borderTopLeftRadius:'6px',borderTopRightRadius:'6px'}}>
                                    <Nav.Item>
                                        <Nav.Link eventKey="first">{labelKey.expenseSummary}</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="second">{labelKey.expenseDetail}</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="third">{labelKey.expenseByCategory}</Nav.Link>
                                    </Nav.Item>
                                    {/* <Nav.Item>
                                        <Nav.Link eventKey="fourth">{labelKey.expenseByPayment}</Nav.Link>
                                    </Nav.Item> */}
                                </Nav>
                            </Col>
                            <Col sm={12}>
                                <Tab.Content>
                                    <Tab.Pane eventKey="first">
                                        <ExpenseSummary />
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="second">
                                        <ExpenseDetail />
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="third">
                                        <ExpenseByCategory />
                                    </Tab.Pane>
                                    {/* <Tab.Pane eventKey="fourth">
                                        <ExpenseByPayment />
                                    </Tab.Pane> */}
                                </Tab.Content>
                            </Col>
                        </Row>
                    </Tab.Container>
                {/* </div> */}
            </div>
        </>
    )
}

export default ReportAnalyticsPage