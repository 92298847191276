
const BGBlur = () => {
    return (
        <div className='w-100 h-100 position-absolute top-0 left-0' style={{left:0, zIndex: 15, backgroundColor: '#f2f2f280', backdropFilter: 'blur(10px)' }}>
            <h1 className='w-100 h-100 d-flex align-items-center justify-content-center'>
                Please Wait... &nbsp;
                <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </h1>
        </div>
    )
}

export default BGBlur