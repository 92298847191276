import ReactApexChart from 'react-apexcharts';

const AdminUserExpensesChart = ({ getAdminDashboard }: any) => {

  // Extract data from the API response
  const allExpenses = getAdminDashboard?.result?.allExpenses || 0;
  const expenseAmount = getAdminDashboard?.result?.expenseAmount || 0;

  // Chart options
  const pieChartOptions: ApexCharts.ApexOptions = {
    chart: {
      type: 'donut' as 'donut',
    },
    labels: ['All Expenses', 'Expenses Amount'],
    colors: ['#009EF7', '#F1416C'],
    legend: {
      show: true,
      position: 'bottom',
      horizontalAlign: 'center',
      offsetX: 10,
      offsetY: 0,
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 250,
          },
          legend: {
            position: 'bottom',
            horizontalAlign: 'center',
            offsetX: 0,
            offsetY: 0,
          },
        },
      },
    ],
    // tooltip: {
    //   y: {
    //     formatter: function (value: any) {
    //       return value.toLocaleString('en-US');
    //     },
    //   },
    // },
    tooltip: {
      y: [{},
          {
              formatter: function (value: any) {
                  return `$${value.toLocaleString('en-US')}`;
              },
          },
      ],
  },
  };

  // Chart series data
  const pieChartSeries = [allExpenses, expenseAmount];

  return (
    <div className='d-flex justify-content-center w-100'>
      {allExpenses === 0 && expenseAmount === 0 ? (
        <p className='mt-15'>Chart does not have any values to display.</p>
      ) : (
        <div className='legend-center'>
          <ReactApexChart options={pieChartOptions} series={pieChartSeries} type="donut" height={300} />
        </div>
      )}
    </div>
  )
}

export default AdminUserExpensesChart